<template>
  <v-app>
    <v-navigation-drawer app
                         clipped
                         color="secondary"
                         class="no-print"
                         dark
                         v-bind:mini-variant.sync="miniVariant"
                         v-bind:expand-on-hover="$vuetify.breakpoint.lgAndUp"
                         v-model="navigationDrawer"
                         v-if="!isRestricted">
      <router-view name="navigation" />
    </v-navigation-drawer>

    <v-app-bar app
               clipped-left
               color="secondary"
               class="no-print"
               dark
               flat
               v-bind:clipped-right="hasSecondaryNavigation">
      <v-app-bar-nav-icon v-if="$vuetify.breakpoint.mdAndDown && !isRestricted"
                          v-on:click="navigationDrawer = !navigationDrawer" />

      <v-toolbar-title>
        <span class="font-weight-bold accent--text">SAGASSER</span>
        Sales Portal
      </v-toolbar-title>

      <v-spacer />
      <template v-if="!isRestricted">
        <locale-block v-bind:locales="locales"
                      v-on:set-locale="setLocale" />

        <account-block v-bind:display-name="displayName"
                      v-bind:email="email"
                      v-bind:is-authenticated="isAuthenticated"
                      v-bind:picture="picture"
                      v-on:sign-in="signIn"
                      v-on:sign-out="signOut">
        </account-block>
      </template>
    </v-app-bar>

    <v-main class="main">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import locales from '@/locales.json'

  import AccountBlock from '@/components/common/AccountBlock'
  import LocaleBlock from '@/components/common/LocaleBlock'

  export default {
    components: {
      AccountBlock,
      LocaleBlock
    },
    computed: {
      isRestricted() {
        const matched = this.$route.matched

        if (!matched[0] || !matched[0].meta) return false

        return matched[0].meta.isRestricted === true
      },
      hasSecondaryNavigation() {
        const matched = this.$route.matched

        if (!matched[0] || !matched[0].meta) return false

        return matched[0].meta.hasSecondaryNavigation === true
      },
      locales() {
        return locales
      },
      ...mapGetters({
        displayName: 'auth/displayName',
        email: 'auth/email',
        hasRole: 'auth/hasRole',
        isAuthenticated: 'auth/isAuthenticated',
        picture: 'auth/picture'
      })
    },
    data: () => ({
      miniVariant: null,
      navigationDrawer: true
    }),
    methods: {
      setLocale({ localeId }) {
        this.$root.$i18n.locale = localeId
      },
      updateUi() {
        this.miniVariant = this.$vuetify.breakpoint.lgAndUp
        this.navigationDrawer = this.$vuetify.breakpoint.lgAndUp
      },
      ...mapActions({
        signIn: 'auth/signIn',
        signOut: 'auth/signOut'
      })
    },
    mounted() {
      this.updateUi()
    },
    watch: {
      '$vuetify.breakpoint.lgAndUp'() {
        this.updateUi()
      }
    },
    name: 'App'
  }
</script>

<style>
  @media only screen {
    .main {
      background-color: #eee;
    }
  }

  @media print
  {


    .no-print, .no-print *
    {
      display: none !important;
    }

    .main
    {
      padding: 0 !important;
    }
  }
</style>