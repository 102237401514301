<template>
  <div>
    <top-header v-bind:title="title | defaultValue($t('exam'))">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'learning-my-exams', params: { invitationKey } }" />
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-alert tile
             type="error"
             v-if="exam != null && isActive && examTrainee.correctQuestions != null && !examTrainee.hasPassed">
      {{ $t('thankYouForYourParticipationYouAnsweredOutOfQuestionsCorrectlyAndThereforeUnfortunatelyFailed', { questionCount: examTrainee.correctQuestions, totalQuestionsCount: exam.subject.examQuestionCount }) }}
      <span v-if="!examTrainee.usedSecondAttempt">{{ $t('youCanTryAgainHoweverThisIsYourLastAttempt') }}</span>
    </v-alert>

    <v-alert tile
             type="success"
             v-if="exam != null && isActive && examTrainee.correctQuestions != null && examTrainee.hasPassed">
      {{ $t('thankYouForYourParticipationYouAnsweredOutOfQuestionsCorrectlyAndThusPassed', { questionCount: examTrainee.correctQuestions, totalQuestionsCount: exam.subject.examQuestionCount }) }}
    </v-alert>

    <v-alert tile
             type="error"
             v-if="exam != null && !isActive">
      {{ $t('theExamPeriodIsBetweenAnd', { start: formatDate(exam.start), end: formatDate(exam.end) }) }}
    </v-alert>

    <v-container v-if="exam != null">
      <my-exam-card v-bind:allow-questionnaire="allowQuestionnaire"
                    v-bind:exam="exam"
                    v-bind:invitationKey="invitationKey" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import MyExamCard from '@/components/learning/MyExamCard.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const invitationKey = to.params.invitationKey
      const id = parseInt(to.params.id)

      await this.get(invitationKey, id)

      next()
    },
    components: {
      HeaderButton,
      MyExamCard,
      TopHeader
    },
    computed: {
      allowQuestionnaire() {
        if (!this.isActive) {
          return false
        }

        if (this.examTrainee.correctQuestions != null && this.examTrainee.hasPassed) {
          return false
        }

        if (this.examTrainee.correctQuestions != null && this.examTrainee.usedSecondAttempt) {
          return false
        }

        return true
      },
      examTrainee() {
        return this.exam?.trainees.find(t => t.trainee.invitationKey === this.invitationKey)
      },
      id() {
        return this.exam?.id
      },
      isActive() {
        return this.exam.isActive || this.examTrainee.allowGracePeriod
      },
      title() {
        return this.exam?.title
      },
      ...mapGetters({
        getExam: 'learning/exam'
      })
    },
    async created() {
      const invitationKey = this.$route.params.invitationKey
      const id = parseInt(this.$route.params.id)

      await this.get(invitationKey, id)
    },
    data: () => ({
      exam: null,
      invitationKey: null,
      loading: false
    }),
    methods: {
      formatDate(date) {
        return this.$options.filters.formatDate(date);
      },
      async get(invitationKey, id) {
        this.invitationKey = invitationKey

        this.loading = true

        await this.loadExamsForInvitationKey({ invitationKey })

        this.loading = false

        this.exam = Object.assign({}, this.getExam(id))
      },
      ...mapActions({
        loadExamsForInvitationKey: 'learning/loadExamsForInvitationKey'
      })
    },
    name: 'MyExam'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "exam": "Prüfung",
      "thankYouForYourParticipationYouAnsweredOutOfQuestionsCorrectlyAndThereforeUnfortunatelyFailed": "Vielen Dank für Ihre Teilnahme! Sie haben {questionCount} von {totalQuestionsCount} Fragen korrekt beantwortet und somit leider nicht bestanden.",
      "thankYouForYourParticipationYouAnsweredOutOfQuestionsCorrectlyAndThusPassed": "Vielen Dank für Ihre Teilnahme! Sie haben {questionCount} von {totalQuestionsCount} Fragen korrekt beantwortet und somit bestanden.",
      "theExamPeriodIsBetweenAnd": "Der Prüfungszeitraum liegt zwischen dem {start} und dem {end}.",
      "youCanTryAgainHoweverThisIsYourLastAttempt": "Sie können es noch einmal erneut versuchen, jedoch ist dies Ihr letzter Versuch."
    },
    "en": {
      "back": "Back",
      "exam": "Exam",
      "thankYouForYourParticipationYouAnsweredOutOfQuestionsCorrectlyAndThereforeUnfortunatelyFailed": "Thank you for your participation! You answered {questionCount} out of {totalQuestionsCount} questions correctly and therefore unfortunately failed",
      "thankYouForYourParticipationYouAnsweredOutOfQuestionsCorrectlyAndThusPassed": "Thank you for your participation! You answered {questionCount} out of {totalQuestionsCount} questions correctly and thus passed.",
      "theExamPeriodIsBetweenAnd": "The exam period is between {start} and {end}.",
      "youCanTryAgainHoweverThisIsYourLastAttempt": "You can try again, however this is your last attempt."
    }
  }
</i18n>