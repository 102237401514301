<template>
  <div v-if="question != null">
    <v-dialog v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="1000">
      <v-card>
        <v-toolbar color="secondary"
                   dark
                   flat>
          <v-toolbar-title>{{ $t('question') }}</v-toolbar-title>
        </v-toolbar>
        <v-form ref="form">
          <v-card-text class="pt-5">
            <multilingual-textarea filled
                                   hide-details="auto"
                                   v-bind:label="$t('text')"
                                   v-bind:rules="[ rules.required ]"
                                   v-model="question.text" />
          </v-card-text>
        </v-form>
        <v-divider />
        <v-row align="center"
               no-gutters>
          <v-col cols="auto">
            <v-checkbox class="ml-4 mr-3 my-0 shrink"
                        hide-details
                        v-model="newAnswerIsCorrect" />
          </v-col>
          <v-col>
            <multilingual-text-field flat
                                     hide-details="auto"
                                     ref="newAnswerTextField"
                                     solo
                                     v-bind:label="$t('addAnswer')"
                                     v-model="newAnswerText" />
          </v-col>
          <v-col cols="auto">
            <v-btn block
                   class="v-btn--search"
                   color="accent"
                   dark
                   depressed
                   large
                   tile
                   v-on:click="addAnswer">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-divider v-if="question.answers.length > 0" />
        <v-list-item v-bind:key="index"
                     v-for="(answer, index) in question.answers">
          <v-list-item-action>
            <v-checkbox v-model="answer.isCorrect" />
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ answer.text | localizedText }}</v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <delete-confirmation-button v-bind:item="{ index }"
                                        v-on:delete="deleteAnswer($event.index)" />
          </v-list-item-action>
        </v-list-item>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary"
                 text
                 v-on:click="cancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="accent"
                 text
                 v-on:click="confirm">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import DeleteConfirmationButton from '@/components/common/DeleteConfirmationButton.vue'
  import MultilingualTextField from '@/components/learning/MultilingualTextField.vue'
  import MultilingualTextarea from '@/components/learning/MultilingualTextarea.vue'

  export default {
    components: {
      DeleteConfirmationButton,
      MultilingualTextField,
      MultilingualTextarea
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    data: () => ({
      newAnswerIsCorrect: false,
      newAnswerText: null,
      resolve: null
    }),
    methods: {
      addAnswer() {
        if (!this.$refs.newAnswerTextField.validate()) {
          return
        }

        const answer = {
          text: this.newAnswerText,
          isCorrect: this.newAnswerIsCorrect,
          questionId: this.question.id
        }

        this.question.answers.push(answer)

        this.newAnswerIsCorrect = false
        this.newAnswerText = null
      },
      cancel() {
        this.$emit('input', false)
        this.resolve(false)
      },
      confirm() {
        if (!this.$refs.form.validate()) {
          return
        }

        this.$emit('input', false)
        this.$emit('confirm')
        this.resolve(this.question)
      },
      async open(resolve) {
        this.resolve = resolve
        this.$emit('input', true)
      },
      deleteAnswer(answerIndex) {
        this.question.answers.splice(answerIndex, 1)
      }
    },
    name: 'QuestionDialog',
    props: {
      question: Object,
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "addAnswer": "Antwort hinzufügen",
      "cancel": "Abbrechen",
      "question": "Frage",
      "save": "Speichern",
      "text": "Text"
    },
    "en": {
      "addAnswer": "Add answer",
      "cancel": "Cancel",
      "question": "Question",
      "save": "Save",
      "text": "Text"
    }
  }
</i18n>