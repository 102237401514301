<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('ranking') }}</v-toolbar-title>
      </v-toolbar>
      <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                    v-bind:headers="headers"
                    v-bind:items="siteMeterRankings"
                    v-bind:items-per-page="50"
                    v-bind:loading="loading"
                    v-bind:options="{ sortBy: [ 'rank' ], sortDesc: [ false ] }"
                    v-bind:item-class="itemClass">

        <template v-slot:[`item.waterRating`]="{ item }">
          {{ item.waterRating | formatNumber({ fractionDigits: 3 }) }}
        </template>

        <template v-slot:[`item.electricityRating`]="{ item }">
          {{ item.electricityRating | formatNumber({ fractionDigits: 3 }) }}
        </template>

        <template v-slot:[`item.gasRating`]="{ item }">
          {{ item.gasRating | formatNumber({ fractionDigits: 3 }) }}
        </template>

        <template v-slot:[`item.heatQuantityRating`]="{ item }">
          {{ item.heatQuantityRating | formatNumber({ fractionDigits: 3 }) }}
        </template>

        <template v-slot:[`item.totalRating`]="{ item }">
          {{ item.totalRating | formatNumber({ fractionDigits: 3 }) }}
        </template>

        <template v-slot:[`item.siteName`]="{ item }">
          {{ siteTitle(item.siteId) }}
        </template>

      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      headers() {
        return [
          {
            text: this.$t('rank'),
            value: 'rank'
          },
          {
            text: this.$t('siteId'),
            value: 'siteId'
          },
          {
            text: this.$t('siteName'),
            value: 'siteName'
          },
          {
            text: this.$t('size'),
            value: 'size',
            align: 'right'
          },
          {
            text: this.$t('waterRating'),
            value: 'waterRating',
            align: 'right'
          },
          {
            text: this.$t('electricityRating'),
            value: 'electricityRating',
            align: 'right'
          },
          {
            text: this.$t('gasRating'),
            value: 'gasRating',
            align: 'right'
          },
          {
            text: this.$t('heatQuantityRating'),
            value: 'heatQuantityRating',
            align: 'right'
          },
          {
            text: this.$t('totalRating'),
            value: 'totalRating',
            align: 'right'
          }
        ]
      },
      ...mapGetters({
        siteId: 'common/currentSiteId',
        siteTitle: 'common/siteTitle'
        //siteMeterTypeLabel: 'meterManagement/siteMeterTypeLabel'
      })
    },
    data: () => ({
      selectedGroup: null
    }),
    name: 'MeterRankingTableCard',
    methods: {
      itemClass(item) {
        return item.siteId === this.siteId ? 'yellow font-weight-bold' : ''
      }
    },
    props: {
      siteMeterRankings: Array,
      loading: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "rank": "Rang",
      "siteId": "Marktnr.",
      "siteName": "Markt",
      "size": "Größe",
      "waterRating": "Ø Wasser / qm / Tag",
      "electricityRating": "Ø Strom / qm / Tag",
      "gasRating": "Ø Gas / qm / Tag",
      "heatQuantityRating": "Ø Wärmemenge / qm / Tag",
      "totalRating": "Ø Gesamt / qm / Tag",
      "ranking": "Ranking"
    },
    "en": {
     "rank": "Rank",
     "siteId": "Site ID",
      "siteName": "Site Name",
      "size": "Size",
      "waterRating": "Water Rating",
      "electricityRating": "Electricity Rating",
      "gasRating": "Gas Rating",
      "heatQuantityRating": "Heat Quantity Rating",
      "totalRating": "Total Rating",
      "ranking": "Ranking"
    }
  }
</i18n>