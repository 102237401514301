<template>
  <div>
    <v-file-input filled
                  hide-details="auto"
                  v-bind:accept="accept"
                  v-bind:error-messages="errorMessages"
                  v-bind:hint="hasMaxSize ? $t('maximumFileSize', { maxSize }) : null"
                  v-bind:label="label"
                  v-bind:loading="loading"
                  v-bind:persistent-hint="hasMaxSize"
                  v-if="value == null"
                  v-on:change="change" />
    <div v-else>
      <v-img v-bind:aspect-ratio="16/9"
             v-bind:src="attachmentUrl(value)"
             v-if="showImage">
        <v-chip dark
                class="ma-4"
                close
                color="grey darken-3"
                label
                large
                v-on:click:close="$emit('input', null)">
          {{ label }}
        </v-chip>
      </v-img>
      <v-chip dark
              close
              color="grey darken-3"
              label
              large
              target="_blank"
              v-else
              v-bind:href="attachmentUrl(value)"
              v-on:click:close="$emit('input', null); $emit('change', null)">
        {{ label }}
      </v-chip>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    computed: {
      errorMessages() {
        if (this.maxSizeExceeded) {
          return [ this.$t('theMaximumFileSizeOfWasExceeded', { maxSize: this.maxSize }) ]
        }

        return []
      },
      hasMaxSize() {
        return this.maxSize != null && this.maxSize > 0
      },
      ...mapGetters({ attachmentUrl: 'common/attachmentUrl' })
    },
    data: () => ({
      loading: false,
      maxSizeExceeded: false
    }),
    name: 'AttachmentField',
    methods: {
      async change(file) {
        if (file == null) {
          return
        }

        if (this.hasMaxSize && file.size / 1024 > this.maxSize) {
          this.maxSizeExceeded = true

          return
        }

        this.maxSizeExceeded = false

        this.loading = true

        try {
          const data = new FormData()
          data.append('file', file)

          const result = await this.createAttachment({ data })

          this.$emit('input', result.id)
        } finally {
          this.loading = false
          this.$emit('change', this.value)
        }
      },
      ...mapActions({ createAttachment: 'common/createAttachment' })
    },
    props: {
      accept: String,
      label: String,
      maxSize: Number,
      showImage: Boolean,
      value: Number
    }
  }
</script>

<i18n>
  {
    "de": {
      "maximumFileSize": "Maximale Dateigröße: {maxSize} kB",
      "theMaximumFileSizeOfWasExceeded": "Die maximale Dateigröße von {maxSize} kB wurde überschritten."
    },
    "en": {
      "maximumFileSize": "Maximum file size: {maxSize} kB",
      "theMaximumFileSizeOfWasExceeded": "The maximum file size of {maxSize} kB was exceeded."
    }
  }
</i18n>