<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisDepartmentIrrevocably')"
                    v-bind:title="$t('deleteDepartment')"
                    v-model="confirmDeleteDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="formattedName | defaultValue($t('department'))">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'departments' }" />

      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateDepartment" />

      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="deleteDepartment">
            <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container v-if="department != null">
      <v-row>
        <v-col cols="12"
               lg="6">
          <department-details-card ref="departmentDetailsCard"
                                   v-bind:department="department" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import DepartmentDetailsCard from '@/components/DepartmentDetailsCard.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      ConfirmDialog,
      DepartmentDetailsCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      formattedName() {
        if (this.department == null) {
          return null
        }

        return this.department.title
      },
      id() {
        return this.department?.id
      },
      ...mapGetters({
        getDepartment: 'common/department'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      confirmDeleteDialog: false,
      department: null,
      loading: false,
      validationSnackbar: false
    }),
    methods: {
      async deleteDepartment() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteDepartment({ id: this.id })

          this.loading = false

          this.$router.push({ name: 'departments' })
        })
      },
      async get(id) {
        this.loading = true

        await this.loadDepartment({ id })

        this.loading = false

        this.department = Object.assign({}, this.getDepartment(id))
      },
      async updateDepartment() {
        if (!this.$refs.departmentDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateDepartment({ department: this.department })

        this.loading = false

        this.$router.push({ name: 'departments' })
      },
      ...mapActions({
        doDeleteDepartment: 'common/deleteDepartment',
        doUpdateDepartment: 'common/updateDepartment',
        loadDepartment: 'common/loadDepartment'
      })
    },
    name: 'Department'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisDepartmentIrrevocably": "Sind Sie sicher, dass Sie diese Abteilung unwiderruflich löschen möchten?",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "delete": "Löschen",
      "deleteDepartment": "Abteilung löschen",
      "department": "Abteilung",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "areYouSureYouWantToDeleteThisDepartmentIrrevocably": "Are you sure you want to delete this department irrevocably?",
      "back": "Back",
      "cancel": "Cancel",
      "delete": "Delete",
      "deleteDepartment": "Delete department",
      "department": "Department",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>