<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('title')"
                        v-bind:rules="[ rules.required ]"
                        v-model="exam.title" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <date-field v-bind:label="$t('start')"
                      v-bind:rules="[ rules.required ]"
                      v-model="exam.start" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <date-field v-bind:label="$t('end')"
                      v-bind:rules="[ rules.required ]"
                      v-model="exam.end" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-select filled
                    hide-details="auto"
                    v-bind:items="subjects | items({ text: 'title', value: 'id' })"
                    v-bind:label="$t('subject')"
                    v-bind:rules="[ rules.required ]"
                    v-model="exam.subjectId" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DateField from '@/components/common/DateField.vue'

  export default {
    components: {
      DateField
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      ...mapGetters({
        subjects: 'learning/subjects'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'ExamDetails',
    props: {
      exam: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "end": "Ende",
      "start": "Start",
      "subject": "Modul",
      "title": "Bezeichnung"
    },
    "en": {
      "end": "End",
      "start": "Start",
      "subject": "Subject",
      "title": "Title"
    }
  }
</i18n>