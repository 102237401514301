<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisExamIrrevocably')"
                    v-bind:title="$t('deleteExam')"
                    v-model="confirmDeleteDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="title | defaultValue($t('exam'))">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'learning-exams' }" />

      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateExam" />

      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="deleteExam">
            <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container v-if="exam != null">
      <v-row>
        <v-col cols="12"
               lg="6">
          <exam-details-card ref="examDetailsCard"
                             v-bind:exam="exam" />
        </v-col>
        <v-col cols="12"
               lg="6">
          <exam-stats-card class="mb-6"
                           v-bind:exam="exam" />
          <exam-trainee-list-card ref="examTraineeListCard"
                                  v-bind:exam-trainees="exam.trainees"
                                  v-on:add-department="addDepartment"
                                  v-on:add-exam-trainee="addExamTrainee"
                                  v-on:allow-grace-period-for-exam-trainee="allowGracePeriodForExamTrainee"
                                  v-on:delete-exam-trainee="deleteExamTrainee" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import ExamDetailsCard from '@/components/learning/ExamDetailsCard.vue'
  import ExamStatsCard from '@/components/learning/ExamStatsCard.vue'
  import ExamTraineeListCard from '@/components/learning/ExamTraineeListCard.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      ConfirmDialog,
      ExamDetailsCard,
      ExamStatsCard,
      ExamTraineeListCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      id() {
        return this.exam?.id
      },
      title() {
        return this.exam?.title
      },
      ...mapGetters({
        getExam: 'learning/exam',
        trainees: 'learning/trainees'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      confirmDeleteDialog: false,
      exam: null,
      loading: false,
      validationSnackbar: false
    }),
    methods: {
      async addDepartment(departmentId) {
        const trainees = this.trainees.filter(t => t.departmentId === departmentId)

        for (let trainee of trainees) {

          if (!this.exam.trainees.find(t => t.traineeId === trainee.id)) {
            await this.addExamTrainee(trainee)
          }
        }
      },
      async addExamTrainee(trainee) {
        const examTrainee = {
          examId: this.id,
          traineeId : trainee.id,
          trainee: trainee
        }

        this.loading = true

        await this.createExamTrainee({ examTrainee })

        this.loading = false
      },
      async allowGracePeriodForExamTrainee(examTrainee) {
        examTrainee.allowGracePeriod = true

        this.loading = true

        await this.updateExamTrainee({ examTrainee })

        this.loading = false
      },
      async deleteExam() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteExam({ id: this.id })

          this.loading = false

          this.$router.push({ name: 'learning-exams' })
        })
      },
      async deleteExamTrainee(traineeId) {
        const examTrainee = {
          examId: this.id,
          traineeId : traineeId,
        }

        this.loading = true

        await this.doDeleteExamTrainee({ examTrainee })

        this.loading = false
      },
      async get(id) {
        this.loading = true

        await this.loadDepartments()
        await this.loadExam({ id })
        await this.loadSubjects()
        await this.loadTrainees()

        this.loading = false

        this.exam = Object.assign({}, this.getExam(id))
      },
      async updateExam() {
        if (!this.$refs.examDetailsCard.validate() | !this.$refs.examTraineeListCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateExam({ exam: this.exam })

        this.loading = false

        this.$router.push({ name: 'learning-exams' })
      },
      ...mapActions({
        createExamTrainee: 'learning/createExamTrainee',
        doDeleteExam: 'learning/deleteExam',
        doDeleteExamTrainee: 'learning/deleteExamTrainee',
        doUpdateExam: 'learning/updateExam',
        loadDepartments: 'common/loadDepartments',
        loadExam: 'learning/loadExam',
        loadSubjects: 'learning/loadSubjects',
        loadTrainees: 'learning/loadTrainees',
        updateExamTrainee: 'learning/updateExamTrainee'
      })
    },
    name: 'Exam'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisExamIrrevocably": "Sind Sie sicher, dass Sie diese Prüfung unwiderruflich löschen möchten?",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "delete": "Löschen",
      "deleteExam": "Prüfung löschen",
      "exam": "Prüfung",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "areYouSureYouWantToDeleteThisExamIrrevocably": "Are you sure you want to delete this exam irrevocably?",
      "back": "Back",
      "cancel": "Cancel",
      "delete": "Delete",
      "deleteExam": "Delete exam",
      "exam": "Exam",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>