<template>
  <div>
    <top-header v-bind:title="$t('newReceipt')">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'free-product-accounting-receipts' }" />
    </top-header>
    <v-container>
      <new-receipt-stepper v-bind:receipt="receipt"
                           v-on:complete="saveReceipt"
                           v-on:save-draft="saveReceipt" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import NewReceiptStepper from '@/components/free-product-accounting/NewReceiptStepper'

  export default {
    components: {
      HeaderButton,
      NewReceiptStepper,
      TopHeader
    },
    computed: {
      ...mapGetters({
        currentSiteId: 'common/currentSiteId'
      })
    },
    async created() {
      this.loadManufacturers()
      this.loadProducts()
      this.loadSites()

      this.receipt.siteId = this.currentSiteId
    },
    data: () => ({
      receipt: {
        products: []
      }
    }),
    methods: {
      async saveReceipt() {
        await this.createReceipt({ receipt: this.receipt})

        await this.$router.push({ name: 'free-product-accounting-receipts' })
      },
      ...mapActions({
        createReceipt: 'freeProductAccounting/createReceipt',
        loadManufacturers: 'common/loadManufacturers',
        loadProducts: 'common/loadProducts',
        loadSites: 'common/loadSites'
      })
    },
    name: 'ReceiptAdd'
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "newReceipt": "Neuer Beleg"
    },
    "en": {
      "cancel": "Cancel",
      "newReceipt": "New receipt"
    }
  }
</i18n>