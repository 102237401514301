<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('number')"
                        v-model="inventoryReceipt.id" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        type="number"
                        v-bind:label="$t('state')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventoryReceipt.state" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <date-time-field disabled
                           filled
                           hide-details="auto"
                           v-bind:label="$t('createdAt')"
                           v-model="inventoryReceipt.createdAt" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('createdBy')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventoryReceipt.createdBy" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <date-time-field disabled
                           filled
                           hide-details="auto"
                           v-bind:label="$t('modifiedAt')"
                           v-model="inventoryReceipt.modifiedAt" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('modifiedBy')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventoryReceipt.modifiedBy" />
        </v-col>
      </v-row>
      
    </v-card-text>
  </v-form>
</template>

<script>
  import DateTimeField from '@/components/common/DateTimeField'

  export default {
    components: {
      DateTimeField
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'InventoryReceiptDetails',
    props: {
      inventoryReceipt: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "number": "Nummer",
      "state": "Status",
      "createdAt": "Erstellt am",
      "createdBy": "Erstellt von",
      "modifiedAt": "Geändert am",
      "modifiedBy": "Geändert von"
    },
    "en": {
      "number": "Number",
      "state": "State",
      "createdAt": "Created at",
      "createdBy": "Created by",
      "modifiedAt": "Modified at",
      "modifiedBy": "Modified by"
    }
  }
</i18n>