<template>
  <div>
    <top-header v-bind:title="$t('preOrders', { siteId: siteId })">
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container v-if="sitePreOrders != null">
      <v-row>
        <v-col>
          <pre-order-table-card v-bind:pre-orders="sitePreOrders" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import PreOrderTableCard from '@/components/pre-order/PreOrderTableCard.vue'

  export default {
    components: {
      TopHeader,
      PreOrderTableCard
    },
    computed: {
      ...mapGetters({
        currentSiteId: 'common/currentSiteId',
        sitePreOrders: 'preOrder/sitePreOrders'
      })
    },
    async created() {
      this.siteId = this.currentSiteId

      await this.reload()
    },
    data: () => ({
      loading: false,
      siteId: null
    }),
    methods: {
      async reload() {
        this.loading = true

        await this.loadSitePreOrders({ id: this.siteId })

        this.loading = false
      },
      ...mapActions({
        loadSitePreOrders: 'preOrder/loadSitePreOrders'
      })
    },
    name: 'SitePreOrders'
  }
</script>

<i18n>
  {
    "de": {
      "preOrders": "Vorbestellungen {siteId}"
    },
    "en": {
      "preOrders": "Pre-orders {siteId}"
    }
  }
</i18n>