<template>
  <div>
    <top-header v-bind:title="$t('inventories')" />
    <v-progress-linear color="accent"
                       indeterminate
                       v-bind:active="loading" />
    <inventory-list-card item-route-name="inventory-management-app-inventory"
                                v-bind:inventories="inventories"
                                
                                hide-title-bar />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import InventoryListCard from '@/components/inventory-management/InventoryListCard'

  export default {
    components: {
      TopHeader,
      HeaderButton,
      InventoryListCard
    },
    computed: {
      ...mapGetters({ 
        hasRole: 'auth/hasRole',
        inventories: 'inventoryManagement/inventories'
      })
    },
    async created() {
      this.loading = true

      await this.loadWarehouses()
      await this.loadInventories()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions({
        loadInventories: 'inventoryManagement/loadInventories',
        loadWarehouses: 'common/loadWarehouses'
      })
    },
    name: 'AppInventories'
  }
</script>

<i18n>
  {
    "de": {
      "inventories": "Inventuren",
      "add": "Neu"
    },
    "en": {
      "inventories": "Inventories",
      "add": "Add"
    }
  }
</i18n>