<template>
  <div>
    <top-header v-bind:title="title">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'inventory-management-app-inventories' }" />
    </top-header>
    <v-progress-linear color="accent"
                       indeterminate
                       v-bind:active="loading" />
    <inventory-receipt-list-card addRouteName="inventory-management-inventory-receipt-add"
                                  itemRouteName="inventory-management-app-inventory-receipt"
                                  v-bind:routeParams="{ inventoryId: id }"
                                  v-bind:inventory-receipts="inventoryReceipts"
                                  hide-title-bar
                                  v-if="inventory != null" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import InventoryReceiptListCard from '@/components/inventory-management/InventoryReceiptListCard.vue'
  export default {
    async beforeRouteUpdate(to, _, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      HeaderButton,
      TopHeader,
      InventoryReceiptListCard
    },
    computed: {
      title() {
        return `${this.inventory?.id || this.$t('inventory')}`
      },
      id() {
        return this.inventory?.id
      },
      inventoryReceipts() {
        return this.getInventoryReceipts.filter(i => i.inventoryId == this.id)
      },
      ...mapGetters({
        getInventory: 'inventoryManagement/inventory',
        getInventoryReceipts: 'inventoryManagement/inventoryReceipts'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      loading: false,
      inventory: null
    }),
    methods: {
      async get(id) {
        this.loading = true
        
        await this.loadInventoryReceipts()
        await this.loadInventories()

        this.loading = false

        this.inventory = Object.assign({}, this.getInventory(id))
      },
      ...mapActions({
        loadInventories: 'inventoryManagement/loadInventories',
        loadInventoryReceipts: 'inventoryManagement/loadInventoryReceipts',
      })
    },
    name: 'AppInventory'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "inventory": "Inventur"
    },
    "en": {
      "back": "Back",
      "inventory": "Inventory"
    }
  }
</i18n>