<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat
                 v-if="!hideTitleBar">
        <v-toolbar-title>{{ $t('inventories') }}</v-toolbar-title>
        <v-spacer />
        <v-btn exact
               icon
               v-bind:to="{ name: this.addRouteName, params: routeParams }">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                       v-bind:items="inventories"
                       v-bind:items-per-page="30"
                       sort-by="id"
                       v-bind:sort-desc="true">
        <template v-slot:default="props">
          <v-list>
            <v-list-item two-line
                         v-bind:key="inventory.id"
                         v-for="inventory in props.items"
                         v-on:click="goToItem(inventory)">
              <v-list-item-avatar>
                <v-icon class="primary lighten-1"
                        dark>
                  mdi-warehouse
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ inventory.id }} – <strong>{{ warehouseTitle(inventory.warehouseId) }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle>{{ inventory.createdAt | formatDate('L LT') }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider />
        </template>
        <template v-slot:no-data>
        <v-card-text class="text-center text--disabled">
          {{ $t('noInventoriesAvailable') }}
        </v-card-text>
        <v-divider />
      </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';

  export default {
    computed: {
      ...mapGetters({
        warehouseTitle: 'common/warehouseTitle'
      })
    },
    name: 'InventoryReceiptListCard',
    methods: {
      goToItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id, ...this.routeParams }})
      }
    },
    props: {
      addRouteName: String,
      inventories: Array,
      itemRouteName: String,
      routeParams: Object,
      hideTitleBar: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "inventories": "Inventuren",
      "noInventoriesAvailable": "Keine Inventuren vorhanden"
    },
    "en": {
      "inventories": "Inventories",
      "noInventoriesAvailable": "No inventories available"
    }
  }
</i18n>