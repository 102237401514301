<template>
  <div>
    <top-header v-bind:title="title">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'booking-public' }"
                     v-if="category !== 'Hausmesse' && subCategory !== 'Allgemein'" />
    </top-header>
    <v-container>
      <public-event-tiles v-bind:events="filteredEvents" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import PublicEventTiles from '@/components/booking/PublicEventTiles.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      this.category = to.params.category
      this.eventId = to.params.eventId
      this.subCategory = to.params.subCategory

      next()
    },
    components: {
      HeaderButton,
      PublicEventTiles,
      TopHeader
    },
    computed: {
      filteredEvents() {
        if (this.events == null) {
          return []
        }

        let filteredEvents = this.events.filter(e => e.hasBookableDates && e.category === this.category && e.subCategory === this.subCategory)

        if (this.eventId != null) {
          filteredEvents = filteredEvents.filter(e => e.id === parseInt(this.eventId))
        }

        return filteredEvents
      },
      title() {
        if (this.category == null || this.subCategory == null) {
          return this.$t('events')
        }

        return `${this.category} – ${this.subCategory}`
      },
      ...mapGetters({
        events: 'booking/events'
      })
    },
    async created() {
      this.category = this.$route.params.category
      this.eventId = this.$route.params.eventId
      this.subCategory = this.$route.params.subCategory

      this.loading = true

      await this.loadEvents()

      this.loading = false
    },
    data: () => ({
      category: null,
      eventId: null,
      loading: false,
      subCategory: null
    }),
    methods: {
      ...mapActions({
        loadEvents: 'booking/loadEvents'
      })
    },
    name: 'Events'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "events": "Seminare"
    },
    "en": {
      "back": "Zurück",
      "events": "Events"
    }
  }
</i18n>