<template>
  <div>
    <top-header v-bind:title="title">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'inventory-management-app-inventory', params: { id: inventoryId } }" />
    </top-header>
    <v-progress-linear color="accent"
                       indeterminate
                       v-bind:active="loading" />
    <v-container v-if="inventoryReceipt != null">
      <v-row>
        <v-col cols="6">
          <icon-button-card color="primary"
                            icon="mdi-bottle-wine"
                            v-bind:to="{ name: 'inventory-management-app-inventory-filled-stock', params: { inventoryId, id }}"
                            v-bind:title="$t('filledStock')" />
        </v-col>
        <v-col cols="6">
          <icon-button-card color="orange"
                            icon="mdi-bottle-wine-outline"
                            v-bind:to="{ name: 'inventory-management-app-inventory-empties', params: { inventoryId, id }}"
                            v-bind:title="$t('empties')" />
        </v-col>
        <v-col cols="6">
          <icon-button-card color="black"
                            icon="mdi-file-document-edit-outline"
                            v-bind:to="{ name: 'inventory-management-app-inventory-transactions', params: { inventoryId, inventoryReceiptId: id }}"
                            v-bind:title="$t('transactions')" />
        </v-col>
      </v-row>
      
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import IconButtonCard from '@/components/inventory-management/IconButtonCard.vue'

  export default {
    async beforeRouteUpdate(to, _, next) {
      const id = to.params.id
      const inventoryId = parseInt(to.params.inventoryId)

      await this.get(id, inventoryId)

      next()
    },
    components: {
      TopHeader,
      HeaderButton,
      IconButtonCard
    },
    computed: {
      title() {
        return `${this.inventoryReceipt?.id || this.$t('receipt')}`
      },
      id() {
        return this.inventoryReceipt?.id
      },
      ...mapGetters({
        getInventoryReceipt: 'inventoryManagement/inventoryReceipt'
      })
    },
    async created() {
      const id = this.$route.params.id
      const inventoryId = parseInt(this.$route.params.inventoryId)

      await this.get(id, inventoryId)
    },
    data: () => ({
      inventoryId: null,
      inventoryReceipt: null,
      loading: false
    }),
    methods: {
      async get(id, inventoryId) {
        this.inventoryId = inventoryId

        this.loading = true
        
        await this.loadInventoryReceipt({ id })

        this.loading = false

        this.inventoryReceipt = Object.assign({}, this.getInventoryReceipt(id))
      },
      ...mapActions({
        loadInventoryReceipt: 'inventoryManagement/loadInventoryReceipt',
      })
    },
    name: 'AppInventoryReceipt'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "receipt": "Beleg",
      "filledStock": "Vollgut",
      "empties": "Leergut",
      "transactions": "Buchungen"
    },
    "en": {
      "back": "Back",
      "receipt": "Receipt",
      "filledStock": "Filled Stock",
      "empties": "Empties",
      "transactions": "Transactions"
    }
  }
</i18n>