<template>
  <div>
    <div class="mb-4">
      <v-btn color="accent"
             v-on:click="clear">
        <v-icon left>mdi-repeat</v-icon>
        {{ $t('repeat') }}
      </v-btn>
    </div>

    <canvas class="signature-canvas"
            height="240"
            ref="signatureCanvas"
            width="600" />

    <p class="mb-0">{{ label }}</p>
  </div>
</template>

<script>
  import SignaturePad from 'signature_pad'

  export default {
    data: () => ({
      signaturePad: null
    }),
    methods: {
      clear() {
        this.signaturePad.clear()
      },
      getSignature() {
        return this.signaturePad.toDataURL()
      },
      validate() {
        return !this.signaturePad.isEmpty()
      }
    },
    mounted() {
      const signatureCanvas = this.$refs.signatureCanvas

      this.signaturePad = new SignaturePad(signatureCanvas, {
        backgroundColor: 'rgb(238, 238, 238)'
      })

    },
    name: 'SignaturePad',
    props: {
      label: String
    }
  }
</script>

<style scoped>

</style>

<i18n>
  {
    "de": {
      "repeat": "Wiederholen"
    },
    "en": {
      "repeat": "Repeat"
    }
  }
</i18n>