import SitePreOrders from '../views/pre-order/SitePreOrders.vue'
import PrimaryNavigation from '../views/navigation/PrimaryNavigation.vue'

export default [
  {
    components: {
      default: SitePreOrders,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalSiteEvaluation') || hasRole('SagasserPortalAdministrator'))
    },
    name: 'pre-orders-site',
    path: '/pre-orders/site'
  }
]