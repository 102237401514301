export default class CampaignService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async create(campaign, options) {
    const response = await fetch(`${this.baseUrl}/campaign`, {
      body: JSON.stringify(campaign),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(id, options) {
    const response = await fetch(`${this.baseUrl}/campaign/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(id, options) {
    try {
      const response = await fetch(`${this.baseUrl}/campaign/${id}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUrl}/campaign`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async update(id, campaign, options) {
    const response = await fetch(`${this.baseUrl}/campaign/${id}`, {
      body: JSON.stringify(campaign),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }


  async createProduct(campaignId, product, options) {
    const response = await fetch(`${this.baseUrl}/campaign/${campaignId}/product`, {
      body: JSON.stringify(product),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async deleteProduct(campaignId, productId, options) {
    const response = await fetch(`${this.baseUrl}/campaign/${campaignId}/product/${productId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async createSite(campaignId, site, options) {
    const response = await fetch(`${this.baseUrl}/campaign/${campaignId}/site`, {
      body: JSON.stringify(site),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async deleteSite(campaignId, siteId, options) {
    const response = await fetch(`${this.baseUrl}/campaign/${campaignId}/site/${siteId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }


  async getAllOrdersForCampaign(id, options) {
    const response = await fetch(`${this.baseUrl}/campaign/${id}/order`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAllOrdersForCampaignAndSite(id, siteId, options) {
    const response = await fetch(`${this.baseUrl}/campaign/${id}/order/${siteId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async updateOrdersForCampaignAndSite(id, siteId, orders, options) {
    const response = await fetch(`${this.baseUrl}/campaign/${id}/order/${siteId}`, {
      body: JSON.stringify(orders),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }
}