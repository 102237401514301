import Vue from 'vue'

import CampaignService from '../api/sale-management/campaign-service'

import settings from '../settings'

const campaignService = new CampaignService(settings.apiUri)

export default {
  actions: {
    // campaign
    // ========
    async createCampaign({ commit, rootGetters }, { campaign }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await campaignService.create(campaign, { accessToken })

      campaign.id = result.id
      campaign.state = result.state

      commit('createCampaign', { campaign })
    },
    async deleteCampaign({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await campaignService.delete(id, { accessToken })

      if (result) {
        commit('deleteCampaign', { id })
      }
    },
    async loadCampaigns({ commit, getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const campaigns = await campaignService.getAll({ accessToken })

      commit('setCampaigns', { campaigns })
    },
    async setCampaignState({ dispatch, getters }, { id, state }) {
      const campaign = { ...getters.campaign(id) }

      if (campaign == null) {
        return
      }

      campaign.state = state

      await dispatch('updateCampaign', { campaign })
    },
    async updateCampaign({ commit, rootGetters }, { campaign }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await campaignService.update(campaign.id, campaign, { accessToken })

      if (result) {
        commit('updateCampaign', { campaign })
      }
    },

    // campaignProduct
    // ===============
    async createCampaignProduct({ commit, rootGetters }, { campaignId, product }) {
      const accessToken = rootGetters['auth/accessToken']

      product.campaignId = campaignId

      const result = await campaignService.createProduct(campaignId, product, { accessToken })

      commit('createCampaignProduct', { campaignId, product })
    },
    async deleteCampaignProduct({ commit, rootGetters }, { campaignId, productId }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await campaignService.deleteProduct(campaignId, productId, { accessToken })

      if (result) {
        commit('deleteCampaignProduct', { campaignId, productId })
      }
    },

    // campaignSite
    // ============
    async createCampaignSite({ commit, rootGetters }, { campaignId, site }) {
      const accessToken = rootGetters['auth/accessToken']

      site.campaignId = campaignId

      const result = await campaignService.createSite(campaignId, site, { accessToken })

      commit('createCampaignSite', { campaignId, site })
    },
    async deleteCampaignSite({ commit, rootGetters }, { campaignId, siteId }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await campaignService.deleteSite(campaignId, siteId, { accessToken })

      if (result) {
        commit('deleteCampaignSite', { campaignId, siteId })
      }
    },

    // campaignOrder
    // =============
    async loadCampaignOrdersForCampaign({ commit, rootGetters }, { campaignId }) {
      const accessToken = rootGetters['auth/accessToken']

      const campaignOrders = await campaignService.getAllOrdersForCampaign(campaignId, { accessToken })

      commit('setCampaignOrders', { campaignOrders })
    },
    async loadCampaignOrdersForCampaignAndSite({ commit, rootGetters }, { campaignId, siteId }) {
      const accessToken = rootGetters['auth/accessToken']

      const campaignOrders = await campaignService.getAllOrdersForCampaignAndSite(campaignId, siteId, { accessToken })

      commit('setCampaignOrders', { campaignOrders })
    },
    async updateCampaignOrders({ commit, rootGetters }, { campaignId, siteId, orders }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await campaignService.updateOrdersForCampaignAndSite(campaignId, siteId, orders, { accessToken })

      /*if (result) {
        commit('updateCampaign', { campaign })
      }*/
    }
  },
  getters: {
    campaign: state => id => state.campaigns.find(c => c.id === id),
    campaigns: state => state.campaigns,
    campaignOrdersForCampaign: state => campaignId => state.campaignOrders.filter(c => c.campaignId === campaignId),
    campaignOrdersForCampaignAndSite: state => (campaignId, siteId) => state.campaignOrders.filter(c => c.campaignId === campaignId && c.siteId === siteId),
    campaignStateColor: state => value => state.campaignStates.find(c => c.value === value)?.color,
    campaignStates: state => state.campaignStates,
    campaignStateLabel: state => value => state.campaignStates.find(c => c.value === value)?.label,
  },
  mutations: {
    // campaign
    // ========
    createCampaign(state, { campaign }) {
      state.campaigns.push(campaign)
    },
    deleteCampaign(state, { id }) {
      const index = state.campaigns.findIndex(c => c.id === id)

      if (index !== -1) {
        state.campaigns.splice(index, 1)
      }
    },
    setCampaigns(state, { campaigns }) {
      state.campaigns = campaigns
    },
    updateCampaign(state, { campaign }) {
      const index = state.campaigns.findIndex(c => c.id === campaign.id)

      Vue.set(state.campaigns, index, campaign)
    },

    // campaignProduct
    // ===============
    createCampaignProduct(state, { campaignId, product }) {
      const campaign = state.campaigns.find(c => c.id === campaignId)

      if (campaign == null || campaign.products == null) {
        return
      }

      campaign.products.push(product)
    },
    deleteCampaignProduct(state, { campaignId, productId }) {
      const campaign = state.campaigns.find(c => c.id === campaignId)

      if (campaign == null || campaign.products == null) {
        return
      }

      const index = campaign.products.findIndex(p => p.campaignId === campaignId && p.productId === productId)

      if (index !== -1) {
        campaign.products.splice(index, 1)
      }
    },

    // campaignSite
    // ============
    createCampaignSite(state, { campaignId, site }) {
      const campaign = state.campaigns.find(c => c.id === campaignId)

      if (campaign == null || campaign.sites == null) {
        return
      }

      campaign.sites.push(site)
    },
    deleteCampaignSite(state, { campaignId, siteId }) {
      const campaign = state.campaigns.find(c => c.id === campaignId)

      if (campaign == null || campaign.sites == null) {
        return
      }

      const index = campaign.sites.findIndex(s => s.campaignId === campaignId && s.siteId === siteId)

      if (index !== -1) {
        campaign.sites.splice(index, 1)
      }
    },

    // campaignOrder
    // =============
    setCampaignOrders(state, { campaignOrders }) {
      state.campaignOrders = campaignOrders
    }
  },
  namespaced: true,
  state: {
    campaigns: [],
    campaignOrders: [],
    campaignStates: [
      {
        color: 'light-green',
        label: 'global.active',
        value: 'active'
      },
      {
        color: 'deep-orange',
        label: 'global.canceled',
        value: 'canceled'
      },
      {
        color: 'grey',
        label: 'global.closed',
        value: 'closed'
      },
      {
        color: 'pink',
        label: 'global.pending',
        value: 'pending'
      },
      {
        color: 'amber',
        label: 'global.upcoming',
        value: 'upcoming'
      }
    ]
  }
}