<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('documents') }}</v-toolbar-title>
      </v-toolbar>
      <v-row no-gutters>
        <v-col>
          <v-text-field flat
                        hide-details="auto"
                        ref="newDocumentTextField"
                        solo
                        v-bind:label="$t('addDocument')"
                        v-model="newDocumentTitle" />
        </v-col>
        <v-col cols="auto">
          <v-btn block
                 class="v-btn--search"
                 color="accent"
                 dark
                 depressed
                 large
                 tile
                 v-on:click="addSubjectDocument">
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                       v-bind:items="subjectDocuments"
                       v-bind:items-per-page="10">
        <template v-slot:default="props">
          <v-list>
            <v-list-item two-line
                         v-bind:key="index"
                         v-for="(subjectDocument, index) in props.items">
              <v-list-item-icon>
                <v-icon color="secondary lighten-5"
                        v-text="getIcon(subjectDocument.attachment.contentType)" />
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ subjectDocument.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ subjectDocument.attachment.creation | formatDate('LLL') }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn color="secondary"
                       icon
                       target="_blank"
                       v-bind:href="attachmentUrl(subjectDocument.attachmentId)">
                  <v-icon color="secondary">mdi-open-in-new</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <delete-confirmation-button v-bind:item="subjectDocument"
                                            v-on:delete="deleteSubjectDocument($event.id)" />
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider />
        </template>
        <template v-slot:no-data>
          <v-card-text class="text-center text--disabled">
            {{ $t('noDocumentsAvailable') }}
          </v-card-text>
          <v-divider />
        </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DeleteConfirmationButton from '@/components/common/DeleteConfirmationButton.vue'

  export default {
    components: {
      DeleteConfirmationButton
    },
    computed: {
      ...mapGetters({
        attachmentUrl: 'common/attachmentUrl'
      })
    },
    data: () => ({
      newDocumentTitle: null
    }),
    name: 'SubjectDocumentListCard',
    methods: {
      addSubjectDocument() {
        if (this.newDocumentTitle == null || this.newDocumentTitle.length === 0) {
          return
        }

        this.$emit('add-subject-document', this.newDocumentTitle)

        this.newDocumentTitle = null
      },
      deleteSubjectDocument(documentId) {
        this.$emit('delete-subject-document', documentId)
      },
      getIcon(contentType) {
        if (contentType === 'image/png' || contentType === 'image/jpeg') {
          return 'mdi-file-image'
        } else if (contentType === 'application/pdf') {
          return 'mdi-file-document'
        } else if (contentType === 'video/mp4') {
          return 'mdi-file-video'
        } else {
          return 'mdi-file'
        }
      },
      validate() {
        return true
      }
    },
    props: {
      subjectDocuments: Array
    }
  }
</script>

<style>
  .v-btn.v-btn--search {
    min-height: 48px;
  }
</style>

<i18n>
  {
    "de": {
      "addDocument": "Dokument hinzufügen",
      "noDocumentsAvailable": "Keine Dokumente vorhanden",
      "documents": "Dokumente"
    },
    "en": {
      "addDocument": "Add document",
      "noDocumentsAvailable": "No documents available",
      "documents": "Documents"
    }
  }
</i18n>