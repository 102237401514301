import Vue from 'vue'


import PriceTagPdfService from '../api/price-tag/price-tag-pdf-service'

import settings from '../settings'

const priceTagPdfService = new PriceTagPdfService(settings.apiUri)

export default {
  actions: {
    async setProducts({ commit, getters, rootGetters }, { products }) {

      commit('setProducts', { products })
    },
    async createPriceTagPdf({ commit, getters, rootGetters }, { productPriceTags }) {
      const accessToken = rootGetters['auth/accessToken']

      const response = await priceTagPdfService.create(productPriceTags, { accessToken })

      return response
    }
  },
  getters: {
    products: state => state.products
  },
  mutations: {
    setProducts(state, { products }) {
      state.products = products
    }
  },
  namespaced: true,
  state: {
    products: []
  }
}