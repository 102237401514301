<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-select clearable
                      flat
                      hide-details="auto"
                      multiple
                      prepend-icon="mdi-tag"
                      solo
                      v-bind:items="receiptStateItems"
                      v-bind:label="$t('state')"
                      v-bind:loading="loading"
                      v-model="stateFilter" />
          </v-col>
          <v-col>
            <v-select clearable
                      flat
                      hide-details="auto"
                      prepend-icon="mdi-calendar"
                      solo
                      v-bind:items="[ 'Aktueller Monat', 'Aktuelles Quartal', 'Aktuelles Jahr' ]"
                      v-bind:label="$t('period')"
                      v-model="periodFilter" />
          </v-col>
          <v-col v-if="hasRole('SagasserPortalAdministrator')">
            <v-autocomplete clearable
                            flat
                            hide-details="auto"
                            multiple
                            prepend-icon="mdi-store"
                            solo
                            v-bind:items="siteItems"
                            v-bind:label="$t('site')"
                            v-model="siteFilter" />
          </v-col>
          <v-col>
            <v-autocomplete clearable
                            flat
                            hide-details="auto"
                            multiple
                            prepend-icon="mdi-truck"
                            solo
                            v-bind:items="manufacturerItems"
                            v-bind:label="$t('manufacturer')"
                            v-model="manufacturerFilter" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('receipts') }}</v-toolbar-title>
      <!--<v-text-field class="ml-4"
                    hide-details
                    flat
                    light
                    prepend-inner-icon="mdi-magnify"
                    solo
                    v-model="search" />-->
    </v-toolbar>
    <v-data-table v-bind:headers="headers"
                  v-bind:items="filteredReceipts"
                  v-on:click:row="selectReceipt">
      <template v-slot:[`item.date`]="{ item }">
        {{ item.date | formatDate('LLL') }}
      </template>
      <template v-slot:[`item.state`]="{ item }">
        {{ $t(receiptStateLabel(item.state)) }}
      </template>
      <template v-slot:footer>
        <div>
          <v-btn block
                 text
                 v-on:click="$emit('load-all')">
            {{ $t('loadAll') }}
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  import moment from 'moment'

  export default {
    computed: {
      filteredReceipts() {
        let filteredReceipts = this.receipts

        if (this.siteFilter != null && this.siteFilter.length !== 0) {
          filteredReceipts = filteredReceipts.filter(f => this.siteFilter.includes(f.siteId))
        }

        if (this.stateFilter != null && this.stateFilter.length !== 0) {
          filteredReceipts = filteredReceipts.filter(f => this.stateFilter.includes(f.state))
        }

        if (this.periodFilter != null && this.periodFilter === 'Aktueller Monat') {
          filteredReceipts = filteredReceipts.filter(f => moment(f.date).get('month') === moment().get('month'))
        } else if (this.periodFilter != null && this.periodFilter === 'Aktuelles Quartal') {
          filteredReceipts = filteredReceipts.filter(f => moment(f.date).get('quarter') === moment().get('quarter'))
        } else if (this.periodFilter != null && this.periodFilter === 'Aktuelles Jahr') {
          filteredReceipts = filteredReceipts.filter(f => moment(f.date).get('year') === moment().get('year'))
        }

        if (this.manufacturerFilter != null && this.manufacturerFilter.length !== 0) {
          filteredReceipts = filteredReceipts.filter(f => this.manufacturerFilter.includes(f.manufacturerId))
        }

        return filteredReceipts
      },
      headers() {
        return [
          {
            text: this.$t('date'),
            value: 'date'
          },
          {
            text: this.$t('site'),
            value: 'site.title'
          },
          {
            text: this.$t('manufacturer'),
            value: 'manufacturer.title'
          },
          {
            text: this.$t('representative'),
            value: 'manufacturerRepresentative'
          },
          {
            text: this.$t('state'),
            value: 'state'
          }
        ]
      },
      receiptStateItems() {
        return this.receiptStates.map(r => ({
          text: this.$t(r.label),
          value: r.value
        }))
      },
      manufacturerItems() {
        return this.manufacturers.map(m => ({
          text: m.title,
          value: m.id
        }))
      },
      siteItems() {
        return this.sites.map(s => ({
          text: s.title,
          value: s.id
        }))
      },
      ...mapGetters({
        hasRole: 'auth/hasRole',
        manufacturers: 'common/manufacturers',
        receiptStateLabel: 'freeProductAccounting/receiptStateLabel',
        receiptStates: 'freeProductAccounting/receiptStates',
        sites: 'common/sites'
      })
    },
    data: () => ({
      manufacturerFilter: null,
      periodFilter: null,
      siteFilter: null,
      stateFilter: null
    }),
    methods: {
      selectReceipt(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id }})
      }
    },
    name: 'ReceiptTableCard',
    props: {
      itemRouteName: String,
      loading: Boolean,
      receipts: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "date": "Datum",
      "loadAll": "Alle laden",
      "manufacturer": "Hersteller",
      "period": "Zeitraum",
      "receipts": "Belege",
      "representative": "Außendienstmitarbeiter:in",
      "site": "Markt",
      "state": "Status"
    },
    "en": {
      "date": "Date",
      "loadAll": "Load all",
      "manufacturer": "Manufacturer",
      "period": "Period",
      "receipts": "Receipts",
      "representative": "Representative",
      "site": "Site",
      "state": "State"
    }
  }
</i18n>