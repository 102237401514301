import Vue from 'vue'

import SiteProductPerformanceService from '../api/product-performance/site-product-performance-service'

import settings from '../settings'

const siteProductPerformanceService = new SiteProductPerformanceService(settings.apiUri)

export default {
  actions: {
    async loadSiteFlopProducts({ commit, getters, rootGetters }, { id, group}) {
      const accessToken = rootGetters['auth/accessToken']

      const siteFlopProducts = await siteProductPerformanceService.getFlopProducts(id, group, { accessToken })

      commit('setSiteFlopProducts', { siteFlopProducts })
    },
    async loadSiteProductPerformance({ commit, getters, rootGetters }, { id, group}) {
      const accessToken = rootGetters['auth/accessToken']

      const siteProductPerformance = await siteProductPerformanceService.getAllPerformance(id, group, { accessToken })

      commit('setSiteProductPerformance', { siteProductPerformance })
    },
    async loadSiteTopProducts({ commit, getters, rootGetters }, { id, group}) {
      const accessToken = rootGetters['auth/accessToken']

      const siteTopProducts = await siteProductPerformanceService.getTopProducts(id, group, { accessToken })

      commit('setSiteTopProducts', { siteTopProducts })
    },

    async loadGroups({ commit, getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const groups = await siteProductPerformanceService.getAllGroups({ accessToken })

      commit('setGroups', { groups })
    },
    async loadKinds({ commit, getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const kinds = await siteProductPerformanceService.getAllKinds({ accessToken })

      commit('setKinds', { kinds })
    }
  },
  getters: {
    setSiteFlopProducts: state => state.setSiteFlopProducts,
    setSiteProductPerformance: state => state.setSiteProductPerformance,
    setSiteTopProdocts: state => state.setSiteTopProdocts,
    groups: state => state.groups,
    kinds: state => state.kinds
  },
  mutations: {
    setSiteFlopProducts(state, { siteFlopProducts }) {
      state.siteFlopProducts = siteFlopProducts
    },
    setSiteProductPerformance(state, { siteProductPerformance }) {
      state.siteProductPerformance = siteProductPerformance
    },
    setSiteTopProdocts(state, { siteTopProducts }) {
      state.siteTopProducts = siteTopProducts
    },
    setGroups(state, { groups }) {
      state.groups = groups
    },
    setKinds(state, { kinds }) {
      state.kinds = kinds
    }
  },
  namespaced: true,
  state: {
    siteFlopProducts: [],
    siteProductPerformance: [],
    siteTopProducts: [],
    groups: [],
    kinds: []
  }
}