<template>
  <div>
    <v-dialog v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="500">
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('addProduct') }}</span>
        </v-card-title>
        <v-tabs background-color="transparent"
                v-model="tab">
          <v-tab href="#catalog">{{ $t('catalog') }}</v-tab>
          <v-tab href="#new"
                  v-bind:disabled="!showNew">
            {{ $t('new') }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="catalog">
            <v-form ref="form">
              <v-card-text>
                <v-text-field class="mb-6"
                              filled
                              hide-details="auto"
                              type="number"
                              v-bind:label="$t('quantity')"
                              v-bind:rules="tab === 'catalog' ? [ rules.required ] : []"
                              v-if="showQuantity"
                              v-model="productEntry.quantity" />
                <v-autocomplete class="mb-6"
                                filled
                                hide-details="auto"
                                v-bind:clearable="allowAllProducts"
                                v-bind:items="manufacturerItems"
                                v-bind:label="$t('manufacturer')"
                                v-bind:rules="tab === 'catalog' ? [ rules.required ] : []"
                                v-if="!hideManufacturer"
                                v-model="manufacturerId" />
                <v-autocomplete class="mb-6"
                                filled
                                hide-details="auto"
                                v-bind:disabled="!allowAllProducts && manufacturerId == null"
                                v-bind:items="productItems"
                                v-bind:label="$t('product')"
                                v-bind:rules="tab === 'catalog' ? [ rules.required ] : []"
                                v-model="productEntry.productId"
                                v-on:change="productSelected" />
                <v-select class="mb-6"
                          filled
                          hide-details="auto"
                          v-bind:disabled="productEntry.productId == null"
                          v-bind:items="unitItems"
                          v-bind:label="$t('unit')"
                          v-bind:rules="tab === 'catalog' ? [ rules.required ] : []"
                          v-if="showUnit"
                          v-model="productEntry.unit" />
                <v-text-field class="mb-6"
                              filled
                              hide-details="auto"
                              v-bind:label="$t('discount')"
                              v-if="showDiscount"
                              v-model="productEntry.discount" />
              </v-card-text>
            </v-form>
          </v-tab-item>
          <v-tab-item value="new">
            <v-form ref="form">
              <v-card-text>
                <v-text-field class="mb-6"
                              filled
                              hide-details="auto"
                              type="number"
                              v-bind:label="$t('quantity')"
                              v-bind:rules="tab === 'new' ? [ rules.required ] : []"
                              v-if="showQuantity"
                              v-model="productEntry.quantity" />
                <v-autocomplete class="mb-6"
                                filled
                                hide-details="auto"
                                v-bind:clearable="allowAllProducts"
                                v-bind:items="manufacturerItems"
                                v-bind:label="$t('manufacturer')"
                                v-bind:rules="tab === 'new' ? [ rules.required ] : []"
                                v-if="!hideManufacturer"
                                v-model="manufacturerId" />
                <v-text-field class="mb-6"
                              filled
                              hide-details="auto"
                              validate-on-blur
                              v-bind:label="$t('productId')"
                              v-bind:rules="tab === 'new' ? [ rules.required ] : []"
                              v-model="productEntry.productId" />
                <v-text-field class="mb-6"
                              filled
                              hide-details="auto"
                              v-bind:label="$t('product')"
                              v-bind:rules="tab === 'new' ? [ rules.required ] : []"
                              v-model="productEntry.product" />
                <v-text-field class="mb-6"
                              filled
                              hide-details="auto"
                              v-bind:label="$t('unit')"
                              v-bind:rules="tab === 'new' ? [ rules.required ] : []"
                              v-model="productEntry.unit" />
                <v-text-field class="mb-6"
                              filled
                              hide-details="auto"
                              v-bind:label="$t('discount')"
                              v-model="productEntry.discount" />
              </v-card-text>
            </v-form>
          </v-tab-item>
        </v-tabs-items>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary"
                  text
                  v-on:click="cancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="accent"
                  text
                  v-on:click="confirm">
            {{ isNew ? $t('add') : $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      manufacturerItems() {
        return this.manufacturers.map(m => ({
          text: `${m.title} (${m.id})`,
          value: m.id
        }))
      },
      productItems() {
        let items = null

        if (this.manufacturerId == null || this.manufacturerId.length === 0) {
          items = this.products
        } else {
          items = this.productsOfManufacturer(this.manufacturerId)
        }

        return items.map(p => ({
          text: `${p.title} (${p.id})`,
          value: p.id
        }))
      },
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField'),
          uniqueProductId: v => {
            const isUnique = !this.products?.map(p => p.id).includes(v) ?? true

            if (isUnique) {
              return true
            } else {
              return this.$t('pleaseEnterAUniqueProductId')
            }
          }
        }
      },
      selectedProduct() {
        return this.product(this.productEntry.productId)
      },
      unitItems() {
        if (this.selectedProduct == null) {
          return []
        }

        return [this.selectedProduct.primaryUnit, this.selectedProduct.baseUnit].map(u => ({
          text: u,
          value: u
        }))
      },
      ...mapGetters({
        manufacturers: 'common/manufacturers',
        product: 'common/product',
        products: 'common/products',
        productsOfManufacturer: 'common/productsOfManufacturer'
      })
    },
    data: () => ({
      isNew: false,
      manufacturerId: null,
      productEntry: {
        quantity: 1
      },
      resolve: null,
      tab: null
    }),
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(null)
      },
      confirm() {
        if (!this.$refs.form.validate()) {
          return
        }

        this.productEntry.manufacturerId = this.manufacturerId
        this.productEntry.new = this.tab === 'new'

        this.$emit('input', false)
        this.resolve(this.productEntry)
      },
      async open(resolve, { manufacturerId, productEntry }) {
        this.tab = 'catalog'

        if (productEntry != null) {
          this.isNew = false
          this.manufacturerId = productEntry.manufacturerId
          this.productEntry = productEntry
        } else {
          this.isNew = true
          this.manufacturerId = manufacturerId
          this.productEntry = {
            quantity: 1
          }
        }

        this.resolve = resolve

        this.$emit('input', true)
      },
      productSelected() {
        this.manufacturerId = this.selectedProduct.manufacturerId
        this.productEntry.unit = this.selectedProduct.baseUnit
      }
    },
    name: 'NewProductEntryDialog',
    props: {
      allowAllProducts: Boolean,
      hideManufacturer: Boolean,
      showDiscount: Boolean,
      showNew: Boolean,
      showQuantity: Boolean,
      showUnit: Boolean,
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "add": "Hinzufügen",
      "addProduct": "Artikel hinzufügen",
      "cancel": "Abbrechen",
      "catalog": "Katalog",
      "discount": "Kondition",
      "manufacturer": "Hersteller",
      "new": "Neu",
      "pleaseEnterAUniqueProductId": "Bitte geben Sie eine nicht-vergebene Artikelnummer an.",
      "product": "Artikel",
      "productId": "Artikelnummer",
      "quantity": "Menge",
      "save": "Speichern",
      "unit": "Einheit"
    },
    "en": {
      "add": "Add",
      "addProduct": "Add product",
      "cancel": "Cancel",
      "catalog": "Catalog",
      "discount": "Discount",
      "manufacturer": "Manufacturer",
      "new": "New",
      "pleaseEnterAUniqueProductId": "Please enter a unique product id.",
      "product": "Product",
      "productId": "Product id",
      "quantity": "Quantity",
      "save": "Save",
      "unit": "Unit"
    }
  }
</i18n>