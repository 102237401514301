<template>
  <div>
    <top-header v-bind:title="$t('events')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-bind:to="{ name: 'booking-event-add' }" />
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-bind:to="{ name: 'booking-public'}">
            <v-list-item-title>{{ $t('publicEventOverview') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-container>
      <event-table-card item-route-name="booking-event"
                        v-bind:loading="loading"
                        v-bind:events="events" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import EventTableCard from '@/components/booking/EventTableCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      EventTableCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      ...mapGetters({
        events: 'booking/events'
      })
    },
    async created() {
      this.loading = true

      await this.loadEvents()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions({
        loadEvents: 'booking/loadEvents'
      })
    },
    name: 'Events'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Neu",
      "events": "Seminare",
      "publicEventOverview": "Öffentliche Schulinungsübersicht"
    },
    "en": {
      "add": "Add",
      "events": "Events",
      "publicEventOverview": "Public event overview"
    }
  }
</i18n>