<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <v-toolbar-title>{{ $t('receipt') }}</v-toolbar-title>
    </v-toolbar>
    <v-list class="py-0" two-line>
      <v-subheader>{{ $t('details') }}</v-subheader>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-calendar</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ receipt.date | formatDate('LLL') }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('date') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-tag</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t(receiptStateLabel(receipt.state)) }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('state') }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="hasRole('SagasserPortalAdministrator') || receipt.state !== 'completed'">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon>
                <v-icon color="accent"
                        v-bind="attrs"
                        v-on="on">
                  mdi-pencil
                </v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item v-if="hasRole('SagasserPortalAdministrator') && receipt.state === 'open' || receipt.refundType === 'delivery' && receipt.state === 'open'"
                           v-on:click="$emit('set-receipt-state', { id: receipt.id, state: 'completed' })">
                <v-list-item-title>{{ $t('complete') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="receipt.state === 'open'"
                           v-on:click="$emit('set-receipt-state', { id: receipt.id, state: 'canceled' })">
                <v-list-item-title class="error--text">{{ $t('cancel') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="receipt.state === 'draft'"
                           v-on:click="$emit('delete-receipt', { id: receipt.id })">
                <v-list-item-title class="error--text">{{ $t('delete') }}</v-list-item-title>
              </v-list-item>
              <v-list-item v-if="hasRole('SagasserPortalAdministrator') && receipt.state !== 'draft' && receipt.state !== 'open' || receipt.state === 'canceled'"
                           v-on:click="$emit('set-receipt-state', { id: receipt.id, state: 'open' })">
                <v-list-item-title>{{ $t('reopen') }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-glass-wine</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t(reasonLabel(receipt.reason)) }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('reason') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-truck</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ $t(refundTypeLabel(receipt.refundType)) }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('refundType') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-subheader>{{ $t('site') }}</v-subheader>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-store</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ receipt.site.title }} ({{ receipt.site.id }})</v-list-item-title>
          <v-list-item-subtitle>{{ $t('site') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-map-marker</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ siteAddress(receipt.siteId) }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('location') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ receipt.siteEmployee }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('employee') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider />

      <v-subheader>{{ $t('manufacturer') }}</v-subheader>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-truck</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ receipt.manufacturer.title }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('manufacturer') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-icon>
          <v-icon color="primary">mdi-account</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>{{ receipt.manufacturerRepresentative }}</v-list-item-title>
          <v-list-item-subtitle>{{ $t('representative') }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-divider />

    <v-subheader>{{ $t('products') }}</v-subheader>

    <product-entry-table show-quantity
                         v-bind:product-entries="receipt.products" />

    <v-divider />

    <div class="pa-4">
      <template v-if="receipt.state === 'draft'">
        <signature-pad ref="signaturePad"
                       v-bind:label="$t('representative')" />
        <p class="error--text mb-0 mt-2 text-caption"
           v-if="hasSignatureErrors">
          {{ $t('pleaseSignWithinTheDesignatedArea') }}
        </p>
        <v-checkbox v-bind:label="$t('noSignatureRequiredDiscussedOverThePhone')"
                    v-model="receipt.noSignatureRequired" />
      </template>
      <template v-else>
        <template v-if="receipt.noSignatureRequired">
          <p class="mb-0">{{ $t('noSignatureRequiredDiscussedOverThePhone') }}</p>
        </template>
        <template v-else>
          <v-img class="mb-2"
                max-width="480"
                v-bind:src="receipt.signature" />
          <p class="primary--text mb-0">{{ $t('signatureRepresentative') }}</p>
        </template>
      </template>
    </div>
    <template v-if="receipt.state === 'draft'">
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="accent"
               text
               v-on:click="$emit('sign')">
          {{ $t('signAndComplete') }}
        </v-btn>
      </v-card-actions>
    </template>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ProductEntryTable from '@/components/ProductEntryTable.vue'
  import SignaturePad from '@/components/common/SignaturePad'

  export default {
    components: {
      ProductEntryTable,
      SignaturePad
    },
    computed: {
      ...mapGetters({
        hasRole: 'auth/hasRole',
        reasonLabel: 'freeProductAccounting/reasonLabel',
        receiptStateLabel: 'freeProductAccounting/receiptStateLabel',
        refundTypeLabel: 'freeProductAccounting/refundTypeLabel',
        siteAddress: 'common/siteAddress',
      })
    },
    data: () => ({
      hasSignatureErrors: false
    }),
    methods: {
      getSignature() {
        return this.$refs.signaturePad.getSignature()
      },
      validateSignature() {
        if (this.receipt.noSignatureRequired) {
          return true
        }

        if (this.$refs.signaturePad.validate()) {
          return true
        } else {
          this.hasSignatureErrors = true

          return false
        }
      }
    },
    name: 'ReceiptCard',
    props: {
      receipt: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Stornieren",
      "complete": "Abschließen",
      "date": "Eingangsdatum",
      "delete": "Löschen",
      "details": "Details",
      "employee": "Angestellte:r",
      "location": "Ort",
      "manufacturer": "Hersteller",
      "noSignatureRequiredDiscussedOverThePhone": "Keine Unterschrift erforderlich (telefonisch besprochen)",
      "pleaseSignWithinTheDesignatedArea": "Bitte unterschreiben Sie im dafür vorgesehenen Feld.",
      "products": "Artikel",
      "reason": "Grund",
      "receipt": "Beleg",
      "refundType": "Erstattungsart",
      "reopen": "Wieder eröffnen",
      "representative": "Außendienstmitarbeiter:in",
      "signAndComplete": "Unterschreiben und abschließen",
      "signatureRepresentative": "Unterschrift Außendienstmitarbeiter:in",
      "site": "Markt",
      "state": "Status"
    },
    "en": {
      "cancel": "Cancel",
      "complete": "Complete",
      "date": "Date",
      "delete": "Delete",
      "details": "Details",
      "employee": "Angestellte:r",
      "location": "Location",
      "manufacturer": "Manufacturer",
      "noSignatureRequiredDiscussedOverThePhone": "No signature required (discussed over the phone)",
      "pleaseSignWithinTheDesignatedArea": "Please sign within the designated area.",
      "products": "Products",
      "reason": "Reason",
      "receipt": "Receipt",
      "refundType": "Refund type",
      "reopen": "Reopen",
      "representative": "Representative",
      "signAndComplete": "Sign and complete",
      "signatureRepresentative": "Signature representative",
      "site": "Site",
      "state": "State"
    }
  }
</i18n>