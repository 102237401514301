import Vue from 'vue'

import SiteMeterService from '../api/meter-management/site-meter-service'
import SiteMeterValueService from '../api/meter-management/site-meter-value-service'
import SiteMeterRankingService from '../api/meter-management/site-meter-ranking-service'

import settings from '../settings'

const siteMeterService = new SiteMeterService(settings.apiUri)
const siteMeterValueService = new SiteMeterValueService(settings.apiUri)
const siteMeterRankingService = new SiteMeterRankingService(settings.apiUri)

export default {
  actions: {
    // siteMeters
    // ==========
    async createSiteMeter({ commit, rootGetters }, { siteMeter }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await siteMeterService.create(siteMeter, { accessToken })

      siteMeter.id = result.id

      commit('createSiteMeter', { siteMeter })
    },
    async deleteSiteMeter({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await siteMeterService.delete(id, { accessToken })

      if (result) {
        commit('deleteSiteMeter', { id })
      }
    },
    async loadSiteMeters({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const siteMeters = await siteMeterService.getAll({ accessToken })

      commit('setSiteMeters', { siteMeters })
    },
    async updateSiteMeter({ commit, rootGetters }, { siteMeter }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await siteMeterService.update(siteMeter.id, siteMeter, { accessToken })

      if (result) {
        commit('updateSiteMeter', { siteMeter })
      }
    },
    // siteMeterValues
    // ===============
    async createSiteMeterValue({ commit, rootGetters }, { siteMeterValue }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await siteMeterValueService.create(siteMeterValue, { accessToken })

      siteMeterValue.id = result.id

      commit('createSiteMeterValue', { siteMeterValue })
    },
    async deleteSiteMeterValue({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await siteMeterValueService.delete(id, { accessToken })

      if (result) {
        commit('deleteSiteMeterValue', { id })
      }
    },
    async loadSiteMeterValues({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const siteMeterValues = await siteMeterValueService.getAll({ accessToken })

      commit('setSiteMeterValues', { siteMeterValues })
    },
    async updateSiteMeterValue({ commit, rootGetters }, { siteMeterValue }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await siteMeterValueService.update(siteMeterValue.id, siteMeterValue, { accessToken })

      if (result) {
        commit('updateSiteMeterValue', { siteMeterValue })
      }
    },
    // siteMeterRankings
    // =================
    async loadSiteMeterRankings({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const siteMeterRankings = await siteMeterRankingService.getAll({ accessToken })

      commit('setSiteMeterRankings', { siteMeterRankings })
    },
  },
  getters: {
    siteMeter: state => id => state.siteMeters.find(s => s.id === id),
    siteMeters: state => state.siteMeters,

    siteMeterTypeLabel: state => type => {
      if (type === 'water') {
        return 'global.water'
      } else if (type === 'electricity') {
        return 'global.electricity'
      } else if (type === 'gas') {
        return 'global.gas'
      } else if (type === 'heat-quantity') {
        return 'global.heatQuantity'
      }
    },

    siteMeterValue: state => (date, siteMeterId) => state.siteMeterValues.find(s => s.date === date && s.siteMeterId === siteMeterId),
    siteMeterValues: state => state.siteMeterValues,

    siteMeterRankings: state => state.siteMeterRankings
  },
  mutations: {
    // siteMeters
    // ==========
    createSiteMeter(state, { siteMeter }) {
      state.siteMeters.push(siteMeter)
    },
    deleteSiteMeter(state, { id }) {
      const index = state.siteMeters.findIndex(s => s.id === id)

      if (index !== -1) {
        state.siteMeters.splice(index, 1)
      }
    },
    setSiteMeters(state, { siteMeters }) {
      state.siteMeters = siteMeters
    },
    updateSiteMeter(state, { siteMeter }) {
      const index = state.siteMeters.findIndex(s => s.id === siteMeter.id)

      Vue.set(state.siteMeters, index, siteMeter)
    },
    // siteMeterValues
    // ===============
    createSiteMeterValue(state, { siteMeterValue }) {
      state.siteMeterValues.push(siteMeterValue)
    },
    deleteSiteMeterValue(state, { id }) {
      const index = state.siteMeterValues.findIndex(s => s.id === id)

      if (index !== -1) {
        state.siteMeterValues.splice(index, 1)
      }
    },
    setSiteMeterValues(state, { siteMeterValues }) {
      state.siteMeterValues = siteMeterValues
    },
    updateSiteMeterValues(state, { siteMeterValue }) {
      const index = state.siteMeterValues.findIndex(s => s.id === siteMeterValue.id)

      Vue.set(state.siteMeterValues, index, siteMeterValue)
    },
    // siteMeterRankings
    // =================
    setSiteMeterRankings(state, { siteMeterRankings }) {
      state.siteMeterRankings = siteMeterRankings
    }
  },
  namespaced: true,
  state: {
    siteMeters: [],
    siteMeterValues: [],
    siteMeterRankings: []
  }
}