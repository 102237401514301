<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat
                 v-if="!hideTitleBar">
        <v-toolbar-title>{{ $t('receipts') }}</v-toolbar-title>
        <v-spacer />
        <v-btn exact
               icon
               v-bind:to="{ name: this.addRouteName, params: routeParams }">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                       v-bind:items="inventoryReceipts"
                       v-bind:items-per-page="30">
        <template v-slot:default="props">
          <v-list>
            <v-list-item two-line
                         v-bind:key="inventoryReceipt.id"
                         v-for="inventoryReceipt in props.items"
                         v-on:click="goToItem(inventoryReceipt)">
              <v-list-item-avatar>
                <v-icon class="primary lighten-1"
                        dark>
                  mdi-receipt
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ inventoryReceipt.id }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ inventoryReceipt.createdAt | formatDate('L LT') }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider />
        </template>
        <template v-slot:no-data>
        <v-card-text class="text-center text--disabled">
          {{ $t('noReceiptsAvailable') }}
        </v-card-text>
        <v-divider />
      </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'InventoryReceiptListCard',
    methods: {
      goToItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id, ...this.routeParams }})
      }
    },
    props: {
      addRouteName: String,
      inventoryReceipts: Array,
      itemRouteName: String,
      routeParams: Object,
      hideTitleBar: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "receipts": "Belege",
      "noReceiptsAvailable": "Keine Belege vorhanden"
    },
    "en": {
      "receipts": "Receipts",
      "noReceiptsAvailable": "No receipts available"
    }
  }
</i18n>