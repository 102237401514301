<template>
  <div>
    <v-dialog persistent
              v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="1000">
      <v-card>
        <v-toolbar color="secondary"
                   dark
                   flat>
          <v-toolbar-title>{{ $t('selectSites') }}</v-toolbar-title>
        </v-toolbar>

        <site-selection-table ref="siteTable"
                              v-model="sites" />

        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary"
                 text
                 v-on:click="cancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="accent"
                 text
                 v-bind:disabled="sites.length === 0"
                 v-on:click="confirm">
            {{ $t('add') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import SiteSelectionTable from '@/components/sale-management/SiteSelectionTable'

  export default {
    components: {
      SiteSelectionTable
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    data: () => ({
      resolve: null,
      sites: []
    }),
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(false)
      },
      confirm() {
        this.$emit('input', false)
        this.$emit('confirm')
        this.resolve(this.sites)
      },
      async open(resolve) {
        this.resolve = resolve
        this.$emit('input', true)
      }
    },
    name: 'SiteSelectionDialog',
    props: {
      value: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "add": "Hinzufügen",
      "cancel": "Abbrechen",
      "selectSites": "Märkte wählen"
    },
    "en": {
      "add": "Add",
      "cancel": "Cancel",
      "selectSites": "Select sites"
    }
  }
</i18n>