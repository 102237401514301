<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details
                          prepend-inner-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
          <v-col>
            <v-select clearable
                      flat
                      hide-details="auto"
                      multiple
                      prepend-icon="mdi-tag"
                      solo
                      v-bind:items="campaignStateItems"
                      v-bind:label="$t('state')"
                      v-model="stateFilter" />
          </v-col>
          <v-col>
            <v-select clearable
                      flat
                      hide-details="auto"
                      prepend-icon="mdi-calendar"
                      solo
                      v-bind:items="[ 'Aktueller Monat', 'Aktuelles Quartal', 'Aktuelles Jahr' ]"
                      v-bind:label="$t('period')" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('campaigns') }}</v-toolbar-title>
      <!--<v-text-field class="ml-4"
                    hide-details
                    flat
                    light
                    prepend-inner-icon="mdi-magnify"
                    solo
                    v-model="search" />-->
    </v-toolbar>
    <v-data-table v-bind:headers="headers"
                  v-bind:items="filteredCampaigns"
                  v-bind:sort-by.sync="sortBy"
                  v-bind:sort-desc="sortDesc"
                  v-on:click:row="selectCampaign">
      <template v-slot:[`item.endDate`]="{ item }">
        {{ item.endDate | formatDate('LL') }}
      </template>
      <template v-slot:[`item.startDate`]="{ item }">
        {{ item.startDate | formatDate('LL') }}
      </template>
      <template v-slot:[`item.state`]="{ item }">
        <v-chip dark
                label
                v-bind:color="campaignStateColor(item.state)">
          {{ $t(campaignStateLabel(item.state)) }}
        </v-chip>

        <v-chip dark
                class="ml-2"
                color="light-green"
                label
                v-if="item.hasOrders">
          {{ $t('withOrders') }}
        </v-chip>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    computed: {
      filteredCampaigns() {
        let filteredCampaigns = this.campaigns

        if (this.search != null && this.search.length !== 0) {
          filteredCampaigns = filteredCampaigns.filter(f => f.title.toLowerCase().includes(this.search.toLowerCase()))
        }

        if (this.stateFilter != null && this.stateFilter.length !== 0) {
          filteredCampaigns = filteredCampaigns.filter(f => this.stateFilter.includes(f.state))
        }

        return filteredCampaigns
      },
      headers() {
        return [
          {
            text: this.$t('title'),
            value: 'title'
          },
          {
            text: this.$t('start'),
            value: 'startDate'
          },
          {
            text: this.$t('end'),
            value: 'endDate'
          },
          {
            text: this.$t('state'),
            value: 'state'
          }
        ]
      },
      campaignStateItems() {
        return this.campaignStates.map(c => ({
          text: this.$t(c.label),
          value: c.value
        }))
      },
      ...mapGetters({
        hasRole: 'auth/hasRole',
        campaignStateColor: 'saleManagement/campaignStateColor',
        campaignStateLabel: 'saleManagement/campaignStateLabel',
        campaignStates: 'saleManagement/campaignStates',
        sortPropertiesFor: 'local/sortPropertiesFor'
      })
    },
    created() {
      const sortProperties = this.sortPropertiesFor('campaignTable')
      this.sortBy = sortProperties.sortBy
      this.sortDesc = sortProperties.sortDesc
    },
    data: () => ({
      search: null,
      sortBy: [],
      sortDesc: [],
      stateFilter: null
    }),
    methods: {
      selectCampaign(item) {
        this.$emit('select-campaign', item.id)
      },
      ...mapActions({
        updateSortPropertiesFor: 'local/updateSortPropertiesFor'
      })
    },
    name: 'CampaignTableCard',
    props: {
      itemRouteName: String,
      itemRouteParams: {
        default: () => ({}),
        type: Object
      },
      campaigns: Array
    },
    watch: {
      sortBy() {
        this.updateSortPropertiesFor({ id: 'campaignTable', sortProperties: {
          sortBy: this.sortBy,
          sortDesc: this.sortDesc
        }})
      },
      sortDesc() {
        this.updateSortPropertiesFor({ id: 'campaignTable', sortProperties: {
          sortBy: this.sortBy,
          sortDesc: this.sortDesc
        }})
      }
    }
  }
</script>

<i18n>
  {
    "de": {
      "campaigns": "Aktionen",
      "end": "Ende",
      "period": "Zeitraum",
      "search": "Suche",
      "start": "Start",
      "state": "Status",
      "title": "Titel",
      "withOrders": "mit Bestellungen"
    },
    "en": {
      "campaigns": "Campaigns",
      "end": "End",
      "manufacturer": "Manufacturer",
      "period": "Period",
      "search": "Search",
      "start": "Start",
      "state": "State",
      "title": "Title",
      "withOrders": "with orders"
    }
  }
</i18n>