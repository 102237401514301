<template>
  <v-card class="fill-height"
          elevation="0"
          tile>
    <v-card-text>
      <div class="mb-1 text-center text-h6">
        <span>{{ title }}</span>
      </div>
      <div class="text-center">
        <v-progress-circular v-bind:size="100"
                             v-bind:width="16"
                             v-bind:value="percentValue"
                             color="accent">
        {{ value | formatPercentValue }}
      </v-progress-circular>
    </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    computed: {
      percentValue() {
        if (this.value == null) {
          return 0
        }

        return this.value * 100
      }
    },
    name: 'RatioDashboardCard',
    props: {
      icon: String,
      subTitle: String,
      title: String,
      to: Object,
      value: Number
    }
  }
</script>