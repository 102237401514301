import Vue from 'vue'

import SiteEvaluationService from '../api/evaluation/site-evaluation-service'

import settings from '../settings'

const siteEvaluationService = new SiteEvaluationService(settings.apiUri)

export default {
  actions: {
    async loadSiteEvaluation({ commit, getters, rootGetters }, { id, start, end }) {
      const accessToken = rootGetters['auth/accessToken']

      const siteEvaluation = await siteEvaluationService.find(id, start, end, { accessToken })

      commit('setSiteEvaluation', { siteEvaluation })
    },
    async loadProductPerformance({ commit, getters, rootGetters }, { id, group }) {
      const accessToken = rootGetters['auth/accessToken']

      const productPerformance = await siteEvaluationService.findProductPerformance(id, group, { accessToken })

      commit('setProductPerformance', { productPerformance })
    }
  },
  getters: {
    siteEvaluation: state => state.siteEvaluation,
    productPerformance: state => state.productPerformance
  },
  mutations: {
    setSiteEvaluation(state, { siteEvaluation }) {
      state.siteEvaluation = siteEvaluation
    },
    setProductPerformance(state, { productPerformance }) {
      state.productPerformance = productPerformance
    }
  },
  namespaced: true,
  state: {
    siteEvaluation: null,
    productPerformance: null
  }
}