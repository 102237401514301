<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <v-toolbar-title>{{ $t('order') }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      {{ campaign.description }}
    </v-card-text>
    <v-card-text>
      <slot />
    </v-card-text>
    <v-divider />
    <v-form ref="form">
      <v-list class="py-0">
        <template v-for="(order, index) of orders">
          <v-card-text v-bind:key="`${order.productId}-card_text`">
            <v-row align="center">
              <v-col cols="auto">
                <v-text-field class="quantity-field"
                              hide-details="always"
                              outlined
                              suffix="×"
                              type="number"
                              v-bind:background-color="textFieldColor(order)"
                              v-bind:disabled="!hasRole('SagasserPortalAdministrator') && closed"
                              v-bind:rules="[ rules.validQuantity ]"
                              v-model="order.quantity" />
              </v-col>
              <v-col>
                <p>
                  <strong>{{ order.unit }}</strong>
                  {{ productTitle(order.productId) }} ({{ order.productId }})<br>
                  {{ order.discount }}
                </p>
              </v-col>
              <v-col cols="auto"
                     v-if="campaign.allowComment">
                <v-text-field class="comment-field"
                              hide-details="always"
                              outlined
                              v-bind:disabled="closed"
                              v-bind:placeholder="$t('comment')"
                              v-model="order.comment" />
              </v-col>
            </v-row>
          </v-card-text>
          <v-divider v-bind:key="`${order.productId}-divider`"
                     v-if="index !== campaign.products.length - 1" />
        </template>
      </v-list>
    </v-form>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    components: {
    },
    computed: {
      rules() {
        return {
          validQuantity: v => v != null && v.length !== 0 && v % 1 == 0 && v >= 0
        }
      },
      ...mapGetters({
        productTitle: 'common/productTitle',
        hasRole: 'auth/hasRole'
      })
    },
    methods: {
      textFieldColor(order) {
        if (order.quantity === 0 && order.personInCharge == null) {
          return null
        }

        if (order.quantity === order.lastQuantity) {
          return 'light-green lighten-2'
        }

        return 'amber lighten-2'
      },
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'CampaignOrderCard',
    props: {
      campaign: Object,
      closed: Boolean,
      orders: Array
    }
  }
</script>

<style scoped>
  .comment-field {
    width: 320px;
  }
  .quantity-field {
    width: 88px;
  }
</style>

<i18n>
  {
    "de": {
      "comment": "Kommentar",
      "order": "Bestellung"
    },
    "en": {
      "comment": "Comment",
      "order": "Order"
    }
  }
</i18n>