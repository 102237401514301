import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

function loadMessages() {
  const context = require.context("@/locales", true, /[a-z0-9-_]+\.json$/i)
  const messages = context
    .keys()
    .map(key => ({ key, locale: key.match(/[a-z0-9-_]+/i)[0] }))
    .reduce((messages, { key, locale }) => ({ ...messages, [locale]: context(key) }), {})

  return messages
}

export default new VueI18n({
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  messages: loadMessages(),
  silentFallbackWarn: true
})