<template>
  <div>
    <top-header v-bind:title="$t('ranking')" />
    <v-container>
      <meter-rankings-table-card v-bind:site-meter-rankings="rankings"
                                   v-bind:loading="loading" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import MeterRankingsTableCard from '@/components/meter-management/MeterRankingsTableCard'


  export default {
    components: {
      TopHeader,
      MeterRankingsTableCard
    },
    async created() {
      this.loading = true

      await this.loadSites()
      await this.loadSiteMeterRankings()

      this.rankings = [...this.siteMeterRankings]

      this.loading = false
    },
    computed: {
      ...mapGetters({
        siteId: 'common/currentSiteId',
        siteMeterRankings: 'meterManagement/siteMeterRankings'
      })
    },
    data: () => ({
      rankings: [],
      loading: false
    }),
    methods: {
      ...mapActions({
        loadSites: 'common/loadSites',
        loadSiteMeterRankings: 'meterManagement/loadSiteMeterRankings'
      })
    },
    name: 'MeterRanking'
  }
</script>

<i18n>
  {
    "de": {
      "ranking": "Ranking"
    },
    "en": {
      "ranking": "Ranking"
    }
  }
</i18n>