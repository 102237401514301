<template>
  <v-list class="py-0"
          dark>
    <v-list-item exact
                 v-bind:to="{ name: 'inventory-management-app-inventories' }">
      <v-list-item-icon>
        <v-icon>mdi-warehouse</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('inventories') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    name: 'AppInventoryManagementNavigation'
  }
</script>

<i18n>
  {
    "de": {
      "inventories": "Inventuren"
    },
    "en": {
      "inventories": "Inventories"
    }
  }
</i18n>