<template>
  <div>
    <new-product-entry-dialog hide-manufacturer
                              ref="newProductEntryDialog"
                              show-quantity
                              show-unit
                              v-model="newProductEntryDialog" />
    <v-card flat
            tile>
      <v-subheader>{{ $t('products') }}</v-subheader>

      <product-entry-table show-delete
                           show-quantity
                           v-bind:product-entries="receipt.products"
                           v-on:delete-product="deleteProduct" />

      <v-divider />

      <v-btn block
             color="accent"
             text
             tile
             v-on:click="openNewProductEntryDialog">
        {{ $t('addProduct') }}
      </v-btn>

      <v-divider />

      <v-card-actions v-if="!!nextStepLabel || !!previousStepLabel">
        <v-btn text
               v-if="!!previousStepLabel"
               v-on:click="$emit('previous-step')">
          {{ previousStepLabel }}
        </v-btn>
        <v-spacer />
        <v-btn color="accent"
               text
               v-if="!!nextStepLabel"
               v-on:click="$emit('next-step')">
          {{ nextStepLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import NewProductEntryDialog from '@/components/NewProductEntryDialog.vue'
  import ProductEntryTable from '@/components/ProductEntryTable.vue'

  export default {
    components: {
      NewProductEntryDialog,
      ProductEntryTable
    },
    data: () => ({
      newProductEntryDialog: false
    }),
    name: 'ReceiptProductsStepCard',
    methods: {
      deleteProduct(productId) {
        const index = this.receipt.products.findIndex(p => p.productId === productId)

        if (index !== -1) {
          this.receipt.products.splice(index, 1)
        }
      },
      openNewProductEntryDialog() {
        this.$refs.newProductEntryDialog.open(productEntry => {
          if (productEntry == null) {
            return
          }

          this.deleteProduct(productEntry.productId)

          this.receipt.products.push(productEntry)
        }, { manufacturerId: this.receipt.manufacturerId })
      }
    },
    props: {
      nextStepLabel: String,
      previousStepLabel: String,
      receipt: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "addProduct": "Artikel hinzufügen",
      "products": "Artikel"
    },
    "en": {
      "addProduct": "Add product",
      "products": "Products"
    }
  }
</i18n>