<template>
  <div>
    <v-icon color="black"
            v-bind:key="`empty-${index}`"
            v-for="index in number">{{ !!icon ? icon : 'mdi-circle' }}</v-icon>
    <v-icon color="grey lighten-1"
            v-bind:key="`full-${index}`"
            v-for="index in count - number">{{ !!iconEmpty ? iconEmpty : 'mdi-circle-outline' }}</v-icon>
  </div>
</template>

<script>
  export default {
    name: 'ScaleDisplay',
    props: {
      number: Number,
      count: Number,
      icon: String,
      iconEmpty: String
    }
  }
</script>