<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <v-toolbar-title>{{ $t('products') }}</v-toolbar-title>
    </v-toolbar>
    <v-row>
      <v-col cols="auto">
        <v-text-field label="Artikelnummer"
                      solo
                      flat
                      hide-details="auto"
                      v-model.number="newProductCount"
                      suffix="x"
                      class="count-field" />
      </v-col>
      <v-col>
        <v-autocomplete clearable
                        solo
                        hide-details="auto"
                        flat
                        v-on:change="addProduct"
                        v-bind:items="liveProductItems"
                        v-bind:label="$t('addProduct')"
                        v-bind:loading="loading"
                        v-model="newProduct"
                        append-icon="mdi-plus"
                        v-bind:filter="filterItems"/>
      </v-col>
    </v-row>
    <v-divider />
    <v-data-table  item-key="productId" v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="products"
                  v-bind:items-per-page="50"
                  v-bind:options="{ sortBy: [ 'productId' ] }">
      <!--<template v-slot:item.count="props">
        <v-edit-dialog>
          <div>{{ props.item.count }}</div>
          <template v-slot:input>
            <v-text-field v-bind:value="props.item.count"
                          type="number"
                          hide-details="auto"
                          solo
                          flat
                          autofocus
                          v-on:input="changeCount(props.index, $event)" />
          </template>
        </v-edit-dialog>
      </template>-->
      <template v-slot:item.count="props">
        <v-edit-dialog :return-value.sync="props.item.count">
          <div>{{ props.item.count }}</div>
          <template v-slot:input>

            <v-text-field
              v-model.number="props.item.count"
              type="number"
              flat
              hide-details="auto"
              solo
              autofocus
            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <delete-confirmation-button v-bind:item="item"
                                    v-on:delete="deleteItem($event.productId)" />
      </template>
      <template v-slot:[`item.basePrice`]="{ item }">
        <span v-if="price(item.productId) != null">
          {{ price(item.productId) | formatCurrencyValue }} | {{ price(item.productId, 2) | formatCurrencyValue }} <span v-if="!!price(item.productId,3)">| {{ price(item.productId,3) | formatCurrencyValue }}</span>
        </span>
        <span v-else>
          <v-icon color="deep-orange" small>mdi-alert</v-icon>
        </span>
      </template>
    </v-data-table>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn color="accent"
             text
             v-on:click="$emit('print')"
             v-bind:loading="loading">
        {{ $t('print') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import DeleteConfirmationButton from '@/components/common/DeleteConfirmationButton.vue'

  export default {
    components: {
      DeleteConfirmationButton
    },
    computed: {
      /*enrichedProducts() {
        return this.products.map(p => {
          const prices = this.livePrices.filter(lp => lp.productId === p.productId)

          return {
            ...p,
            basePrice: prices.find(p => p.position === 1) ?? {},
            secondaryPrice: prices.find(p => p.position === 2) ?? {}
          }
        })
      },*/
      liveProductItems() {
        return this.liveProducts.map(p => ({
          text: `${p.productId} - ${p.productTitle} ${ p.ean ? `(${p.ean})` : ''}`,
          value: p.productId
        }))
      },
      headers() {
        return [
          {
            text: this.$t('count'),
            value: 'count'
          },
          {
            text: this.$t('number'),
            value: 'productId'
          },
          {
            text: this.$t('title'),
            value: 'productTitle'
          },
          {
            text: this.$t('group'),
            value: 'group'
          },
          {
            text: this.$t('price'),
            value: 'basePrice'
          },
          {
            sortable: false,
            value: 'actions',
            width: '96px'
          }
        ]
      },
      ...mapGetters({
        liveProducts: 'common/liveProducts',
        livePrices: 'common/livePrices',
        siteId: 'common/currentSiteId'
      })
    },
    data: () => ({
      newProduct: null,
      newProductCount: 1
    }),
    methods: {
      price(productId, position = 1) {
        const prices = this.livePrices.filter(lp => lp.productId === productId)

        return prices.find(p => p.position === position)?.price
      },
      async addProductById(id ,count) {
        this.newProduct = id
        this.newProductCount = count

        await this.addProduct()
      },
      async addProduct() {

        this.loading = true

        if (this.products.find(p => p.productId === this.newProduct)) {
          this.loading = false

          return
        }

        if (!!this.newProduct) {
          let product = this.liveProducts.find(p => p.productId === this.newProduct)

          if (!product) {
            this.loading = false
            return
          }
          
          product.count = this.newProductCount

          if (!!this.printStyle) {
            product.printStyle = this.printStyle
          }
          
          await this.loadLivePrices({ siteId: this.siteId, productId: product.productId })

          this.products.push(product)
          this.newProduct = null
          this.newProductCount = 1
        }

        this.loading = false
      },
      
      deleteItem(id) {
        const index = this.products.findIndex(p => p.productId === id)

        if (index >= 0) {
          this.products.splice(index, 1)
        }
      },
      filterItems(item, queryText, itemText) {


        if (!queryText) {
          return true
        }

        return queryText.split(' ').every(q => itemText.toLowerCase().includes(q.toLowerCase()))
      },
      ...mapActions({
        loadLivePrices: 'common/loadLivePrices'
      })
    },
    name: 'PriceTagProductTableCard',
    props: {
      products: Array,
      loading: Boolean,
      printStyle: String
    }
  }
</script>

<style>
  .count-field {
    width: 96px;
  }
</style>

<i18n>
  {
    "de": {
      "addProduct": "Artikel hinzufügen",
      "products": "Artikel",
      "number": "Nummer",
      "title": "Bezeichnung",
      "print": "Drucken",
      "group": "Gruppe",
      "count": "Anzahl",
      "price": "Preis"
    },
    "en": {
      "addProduct": "Add product",
      "products": "Products",
      "number": "Number",
      "title": "Title",
      "print": "Print",
      "group": "Group",
      "count": "Count",
      "price": "Price"
    }
  }
</i18n>