import Vue from 'vue'
import Vuex from 'vuex'

import { createAuthStoreModule } from 'kayleen-vue-auth0-client'

import auth0Settings from '../auth0-settings'
import bookingStore from './booking-store'
import commonStore from './common-store'
import evaluationStore from './evaluation-store'
import freeProductAccountingStore from './free-product-accounting-store'
import learningStore from './learning-store'
import localStore from './local-store'
import preOrderStore from './pre-order-store'
import productPerformanceStore from './product-performance-store'
import saleManagementStore from './sale-management-store'
import priceTagStore from './price-tag-store'
import meterManagementStore from './meter-management-store'
import inventoryManagementStore from './inventory-management-store'

Vue.use(Vuex)

export default new Vuex.Store({
  actions: {
  },
  getters: {
  },
  modules: {
    auth: createAuthStoreModule(auth0Settings),
    booking: bookingStore,
    common: commonStore,
    evaluation: evaluationStore,
    freeProductAccounting: freeProductAccountingStore,
    learning: learningStore,
    local: localStore,
    preOrder: preOrderStore,
    productPerformance: productPerformanceStore,
    saleManagement: saleManagementStore,
    priceTag: priceTagStore,
    meterManagement: meterManagementStore,
    inventoryManagement: inventoryManagementStore
  },
  mutations: {
  },
  state: {
  }
})