export default class SiteEvaluationService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }


  async find(id, options) {
    try {
      const response = await fetch(`${this.baseUrl}/preOrder/site/${id}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }
}