<template>
  <div>
    <v-chip-group column>
      <v-chip dark
              label
              v-bind:key="index"
              v-bind:to="{ name: 'booking-public-event', params: { category, subCategory } }"
              v-for="(subCategory, index) in subCategories">
        {{ subCategory }}
      </v-chip>
    </v-chip-group>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  //import DeleteConfirmationButton from '@/components/common/DeleteConfirmationButton.vue'

  export default {
    components: {
      //DeleteConfirmationButton
    },
    computed: {
      ...mapGetters({
      })
    },
    name: 'PublicEventCategoryView',
    props: {
      category: String,
      subCategories: Array
    }
  }
</script>

<i18n>
  {
    "de": {
    },
    "en": {
    }
  }
</i18n>