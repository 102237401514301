import { render, staticRenderFns } from "./RichTextField.vue?vue&type=template&id=8bb9b504&scoped=true"
import script from "./RichTextField.vue?vue&type=script&lang=js"
export * from "./RichTextField.vue?vue&type=script&lang=js"
import style0 from "./RichTextField.vue?vue&type=style&index=0&id=8bb9b504&prod&scoped=true&lang=css"
import style1 from "quill/dist/quill.snow.css?vue&type=style&index=1&prod&lang=css&external"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8bb9b504",
  null
  
)

export default component.exports