<template>
  <div>
    <top-header v-bind:title="$t('myCampaigns')" />
    <v-container>
      <campaign-table-card item-route-name="sale-management-campaign-order"
                           v-bind:campaigns="myCampaigns"
                           v-on:select-campaign="selectCampaign" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import CampaignTableCard from '@/components/sale-management/CampaignTableCard.vue'

  export default {
    components: {
      CampaignTableCard,
      TopHeader
    },
    computed: {
      myCampaigns() {
        return this.campaigns.filter(c => (c.state === 'active' || c.state === 'closed') && c.sites.some(s => s.siteId === this.currentSiteId))
      },
      ...mapGetters({
        getCampaignOrdersForCampaignAndSite: 'saleManagement/campaignOrdersForCampaignAndSite',
        getCampaigns: 'saleManagement/campaigns',
        currentSiteId: 'common/currentSiteId'
      })
    },
    data: () => ({
      campaigns: []
    }),
    async created() {
      await this.loadCampaigns()

      const campaigns = this.getCampaigns


      this.campaigns = await Promise.all(campaigns.map(async campaign => {
        await this.loadCampaignOrdersForCampaignAndSite({ campaignId: campaign.id, siteId: this.currentSiteId, siteKey: null })
        const orders = this.getCampaignOrdersForCampaignAndSite(campaign.id, this.currentSiteId)

        return {
          ...campaign,
          hasOrders: orders.some(o => o.quantity > 0)
        }
      }))
    },
    methods: {
      selectCampaign(id) {
        this.$router.push({ name: 'sale-management-campaign-order', params: { campaignId: id, siteId: this.currentSiteId }})
      },
      ...mapActions({
        loadCampaignOrdersForCampaignAndSite: 'saleManagement/loadCampaignOrdersForCampaignAndSite',
        loadCampaigns: 'saleManagement/loadCampaigns'
      })
    },
    name: 'SiteCampaigns'
  }
</script>

<i18n>
  {
    "de": {
      "myCampaigns": "Meine Aktionen"
    },
    "en": {
      "myCampaigns": "My campaigns"
    }
  }
</i18n>