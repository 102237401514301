export default class TenantService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  
  async getAll(options) {
    const response = await fetch(`${this.baseUri}/tenant`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }
}