<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('stats') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row>
          <v-col>
            <p class="mb-0 primary--text text-center text-h3">{{ participatingTrainees }} / {{ traineeCount }}</p>
            <p class="mb-0 text-center text-h6">teilgenommen</p>
          </v-col>
          <v-col>
            <p class="mb-0 primary--text text-center text-h3">{{ passedTraineeCount }} / {{ participatingTrainees }}</p>
            <p class="mb-0 text-center text-h6">bestanden</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      passedTraineeCount() {
        if (this.exam == null) {
          return 0
        }

        return this.exam.trainees.filter(t => t.hasPassed).length
      },
      participatingTrainees() {
        if (this.exam == null) {
          return 0
        }

        return this.exam.trainees.filter(t => t.correctQuestions != null).length
      },
      traineeCount() {
        if (this.exam == null) {
          return 0
        }

        return this.exam.trainees.length
      }
    },
    name: 'ExamStatsCard',
    props: {
      exam: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "stats": "Statistik"
    },
    "en": {
      "stats": "Stats"
    }
  }
</i18n>