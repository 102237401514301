<template>
  <div>
    <v-menu min-width="auto"
            ref="menu"
            offset-y
            transition="scale-transition"
            v-bind:close-on-content-click="false">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-bind="attrs"
                      v-bind:disabled="disabled"
                      v-bind:filled="filled"
                      v-bind:flat="flat"
                      v-bind:hide-details="hideDetails"
                      v-bind:label="label"
                      v-bind:placeholder="formatString"
                      v-bind:prepend-inner-icon="prependInnerIcon"
                      v-bind:rules="rules"
                      v-bind:solo="solo"
                      v-mask="mask"
                      v-model="internalValue"
                      v-on="on"
                      v-on:change="change"
                      v-on:input="input"
                      validate-on-blur />
      </template>
      <v-date-picker color="accent"
                      header-color="secondary"
                      v-bind:locale="$i18n.locale"
                      v-bind:value="value != null ? value.substring(0, 10) : null"
                      v-on:change="changeDatePicker" />
    </v-menu>
  </div>
</template>

<script>
  import moment from 'moment'

  export default {
    computed: {
      formatString() {
        return 'DD.MM.YYYY HH:mm'
      },
      mask() {
        return this.formatString.replace(/[DMYHm]/g, '#');
      }
    },
    created() {
      if (this.value != null) {
        this.internalValue = moment.utc(this.value).locale(this.$i18n.locale).format(this.formatString)
      }
    },
    data: () => ({
      internalValue: null
    }),
    methods: {
      change() {
        if (this.value == null) {
          this.internalValue = null
        }
      },
      changeDatePicker(value) {
        this.$emit('input', value)
        this.internalValue = moment.utc(value).locale(this.$i18n.locale).format(this.formatString)
      },
      input(value) {
        let date = moment.utc(value, this.formatString, this.$i18n.locale, true).toISOString()

        this.$emit('input', date)
      }
    },
    name: 'DateField',
    props: {
      disabled: Boolean,
      filled: Boolean,
      flat: Boolean,
      hideDetails: String,
      label: String,
      prependInnerIcon: String,
      rules: Array,
      solo: Boolean,
      value: String
    }
  }
</script>