<template>
  <div>
    <new-product-entry-dialog allow-all-products
                              ref="newProductEntryDialog"
                              show-new
                              show-discount
                              show-unit
                              v-model="newProductEntryDialog" />
    <v-card flat
            tile>
      <v-subheader>{{ $t('products') }}</v-subheader>

      <product-entry-table show-delete
                           show-discount
                           show-edit
                           show-unit
                           v-bind:product-entries="campaign.products"
                           v-on:delete-product="deleteProduct"
                           v-on:edit-product="openEditProductEntryDialog" />

      <v-divider />

      <v-btn block
             color="accent"
             text
             tile
             v-on:click="openNewProductEntryDialog">
        {{ $t('addProduct') }}
      </v-btn>

      <v-divider />

      <v-card-actions v-if="!!nextStepLabel || !!previousStepLabel">
        <v-btn text
               v-if="!!previousStepLabel"
               v-on:click="$emit('previous-step')">
          {{ previousStepLabel }}
        </v-btn>
        <v-spacer />
        <v-btn color="accent"
               text
               v-if="!!nextStepLabel"
               v-on:click="$emit('next-step')">
          {{ nextStepLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import NewProductEntryDialog from '@/components/NewProductEntryDialog.vue'
  import ProductEntryTable from '@/components/ProductEntryTable.vue'

  export default {
    components: {
      NewProductEntryDialog,
      ProductEntryTable
    },
    data: () => ({
      newProductEntryDialog: false
    }),
    name: 'CampaignProductsStepCard',
    methods: {
      deleteProduct(productId) {
        const index = this.campaign.products.findIndex(p => p.productId === productId)

        if (index !== -1) {
          this.campaign.products.splice(index, 1)
        }
      },
      openEditProductEntryDialog(productId) {
        const existingProductEntry = this.campaign.products.find(p => p.productId === productId)

        if (existingProductEntry == null) {
          return
        }

        this.$refs.newProductEntryDialog.open(productEntry => {
          if (productEntry == null) {
            return
          }

          this.deleteProduct(productEntry.productId)

          this.campaign.products.push(productEntry)
        }, { productEntry: Object.assign({}, existingProductEntry) })
      },
      openNewProductEntryDialog() {
        this.$refs.newProductEntryDialog.open(async productEntry => {
          if (productEntry == null) {
            return
          }

          if (productEntry.new) {
            const product = {
              id: productEntry.productId,
              title: productEntry.product,
              primaryUnit: productEntry.unit,
              baseUnit: productEntry.unit,
              manufacturerId: productEntry.manufacturerId,
              temporary: true
            }

            await this.createProduct({ product })
          }

          this.deleteProduct(productEntry.productId)

          this.campaign.products.push(productEntry)
        }, {})
      },
      ...mapActions({
        createProduct: 'common/createProduct'
      })
    },
    props: {
      campaign: Object,
      nextStepLabel: String,
      previousStepLabel: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "addProduct": "Artikel hinzufügen",
      "products": "Artikel"
    },
    "en": {
      "addProduct": "Add product",
      "products": "Products"
    }
  }
</i18n>