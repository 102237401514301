import PrimaryNavigation from '../views/navigation/PrimaryNavigation.vue'
import Campaign from '../views/sale-management/Campaign.vue'
import CampaignAdd from '../views/sale-management/CampaignAdd.vue'
import CampaignEdit from '../views/sale-management/CampaignEdit.vue'
import CampaignOrder from '../views/sale-management/CampaignOrder.vue'
import Campaigns from '../views/sale-management/Campaigns.vue'
import SaleManagement from '../views/sale-management/SaleManagement.vue'
import SiteCampaigns from '../views/sale-management/SiteCampaigns.vue'

export default [
  {
    components: {
      default: SaleManagement,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'sale-management',
    path: '/sale-management'
  },
  {
    components: {
      default: CampaignAdd,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'sale-management-campaign-add',
    path: '/sale-management/campaigns/add'
  },
  {
    components: {
      default: Campaign,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'sale-management-campaign',
    path: '/sale-management/campaigns/:id'
  },
  {
    components: {
      default: CampaignEdit,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'sale-management-campaign-edit',
    path: '/sale-management/campaigns/:id/edit'
  },
  {
    components: {
      default: CampaignOrder,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSite'))
    },
    name: 'sale-management-campaign-order',
    path: '/sale-management/campaigns/:campaignId/orders/:siteId'
  },
  {
    components: {
      default: Campaigns,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'sale-management-campaigns',
    path: '/sale-management/campaigns'
  },
  {
    components: {
      default: SiteCampaigns,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSite'))
    },
    name: 'sale-management-site-campaigns',
    path: '/sale-management/my-campaigns'
  }
]