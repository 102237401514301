import SiteProductPerformance from '../views/product-performance/SiteProductPerformance.vue'
import PrimaryNavigation from '../views/navigation/PrimaryNavigation.vue'

export default [
  {
    components: {
      default: SiteProductPerformance,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalSiteEvaluation') || hasRole('SagasserPortalAdministrator'))
    },
    name: 'product-performance-site',
    path: '/product-performance/site'
  }
]