import Vue from 'vue'

import SitePreOrderService from '../api/pre-order/site-pre-order-service'

import settings from '../settings'

const sitePreOrderService = new SitePreOrderService(settings.apiUri)

export default {
  actions: {
    async loadSitePreOrders({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const sitePreOrders = await sitePreOrderService.find(id, { accessToken })

      commit('setSitePreOrders', { sitePreOrders })
    }
  },
  getters: {
    sitePreOrders: state => state.sitePreOrders
  },
  mutations: {
    setSitePreOrders(state, { sitePreOrders }) {
      state.sitePreOrders = sitePreOrders
    }
  },
  namespaced: true,
  state: {
    sitePreOrders: []
  }
}