<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('exams') }}</v-toolbar-title>
      </v-toolbar>
      <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                       v-bind:items="exams"
                       v-bind:items-per-page="10">
        <template v-slot:default="props">
          <v-list>
            <v-list-item two-line
                         v-bind:key="exam.id"
                         v-bind:to="{ name: 'learning-my-exam', params: { invitationKey, id: exam.id } }"
                         v-for="exam in props.items">
              <v-list-item-content>
                <v-list-item-title>{{ exam.title }}</v-list-item-title>
                <v-list-item-subtitle>{{ exam.start | formatDate }} – {{ exam.end | formatDate }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-divider />
        </template>
        <template v-slot:no-data>
          <v-card-text class="text-center text--disabled">
            {{ $t('noExamsAvailable') }}
          </v-card-text>
          <v-divider />
        </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'ExamListCard',
    props: {
      exams: Array,
      invitationKey: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "noExamsAvailable": "Keine Prüfungen vorhanden",
      "exams": "Prüfungen"
    },
    "en": {
      "noExamsAvailable": "No exams available",
      "exams": "Exams"
    }
  }
</i18n>