import FreeProductAccounting from '../views/free-product-accounting/FreeProductAccounting.vue'
import Receipt from '../views/free-product-accounting/Receipt.vue'
import ReceiptAdd from '../views/free-product-accounting/ReceiptAdd.vue'
import Receipts from '../views/free-product-accounting/Receipts.vue'
import PrimaryNavigation from '../views/navigation/PrimaryNavigation.vue'

export default [
  {
    components: {
      default: FreeProductAccounting,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ isAuthenticated }) => isAuthenticated
    },
    name: 'free-product-accounting',
    path: '/free-product-accounting'
  },
  {
    components: {
      default: ReceiptAdd,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ isAuthenticated }) => isAuthenticated
    },
    name: 'free-product-accounting-receipt-add',
    path: '/free-product-accounting/receipts/add'
  },
  {
    components: {
      default: Receipt,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ isAuthenticated }) => isAuthenticated
    },
    name: 'free-product-accounting-receipt',
    path: '/free-product-accounting/receipts/:id'
  },
  {
    components: {
      default: Receipts,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ isAuthenticated }) => isAuthenticated
    },
    name: 'free-product-accounting-receipts',
    path: '/free-product-accounting/receipts'
  }
]