<template>
  <div>
    <top-header v-bind:title="title | defaultValue($t('exam'))">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'learning-my-exam', params: { invitationKey, id } }"
                     v-if="exam != null" />
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <questionnaire-display v-bind:subject="subject"
                           v-if="subject != null"
                           v-on:submit="submit" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import QuestionnaireDisplay from '@/components/learning/QuestionnaireDisplay.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const invitationKey = to.params.invitationKey
      const id = parseInt(to.params.id)

      await this.get(invitationKey, id)

      next()
    },
    components: {
      HeaderButton,
      QuestionnaireDisplay,
      TopHeader
    },
    computed: {
      id() {
        return this.exam?.id
      },
      subject() {
        return this.exam?.subject
      },
      title() {
        return this.exam?.title
      },
      ...mapGetters({
        getExam: 'learning/exam'
      })
    },
    async created() {
      const invitationKey = this.$route.params.invitationKey
      const id = parseInt(this.$route.params.id)

      await this.get(invitationKey, id)
    },
    data: () => ({
      exam: null,
      invitationKey: null,
      loading: false
    }),
    methods: {
      async get(invitationKey, id) {
        this.invitationKey = invitationKey

        this.loading = true

        await this.loadExamsForInvitationKey({ invitationKey })

        this.loading = false

        this.exam = Object.assign({}, this.getExam(id))
      },
      async submit(model) {
        const examAnswers = Object.keys(model).map(k => ({ questionId: k, answerIds: model[k] }))

        await this.evaluateExam({ invitationKey: this.invitationKey, id: this.id, examAnswers })

        this.$router.push({ name: 'learning-my-exam', params: { invitationKey: this.invitationKey, id: this.id }})
      },
      ...mapActions({
        evaluateExam: 'learning/evaluateExam',
        loadExamsForInvitationKey: 'learning/loadExamsForInvitationKey'
      })
    },
    name: 'MyQuestionnaire'
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "exam": "Prüfung"
    },
    "en": {
      "cancel": "Cancel",
      "exam": "Exam"
    }
  }
</i18n>