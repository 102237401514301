import Vue from 'vue'

import AttendeeService from '../api/booking/attendee-service'
import EventDateService from '../api/booking/event-date-service'
import EventService from '../api/booking/event-service'

import settings from '../settings'

const attendeeService = new AttendeeService(settings.apiUri)
const eventDateService = new EventDateService(settings.apiUri)
const eventService = new EventService(settings.apiUri)

export default {
  actions: {
    // attendee
    // ========
    async cancelAttendee({ commit, rootGetters }, { id, code }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await attendeeService.cancel(id, code, { accessToken })

      return result
    },
    async createAttendee({ commit, rootGetters }, { attendee }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await attendeeService.create(attendee, { accessToken })

      if (result == null) {
        return null
      }

      attendee.id = result.id

      commit('createAttendee', { attendee })

      return attendee
    },
    async deleteAttendee({ commit, rootGetters }, { id, eventDateId }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await attendeeService.delete(id, { accessToken })

      if (result) {
        commit('deleteAttendee', { id, eventDateId })
      }
    },
    async loadAttendee({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const attendee = await attendeeService.find(id, { accessToken })

      if (getters.attendee(id) != null) {
        commit('updateAttendee', { attendee })
      } else {
        commit('createAttendee', { attendee })
      }
    },
    async loadAttendees({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const attendees = await attendeeService.getAll({ accessToken })

      commit('setAttendees', { attendees })
    },
    async updateAttendee({ commit, rootGetters }, { attendee }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await attendeeService.update(attendee.id, attendee, { accessToken })

      if (result) {
        commit('updateAttendee', { attendee })
      }
    },

    // eventDate
    // =========
    async createEventDate({ commit, rootGetters }, { eventDate }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await eventDateService.create(eventDate, { accessToken })

      eventDate.id = result.id

      commit('createEventDate', { eventDate })
    },
    async deleteEventDate({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await eventDateService.delete(id, { accessToken })

      if (result) {
        commit('deleteEventDate', { id })
      }
    },
    async loadEventDate({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const eventDate = await eventDateService.find(id, { accessToken })

      if (getters.eventDate(id) != null) {
        commit('updateEventDate', { eventDate })
      } else {
        commit('createEventDate', { eventDate })
      }
    },
    async loadEventDates({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const eventDates = await eventDateService.getAll({ accessToken })

      commit('setEventDates', { eventDates })
    },
    async updateEventDate({ commit, rootGetters }, { eventDate }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await eventDateService.update(eventDate.id, eventDate, { accessToken })

      if (result) {
        commit('updateEventDate', { eventDate })
      }
    },

    // event
    // =====
    async createEvent({ commit, rootGetters }, { event }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await eventService.create(event, { accessToken })

      event.id = result.id

      commit('createEvent', { event })
    },
    async deleteEvent({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await eventService.delete(id, { accessToken })

      if (result) {
        commit('deleteEvent', { id })
      }
    },
    async loadEvent({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const event = await eventService.find(id, { accessToken })

      if (getters.event(id) != null) {
        commit('updateEvent', { event })
      } else {
        commit('createEvent', { event })
      }
    },
    async loadEvents({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const events = await eventService.getAll({ accessToken })

      commit('setEvents', { events })
    },
    async updateEvent({ commit, rootGetters }, { event }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await eventService.update(event.id, event, { accessToken })

      if (result) {
        commit('updateEvent', { event })
      }
    },
  },
  getters: {
    attendee: state => id => state.attendees.find(a => a.id === id),
    attendees: state => state.attendees,
    eventDate: state => id => state.eventDates.find(e => e.id === id),
    eventDates: state => state.eventDates,
    event: state => id => state.events.find(e => e.id === id),
    events: state => state.events
  },
  mutations: {
    // attendee
    // ========
    createAttendee(state, { attendee }) {
      state.attendees.push(attendee)

      const eventDate = state.eventDates.find(e => e.id === attendee.eventDateId)

      if (eventDate != null) {
        eventDate.attendees.push(attendee)
      }
    },
    deleteAttendee(state, { eventDateId, id }) {
      const index = state.attendees.findIndex(a => a.id === id)

      if (index !== -1) {
        state.attendees.splice(index, 1)
      }

      const eventDate = state.eventDates.find(e => e.id === eventDateId)

      if (eventDate == null) {
        return
      }

      const attendeeIndex = eventDate.attendees.findIndex(a => a.id === id)

      if (attendeeIndex !== -1) {
        eventDate.attendees.splice(attendeeIndex, 1)
      }
    },
    setAttendees(state, { attendees }) {
      state.attendees = attendees
    },
    updateAttendee(state, { attendee }) {
      const index = state.attendees.findIndex(a => a.id === attendee.id)

      Vue.set(state.attendees, index, attendee)

      const eventDate = state.eventDates.find(e => e.id === attendee.eventDateId)

      if (eventDate == null) {
        return
      }

      const attendeeIndex = eventDate.attendees.findIndex(a => a.id === attendee.id)

      Vue.set(eventDate.attendees, attendeeIndex, attendee)
    },

    // eventDate
    // =========
    createEventDate(state, { eventDate }) {
      state.eventDates.push(eventDate)
    },
    deleteEventDate(state, { id }) {
      const index = state.eventDates.findIndex(e => e.id === id)

      if (index !== -1) {
        state.eventDates.splice(index, 1)
      }
    },
    setEventDates(state, { eventDates }) {
      state.eventDates = eventDates
    },
    updateEventDate(state, { eventDate }) {
      const index = state.eventDates.findIndex(e => e.id === eventDate.id)

      Vue.set(state.eventDates, index, eventDate)
    },

    // event
    // =====
    createEvent(state, { event }) {
      state.events.push(event)
    },
    deleteEvent(state, { id }) {
      const index = state.events.findIndex(e => e.id === id)

      if (index !== -1) {
        state.events.splice(index, 1)
      }
    },
    setEvents(state, { events }) {
      state.events = events
    },
    updateEvent(state, { event }) {
      const index = state.events.findIndex(e => e.id === event.id)

      Vue.set(state.events, index, event)
    }
  },
  namespaced: true,
  state: {
    attendees: [],
    eventDates: [],
    events: []
  }
}