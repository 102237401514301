<template>
  <v-card flat
          tile>
    <v-form ref="form">
      <v-list class="py-0" two-line>
        <v-subheader>{{ $t('site') }}</v-subheader>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">mdi-store</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ siteTitle(receipt.siteId) }} ({{ receipt.siteId }})</v-list-item-title>
            <v-list-item-subtitle>{{ $t('site') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">mdi-map-marker</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ siteAddress(receipt.siteId) }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('location') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-card-text>
        <v-text-field filled
                      hide-details="auto"
                      v-bind:label="$t('employee')"
                      v-bind:rules="[ rules.required ]"
                      v-model="receipt.siteEmployee" />
      </v-card-text>

      <v-divider />

      <v-subheader>{{ $t('manufacturer') }}</v-subheader>

      <v-card-text>
        <v-autocomplete class="mb-6"
                        filled
                        hide-details="auto"
                        v-bind:items="manufacturerItems"
                        v-bind:label="$t('manufacturer')"
                        v-bind:rules="[ rules.required ]"
                        v-model="receipt.manufacturerId" />
        <v-text-field filled
                      hide-details="auto"
                      v-bind:label="$t('representative')"
                      v-bind:rules="[ rules.required ]"
                      v-model="receipt.manufacturerRepresentative" />
      </v-card-text>

      <v-divider />

      <v-subheader>{{ $t('details') }}</v-subheader>

      <v-card-text>
        <v-select class="mb-6"
                  filled
                  hide-details="auto"
                  v-bind:items="reasonItems"
                  v-bind:label="$t('reason')"
                  v-bind:rules="[ rules.required ]"
                  v-model="receipt.reason" />
        <v-select filled
                  hide-details="auto"
                  v-bind:items="refundTypeItems"
                  v-bind:label="$t('refundType')"
                  v-bind:rules="[ rules.required ]"
                  v-model="receipt.refundType" />
      </v-card-text>

      <v-divider />
      <v-card-actions v-if="!!nextStepLabel || !!previousStepLabel">
        <v-btn text
              v-if="!!previousStepLabel"
              v-on:click="$emit('previous-step')">
          {{ previousStepLabel }}
        </v-btn>
        <v-spacer />
        <v-btn color="accent"
              text
              v-if="!!nextStepLabel"
              v-on:click="$emit('next-step')">
          {{ nextStepLabel }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      manufacturerItems() {
        return this.manufacturers.map(m => ({
          text: `${m.title} (${m.id})`,
          value: m.id
        }))
      },
      reasonItems() {
        return this.reasons.map(r => ({
          text: this.$t(r.label),
          value: r.value
        }))
      },
      refundTypeItems() {
        return this.refundTypes.map(r => ({
          text: this.$t(r.label),
          value: r.value
        }))
      },
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      ...mapGetters({
        manufacturers: 'common/manufacturers',
        reasons: 'freeProductAccounting/reasons',
        refundTypes: 'freeProductAccounting/refundTypes',
        siteAddress: 'common/siteAddress',
        siteTitle: 'common/siteTitle'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'ReceiptDetailsStepCard',
    props: {
      nextStepLabel: String,
      previousStepLabel: String,
      receipt: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details",
      "employee": "Angestellte:r",
      "location": "Ort",
      "manufacturer": "Hersteller",
      "reason": "Grund",
      "refundType": "Erstattungsart",
      "representative": "Außendienstmitarbeiter:in",
      "site": "Markt"
    },
    "en": {
      "details": "Details",
      "employee": "Employee",
      "location": "Location",
      "manufacturer": "Hersteller",
      "reason": "Reason",
      "refundType": "Refund type",
      "representative": "Representative",
      "site": "Site"
    }
  }
</i18n>