export default class InventoryPackagingUnitService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async create(inventoryPackagingUnit, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPackagingUnit`, {
      body: JSON.stringify(inventoryPackagingUnit),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(inventoryProductId, unit, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPackagingUnit/${inventoryProductId}/${unit}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(inventoryProductId, unit, options) {
    try {
      const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPackagingUnit/${inventoryProductId}/${unit}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPackagingUnit`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async update(inventoryProductId, unit, inventoryPackagingUnit, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPackagingUnit/${inventoryProductId}/${unit}`, {
      body: JSON.stringify(inventoryPackagingUnit),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async sync(deleteMissing, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPackagingUnit/import/sync?deleteMissing=${deleteMissing}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return response.ok
  }
}