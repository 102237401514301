<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisEventIrrevocably')"
                    v-bind:title="$t('deleteEvent')"
                    v-model="confirmDeleteDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="title | defaultValue($t('event'))">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'booking-events' }" />

      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateEvent" />

      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="downloadEventAttendees">
            <v-list-item-title>{{ $t('exportAttendees') }}</v-list-item-title>
          </v-list-item>
          <v-divider />
          <v-list-item v-on:click="deleteEvent">
            <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container v-if="event != null">
      <v-row>
        <v-col cols="12"
               lg="6">
          <event-details-card ref="eventDetailsCard"
                              v-bind:event="event" />
        </v-col>
        <v-col cols="12"
               lg="6">
          <event-date-list-card add-route-name="booking-event-date-add"
                                item-route-name="booking-event-date"
                                v-bind:event-dates="event.dates"
                                v-bind:route-params="{ eventId: id }" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import EventDateListCard from '@/components/booking/EventDateListCard.vue'
  import EventDetailsCard from '@/components/booking/EventDetailsCard.vue'
  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      ConfirmDialog,
      EventDateListCard,
      EventDetailsCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      title() {
        return this.event?.title
      },
      id() {
        return this.event?.id
      },
      ...mapGetters({
        department: 'common/department',
        getEvent: 'booking/event',
        tenant: 'local/tenant'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      confirmDeleteDialog: false,
      event: null,
      loading: false,
      validationSnackbar: false
    }),
    methods: {
      async deleteEvent() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteEvent({ id: this.id })

          this.loading = false

          this.$router.push({ name: 'booking-events' })
        })
      },
      async downloadEventAttendees() {
        // TODO: Clean this code

        const attendees = []

        this.event.dates.forEach(d => {
          attendees.push(...d.attendees.map(a => ({
            ...a,
            end: d.end,
            start: d.start
          })))
        })

        let csv = '"Beginn";"Ende";"Vorname";"Nachname";"Mandant";"Abteilung";"Markt";"E-Mail-Adresse";"Storniert";"Stornierungsdatum"\n'

        attendees.forEach(a => {
          csv += `"${a.start}";"${a.end}";"${a.givenName}";"${a.familyName}";"${this.tenant(a.tenantId)?.title}";"${this.department(a.departmentId)?.title}";"${a.siteId ?? ''}";"${a.email}";"${a.hasCancelled}";"${a.cancelledDate}"\n`
        })

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
        element.setAttribute('download', 'export.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click();

        document.body.removeChild(element)


      },
      async get(id) {
        this.loading = true

        await this.loadDepartments()
        await this.loadEvents()

        this.loading = false

        this.event = Object.assign({}, this.getEvent(id))
      },
      async updateEvent() {
        if (!this.$refs.eventDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateEvent({ event: this.event })

        this.loading = false

        this.$router.push({ name: 'booking-events' })
      },
      ...mapActions({
        doDeleteEvent: 'booking/deleteEvent',
        doUpdateEvent: 'booking/updateEvent',
        loadDepartments: 'common/loadDepartments',
        loadEvents: 'booking/loadEvents'
      })
    },
    name: 'Event'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisEventIrrevocably": "Sind Sie sicher, dass Sie dieses Seminar unwiderruflich löschen möchten?",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "delete": "Löschen",
      "deleteEvent": "Seminar löschen",
      "event": "Seminar",
      "exportAttendees": "Teilnehmer exportieren",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "areYouSureYouWantToDeleteThisEventIrrevocably": "Are you sure you want to delete this event irrevocably?",
      "back": "Back",
      "cancel": "Cancel",
      "delete": "Delete",
      "deleteEvent": "Delete event",
      "event": "Event",
      "exportAttendees": "Export attendees",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>