<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('signin') }}</v-toolbar-title>
      </v-toolbar>
      <user-details ref="userDetails"
                         v-bind:user="user" v-on:submit="$emit('signin')" />
      <v-card-actions>
        <v-spacer />
        <v-btn color="accent"
                text
                v-on:click="$emit('signin')">
          {{ $t('signin') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import UserDetails from '@/components/inventory-management/UserDetails'

  export default {
    components: {
      UserDetails
    },
    name: 'UserDetailsCard',
    methods: {
      validate() {
        return this.$refs.userDetails.validate()
      }
    },
    props: {
      user: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "signin": "Anmelden"
    },
    "en": {
      "signin": "Signin"
    }
  }
</i18n>