<template>
  <div>
    <v-progress-linear color="accent"
                         indeterminate
                         v-if="loading" />
    <div v-for="product in productsWithPrices" v-if="!loading">
      <div class="price-tag" v-for="index in product.count">
        <h2 class="h-label">
          <img src="/images/price-tag/ew.png" alt="Einweg" class="icon-label" v-if="product.label == 'EINWEG'" />
          <img src="/images/price-tag/mw.png" alt="Mehrweg" class="icon-label" v-if="product.label == 'MEHRWEG'" />
          {{ product.label }}
        </h2>
        <div class="h-title-container"><h1 class="h-title" v-html="withLineBreaksTest(insertNonBreakingSpace(product.publicTitle))"></h1></div>
        <h2 class="h-price" v-if="product.sale"><span class="h-sale">Angebot</span> {{ product.salePrice | formatNumber }}</h2>
        <h2 class="h-price" v-else>{{ product.basePrice.price | formatNumber }}</h2>
        <p class="sub-price">
          <span v-if="product.sale">
            {{ product.saleUnit }} {{ product.saleQuantity }} x {{ product.basePrice.capacity | formatNumber }}
            zzgl. Pfand
          </span>
          <span v-else>
            {{ product.basePrice.unit }} {{ product.basePrice.quantity }} x {{ product.basePrice.capacity | formatNumber }}
            l zzgl. Pfand
          </span>

        </p>
        <v-row>
          <v-col cols="6">
            <table class="table"
                  v-if="product.group === 'Bier'">
              <tr>
                <td><strong>Herkunft:</strong></td>
                <td>{{ product.breweryLocation }}</td>
              </tr>
              <tr>
                <td><strong>Bierstil:</strong></td>
                <td>{{ product.kind }}</td>
              </tr>
              <tr>
                <td><strong>Alkoholgehalt:</strong></td>
                <td>{{ product.alcohol | formatNumber({ suffix: '%' }) }}</td>
              </tr>
              <tr>
                <td><strong>Brauart:</strong></td>
                <td>{{ product.brewingStyle }}</td>
              </tr>
              <tr>
                <td><strong>Bittere:</strong></td>
                <td><scale-display icon="mdi-spa" icon-empty="mdi-spa-outline" v-bind:number="product.bitternessScale" v-bind:count="5" /></td>
              </tr>
              <tr>
                <td><strong>Farbe:</strong></td>
                <td>{{ product.color }}</td>
              </tr>
              <tr v-if="product.foodRecommendation != null">
                <td><strong>Speiseempfehlung:</strong></td>
                <td>
                  <img class="food" v-if="isValidFoodRecommendation(food)" v-bind:src="`/images/price-tag/food/${food}.png`" v-for="food of foodRecommendationsForProduct(product)" />
                </td>
              </tr>
            </table>


            <table class="table"
                  v-else-if="product.group === 'AFG' && (product.kind === 'Mineralwasser' || product.kind === 'Heilwasser')">
              <tr v-if="!!product.breweryLocation">
                <td><strong>Quelle:</strong></td>
                <td>{{ product.breweryLocation }}</td>
              </tr>
              <tr v-if="!!product.kind">
                <td><strong>Klassifizierung:</strong></td>
                <td>{{ product.kind }}</td>
              </tr>
              <tr v-if="product.magnesiumScale != null">
                <td><strong>Magnesium:</strong></td>
                <td><scale-display v-bind:number="product.magnesiumScale" v-bind:count="5" /></td>
              </tr>
              <tr v-if="product.calciumScale != null">
                <td><strong>Calcium:</strong></td>
                <td><scale-display v-bind:number="product.calciumScale" v-bind:count="5" /></td>
              </tr>
              <tr v-if="product.hydrogenScale != null">
                <td><strong>Hydrogencarbonat:</strong></td>
                <td><scale-display v-bind:number="product.hydrogenScale" v-bind:count="5" /></td>
              </tr>
              <tr v-if="product.sodiumScale != null">
                <td><strong>Natrium:</strong></td>
                <td><scale-display v-bind:number="product.sodiumScale" v-bind:count="5" /></td>
              </tr>
              <tr v-if="product.chlorideScale != null">
                <td><strong>Chlorid:</strong></td>
                <td><scale-display v-bind:number="product.chlorideScale" v-bind:count="5" /></td>
              </tr>
              <tr v-if="product.foodRecommendation != null">
                <td><strong>Speiseempfehlung:</strong></td>
                <td>
                  <img class="food" v-bind:src="`/images/price-tag/food/${food}.png`" v-for="food of foodRecommendationsForProduct(product)" />
                </td>
              </tr>
            </table>


            <table class="table"
                  v-else>
              <tr v-if="!!product.fruitContent">
                <td><strong>Fruchtgehalt:</strong></td>
                <td>{{ product.fruitContent | formatNumber({ suffix: '%' })}}</td>
              </tr>
              <tr v-if="!!product.sugar">
                <td><strong>Zuckergehalt:</strong></td>
                <td>{{ product.sugar | formatNumber({ suffix: 'g/100 ml' }) }}</td>
              </tr>
              <tr v-if="!!product.caffeine">
                <td><strong>Koffeingehalt:</strong></td>
                <td>{{ product.caffeine | formatNumber({ suffix: 'mg/100 ml' }) }}</td>
              </tr>
              <tr v-if="product.foodRecommendation != null">
                <td><strong>Speiseempfehlung:</strong></td>
                <td>
                  <img class="food" v-bind:src="`/images/price-tag/food/${food}.png`" v-for="food of foodRecommendationsForProduct(product)" />
                </td>
              </tr>
            </table>
          </v-col>
          <v-col cols="6">
            <table class="table table--pricing">
              <tr>
                <td class="hidden-border"></td>
                <td class="align-right"><strong><span v-if="product.sale">{{ product.saleUnit }}</span><span v-else>Kasten</span></strong></td>
                <td class="align-right"><strong>Flasche</strong></td>
              </tr>
              <tr>
                <td><strong>Preis</strong></td>
                <td class="align-right"><span v-if="product.sale">{{ product.salePrice | formatNumber }}</span><span v-else>{{ product.basePrice.price | formatNumber }}</span></td>
                <td class="align-right">{{ product.secondaryPrice.price | formatNumber }}</td>
              </tr>
              <tr>
                <td><strong>Preis / Liter</strong></td>
                <td class="align-right"><span v-if="product.sale">{{ pricePerLiter(product.salePrice, product.saleQuantity, product.secondaryPrice.capacity) | formatNumber }}</span><span v-else>{{ product.basePrice.pricePerLiter | formatNumber }}</span></td>
                <td class="align-right">{{ product.secondaryPrice.pricePerLiter | formatNumber }}</td>
              </tr>
              <tr>
                <td><strong>Pfand</strong></td>
                <td class="align-right"><span v-if="product.sale">{{ (product.basePrice.depositBaseUnit *  product.saleQuantity) | formatNumber }}</span><span v-else>{{ product.basePrice.deposit | formatNumber }}</span></td>
                <td class="align-right">{{ product.basePrice.depositBaseUnit | formatNumber }}</td>
              </tr>
            </table>
            <div class="seals">
              <img class="seal" v-bind:src="`/images/price-tag/${seal}.png`" v-for="seal of sealsForProduct(product)" />
              <img class="seal" v-bind:src="`/images/price-tag/WbaGold.png`" v-if="product.worldBeerAward === 'GOLD'" />
              <img class="seal" v-bind:src="`/images/price-tag/WbaSilber.png`" v-if="product.worldBeerAward === 'SILBER'" />
              <img class="seal" v-bind:src="`/images/price-tag/WbaBronze.png`" v-if="product.worldBeerAward === 'BRONZE'" />
            </div>
          </v-col>
        </v-row>
        <div class="h-addition-container">
          <p v-if="product.sale" class="h-addition">{{ product.saleText }}</p>
        </div>
        <div class="product-id">
          <p class="mb-0">*{{ product.productId }}*</p>
          <p class="mb-0 bar-code">*{{ product.productId }}*</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import ScaleDisplay from '@/components/common/ScaleDisplay.vue'

  export default {
    components: {
      ScaleDisplay
    },
    computed: {
      productsWithPrices() {
        return this.priceTagProducts.map(p => {
          const prices = this.livePrices.filter(lp => lp.productId === p.productId)


          return {
            ...p,
            basePrice: prices.find(p => p.position === 1) ?? {},
            secondaryPrice: prices.find(p => p.position === 2) ?? {}
          }
        })
      },
      ...mapGetters({
        priceTagProducts: 'priceTag/products',
        livePrices: 'common/livePrices',
        siteId: 'common/currentSiteId'
      })
    },
    async created() {
      this.loading = true

      for await (let product of this.priceTagProducts) {
        await this.loadLivePrices({ siteId: this.siteId, productId: product.productId })
      }

      this.loading = false

      setTimeout(() => window.print(), 500)
    },
    data: () => ({
      loading: false
    }),
    methods: {
      withLineBreaksTest(text) {
        return text.replace(/\/\//g, '<br>')
      },
      foodRecommendationsForProduct(product) {
        let foods = product.foodRecommendation.split(',')


        foods = foods.map(f => f.trim())
        foods = foods.map(f => f.replace(/ä/g, "ae").replace(/ö/g, "oe").replace(/ü/g, "ue").replace(/ß/g, "ss"))

        let foodsArray = foods.splice(0, 6)

        if (foodsArray.length > 2) {
          // shuffle 
          foodsArray.sort(() => Math.random() - 0.5)
          
          return foodsArray.slice(0, 2)
        }

        return foodsArray
        
      },
      isValidFoodRecommendation(foodRecommendation) {
        const foodRecommendations = [ 'Braten', 'Fisch', 'Gefluegel', 'Gegrilltes', 'Kaese', 'Meeresfruechte', 'Rind', 'Salat', 'Schwein', 'Vegetarisch', 'Wild' ]

        return foodRecommendations.includes(foodRecommendation)
      },
      sealsForProduct(product) {
        const seals = [ 'BeerAward', 'Baby', 'Organic', 'Dlg', 'NobleMaturation', 'NotForConsumption', 'Abstinence', 'Franken', 'GlutenFree', 'Heartbeat', 'Bone', 'Pregnant', 'Pyrosis', 'Sport', 'Vegan', 'Digestion', 'Wine', 'SugarFree' ]

        const productSeals = seals.filter(s => product[s.toLocaleLowerCase()] === '1')

        if (productSeals.length > 5) {
          // shuffle productSeals
          productSeals.sort(() => Math.random() - 0.5)
          
          return productSeals.slice(0, 5)
        }

        return productSeals

      },
      pricePerLiter(price, quantity, capacity) {
        if (!price || !quantity || !capacity) return '-'

        return price / quantity / capacity
      },
      insertNonBreakingSpace(text) {
        if (!text) return text

        var regex = / (?=l)/g;

        var resultString = text.replace(regex, '\u00A0');

        return resultString;
      },
      ...mapActions({
        loadLivePrices: 'common/loadLivePrices'
      })
    },
    name: 'PriceTagPrint'
  }
</script>

<style scoped>
  @font-face {
    font-family: 'Codedreineun';
    font-weight: 100;
    src: url('/fonts/Codedreineun.woff2') format('woff2');
  }

  .price-tag {
    page-break-after: always;
  }

  .h-label {
    font-size: 7rem;
    text-align: center;
    padding: 0;
    background: #dddddd;
    font-family: 'Roboto Condensed';
    font-weight: 400;
  }

  .icon-label {
    width: 4.5rem;
    height: 4.5rem;
    margin-right: 0.5rem;
  }

  .h-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 23.5rem;
  }

  .h-title {
    font-size: 5.2rem;
    text-align: center;
    margin: 0.5rem 0;
    line-height: 1.1;

  }

  .h-price {
    font-size: 7rem;
    text-align: center;
    border-bottom: 0.125rem solid black;
    border-top: 0.125rem solid black;
    line-height: 1.25;
    margin: 1rem 0 0 0;
  }

  .h-sale {
    font-size: 5rem;
  }

  .sub-price {
    text-align: right;
    font-size: 1.5rem;
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .table {
    width: 100%;
    font-size: 1.125rem;
    line-height: 1.125;
  }

  .table--pricing {
    border-collapse:collapse;
  }

 .table--pricing td {
    border: 0.125rem solid black;
    padding: 0.125rem 0.25rem;
  }

  .hidden-border {
    border: none !important;
  }

  .align-right {
    text-align: right;
  }

  .product-id {
    margin: 0 0 0 auto;
    width: fit-content;
    font-size: 1.1rem;
    margin-top: 0rem;
  }

  .bar-code {
    font-family: 'Codedreineun';
    font-size: 2.75rem;
    line-height: 1;
  }

  .seals {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    min-height: 48px;
    margin-top: 16px;
  }

  .seal {
    display: block;
    width: 52px;
    height: 52px;
  }

  .food {
    display: inline-block;
    width: 48px;
    height: 48px;
    margin-right: 0.25rem;
  }

  .h-addition {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
  }

  .h-addition-container {
    min-height: 2rem;
  }
</style>

<i18n>
  {
    "de": {
    },
    "en": {
    }
  }
</i18n>

