export default class InventoryPositionService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async create(inventoryPosition, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPosition`, {
      body: JSON.stringify(inventoryPosition),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(inventoryReceiptId, positionNumber, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPosition/${inventoryReceiptId}/${positionNumber}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(inventoryReceiptId, positionNumber, options) {
    try {
      const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPosition/${inventoryReceiptId}/${positionNumber}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPosition`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async getAllForReceipt(receiptId, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPosition/receipt/${receiptId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async update(inventoryReceiptId, positionNumber, inventoryPosition, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPosition/${inventoryReceiptId}/${positionNumber}`, {
      body: JSON.stringify(inventoryPosition),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async sync(inventoryReceiptId, deleteMissing, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryPosition/import/sync/${inventoryReceiptId}?deleteMissing=${deleteMissing}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return response.ok
  }
}