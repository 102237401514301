import Vue from 'vue'
import VueRouter from 'vue-router'

import { createAuthRouterGuard } from 'kayleen-vue-auth0-client'

import store from '../store'
import administration from './administration'
import bookingRoutes from './booking-routes'
import commonRoutes from './common-routes'
import evaluationRoutes from './evaluation-routes'
import freeProductAccountingRoutes from './free-product-accounting-routes'
import learningRoutes from './learning-routes'
import preOrderRoutes from './pre-order-routes'
import priceTagRoutes from './price-tag-routes'
import productPerformanceRoutes from './product-performance-routes'
import saleManagementRoutes from './sale-management-routes'
import meterManagementRoutes from './meter-management-routes'
import inventoryManagementRoutes from './inventory-management-routes'

Vue.use(VueRouter)

const routes = [
  ...administration,
  ...bookingRoutes,
  ...commonRoutes,
  ...evaluationRoutes,
  ...freeProductAccountingRoutes,
  ...learningRoutes,
  ...preOrderRoutes,
  ...priceTagRoutes,
  ...productPerformanceRoutes,
  ...saleManagementRoutes,
  ...meterManagementRoutes,
  ...inventoryManagementRoutes
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})

router.beforeEach(createAuthRouterGuard(store))

router.onError(_error => {
  router.push('/')
})

export default router