<template>
  <div>
    <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                      v-bind:items="events"
                      v-bind:items-per-page="10">
      <template v-slot:default="props">
        <v-row>
          <v-col cols="12"
                 md="6"
                 lg="4"
                 v-for="event in props.items"
                 v-bind:key="event.id">
            <v-card flat
                    tile>
              <v-toolbar color="secondary"
                         dark
                         flat>
                <v-toolbar-title>{{ event.title }}</v-toolbar-title>
              </v-toolbar>
              <template v-if="event.description != null && event.description.length !== 0">
                <v-card-text>
                  <v-chip class="mb-2"
                          color="deep-orange"
                          dark
                          label
                          small
                          v-if="event.private">
                    {{ $t('private') }}
                  </v-chip>
                  <p class="mb-0" v-html="event.description"></p>
                </v-card-text>
                <v-divider />
              </template>
              <v-subheader>{{ $t('dates') }}</v-subheader>
              <v-list two-line
                      v-if="event.dates.filter(d => d.isBookable).length > 0">
                <v-list-item v-bind:key="eventDate.id"
                             v-for="eventDate in event.dates.filter(d => d.isBookable)"
                             v-bind:to="{ name: 'booking-public-event-booking', params: { id: event.id, eventDateId: eventDate.id } }">
                  <v-list-item-content>
                    <v-list-item-title>{{ eventDate.start | formatDate('L LT') }} - {{ eventDate.end | formatDate('L LT') }}</v-list-item-title>
                    <v-list-item-subtitle>{{ eventDate.online ? $t('online') : $t('at', { location: eventDate.location })}}</v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-chip class="ml-2 white--text"
                                label
                                small
                                v-bind="attrs"
                                v-bind:color="getColor(eventDate)"
                                v-on="on">
                          {{ eventDate.reservedSlots }} / {{ eventDate.slots }}
                        </v-chip>
                      </template>
                      <span>{{ $t('theRegistrationPeriodEndsOn', { date: $options.filters.formatDate(eventDate.registrationPeriod, 'L LT') }) }}</span>
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
              <v-card-text class="text--disabled"
                           v-if="event.dates.filter(d => d.isBookable).length === 0">
                {{ $t('noBookableDatesAvailable') }}
              </v-card-text>
              <v-divider />
              <v-list two-line>
                <v-subheader>{{ $t('referee') }}</v-subheader>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="accent">
                      mdi-account-tie
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ event.referee }}</v-list-item-title>
                    <v-list-item-subtitle>{{ event.email }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
                <v-divider />
                <v-subheader>{{ $t('fees') }}</v-subheader>
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon color="accent">
                      mdi-currency-eur
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ event.attendanceFee | formatCurrencyValue }}</v-list-item-title>
                    <v-list-item-subtitle>{{ $t('attendanceFee') }}</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <template v-if="event.cancellationPolicy != null && event.cancellationPolicy.length !== 0">
                <v-divider />
                <v-subheader>{{ $t('cancellationPolicy') }}</v-subheader>
                <v-card-text>
                  <p class="mb-0">{{ event.cancellationPolicy }}</p>
                </v-card-text>
              </template>
            </v-card>
          </v-col>
        </v-row>
      </template>
      <template v-slot:no-data>
        <v-card-text class="text-center">
          {{ $t('noEventsAvailable') }}
        </v-card-text>
      </template>
    </v-data-iterator>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  //import DeleteConfirmationButton from '@/components/common/DeleteConfirmationButton.vue'

  export default {
    components: {
      //DeleteConfirmationButton
    },
    computed: {
      ...mapGetters({
        //departmentTitle: 'common/departmentTitle',
        //tenantTitle: 'local/tenantTitle'
      })
    },
    name: 'PublicEventTiles',
    methods: {
      getColor(eventDate) {
        if (eventDate.hasFreeSlots) {
          return 'light-green'
        }

        return 'deep-orange'
      },
    },
    props: {
      events: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "attendanceFee": "Teilnamegebühr",
      "cancellationPolicy": "Stornobedingungen",
      "dates": "Termine",
      "email": "E-Mail-Adresse",
      "fees": "Gebühren",
      "events": "Seminare",
      "at": "in {location}",
      "noBookableDatesAvailable": "Keine buchbaren Termine vorhanden",
      "noEventsAvailable": "Keine Seminare vorhanden",
      "online": "Online",
      "private": "Privat",
      "referee": "Referent",
      "theRegistrationPeriodEndsOn": "Die Anmeldefrist endet am {date}."
    },
    "en": {
      "attendanceFee": "Attendance fee",
      "cancellationPolicy": "Cancellation policy",
      "dates": "Dates",
      "email": "Email",
      "fees": "Fees",
      "events": "Events",
      "at": "at {location}",
      "noBookableDatesAvailable": "No bookable dates available",
      "noEventsAvailable": "No events available",
      "online": "Online",
      "private": "Private",
      "referee": "Referee",
      "theRegistrationPeriodEndsOn": "The registration period ends on {date}."
    }
  }
</i18n>