<template>
  <v-form ref="form" v-on:submit.prevent="$emit('submit')">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('username')"
                        v-bind:rules="[ rules.required ]"
                        v-model="user.username" />
        </v-col>
      </v-row>      
    </v-card-text>
  </v-form>
</template>

<script>
  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'UserDetails',
    props: {
      user: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "username": "Benutzername"
    },
    "en": {
      "username": "Username"
    }
  }
</i18n>