<template>
  <div>
    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="$t('newExam')">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'learning-exams' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="createExam" />
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container>
      <v-row>
        <v-col cols="12"
               lg="6">
          <exam-details-card ref="examDetailsCard"
                             v-bind:exam="exam" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import ExamDetailsCard from '@/components/learning/ExamDetailsCard.vue'

  export default {
    components: {
      ExamDetailsCard,
      HeaderButton,
      TopHeader
    },
    async created() {
      this.loading = true

      await this.loadSubjects()

      this.loading = false
    },
    data: () => ({
      exam: {},
      loading: false,
      validationSnackbar: false
    }),
    methods: {
      async createExam() {
        if (!this.$refs.examDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        await this.doCreateExam({ exam: this.exam })

        this.$router.push({ name: 'learning-exams' })
      },
      ...mapActions({
        doCreateExam: 'learning/createExam',
        loadSubjects: 'learning/loadSubjects'
      })
    },
    name: 'ExamAdd'
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "newExam": "Neue Prüfung",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "cancel": "Cancel",
      "newExam": "New exam",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>