<template>
  <v-card flat
          tile>
    <v-form ref="form">
      <v-subheader>{{ $t('details') }}</v-subheader>

      <v-card-text>
        <campaign-details ref="campaignDetails"
                          v-bind:campaign="campaign" />
      </v-card-text>

      <v-divider />
      <v-card-actions v-if="!!nextStepLabel || !!previousStepLabel">
        <v-btn text
               v-if="!!previousStepLabel"
               v-on:click="$emit('previous-step')">
          {{ previousStepLabel }}
        </v-btn>
        <v-spacer />
        <v-btn color="accent"
               text
               v-if="!!nextStepLabel"
               v-on:click="$emit('next-step')">
          {{ nextStepLabel }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import CampaignDetails from '@/components/sale-management/CampaignDetails.vue'

  export default {
    components: {
      CampaignDetails
    },
    methods: {
      validate() {
        return this.$refs.campaignDetails.validate()
      }
    },
    name: 'CampaignDetailsStepCard',
    props: {
      nextStepLabel: String,
      previousStepLabel: String,
      campaign: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details"
    },
    "en": {
      "details": "Details"
    }
  }
</i18n>