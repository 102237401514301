<template>
  <div v-if="campaign != null">
    <confirm-dialog ref="confirmDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisCampaignIrrevocably')"
                    v-bind:title="$t('deleteCampaign')"
                    v-model="confirmDialog" />

    <site-selection-dialog ref="siteSelectionDialog"
                           v-model="siteSelectionDialog" />

    <top-header v-bind:title="campaign.title">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'sale-management-campaigns' }" />

      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="duplicate">
            <v-list-item-title>{{ $t('duplicate') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="downloadOrders">
            <v-list-item-title>{{ $t('exportOrders') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-bind:to="{ name: 'sale-management-campaign-edit', params: { id: campaign.id }}">
            <v-list-item-title>{{ $t('edit') }}</v-list-item-title>
          </v-list-item>
          <template v-if="campaign.state === 'pending'">
          <v-divider />
            <v-list-item v-on:click="deleteCurrentCampaign">
              <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>

      <v-tabs background-color="transparent"
              dark
              slot="tabs"
              v-model="tab">
        <v-tab href="#common">{{ $t('common') }}</v-tab>
        <v-tab href="#sites">{{ $t('sites') }}</v-tab>
        <v-tab href="#orders">{{ $t('orders') }}</v-tab>
      </v-tabs>
    </top-header>

    <v-tabs-items class="transparent"
                  v-model="tab">
      <v-tab-item value="common">
        <v-container>
          <campaign-card v-bind:campaign="campaign"
                         v-on:add-product="addProduct"
                         v-on:change="updateCurrentCampaign"
                         v-on:delete-product="deleteProduct"
                         v-on:set-campaign-state="setCampaignStateAndRefresh" />
        </v-container>
      </v-tab-item>
      <v-tab-item value="sites">
        <v-container>
          <campaign-site-orders-card v-bind:sites="campaignSites"
                                     v-on:add-site="showSiteDialog"
                                     v-on:delete-site="deleteSite"
                                     v-on:select-site="selectSite" />
        </v-container>
      </v-tab-item>
      <v-tab-item value="orders">
        <v-container>
          <campaign-orders-card v-bind:orders="orders" />
        </v-container>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import CampaignCard from '@/components/sale-management/CampaignCard.vue'
  import CampaignOrdersCard from '@/components/sale-management/CampaignOrdersCard.vue'
  import CampaignSiteOrdersCard from '@/components/sale-management/CampaignSiteOrdersCard.vue'
  import SiteSelectionDialog from '@/components/sale-management/SiteSelectionDialog.vue'

  export default {
    async beforeRouteUpdate(to, _, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      CampaignCard,
      CampaignOrdersCard,
      CampaignSiteOrdersCard,
      ConfirmDialog,
      HeaderButton,
      SiteSelectionDialog,
      TopHeader
    },
    computed: {
      campaignSites() {
        const campaignSites = this.campaign.sites.map(s => s.siteId)

        return this.sites.filter(s => campaignSites.includes(s.id)).map(s => ({
          ...s,
          orders: this.orders.filter(o => o.siteId === s.id).length
        }))
      },
      orders() {
        return this.getCampaignOrdersForCampaign(this.campaign.id)
      },
      ...mapGetters({
        getCampaign: 'saleManagement/campaign',
        getCampaignOrdersForCampaign: 'saleManagement/campaignOrdersForCampaign',
        sites: 'common/sites'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      campaign: null,
      confirmDialog: false,
      siteSelectionDialog: false,
      tab: null
    }),
    methods: {
      async addProduct(product) {
        await this.createCampaignProduct({
          campaignId: this.campaign.id,
          product
        })
      },
      async deleteCurrentCampaign() {
        await this.$refs.confirmDialog.open(async r => {
          if (!r) {
            return
          }

          await this.deleteCampaign({ id: this.campaign.id })

          this.$router.push({ name: 'sale-management-campaigns' })
        })
      },
      async deleteProduct(productId) {
        await this.deleteCampaignProduct({
          campaignId: this.campaign.id,
          productId
        })
      },
      async deleteSite(siteId) {
        await this.deleteCampaignSite({
          campaignId: this.campaign.id,
          siteId
        })
      },
      async downloadOrders() {
        // TODO: Clean this code

        //let csv = '"campaignId";"siteId";"productId";"date";"quantity";"unit"\n'

        let csv = '"Kampagnennummer";"Bezeichnung";"Marktnummer";"Marktbezeichnung";"Mandant";"ABSA";"Artikelnummer";"Artikelbezeichnung";"Datum";"Anzahl";"Einheit";"Bearbeiter";"Kommentar"\n'

        console.log(this.orders)

        this.orders.forEach(o => {
          csv += `"${o.campaignId}";"${this.campaign.title}";"${o.siteId}";"${o.site.title}";"${o.site.tenant}";"${o.externalSiteId}";"${o.productId}";"${o.productTitle}";"${o.date}";"${o.quantity}";"${o.unit}";"${o.personInCharge}";"${o.comment}"\n`
        })

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
        element.setAttribute('download', 'export.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click();

        document.body.removeChild(element)
      },
      async duplicate() {
        this.$router.push({ name: 'sale-management-campaign-add', query: { from: this.campaign.id } })
      },
      async get(id) {
        await this.loadCampaignOrdersForCampaign({ campaignId: id })
        await this.loadCampaigns()
        await this.loadManufacturers()
        await this.loadProducts()
        await this.loadSites()

        this.campaign = Object.assign({}, this.getCampaign(id))
      },
      async showSiteDialog() {
        await this.$refs.siteSelectionDialog.open(async r => {
          if (!r) {
            return
          }

          for (const site of r) {
            if (!this.campaign.sites.find(s => s.siteId === site.id)) {
              await this.createCampaignSite({
                campaignId: this.campaign.id,
                site: {
                  siteId: site.id
                }
              })
            }
          }
        })
      },
      selectSite(site) {
        this.$router.push({ name: 'sale-management-campaign-order', params: { campaignId: this.campaign.id, siteId: site.id }})
      },
      async setCampaignStateAndRefresh({ id, state }) {
        await this.setCampaignState({ id, state })

        await this.get(this.campaign.id)
      },
      async updateCurrentCampaign() {
        await this.updateCampaign({ campaign: this.campaign })
      },
      ...mapActions({
        createCampaignProduct: 'saleManagement/createCampaignProduct',
        createCampaignSite: 'saleManagement/createCampaignSite',
        deleteCampaign: 'saleManagement/deleteCampaign',
        deleteCampaignProduct: 'saleManagement/deleteCampaignProduct',
        deleteCampaignSite: 'saleManagement/deleteCampaignSite',
        loadCampaignOrdersForCampaign: 'saleManagement/loadCampaignOrdersForCampaign',
        loadCampaigns: 'saleManagement/loadCampaigns',
        loadManufacturers: 'common/loadManufacturers',
        loadProducts: 'common/loadProducts',
        loadSites: 'common/loadSites',
        setCampaignState: 'saleManagement/setCampaignState',
        updateCampaign: 'saleManagement/updateCampaign'
      })
    },
    name: 'Campaign'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisCampaignIrrevocably": "Sind Sie sicher, dass Sie diese Aktion unwiderruflich löschen möchten?",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "common": "Allgemein",
      "delete": "Löschen",
      "deleteCampaign": "Aktion löschen",
      "duplicate": "Duplizieren",
      "edit": "Bearbeiten",
      "exportOrders": "Bestellungen exportieren",
      "orders": "Bestellungen",
      "sites": "Märkte"
    },
    "en": {
      "areYouSureYouWantToDeleteThisCampaignIrrevocably": "Are you sure you want to delete this campaign irrevocably?",
      "back": "Back",
      "cancel": "Cancel",
      "common": "Common",
      "delete": "Delete",
      "deleteCampaign": "Delete campaign",
      "duplicate": "Duplicate",
      "edit": "Edit",
      "export": "Export",
      "exportOrders": "Export orders",
      "orders": "Orders",
      "sites": "Sites"
    }
  }
</i18n>