<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('registration') }}</v-toolbar-title>
      </v-toolbar>
      <attendee-details ref="attendeeDetails"
                        v-bind:attendee="attendee"
                        v-bind:external="external" />
      <v-card-text>
        <v-form ref="form">
          <v-row v-if="private">
            <v-col>
              <v-text-field filled
                            hide-details="auto"
                            v-bind:append-icon="showCode ? 'mdi-eye' : 'mdi-eye-off'"
                            v-bind:label="$t('code')"
                            v-bind:rules="[ rules.required ]"
                            v-bind:type="showCode ? 'text' : 'password'"
                            v-on:click:append="showCode = !showCode"
                            v-model="attendee.eventCode" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox class="mt-0"
                          hide-details="always"
                          v-bind:label="$t('yesIWouldLikeToParticipateInTheSeminarOn', { start: $options.filters.formatDate(start, 'L LT'), title })"
                          v-bind:rules="[ rules.required ]"
                          v-model="attendee.participate" />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-checkbox class="mt-0"
                          hide-details="always"
                          v-bind:label="$t('yesIHaveAcceptedTheCancellationPolicy')"
                          v-bind:rules="[ rules.required ]"
                          v-model="attendee.acceptCancellationPolicy"
                          v-if="!external" />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="accent"
               text
               v-bind:disabled="disabled"
               v-on:click="$emit('register')">
          {{ $t('register') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import AttendeeDetails from '@/components/booking/AttendeeDetails'

  export default {
    components: {
      AttendeeDetails
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    data: () => ({
      showCode: false
    }),
    name: 'PublicEventAttendeeCard',
    methods: {
      validate() {
        return this.$refs.attendeeDetails.validate() & this.$refs.form.validate()
      }
    },
    props: {
      attendee: Object,
      disabled: Boolean,
      private: Boolean,
      start: String,
      title: String,
      external: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "code": "Code",
      "register": "Anmelden",
      "registration": "Anmeldung",
      "yesIHaveAcceptedTheCancellationPolicy": "Ja, ich habe die Stornobedingungen zur Kenntnis genommen.",
      "yesIWouldLikeToParticipateInTheSeminarOn": "Ja, ich möchte am {start} am Seminar „{title}“ teilnehmen."
    },
    "en": {
      "code": "Code",
      "register": "Register",
      "registration": "Registration",
      "yesIHaveAcceptedTheCancellationPolicy": "Yes, I have accepted the cancellation policy.",
      "yesIWouldLikeToParticipateInTheSeminarOn": "Yes, I would like to participate in the seminar \"{title}\" on {start}."
    }
  }
</i18n>