import Vue from 'vue'

import ExamService from '../api/learning/exam-service'
import QuestionService from '../api/learning/question-service'
import SubjectDocumentService from '../api/learning/subject-document-service'
import SubjectService from '../api/learning/subject-service'
import TraineeService from '../api/learning/trainee-service'

import settings from '../settings'

const examService = new ExamService(settings.apiUri)
const questionService = new QuestionService(settings.apiUri)
const subjectDocumentService = new SubjectDocumentService(settings.apiUri)
const subjectService = new SubjectService(settings.apiUri)
const traineeService = new TraineeService(settings.apiUri)

export default {
  actions: {
    // exam
    // ====
    async createExam({ commit, rootGetters }, { exam }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await examService.create(exam, { accessToken })

      exam.id = result.id

      commit('createExam', { exam })
    },
    async createExamTrainee({ commit, rootGetters }, { examTrainee }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await examService.createTrainee(examTrainee.examId, examTrainee, { accessToken })

      commit('createExamTrainee', { examTrainee })
    },
    async deleteExam({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await examService.delete(id, { accessToken })

      if (result) {
        commit('deleteExam', { id })
      }
    },
    async deleteExamTrainee({ commit, rootGetters }, { examTrainee }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await examService.deleteTrainee(examTrainee.examId, examTrainee.traineeId, { accessToken })

      if (result) {
        commit('deleteExamTrainee', { examTrainee })
      }
    },
    async evaluateExam({ commit, rootGetters }, { invitationKey, id, examAnswers }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await examService.evaluate(invitationKey, id, examAnswers, { accessToken })
    },
    async loadExam({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const exam = await examService.find(id, { accessToken })

      if (getters.exam(id) != null) {
        commit('updateExam', { exam })
      } else {
        commit('createExam', { exam })
      }
    },
    async loadExams({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const exams = await examService.getAll({ accessToken })

      commit('setExams', { exams })
    },
    async loadExamsForInvitationKey({ commit, rootGetters }, { invitationKey }) {
      const accessToken = rootGetters['auth/accessToken']

      const exams = await examService.getAllForInvitationKey(invitationKey, { accessToken })

      commit('setExams', { exams })
    },
    async updateExam({ commit, rootGetters }, { exam }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await examService.update(exam.id, exam, { accessToken })

      if (result) {
        commit('updateExam', { exam })
      }
    },
    async updateExamTrainee({ commit, rootGetters }, { examTrainee }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await examService.updateTrainee(examTrainee.examId, examTrainee.traineeId, examTrainee, { accessToken })

      if (result) {
        commit('updateExamTrainee', { examTrainee })
      }
    },

    // question
    // ========
    async createQuestion({ commit, rootGetters }, { question }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await questionService.create(question, { accessToken })

      question.id = result.id

      commit('createQuestion', { question })
    },
    /*async createQuestionAnswer({ commit, rootGetters }, { answer }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await questionService.createAnswer(answer.questionId, answer, { accessToken })

      commit('createQuestionAnswer', { answer })
    },*/
    async deleteQuestion({ commit, rootGetters }, { subjectId, id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await questionService.delete(id, { accessToken })

      if (result) {
        commit('deleteQuestion', { subjectId, id })
      }
    },
    /*async deleteQuestionAnswer({ commit, rootGetters }, { answer }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await questionService.deleteAnswer(answer.questionId, answer.id, { accessToken })

      if (result) {
        commit('deleteQuestionAnswer', { answer })
      }
    },*/
    async loadQuestion({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const question = await questionService.find(id, { accessToken })

      if (getters.question(id) != null) {
        commit('updateQuestion', { question })
      } else {
        commit('createQuestion', { question })
      }
    },
    async loadQuestions({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const questions = await questionService.getAll({ accessToken })

      commit('setQuestions', { questions })
    },
    async updateQuestion({ commit, rootGetters }, { question }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await questionService.update(question.id, question, { accessToken })

      if (result) {
        commit('updateQuestion', { question })
      }
    },

    // subject
    // =======
    async createSubject({ commit, rootGetters }, { subject }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await subjectService.create(subject, { accessToken })

      subject.id = result.id

      commit('createSubject', { subject })
    },
    async deleteSubject({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await subjectService.delete(id, { accessToken })

      if (result) {
        commit('deleteSubject', { id })
      }
    },
    async loadSubject({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const subject = await subjectService.find(id, { accessToken })

      if (getters.subject(id) != null) {
        commit('updateSubject', { subject })
      } else {
        commit('createSubject', { subject })
      }
    },
    async loadSubjects({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const subjects = await subjectService.getAll({ accessToken })

      commit('setSubjects', { subjects })
    },
    async updateSubject({ commit, rootGetters }, { subject }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await subjectService.update(subject.id, subject, { accessToken })

      if (result) {
        commit('updateSubject', { subject })
      }
    },

    // subjectDocument
    // ===============
    async createSubjectDocument({ commit, rootGetters }, { subjectDocument }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await subjectDocumentService.create(subjectDocument, { accessToken })

      subjectDocument.id = result.id

      commit('createSubjectDocument', { subjectDocument })
    },
    async deleteSubjectDocument({ commit, rootGetters }, { subjectId, id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await subjectDocumentService.delete(id, { accessToken })

      if (result) {
        commit('deleteSubjectDocument', { subjectId, id })
      }
    },
    async loadSubjectDocument({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const subjectDocument = await subjectDocumentService.find(id, { accessToken })

      if (getters.subjectDocument(id) != null) {
        commit('updateSubjectDocument', { subjectDocument })
      } else {
        commit('createSubjectDocument', { subjectDocument })
      }
    },
    async loadSubjectDocuments({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const subjectDocuments = await subjectDocumentService.getAll({ accessToken })

      commit('setSubjectDocuments', { subjectDocuments })
    },
    async updateSubjectDocument({ commit, rootGetters }, { subjectDocument }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await subjectDocumentService.update(exam.id, examDocument, { accessToken })

      if (result) {
        commit('updateSubjectDocument', { subjectDocument })
      }
    },

    // trainee
    // =======
    async createTrainee({ commit, rootGetters }, { trainee }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await traineeService.create(trainee, { accessToken })

      trainee.id = result.id

      commit('createTrainee', { trainee })
    },
    async deleteTrainee({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await traineeService.delete(id, { accessToken })

      if (result) {
        commit('deleteTrainee', { id })
      }
    },
    async loadTrainee({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const trainee = await traineeService.find(id, { accessToken })

      if (getters.trainee(id) != null) {
        commit('updateTrainee', { trainee })
      } else {
        commit('createTrainee', { trainee })
      }
    },
    async loadTrainees({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const trainees = await traineeService.getAll({ accessToken })

      commit('setTrainees', { trainees })
    },
    async updateTrainee({ commit, rootGetters }, { trainee }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await traineeService.update(trainee.id, trainee, { accessToken })

      if (result) {
        commit('updateTrainee', { trainee })
      }
    }
  },
  getters: {
    exam: state => id => state.exams.find(e => e.id === id),
    exams: state => state.exams,
    examsForInvitationKey: state => invitationKey => state.exams.filter(e => e.trainees.some(t => t.trainee.invitationKey === invitationKey)),
    examTitle: state => id => state.exams.find(e => e.id === id)?.title,
    question: state => id => state.questions.find(q => q.id === id),
    questions: state => state.questions,
    subject: state => id => state.subjects.find(s => s.id === id),
    subjectDocument: state => id => state.subjectDocuments.find(s => s.id === id),
    subjectDocuments: state => state.subjectDocuments,
    subjects: state => state.subjects,
    subjectTitle: (state, getters) => id => getters.subject(id)?.title,
    trainee: state => id => state.trainees.find(t => t.id === id),
    trainees: state => state.trainees
  },
  mutations: {
    // exam
    // ====
    createExam(state, { exam }) {
      state.exams.push(exam)
    },
    createExamTrainee(state, { examTrainee }) {
      const exam = state.exams.find(e => e.id === examTrainee.examId)

      if (exam == null) {
        return
      }

      exam.trainees.push(examTrainee)
    },
    deleteExam(state, { id }) {
      const index = state.exams.findIndex(e => e.id === id)

      if (index !== -1) {
        state.exams.splice(index, 1)
      }
    },
    deleteExamTrainee(state, { examTrainee }) {
      const exam = state.exams.find(e => e.id === examTrainee.examId)

      if (exam == null) {
        return
      }

      const index = exam.trainees.findIndex(t => t.traineeId === examTrainee.traineeId)

      if (index !== -1) {
        exam.trainees.splice(index, 1)
      }
    },
    setExams(state, { exams }) {
      state.exams = exams
    },
    updateExam(state, { exam }) {
      const index = state.exams.findIndex(e => e.id === exam.id)

      Vue.set(state.exams, index, exam)
    },
    updateExamTrainee(state, { examTrainee }) {
      const exam = state.exams.find(e => e.id === examTrainee.examId)

      if (exam == null) {
        return
      }

      const examTraineeIndex = exam.trainees.findIndex(t => t.examId === examTrainee.examId && t.traineeId == examTrainee.traineeId)

      Vue.set(exam.trainees, examTraineeIndex, examTrainee)
    },

    // question
    // ========
    createQuestion(state, { question }) {
      state.questions.push(question)

      const subject = state.subjects.find(s => s.id === question.subjectId)

      if (subject != null) {
        subject.questions.push(question)
      }
    },
    /*createQuestionAnswer(state, { answer }) {
      const question = state.questions.find(q => q.id === answer.questionId)

      if (question == null) {
        return
      }

      question.answers.push(answer)
    },*/
    deleteQuestion(state, { subjectId, id }) {
      const index = state.questions.findIndex(q => q.id === id)

      if (index === -1) {
        return
      }

      state.questions.splice(index, 1)

      const subject = state.subjects.find(s => s.id === subjectId)

      if (subject == null) {
        return
      }

      const subjectQuestionIndex = subject.questions.findIndex(q => q.id === id)

      if (subjectQuestionIndex !== -1) {
        subject.questions.splice(subjectQuestionIndex, 1)
      }
    },
    /*deleteQuestionAnswer(state, { answer }) {
      const question = state.questions.find(q => q.id === answer.questionId)

      if (question == null) {
        return
      }

      const index = question.answers.findIndex(a => a.id === answer.id)

      if (index !== -1) {
        question.answers.splice(index, 1)
      }
    },*/
    setQuestions(state, { questions }) {
      state.questions = questions
    },
    updateQuestion(state, { question }) {
      const index = state.questions.findIndex(q => q.id === question.id)

      Vue.set(state.questions, index, question)

      const subject = state.subjects.find(s => s.id === question.subjectId)

      if (subject == null) {
        return
      }

      const subjectQuestionIndex = subject.questions.findIndex(q => q.id === question.id)

      Vue.set(subject.questions, subjectQuestionIndex, question)
    },

    // subject
    // =======
    createSubject(state, { subject }) {
      state.subjects.push(subject)
    },
    deleteSubject(state, { id }) {
      const index = state.subjects.findIndex(s => s.id === id)

      if (index !== -1) {
        state.subjects.splice(index, 1)
      }
    },
    setSubjects(state, { subjects }) {
      state.subjects = subjects
    },
    updateSubject(state, { subject }) {
      const index = state.subjects.findIndex(s => s.id === subject.id)

      Vue.set(state.subjects, index, subject)
    },

    // subjectDocuments
    // ================
    createSubjectDocument(state, { subjectDocument }) {
      state.subjectDocuments.push(subjectDocument)

      const subject = state.subjects.find(s => s.id === subjectDocument.subjectId)

      if (subject != null) {
        subject.documents.push(subjectDocument)
      }
    },
    deleteSubjectDocument(state, { subjectId, id }) {
      const index = state.subjectDocuments.findIndex(s => s.id === id)

      if (index === -1) {
        return
      }

      state.subjectDocuments.splice(index, 1)

      const subject = state.subjects.find(s => s.id === subjectId)

      if (subject == null) {
        return
      }

      const subjectDocumentIndex = subject.documents.findIndex(d => d.id === id)

      if (subjectDocumentIndex !== -1) {
        subject.documents.splice(subjectDocumentIndex, 1)
      }
    },
    setSubjectDocuments(state, { subjectDocuments }) {
      state.subjectDocuments = subjectDocuments
    },
    updateSubjectDocument(state, { subjectDocument }) {
      const index = state.subjectDocuments.findIndex(s => s.id === subjectDocument.id)

      Vue.set(state.subjectDocuments, index, subjectDocument)
    },

    // trainee
    // =======
    createTrainee(state, { trainee }) {
      state.trainees.push(trainee)
    },
    deleteTrainee(state, { id }) {
      const index = state.trainees.findIndex(t => t.id === id)

      if (index !== -1) {
        state.trainees.splice(index, 1)
      }
    },
    setTrainees(state, { trainees }) {
      state.trainees = trainees
    },
    updateTrainee(state, { trainee }) {
      const index = state.trainees.findIndex(t => t.id === trainee.id)

      Vue.set(state.trainees, index, trainee)
    }
  },
  namespaced: true,
  state: {
    exams: [],
    questions: [],
    subjectDocuments: [],
    subjects: [],
    trainees: []
  }
}