import Vue from 'vue'

import AttachmentService from '../api/common/attachment-service'
import DepartmentService from '../api/common/department-service'
import ManufacturerService from '../api/common/manufacturer-service'
import ProductService from '../api/common/product-service'
import SiteService from '../api/common/site-service'
import TenantService from '../api/common/tenant-service'
import WarehouseService from '../api/common/warehouse-service'

import settings from '../settings'

const attachmentService = new AttachmentService(settings.apiUri)
const departmentService = new DepartmentService(settings.apiUri)
const manufacturerService = new ManufacturerService(settings.apiUri)
const productService = new ProductService(settings.apiUri)
const siteService = new SiteService(settings.apiUri)
const tenantService = new TenantService(settings.apiUri)
const warehouseService = new WarehouseService(settings.apiUri)

export default {
  actions: {
    // attachment
    // ==========
    async createAttachment({ rootGetters }, { data }) {
      const accessToken = rootGetters['auth/accessToken']

      return await attachmentService.create(data, { accessToken })
    },

    // department
    // ==========
    async createDepartment({ commit, rootGetters }, { department }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await departmentService.create(department, { accessToken })

      department.id = result.id

      commit('createDepartment', { department })
    },
    async deleteDepartment({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await departmentService.delete(id, { accessToken })

      if (result) {
        commit('deleteDepartment', { id })
      }
    },
    async loadDepartment({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const department = await departmentService.find(id, { accessToken })

      if (getters.department(id) != null) {
        commit('updateDepartment', { department })
      } else {
        commit('createDepartment', { department })
      }
    },
    async loadDepartments({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const departments = await departmentService.getAll({ accessToken })

      commit('setDepartments', { departments })
    },
    async updateDepartment({ commit, rootGetters }, { department }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await departmentService.update(department.id, department, { accessToken })

      if (result) {
        commit('updateDepartment', { department })
      }
    },

    // manufacturer
    // ============
    async importManufacturers({ commit, rootGetters }, { deleteMissing, manufacturers }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await manufacturerService.import(manufacturers, deleteMissing, { accessToken })

      if (result) {
        commit('setManufacturers', { manufacturers })
      } else {
        throw new Exception()
      }
    },
    async importSqlManufacturers({ commit, rootGetters }, { deleteMissing }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await manufacturerService.importSql(deleteMissing, { accessToken })

      if (result) {
        
      } else {
        throw new Exception()
      }
    },
    async loadManufacturers({ commit, getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const manufacturers = await manufacturerService.getAll({ accessToken })

      commit('setManufacturers', { manufacturers })
    },

    // product
    // =======
    async createProduct({ commit, rootGetters }, { product }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await productService.create(product, { accessToken })

      commit('createProduct', { product })
    },
    async importProducts({ commit, rootGetters }, { deleteMissing, products }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await productService.import(products, deleteMissing, { accessToken })

      if (result) {
        commit('setProducts', { products })
      } else {
        throw new Exception()
      }
    },
    async importSqlProducts({ commit, rootGetters }, { deleteMissing }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await productService.importSql(deleteMissing, { accessToken })

      if (result) {
        
      } else {
        throw new Exception()
      }
    },
    async loadProducts({ commit, getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      if (getters.products.length !== 0) {
        return
      }

      const products = await productService.getAll({ accessToken })

      commit('setProducts', { products })
    },
    async loadLiveProducts({ commit, getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      if (getters.liveProducts.length !== 0) {
        return
      }

      const products = await productService.getAllLive({ accessToken })

      commit('setLiveProducts', { products })
    },
    async loadLivePrices({ commit, getters, rootGetters }, { productId, siteId }) {
      const accessToken = rootGetters['auth/accessToken']

      const prices = await productService.getPricesLive(productId, siteId, { accessToken })

      commit('setLivePrices', { prices })
    },
    async loadLiveSalePrices({ commit, getters, rootGetters }, { siteId }) {
      const accessToken = rootGetters['auth/accessToken']

      const prices = await productService.getSalePricesLive(siteId, { accessToken })

      commit('setLiveSalePrices', { prices })
    },

    // site
    // ====
    async importSites({ commit, rootGetters }, { deleteMissing, sites }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await siteService.import(sites, deleteMissing, { accessToken })

      if (result) {
        commit('setSites', { sites })
      } else {
        throw new Exception()
      }
    },
    async loadSites({ commit, getters, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      if (getters.sites.length !== 0) {
        return
      }

      const sites = await siteService.getAll({ accessToken })

      commit('setSites', { sites })
    },
    async loadSitesExternal({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const externalSites = await siteService.getAllExternal({ accessToken })

      commit('setExternalSites', { externalSites })
    },

    // tenant
    // ======
    async loadTenants({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const tenants = await tenantService.getAll({ accessToken })

      commit('setTenants', { tenants })
    },

    // warehouse
    // =========
    async loadWarehouses({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const warehouses = await warehouseService.getAll({ accessToken })

      commit('setWarehouses', { warehouses })
    },
  },
  getters: {
    attachmentUrl: state => id => `${settings.apiUri}/attachment/${id}/content`,
    currentSite: (state, getters) => getters.site(getters.currentSiteId),
    currentSiteId: (state, getters, rootState, rootGetters) => {
      const user = rootGetters['auth/user']

      if (user == null) {
        return null
      }

      return user['https://www.wachter.biz/siteId']
    },
    department: state => id => state.departments.find(d => d.id === id),
    departments: state => state.departments,
    departmentTitle: state => id => state.departments.find(d => d.id === id)?.title,
    manufacturer: state => id => state.manufacturers.find(m => m.id === id),
    manufacturers: state => state.manufacturers,
    product: state => id => state.products.find(p => p.id === id),
    products: state => state.products,
    liveProducts: state => state.liveProducts,
    livePrices: state => state.livePrices,
    liveSalePrices: state => state.liveSalePrices,
    productsOfManufacturer: state => id => state.products.filter(p => p.manufacturerId === id),
    productTemporary: state => id => state.products.find(p => p.id === id)?.temporary,
    productTitle: state => id => state.products.find(p => p.id === id)?.title,
    site: state => id => state.sites.find(s => s.id === id),
    siteAddress: state => id => {
      const site = state.sites.find(s => s.id === id)

      if (site == null) {
        return '-'
      }

      if (site.streetAddress == null || site.streetAddress == '' || site.postalCode == null || site.postalCode == '' || site.city == null || site.city == '') {
        return '-'
      }

      return `${site.streetAddress}, ${site.postalCode} ${site.city}`
    },
    siteByKey: state => key => state.sites.find(s => s.key === key),
    siteGroups: state => [...new Set(state.sites.map(s => s.group))],
    siteRegions: state => [...new Set(state.sites.map(s => s.region))],
    siteTitle: state => id => state.sites.find(s => s.id === id)?.title,
    sites: state => state.sites,
    externalSites: state => state.externalSites,
    tenant: state => id => state.tenants.find(t => t.id === id),
    tenantTitle: state => id => state.tenants.find(t => t.id === id)?.name,
    tenants: state => state.tenants,
    warehouse: state => id => state.warehouses.find(w => w.id === id),
    warehouseTitle: state => id => state.warehouses.find(w => w.id === id)?.name,
    warehouses: state => state.warehouses
  },
  mutations: {
    // department
    // ==========
    createDepartment(state, { department }) {
      state.departments.push(department)
    },
    deleteDepartment(state, { id }) {
      const index = state.departments.findIndex(d => d.id === id)

      if (index !== -1) {
        state.departments.splice(index, 1)
      }
    },
    setDepartments(state, { departments }) {
      state.departments = departments
    },
    updateDepartment(state, { department }) {
      const index = state.departments.findIndex(d => d.id === department.id)

      Vue.set(state.departments, index, department)
    },

    // manufacturer
    // ============
    setManufacturers(state, { manufacturers }) {
      state.manufacturers = manufacturers
    },

    // product
    // =======
    createProduct(state, { product }) {
      state.products.push(product)
    },
    setProducts(state, { products }) {
      state.products = products
    },
    setLiveProducts(state, { products }) {
      state.liveProducts = products
    },
    setLivePrices(state, { prices }) {
      state.livePrices.push(...prices)
    },
    setLiveSalePrices(state, { prices }) {
      state.liveSalePrices = prices
    },

    // site
    // ====
    setSites(state, { sites }) {
      state.sites = sites
    },
    setExternalSites(state, { externalSites }) {
      state.externalSites = externalSites
    },

    // tenenants
    // =========
    setTenants(state, { tenants }) {
      state.tenants = tenants
    },

    // warehouses
    // ==========
    setWarehouses(state, { warehouses }) {
      state.warehouses = warehouses
    }
  },
  namespaced: true,
  state: {
    departments: [],
    manufacturers: [],
    products: [],
    liveProducts: [],
    livePrices: [],
    liveSalePrices: [],
    sites: [],
    externalSites: [],
    tenants: [],
    warehouses: []
  }
}