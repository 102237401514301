<template>
  <div>
    <top-header v-bind:title="$t('meterManagement')" />
    <v-container>
      <v-row>
        <v-col cols="12"
               md="6">
          <icon-dashboard-card icon="mdi-cursor-text"
                               v-bind:title="$t('dataEntry')"
                               v-bind:to="{ name: 'meter-management-data-entry' }" />
        </v-col>
        <v-col cols="12"
               md="6">
          <icon-dashboard-card icon="mdi-table"
                               v-bind:title="$t('values')"
                               v-bind:to="{ name: 'meter-management-values' }" />
        </v-col>
        <v-col cols="12"
               md="6">
          <icon-dashboard-card icon="mdi-podium"
                               v-bind:title="$t('ranking')"
                               v-bind:to="{ name: 'meter-management-ranking' }" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import IconDashboardCard from '@/components/common/IconDashboardCard.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      IconDashboardCard,
      TopHeader
    },
    computed: {
      ...mapGetters({ hasRole: 'auth/hasRole' })
    },
    name: 'MeterManagement'
  }
</script>

<i18n>
  {
    "de": {
      "meterManagement": "Zählerstände",
      "dataEntry": "Datenerfassung",
      "values": "Werte",
      "ranking": "Ranking"
    },
    "en": {
      "meterManagement": "Meter management",
      "dataEntry": "Data entry",
      "values": "Values",
      "ranking": "Ranking"
    }
  }
</i18n>