<template>
  <div>
    <top-header v-bind:title="$t('eventOverview')">
    </top-header>
    <v-container>
      <public-event-category-overview v-bind:events="events" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import PublicEventCategoryOverview from '@/components/booking/PublicEventCategoryOverview.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      PublicEventCategoryOverview,
      TopHeader
    },
    computed: {
      ...mapGetters({
        events: 'booking/events'
      })
    },
    async created() {
      this.loading = true

      await this.loadEvents()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions({
        loadEvents: 'booking/loadEvents'
      })
    },
    name: 'Events'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Neu",
      "eventOverview": "Schulungsübersicht"
    },
    "en": {
      "add": "Add",
      "eventOverview": "Event overview"
    }
  }
</i18n>