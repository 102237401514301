<template>
  <div>
    <v-text-field hide-details="auto"
                  v-bind:filled="filled"
                  v-bind:flat="flat"
                  v-bind:solo="solo"
                  v-bind:label="label"
                  v-bind:value="localizedText"
                  v-on:input="setLocalizedText">
      <template v-slot:append>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary"
                   text
                   v-bind="attrs"
                   v-on="on">
              {{ computedSelectedLocaleId }}
              <v-icon right>mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-bind:key="locale.id"
                         v-for="locale in locales"
                         v-on:click="selectLocaleId(locale.id)">
              <v-list-item-title>{{ locale.id.toUpperCase() }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-text-field>
  </div>
</template>

<script>
  import locales from '@/locales.json'

  export default {
    computed: {
      computedSelectedLocaleId() {
        if (this.selectedLocaleId == null) {
          return this.$i18n.locale
        }

        return this.selectedLocaleId
      },
      localizedText() {
        if (this.value == null) {
          return ''
        }

        return this.value[this.computedSelectedLocaleId]
      },
      locales() {
        return locales
      }
    },
    data: () => ({
      selectedLocaleId: null,
    }),
    methods: {
      selectLocaleId(localeId) {
        this.selectedLocaleId = localeId
      },
      setLocalizedText(text) {
        let value = {}

        if (this.value != null) {
          value = Object.assign({}, this.value)
        }

        value[this.computedSelectedLocaleId] = text

        this.$emit('input', value)
      },
      validate() {
        if (this.value == null) {
          return false
        }

        return Object.keys(this.value).some(l => l != null && l.length > 0)
      }
    },
    name: 'MultilingualTextField',
    props: {
      filled: Boolean,
      flat: Boolean,
      solo: Boolean,
      label: String,
      value: Object
    }
  }
</script>