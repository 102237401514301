<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisDateIrrevocably')"
                    v-bind:title="$t('deleteDate')"
                    v-model="confirmDeleteDialog" />

    <attendee-dialog ref="attendeeDialog"
                     v-bind:attendee="selectedAttendee"
                     v-model="attendeeDialog"
                     v-bind:external="isExternal" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="start | formatDate('LLL')">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="eventId != null ? { name: 'booking-event', params: { id: eventId } } : null" />

      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateEventDate" />

      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="deleteEventDate">
            <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container v-if="eventDate != null">
      <v-row>
        <v-col cols="12"
               lg="6">
          <event-date-details-card ref="eventDateDetailsCard"
                                   v-bind:event-date="eventDate" />
        </v-col>
        <v-col cols="12"
               lg="6">
          <attendee-list-card v-bind:allow-creation="allowCreation"
                              v-bind:attendees="eventDate.attendees"
                              v-on:add-attendee="addAttendee"
                              v-on:delete-attendee="deleteAttendee"
                              v-on:open-attendee="openAttendee"
                              v-bind:external="isExternal" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import AttendeeDialog from '@/components/booking/AttendeeDialog.vue'
  import AttendeeListCard from '@/components/booking/AttendeeListCard.vue'
  import EventDateDetailsCard from '@/components/booking/EventDateDetailsCard.vue'
  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const eventId = parseInt(to.params.eventId)
      const id = parseInt(to.params.id)

      await this.get(eventId, id)

      next()
    },
    components: {
      AttendeeDialog,
      AttendeeListCard,
      ConfirmDialog,
      EventDateDetailsCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      allowCreation() {
        return this.eventDate.slots > this.eventDate.attendees.length
      },
      eventId() {
        return this.eventDate?.eventId
      },
      start() {
        return this.eventDate?.title
      },
      id() {
        return this.eventDate?.id
      },
      isExternal() {
        return this.event?.external
      },
      ...mapGetters({
        getEventDate: 'booking/eventDate',
        getEvent: 'booking/event'
      })
    },
    async created() {
      const eventId = parseInt(this.$route.params.eventId)
      const id = parseInt(this.$route.params.id)

      await this.get(eventId, id)
    },
    data: () => ({
      attendeeDialog: false,
      confirmDeleteDialog: false,
      eventDate: null,
      event: null,
      loading: false,
      selectedAttendee: null,
      validationSnackbar: false
    }),
    methods: {
      async addAttendee() {
        this.selectedAttendee = {
          eventDateId: this.eventDate.id
        }

        await this.$refs.attendeeDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.createAttendee({ attendee: r })

          this.loading = false
        })
      },
      async deleteAttendee(attendeeId) {
        this.loading = true

        await this.doDeleteAttendee({ id: attendeeId, eventDateId: this.id })

        this.loading = false
      },
      async deleteEventDate() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteEventDate({ id: this.id })

          this.loading = false

          this.$router.push({ name: 'booking-event', params: { id: this.eventDate.eventId } })
        })
      },
      async get(eventId, id) {
        this.loading = true

        await this.loadDepartments()
        await this.loadSitesExternal()
        await this.loadEventDate({ id })
        await this.loadEvent({ id: eventId })

        this.loading = false

        this.eventDate = Object.assign({}, this.getEventDate(id))
        this.event = Object.assign({}, this.getEvent(eventId))
      },
      async updateEventDate() {
        if (!this.$refs.eventDateDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateEventDate({ eventDate: this.eventDate })

        this.loading = false

        this.$router.push({ name: 'booking-event', params: { id: this.eventDate.eventId } })
      },
      async openAttendee(attendeeId) {
        this.selectedAttendee = this.eventDate.attendees.find(a => a.id === attendeeId)

        this.selectedAttendee = JSON.parse(JSON.stringify(this.selectedAttendee))

        await this.$refs.attendeeDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.updateAttendee({ attendee: r })

          this.loading = false
        })
      },
      ...mapActions({
        createAttendee: 'booking/createAttendee',
        doDeleteAttendee: 'booking/deleteAttendee',
        doDeleteEventDate: 'booking/deleteEventDate',
        doUpdateEventDate: 'booking/updateEventDate',
        loadDepartments: 'common/loadDepartments',
        loadEventDate: 'booking/loadEventDate',
        updateAttendee: 'booking/updateAttendee',
        loadSitesExternal: 'common/loadSitesExternal',
        loadEvent: 'booking/loadEvent'
      })
    },
    name: 'EventDate'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisDateIrrevocably": "Sind Sie sicher, dass Sie diesen Termin unwiderruflich löschen möchten?",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "date": "Termin",
      "delete": "Löschen",
      "deleteDate": "Termin löschen",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "areYouSureYouWantToDeleteThisDateIrrevocably": "Are you sure you want to delete this date irrevocably?",
      "back": "Back",
      "cancel": "Cancel",
      "date": "Date",
      "delete": "Delete",
      "deleteDate": "Delete date",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>