<template>
  <div>
    <top-header v-bind:title="$t('user')" />
    <v-progress-linear color="accent"
                       indeterminate
                       v-bind:active="loading" />
    <user-details-card ref="userDetailsCard"
                       v-bind:user="user"
                       v-on:signin="signin" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import UserDetailsCard from '@/components/inventory-management/UserDetailsCard.vue'

  export default {
    components: {
      TopHeader,
      HeaderButton,
      UserDetailsCard
    },
    computed: {
      ...mapGetters({ 
        hasRole: 'auth/hasRole',
        getUser: 'inventoryManagement/user'
      })
    },
    async created() {
      this.loading = true

      await this.retrieveUser()

      this.user = this.getUser || {
        username: null
      }

      this.loading = false
    },
    data: () => ({
      user: {
        username: null
      },
      loading: false
    }),
    methods: {
      signin() {
        if (!this.$refs.userDetailsCard.validate()) {
          return
        }

        this.setUser({ user: this.user })

        this.$router.push({ name: 'inventory-management-app-inventories' })
      },
      ...mapActions({
        retrieveUser: 'inventoryManagement/retrieveUser',
        setUser: 'inventoryManagement/setUser'
      })
    },
    name: 'AppUser'
  }
</script>

<i18n>
  {
    "de": {
      "user": "Benutzer"
    },
    "en": {
      "user": "User"
    }
  }
</i18n>