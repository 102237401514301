<template>
  <v-hover v-slot="{ hover }">
    <v-card elevation="0" tile
            v-bind:class="hover ? 'grey lighten-2' : color"
            v-bind:to="to">
      <v-card-title>
        <v-icon color="primary"
                large
                left>
          {{ icon }}
        </v-icon>
        <span class="font-weight-regular">{{ title }}</span><v-spacer /><v-chip class="ml-2" color="white" label v-if="!!chip">{{ chip }}</v-chip>
      </v-card-title>
    </v-card>
  </v-hover>
</template>

<script>
  export default {
    name: 'IconDashboardCard',
    props: {
      icon: String,
      title: String,
      to: Object,
      chip: String,
      color: {
        type: String,
        default: 'white'
      }
    }
  }
</script>