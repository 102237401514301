<template>
  <div>
    <top-header v-bind:title="$t('receipts')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('new')"
                     v-bind:to="{ name: 'free-product-accounting-receipt-add' }"
                     v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSite')" />
      <v-menu slot="menu">
        <template v-if="hasRole('SagasserPortalAdministrator')"
                  v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="downloadReceiptProducts(false, true)">
            <v-list-item-title>{{ $t('exportAll') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="downloadReceiptProducts(false, false)">
            <v-list-item-title>{{ $t('export') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="downloadReceiptProducts(true, false)">
            <v-list-item-title>{{ $t('exportAndSetAsCompleted') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-container>
      <receipt-table-card item-route-name="free-product-accounting-receipt"
                          v-bind:loading="loading"
                          v-bind:receipts="filteredReceipts"
                          v-on:load-all="loadAll" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import ReceiptTableCard from '@/components/free-product-accounting/ReceiptTableCard.vue'

  export default {
    components: {
      HeaderButton,
      TopHeader,
      ReceiptTableCard
    },
    computed: {
      filteredReceipts() {
        if (this.hasRole('SagasserPortalAdministrator')) {
          return this.receipts
        }

        return this.receipts.filter(r => r.siteId == this.currentSiteId)
      },
      ...mapGetters({
        currentSiteId: 'common/currentSiteId',
        hasRole: 'auth/hasRole',
        receipts: 'freeProductAccounting/receipts'
      })
    },
    async created() {
      this.loading = true

      await this.loadManufacturers()
      await this.loadReceipts()
      await this.loadSites()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      async downloadReceiptProducts(setAsCompleted, all) {
        const products = await this.exportReceiptProducts({ setAsCompleted, all })

        // TODO: Clean this code

        let csv = '"receiptId";"date";"productId";"quantity";"unit";"manufacturerId";"customerId";"siteId";"tenant";"siteEmployee";"manufacturerRepresentative";"reason";"refundType";"state"\n'
        
        products.forEach(p => {

          csv += `"${p.receiptId}";"${p.date}";"${p.productId}";"${p.quantity}";"${p.unit}";"${p.manufacturerId}";"${p.customerId}";"${p.siteId}";"${p.tenant}";"${p.siteEmployee}";"${p.manufacturerRepresentative.replace('#', '')}";"${p.reason}";"${p.refundType}";"${p.state}"\n`
        
        })

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
        element.setAttribute('download', 'export.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click();

        document.body.removeChild(element)

        // TODO: Find cleaner approach
        this.loadReceipts()
      },
      async loadAll() {
        this.loading = true

        await this.loadReceipts({ all: true })

        this.loading = false
      },
      ...mapActions({
        exportReceiptProducts: 'freeProductAccounting/exportReceiptProducts',
        loadManufacturers: 'common/loadManufacturers',
        loadReceipts: 'freeProductAccounting/loadReceipts',
        loadSites: 'common/loadSites'
      })
    },
    name: 'Receipts'
  }
</script>

<i18n>
  {
    "de": {
      "export": "Exportieren",
      "exportAndSetAsCompleted": "Exportieren und abschließen",
      "new": "Neu",
      "receipts": "Belege"
    },
    "en": {
      "export": "Export",
      "exportAndSetAsCompleted": "Export and set as completed",
      "new": "New",
      "receipts": "Receipts"
    }
  }
</i18n>