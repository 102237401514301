<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <template v-slot:extension>
          <v-text-field flat
                        hide-details="auto"
                        prepend-icon="mdi-magnify"
                        solo
                        v-bind:label="$t('search')"
                        v-model="search" />
        </template>
        <v-toolbar-title>{{ $t('transactions') }}</v-toolbar-title>
        
      </v-toolbar>
      <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                    v-bind:headers="headers"
                    v-bind:items="filteredInventoryTransactions"
                    v-bind:items-per-page="50"
                    v-bind:loading="loading"
                    v-bind:options="{ sortBy: [ 'id' ], sortDesc: [ true ] }"
                    v-on:click:row="selectInventoryTransaction">
        <template v-slot:[`item.countedAt`]="{ item }">
          {{ item.countedAt | formatDate('L LT') }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      filteredInventoryTransactions() {
        let filteredInventoryTransactions = this.inventoryTransactions || []

        if (this.search != null && this.search.length !== 0) {
          filteredInventoryTransactions = filteredInventoryTransactions.filter(f => `${f.inventoryProductTitle.toLowerCase()} ${f.inventoryProductId}`.includes(this.search.toLowerCase()))
        }

        return filteredInventoryTransactions
      },
      headers() {
        return [
          {
            text: this.$t('number'),
            value: 'id'
          },
          {
            text: this.$t('productId'),
            value: 'inventoryProductId'
          },
          {
            text: this.$t('product'),
            value: 'inventoryProductTitle'
          },
          {
            text: this.$t('unit'),
            value: 'unit'
          },
          {
            text: this.$t('quantity'),
            value: 'countQuantity'
          },
          {
            text: this.$t('countedBy'),
            value: 'countedBy'
          },
          {
            text: this.$t('countedAt'),
            value: 'countedAt'
          }
        ]
      },
      ...mapGetters({
      })
    },
    data: () => ({
      search: null
    }),
    name: 'InventoryTransactionTableCard',
    methods: {
      selectInventoryTransaction(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id, ...this.routeParams }})
      }
    },
    props: {
      inventoryTransactions: Array,
      loading: Boolean,
      itemRouteName: String,
      routeParams: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "transactions": "Buchungen",
      "number": "Nummer",
      "unit": "Einheit",
      "quantity": "Menge",
      "countedBy": "Gezählt von",
      "countedAt": "Gezählt am",
      "productId": "Artikelnr.",
      "product": "Artikel",
      "search": "Suche"
    },
    "en": {
      "transactions": "Transactions",
      "number": "Number",
      "unit": "Unit",
      "quantity": "Quantity",
      "countedBy": "Counted by",
      "countedAt": "Counted at",
      "productId": "Product id",
      "product": "Product",
      "search": "Search"
    }
  }
</i18n>