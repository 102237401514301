<template>
  <v-card flat
          tile>
    <v-form ref="form">
      <v-subheader>{{ $t('sites') }}</v-subheader>

      <site-selection-table ref="siteTable"
                            v-model="campaign.sites" />

      <v-divider />
      <v-card-actions v-if="!!nextStepLabel || !!previousStepLabel">
        <v-btn text
               v-if="!!previousStepLabel"
               v-on:click="$emit('previous-step')">
          {{ previousStepLabel }}
        </v-btn>
        <v-spacer />
        <v-btn color="accent"
               text
               v-if="!!nextStepLabel"
               v-on:click="$emit('next-step')">
          {{ nextStepLabel }}
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
  import SiteSelectionTable from '@/components/sale-management/SiteSelectionTable.vue'

  export default {
    components: {
      SiteSelectionTable
    },
    methods: {
      validate() {
        //return this.$refs.campaignDetails.validate()
      }
    },
    name: 'SiteSelectionStepCard',
    props: {
      nextStepLabel: String,
      previousStepLabel: String,
      showSiteSelection: Boolean,
      campaign: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "sites": "Märkte"
    },
    "en": {
      "sites": "Sites"
    }
  }
</i18n>