<template>
  <v-form ref="form" v-on:submit.prevent="$emit('submit')">
    <v-card-text>
      <v-row>
        <v-col>
          <v-autocomplete filled v-bind:search-input.sync="search" hide-no-data chips ref="positionNumberInput" hide-selected
                          hide-details="auto"
                          v-bind:items="filteredInventoryPositions | items({ text: i => `${i.inventoryProductTitle} (${i.inventoryProductId})`, value: 'positionNumber' })"
                          v-bind:label="$t('product')"
                          v-bind:rules="[ rules.required ]"
                          v-model="transaction.positionNumber"
                          v-on:change="doFocus" />
        </v-col>
      </v-row>
      
      <v-row v-if="selectedPosition != null && selectedPosition.inventoryProduct != null && selectedPosition.inventoryProduct.inventoryEmpty != null">
        <v-col>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('empty') }}</span>: {{ selectedPosition.inventoryProduct.inventoryEmpty.title }}</p>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('number') }}</span>: {{ selectedPosition.inventoryProduct.inventoryEmpty.number }}</p>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('baseUnit') }}</span>: {{ selectedPosition.inventoryProduct.inventoryEmpty.baseUnit }}</p>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('depositValue') }}</span>: {{ selectedPosition.inventoryProduct.inventoryEmpty.depositValue | formatCurrencyValue }}</p>
        </v-col>
      </v-row>

      <template v-if="transaction.positionNumber != null">
        <div v-for="unit in selectedPosition.inventoryProduct.inventoryPackagingUnits"  v-bind:key="unit.unit" >
          <v-row align="center">
            <v-col cols="auto">
              <v-text-field solo
                            ref="quantityInput"
                            hide-details="auto"
                            type="number"
                            v-bind:label="$t('countQuantity')"
                            v-model.number="transaction[unit.unit]"
                            v-on:keyup="doChange($event)" />
            </v-col>
            <v-col>
              <span class="font-weight-bold">{{ unit.unit }}</span>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <p v-if="transaction[unit.unit] > 1000" class="mt-0 mb-4 error--text">Die eingegebene Menge ist sehr hoch. (> 1.000)</p>
            </v-col>
          </v-row>
        </div>
      </template>
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      filteredInventoryPositions() {
        if (!!this.transaction.positionNumber) return this.inventoryPositions.filter(i => i.positionNumber === this.transaction.positionNumber)

        if (!this.search || this.search.length < 3) return []
        return this.inventoryPositions.filter(i => i.type === 'product') 
      },
      selectedPosition() {
        return this.inventoryPositions.find(i => i.positionNumber === this.transaction.positionNumber)
      },
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      ...mapGetters({
        units: 'inventoryManagement/units',
        inventoryPositions: 'inventoryManagement/inventoryPositions'
      })
    },
    data: () => ({
      search: ''
    }),
    methods: {
      doChange(ev) {
        if (ev.key == 'Enter') {
          this.$emit('submit')
        }
      },
      resetSearch(search) {
        this.search = search
        this.$refs.positionNumberInput.$refs.input.focus()
        this.$refs.form.resetValidation()
      },
      doFocus() {
        setTimeout(() => {
          if (!!this.$refs.quantityInput && this.$refs.quantityInput.length > 0) {
            this.$refs.quantityInput[0].$refs.input.focus()
          }
        }, 200)
      },
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'InventoryFilledStockDetails',
    props: {
      transaction: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "product": "Artikel",
      "countQuantity": "Zählmenge",
      "empty": "Leergutartikel",
      "number": "Nummer",
      "baseUnit": "Grundeinheit",
      "depositValue": "Pfandwert"
    },
    "en": {
      "product": "Product",
      "countQuantity": "Count quantity",
      "empty": "Empty",
      "number": "Number",
      "baseUnit": "Base unit",
      "depositValue": "Deposit value"
    }
  }
</i18n>