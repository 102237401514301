<template>
  <div>
    <h1>SaleManagement</h1>
  </div>
</template>

<script>
  export default {
    name: 'SaleManagement'
  }
</script>