<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('empties') }}</v-toolbar-title>
      </v-toolbar>
      <inventory-empties-details ref="inventoryEmptiesDetails"
                                      v-bind:transaction="transaction"
                                      v-on:submit="$emit('submit')"
                                       />
      <v-card-actions>
        <v-spacer />
        <v-btn color="accent"
                text
                v-on:click="$emit('save')">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import InventoryEmptiesDetails from '@/components/inventory-management/InventoryEmptiesDetails'

  export default {
    components: {
      InventoryEmptiesDetails
    },
    name: 'InventoryEmptiesDetailsCard',
    methods: {
      validate() {
        return this.$refs.inventoryEmptiesDetails.validate()
      },
      doFocus() {
        this.$refs.inventoryEmptiesDetails.doFocus()
      },
      resetSearch(search) {
        this.$refs.inventoryEmptiesDetails.resetSearch(search)
      }
    },
    props: {
      transaction: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details",
      "save": "Speichern",
      "empties": "Leergut"
    },
    "en": {
      "details": "Details",
      "save": "Save",
      "empties": "Empties"
    }
  }
</i18n>