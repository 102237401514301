<template>
  <v-dialog hide-overlay
            persistent
            v-model="dialog"
            width="320">
    <v-card color="secondary"
            dark>
      <v-card-text class="pa-6">
        {{ $t('signingOut') }}
      </v-card-text>
      <v-expand-transition>
        <v-card-actions v-show="showCancel">
          <v-spacer />
          <v-btn color="deep-orange"
                 text
                 v-on:click="cancel">
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-expand-transition>
      <v-progress-linear color="deep-orange"
                         indeterminate />
    </v-card>
  </v-dialog>
</template>

<script>
import { signOutCallbackMixin } from 'kayleen-vue-auth0-client'

export default {
  data: () => ({
    dialog: true,
    showCancel: false
  }),
  mixins: [ signOutCallbackMixin ],
  mounted() {
    setTimeout(() => this.showCancel = true, 2000)
  },
  name: 'SignOutCallback'
}
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "signingOut": "Abmeldung erfolgt…"
    },
    "en": {
      "cancel": "Cancel",
      "signingOut": "Signing out…"
    }
  }
</i18n>