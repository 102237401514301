<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisReceiptIrrevocably')"
                    v-bind:title="$t('deleteReceipt')"
                    v-model="confirmDeleteDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="$t('receipt')">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'inventory-management-inventory', params: { id: inventoryId} }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateInventoryReceipt" />
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="syncPositions">
            <v-list-item-title>{{ $t('syncPositions') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="deleteInventoryReceipt">
            <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-progress-linear color="accent"
                       indeterminate
                       v-bind:active="loading" />
    <v-container v-if="inventoryReceipt != null">
      <v-row>
        <v-col cols="12"
               md="6">
          <inventory-receipt-details-card ref="inventoryReceiptDetailsCard"
                                          v-bind:inventory-receipt="inventoryReceipt" />
        </v-col>
        <v-col cols="12"
               md="6">
          <icon-dashboard-card class="mb-6"
                               icon="mdi-sigma"
                               v-bind:title="$t('result')"
                               v-bind:to="{ name: 'inventory-management-inventory-results', params: { inventoryId, inventoryReceiptId: id } }" />
          <icon-dashboard-card class="mb-6"
                               icon="mdi-ticket"
                               v-bind:title="$t('transactions')"
                               v-bind:to="{ name: 'inventory-management-inventory-transactions', params: { inventoryId, inventoryReceiptId: id } }" />
          <inventory-position-list-card  addRouteName="inventory-management-inventory-position-add"
                                         itemRouteName="inventory-management-inventory-position"
                                         v-bind:routeParams="{ inventoryId, inventoryReceiptId: id }"
                                         v-bind:inventory-positions="inventoryReceipt.inventoryPositions" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import InventoryReceiptDetailsCard from '@/components/inventory-management/InventoryReceiptDetailsCard.vue'
  import InventoryPositionListCard from '@/components/inventory-management/InventoryPositionListCard.vue'
  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import IconDashboardCard from '@/components/common/IconDashboardCard.vue'

  export default {
    async beforeRouteUpdate(to, _, next) {
      const id = to.params.id
      const inventoryId = parseInt(to.params.inventoryId)

      await this.get(id, inventoryId)

      next()
    },
    components: {
      HeaderButton,
      TopHeader,
      InventoryReceiptDetailsCard,
      InventoryPositionListCard,
      ConfirmDialog,
      IconDashboardCard
    },
    computed: {
      id() {
        return this.inventoryReceipt?.id
      },
      ...mapGetters({
        getInventoryReceipt: 'inventoryManagement/inventoryReceipt'
      })
    },
    async created() {
      const id = this.$route.params.id
      const inventoryId = parseInt(this.$route.params.inventoryId)

      await this.get(id, inventoryId)
    },
    data: () => ({
      loading: false,
      inventoryReceipt: null,
      inventoryId: null,
      validationSnackbar: false,
      confirmDeleteDialog: false
    }),
    methods: {
      async get(id, inventoryId) {
        this.inventoryId = inventoryId

        this.loading = true
        
        await this.loadInventoryReceipt({ id })

        this.loading = false

        this.inventoryReceipt = Object.assign({}, this.getInventoryReceipt(id))
      },
      async updateInventoryReceipt() {
        if (!this.$refs.inventoryReceiptDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateInventoryReceipt({ inventoryReceipt: this.inventoryReceipt })

        this.loading = false

        this.$router.push({ name: 'inventory-management-inventory', params: { id: this.inventoryId } })
      },
      async syncPositions() {
        this.loading = true

        await this.syncInventoryPositions({ inventoryReceiptId: this.id, deleteMissing: false })
        await this.get(this.id, this.inventoryId)

        this.loading = false
      },
      async deleteInventoryReceipt() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteInventoryReceipt({ id: this.id })

          this.loading = false

          this.$router.push({ name: 'inventory-management-inventory', params: { id: this.inventoryId } })
        })
      },
      ...mapActions({
        syncInventoryPositions: 'inventoryManagement/syncInventoryPositions',
        doDeleteInventoryReceipt: 'inventoryManagement/deleteInventoryReceipt',
        loadInventoryReceipt: 'inventoryManagement/loadInventoryReceipt',
        doUpdateInventoryReceipt: 'inventoryManagement/updateInventoryReceipt'
      })
    },
    name: 'InventoryReceipt'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisReceiptIrrevocably": "Sind Sie sicher, dass Sie diesen Beleg unwiderruflich löschen möchten?",
      "back": "Zurück",
      "receipt": "Beleg",
      "save": "Speichern",
      "delete": "Löschen",
      "cancel": "Abbrechen",
      "deleteReeipt": "Beleg löschen",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern.",
      "transactions": "Buchungen",
      "result": "Ergebnis",
      "syncPositions": "Positionen synchronisieren"
    },
    "en": {
      "areYouSureYouWantToDeleteThisReceiptIrrevocably": "Are you sure you want to delete this receipt irrevocably?",
      "back": "Back",
      "receipt": "Receipt",
      "save": "Save",
      "delete": "Delete",
      "cancel": "Cancel",
      "deleteReceipt": "Delete receipt",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention.",
      "transactions": "Transactions",
      "result": "Result",
      "syncPositions": "Sync positions"
    }
  }
</i18n>