<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        disabled
                        hide-details="auto"
                        v-bind:label="$t('number')"
                        v-model="inventoryTransaction.id" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field filled
                        disabled
                        hide-details="auto"
                        v-bind:label="$t('receiptNumber')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventoryTransaction.inventoryPositionInventoryReceiptId" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field filled
                        disabled
                        hide-details="auto"
                        type="number"
                        v-bind:label="$t('position')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventoryTransaction.inventoryPositionPositionNumber" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('productId') }}</span>: {{ inventoryTransaction.inventoryProductId }}</p>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('product') }}</span>: {{ inventoryTransaction.inventoryProductTitle }}</p>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select filled
                    hide-details="auto"
                    v-bind:items="units | items({ text: 'name', value: 'id' })"
                    v-bind:label="$t('unit')"
                    v-bind:rules="[ rules.required ]"
                    v-model="inventoryTransaction.unit" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        type="number"
                        v-bind:label="$t('quantity')"
                        v-bind:rules="[ rules.required ]"
                        v-model.number="inventoryTransaction.countQuantity" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
          disabled
                        hide-details="auto"
                        v-bind:label="$t('countedBy')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventoryTransaction.countedBy" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <date-time-field disabled
                           filled
                           hide-details="auto"
                           v-bind:label="$t('countedAt')"
                           v-model="inventoryTransaction.countedAt" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import DateTimeField from '@/components/common/DateTimeField'

  export default {
    components: {
      DateTimeField
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      inventoryProduct() {
        return this.inventoryProducts.find(i => i.id === this.inventoryPosition.inventoryProductId)
      },
      ...mapGetters({
        units: 'inventoryManagement/units',
        inventoryProducts: 'inventoryManagement/inventoryProducts'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'InventoryTransactionDetails',
    props: {
      inventoryTransaction: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "number": "Nummer",
      "receiptNumber": "Belegnummer",
      "position": "Position",
      "unit": "Einheit",
      "quantity": "Menge",
      "countedBy": "Gezählt von",
      "countedAt": "Gezählt am",
      "productId": "Artikelnr.",
      "product": "Artikel"
    },
    "en": {
      "number": "Number",
      "receiptNumber": "Receipt Number",
      "position": "Position",
      "unit": "Unit",
      "quantity": "Quantity",
      "countedBy": "Counted by",
      "countedAt": "Counted at",
      "productId": "Product id",
      "product": "Product"
    }
  }
</i18n>