<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('exam') }}</v-toolbar-title>
        <v-spacer />
        <v-btn color="accent"
               v-bind:disabled="!allowQuestionnaire"
               v-bind:to="{ name: 'learning-my-exam-questionnaire', params: { invitationKey, id: exam.id }}">
          {{ $t('startNow') }}
        </v-btn>
      </v-toolbar>
      <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                       v-bind:items="exam.subject.documents"
                       v-bind:items-per-page="30">
        <template v-slot:default="props">
          <v-card-text class="py-0">
            <v-container class="px-0"
                         fluid>
              <v-row>
                <v-col cols="12"
                       lg="3"
                       md="4"
                       sm="6"
                       v-bind:key="document.id"
                       v-for="document in props.items">
                  <v-card flat
                          hover
                          target="_blank"
                          v-bind:href="attachmentUrl(document.attachmentId)">
                    <v-container fluid>
                      <v-row align="center">
                        <v-col cols="auto">
                          <v-icon color="secondary lighten-5"
                                  size="64">
                            {{ getIcon(document.attachment.contentType) }}
                          </v-icon>
                        </v-col>
                        <v-col>
                          <p class="font-weight-bold mb-0 text-truncate">{{ document.title }}</p>
                          <p class="mb-0">{{ document.attachment.creation | formatDate('LLL') }}</p>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
        </template>
        <template v-slot:no-data>
          <v-card-text class="text-center text--disabled">
            {{ $t('noDocumentsAvailable') }}
          </v-card-text>
          <v-divider />
        </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        attachmentUrl: 'common/attachmentUrl'
      })
    },
    methods: {
      getIcon(contentType) {
        if (contentType === 'image/png' || contentType === 'image/jpeg') {
          return 'mdi-file-image'
        } else if (contentType === 'application/pdf') {
          return 'mdi-file-document'
        } else if (contentType === 'video/mp4') {
          return 'mdi-file-video'
        } else {
          return 'mdi-file'
        }
      },
    },
    name: 'MyExamCard',
    props: {
      allowQuestionnaire: Boolean,
      exam: Object,
      invitationKey: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "exam": "Prüfung",
      "noDocumentsAvailable": "Keine Dokumente vorhanden",
      "startNow": "Jetzt beginnen"
    },
    "en": {
      "exam": "Exam",
      "noDocumentsAvailable": "No documents available",
      "startNow": "Start now"
    }
  }
</i18n>