<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <inventory-position-details ref="inventoryPositionDetails"
                         v-bind:inventory-position="inventoryPosition" />
    </v-card>
  </div>
</template>

<script>
  import InventoryPositionDetails from '@/components/inventory-management/InventoryPositionDetails'

  export default {
    components: {
      InventoryPositionDetails
    },
    name: 'InventoryPositionDetailsCard',
    methods: {
      validate() {
        return this.$refs.inventoryPositionDetails.validate()
      }
    },
    props: {
      inventoryPosition: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details"
    },
    "en": {
      "details": "Details"
    }
  }
</i18n>