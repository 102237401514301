import Vue from 'vue'

import InventoryEmptyService from '../api/inventory-management/inventory-empty-service'
import InventoryPositionService from '../api/inventory-management/inventory-position-service'
import InventoryProductService from '../api/inventory-management/inventory-product-service'
import InventoryReceiptService from '../api/inventory-management/inventory-receipt-service'
import InventoryService from '../api/inventory-management/inventory-service'
import InventoryTransactionService from '../api/inventory-management/inventory-transaction-service'
import InventoryResultService from '../api/inventory-management/inventory-result-service'
import InventoryPackagingUnitService from '../api/inventory-management/inventory-packaging-unit-service'
import InventoryStockService from '../api/inventory-management/inventory-stock-service'

import settings from '../settings'

const inventoryEmptyService = new InventoryEmptyService(settings.apiUri)
const inventoryPositionService = new InventoryPositionService(settings.apiUri)
const inventoryProductService = new InventoryProductService(settings.apiUri)
const inventoryReceiptService = new InventoryReceiptService(settings.apiUri)
const inventoryService = new InventoryService(settings.apiUri)
const inventoryTransactionService = new InventoryTransactionService(settings.apiUri)
const inventoryResultService = new InventoryResultService(settings.apiUri)
const inventoryPackagingUnitService = new InventoryPackagingUnitService(settings.apiUri)
const inventoryStockService = new InventoryStockService(settings.apiUri)

export default {
  actions: {
    // inventoryEmpties
    // ================
    async createInventoryEmpty({ commit, rootGetters }, { inventoryEmpty }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryEmptyService.create(inventoryEmpty, { accessToken })

      inventoryEmpty.id = result.id

      commit('createInventoryEmpty', { inventoryEmpty })
    },
    async deleteInventoryEmpty({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryEmptyService.delete(id, { accessToken })

      if (result) {
        commit('deleteInventoryEmpty', { id })
      }
    },
    async loadInventoryEmpties({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const inventoryEmpties = await inventoryEmptyService.getAll({ accessToken })

      commit('setInventoryEmpties', { inventoryEmpties })
    },
    async updateInventoryEmpty({ commit, rootGetters }, { inventoryEmpty }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryEmptyService.update(inventoryEmpty.id, inventoryEmpty, { accessToken })

      if (result) {
        commit('updateInventoryEmpty', { inventoryEmpty })
      }
    },
    async syncInventoryEmpties({ commit, rootGetters }, { deleteMissing}) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryEmptyService.sync(deleteMissing, { accessToken })
    },


    // inventoryPositions
    // ==================
    async createInventoryPosition({ commit, rootGetters }, { inventoryPosition }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryPositionService.create(inventoryPosition, { accessToken })

      inventoryPosition.id = result.id

      commit('createInventoryPosition', { inventoryPosition })
    },
    async deleteInventoryPosition({ commit, rootGetters }, { inventoryReceiptId, positionNumber }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryPositionService.delete(inventoryReceiptId, positionNumber, { accessToken })

      if (result) {
        commit('deleteInventoryPosition', { inventoryReceiptId, positionNumber })
      }
    },
    async loadInventoryPositions({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const inventoryPositions = await inventoryPositionService.getAll({ accessToken })

      commit('setInventoryPositions', { inventoryPositions })
    },
    async loadInventoryPositionsForReceipt({ commit, rootGetters }, { receiptId }) {
      const accessToken = rootGetters['auth/accessToken']

      const inventoryPositions = await inventoryPositionService.getAllForReceipt(receiptId, { accessToken })

      commit('setInventoryPositions', { inventoryPositions })
    },
    async loadInventoryPosition({ commit, getters, rootGetters }, { inventoryReceiptId, positionNumber }) {
      const accessToken = rootGetters['auth/accessToken']

      const inventoryPosition = await inventoryPositionService.find(inventoryReceiptId, positionNumber, { accessToken })

      if (getters.inventoryPosition(inventoryReceiptId, positionNumber) != null) {
        commit('updateInventoryPosition', { inventoryPosition })
      } else {
        commit('createInventoryPosition', { inventoryPosition })
      }
    },
    async updateInventoryPosition({ commit, rootGetters }, { inventoryPosition }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryPositionService.update(inventoryPosition.inventoryReceiptId, inventoryPosition.positionNumber, inventoryPosition, { accessToken })

      if (result) {
        commit('updateInventoryPosition', { inventoryPosition })
      }
    },
    async syncInventoryPositions({ commit, rootGetters }, { inventoryReceiptId, deleteMissing}) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryPositionService.sync(inventoryReceiptId, deleteMissing, { accessToken })
    },


    // inventoryProducts
    // =================
    async createInventoryProduct({ commit, rootGetters }, { inventoryProduct }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryProductService.create(inventoryProduct, { accessToken })

      inventoryProduct.id = result.id

      commit('createInventoryProduct', { inventoryProduct })
    },
    async deleteInventoryProduct({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryProductService.delete(id, { accessToken })

      if (result) {
        commit('deleteInventoryProduct', { id })
      }
    },
    async loadInventoryProducts({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const inventoryProducts = await inventoryProductService.getAll({ accessToken })

      commit('setInventoryProducts', { inventoryProducts })
    },
    async updateInventoryProduct({ commit, rootGetters }, { inventoryProduct }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryProductService.update(inventoryProduct.id, inventoryProduct, { accessToken })

      if (result) {
        commit('updateInventoryProduct', { inventoryProduct })
      }
    },
    async syncInventoryProducts({ commit, rootGetters }, { deleteMissing}) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryProductService.sync(deleteMissing, { accessToken })
    },


    // InventoryReceipts
    // =================
    async createInventoryReceipt({ commit, rootGetters }, { inventoryReceipt }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryReceiptService.create(inventoryReceipt, { accessToken })

      inventoryReceipt.id = result.id

      commit('createInventoryReceipt', { inventoryReceipt })
    },
    async deleteInventoryReceipt({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryReceiptService.delete(id, { accessToken })

      if (result) {
        commit('deleteInventoryReceipt', { id })
      }
    },
    async loadInventoryReceipts({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const inventoryReceipts = await inventoryReceiptService.getAll({ accessToken })

      commit('setInventoryReceipts', { inventoryReceipts })
    },
    async loadInventoryReceipt({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const inventoryReceipt = await inventoryReceiptService.find(id, { accessToken })

      if (getters.inventoryReceipt(id) != null) {
        commit('updateInventoryReceipt', { inventoryReceipt })
      } else {
        commit('createInventoryReceipt', { inventoryReceipt })
      }
    },
    async updateInventoryReceipt({ commit, rootGetters }, { inventoryReceipt }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryReceiptService.update(inventoryReceipt.id, inventoryReceipt, { accessToken })

      if (result) {
        commit('updateInventoryReceipt', { inventoryReceipt })
      }
    },
    async syncInventoryReceipts({ commit, rootGetters }, { deleteMissing}) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryReceiptService.sync(deleteMissing, { accessToken })
    },

    // inventoryPackagingUnits
    // ===========================
    async syncInventoryPackagingUnits({ commit, rootGetters }, { deleteMissing}) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryPackagingUnitService.sync(deleteMissing, { accessToken })
    },


    // inventoryTransactions
    // =====================
    async createInventoryTransaction({ commit, rootGetters }, { inventoryTransaction }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryTransactionService.create(inventoryTransaction, { accessToken })

      inventoryTransaction.id = result.id

      commit('createInventoryTransaction', { inventoryTransaction })
    },
    async deleteInventoryTransaction({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryTransactionService.delete(id, { accessToken })

      if (result) {
        commit('deleteInventoryTransaction', { id })
      }
    },
    async loadInventoryTransaction({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const inventoryTransaction = await inventoryTransactionService.find(id, { accessToken })

      if (getters.inventoryTransaction(id) != null) {
        commit('updateInventoryTransaction', { inventoryTransaction })
      } else {
        commit('createInventoryTransaction', { inventoryTransaction })
      }
    },
    async loadInventoryTransactions({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const inventoryTransactions = await inventoryTransactionService.getAll({ accessToken })

      commit('setInventoryTransactions', { inventoryTransactions })
    },
    async loadInventoryTransactionForReceipt({ commit, rootGetters }, { receiptId }) {
      const accessToken = rootGetters['auth/accessToken']

      const inventoryTransactions = await inventoryTransactionService.getAllForReceipt(receiptId, { accessToken })

      commit('setInventoryTransactions', { inventoryTransactions })
    },
    async updateInventoryTransaction({ commit, rootGetters }, { inventoryTransaction }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryTransactionService.update(inventoryTransaction.id, inventoryTransaction, { accessToken })

      if (result) {
        commit('updateInventoryTransaction', { inventoryTransaction })
      }
    },

    // InventoryResults
    // ================
    async loadInventoryResultsForReceipt({ commit, rootGetters }, { receiptId }) {
      const accessToken = rootGetters['auth/accessToken']

      const inventoryResults = await inventoryResultService.getAllForReceipt(receiptId, { accessToken })

      commit('setInventoryResults', { inventoryResults })
    },

    // Inventories
    // ===========
    async createInventory({ commit, rootGetters }, { inventory }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryService.create(inventory, { accessToken })

      inventory.id = result.id

      commit('createInventory', { inventory })
    },
    async deleteInventory({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryService.delete(id, { accessToken })

      if (result) {
        commit('deleteInventory', { id })
      }
    },
    async loadInventories({ commit, rootGetters }) {
      const accessToken = rootGetters['auth/accessToken']

      const inventories = await inventoryService.getAll({ accessToken })

      commit('setInventories', { inventories })
    },
    async updateInventory({ commit, rootGetters }, { inventory }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryService.update(inventory.id, inventory, { accessToken })

      if (result) {
        commit('updateInventory', { inventory })
      }
    },
    async syncInventories({ commit, rootGetters }, { deleteMissing}) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryService.sync(deleteMissing, { accessToken })
    },

    // inventoryStocks
    async syncInventoryStocks({ commit, rootGetters }, { warehouseId, deleteMissing}) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await inventoryStockService.sync(warehouseId, deleteMissing, { accessToken })
    },


    // user
    // ====
    async setUser({ commit }, { user }) {
      localStorage.setItem('user', JSON.stringify(user))

      commit('setUser', { user })
    },
    async retrieveUser({ commit }) {
      const storage = localStorage.getItem('user')

      if (!storage || storage === 'undefined') {
        commit('setUser', { user: null })

        return
      }

      const user = JSON.parse(storage)

      commit('setUser', { user })
    }
  },
  getters: {
    inventoryEmpty: state => id => state.inventoryEmpties.find(i => i.id === id),
    inventoryEmpties: state => state.inventoryEmpties,


    inventoryPosition: state => (inventoryReceiptId, positionNumber) => state.inventoryPositions.find(i => i.inventoryReceiptId === inventoryReceiptId && i.positionNumber === positionNumber),
    inventoryPositions: state => state.inventoryPositions,

    inventoryProduct: state => id => state.inventoryProducts.find(i => i.id === id),
    inventoryProducts: state => state.inventoryProducts,

    inventoryReceipt: state => id => state.inventoryReceipts.find(i => i.id === id),
    inventoryReceipts: state => state.inventoryReceipts,

    inventory: state => id => state.inventories.find(i => i.id === id),
    inventories: state => state.inventories,

    inventoryTransaction: state => id => state.inventoryTransactions.find(i => i.id === id),
    inventoryTransactions: state => state.inventoryTransactions,

    inventoryResults: state => state.inventoryResults,

    unit: state => id => state.units.find(i => i.id === id),
    units: state => state.units,

    user: state => state.user
  },
  mutations: {
    // InventoryEmpties
    // ================
    createInventoryEmpty(state, { inventoryEmpty }) {
      state.inventoryEmpties.push(inventoryEmpty)
    },
    deleteInventoryEmpty(state, { id }) {
      const index = state.inventoryEmpties.findIndex(i => i.id === id)

      if (index !== -1) {
        state.inventoryEmpties.splice(index, 1)
      }
    },
    setInventoryEmpties(state, { inventoryEmpties }) {
      state.inventoryEmpties = inventoryEmpties
    },
    updateInventoryEmpty(state, { inventoryEmpty }) {
      const index = state.inventoryEmpties.findIndex(i => i.id === inventoryEmpty.id)

      Vue.set(state.inventoryEmpties, index, inventoryEmpty)
    },


    // InventoryPositions
    // ==================
    createInventoryPosition(state, { inventoryPosition }) {
      state.inventoryPositions.push(inventoryPosition)
    },
    deleteInventoryPosition(state, { inventoryReceiptId, positionNumber }) {
      const index = state.inventoryPositions.findIndex(i => i.inventoryReceiptId === inventoryReceiptId && i.positionNumber === positionNumber)

      if (index !== -1) {
        state.inventoryPositions.splice(index, 1)
      }
    },
    setInventoryPositions(state, { inventoryPositions }) {
      state.inventoryPositions = inventoryPositions
    },
    updateInventoryPosition(state, { inventoryPosition }) {
      const index = state.inventoryPositions.findIndex(i => i.inventoryReceiptId === inventoryPosition.inventoryReceiptId && i.positionNumber === inventoryPosition.positionNumber)

      Vue.set(state.inventoryPositions, index, inventoryPosition)
    },



    // InventoryProducts
    // =================
    createInventoryProduct(state, { inventoryProduct }) {
      state.inventoryProducts.push(inventoryProduct)
    },
    deleteInventoryProduct(state, { id }) {
      const index = state.inventoryProducts.findIndex(i => i.id === id)

      if (index !== -1) {
        state.inventoryProducts.splice(index, 1)
      }
    },
    setInventoryProducts(state, { inventoryProducts }) {
      state.inventoryProducts = inventoryProducts
    },
    updateInventoryProduct(state, { inventoryProduct }) {
      const index = state.inventoryProducts.findIndex(i => i.id === inventoryProduct.id)

      Vue.set(state.inventoryProducts, index, inventoryProduct)
    },


    // InventoryReceipts
    // =================
    createInventoryReceipt(state, { inventoryReceipt }) {
      state.inventoryReceipts.push(inventoryReceipt)
    },
    deleteInventoryReceipt(state, { id }) {
      const index = state.inventoryReceipts.findIndex(i => i.id === id)

      if (index !== -1) {
        state.inventoryReceipts.splice(index, 1)
      }
    },
    setInventoryReceipts(state, { inventoryReceipts }) {
      state.inventoryReceipts = inventoryReceipts
    },
    updateInventoryReceipt(state, { inventoryReceipt }) {
      const index = state.inventoryReceipts.findIndex(i => i.id === inventoryReceipt.id)

      Vue.set(state.inventoryReceipts, index, inventoryReceipt)
    },


    // Inventories
    // ===========
    createInventory(state, { inventory }) {
      state.inventories.push(inventory)
    },
    deleteInventory(state, { id }) {
      const index = state.inventories.findIndex(i => i.id === id)

      if (index !== -1) {
        state.inventories.splice(index, 1)
      }
    },
    setInventories(state, { inventories }) {
      state.inventories = inventories
    },
    updateInventory(state, { inventory }) {
      const index = state.inventories.findIndex(i => i.id === inventory.id)

      Vue.set(state.inventories, index, inventory)
    },


    // InventoryTransactions
    // =====================
    createInventoryTransaction(state, { inventoryTransaction }) {
      state.inventoryTransactions.push(inventoryTransaction)
    },
    deleteInventoryTransaction(state, { id }) {
      const index = state.inventoryTransactions.findIndex(i => i.id === id)

      if (index !== -1) {
        state.inventoryTransactions.splice(index, 1)
      }
    },
    setInventoryTransactions(state, { inventoryTransactions }) {
      state.inventoryTransactions = inventoryTransactions
    },
    updateInventoryTransaction(state, { inventoryTransaction }) {
      const index = state.inventoryTransactions.findIndex(i => i.id === inventoryTransaction.id)

      Vue.set(state.inventoryTransactions, index, inventoryTransaction)
    },

    // InventoryResults
    // ================
    setInventoryResults(state, { inventoryResults }) {
      state.inventoryResults = inventoryResults
    },

    // user
    // ====
    setUser(state, { user }) {
      state.user = user
    }
  },
  namespaced: true,
  state: {
    inventoryEmpties: [],
    inventoryPositions: [],
    inventoryProducts: [],
    inventoryReceipts: [],
    inventories: [],
    inventoryTransactions: [],
    inventoryResults: [],
    units: [
      { id: 'Kasten', name: 'Kasten' },
      { id: 'Flasche', name: 'Flasche' },
      { id: 'Stange', name: 'Stange' },
      { id: 'Halber Kasten', name: 'Halber Kasten' },
      { id: 'Schachtel', name: 'Schachtel' },
      { id: 'Karton', name: 'Karton' },
      { id: '8-Pack', name: '8-Pack' },
      { id: '2er Pack', name: '2er Pack' },
      { id: 'Stapel', name: 'Stapel' },
      { id: 'Tray', name: 'Tray' },
      { id: 'Hektoliter', name: 'Hektoliter' },
      { id: 'Umkarton', name: 'Umkarton' },
      { id: 'Dose', name: 'Dose' },
      { id: 'Stück', name: 'Stück' },
      { id: 'Liter', name: 'Liter' },
      { id: '9-Pack', name: '9-Pack' },
      { id: 'Dispenser', name: 'Dispenser' },
      { id: '7-Pack', name: '7-Pack' },
      { id: '3er Pack', name: '3er Pack' },
      { id: 'Container', name: 'Container' },
      { id: '12-Pack', name: '12-Pack' },
      { id: 'Beutel', name: 'Beutel' },
      { id: 'Kanister', name: 'Kanister' },
      { id: '6-Pack', name: '6-Pack' },
      { id: 'Palette', name: 'Palette' },
      { id: 'Pack', name: 'Pack' },
      { id: '4-er Pack', name: '4-er Pack' },
      { id: '5er Pack', name: '5er Pack' },
      { id: 'Faß', name: 'Faß' },
      { id: 'Display', name: 'Display' }
    ],
    user: null
  }
}