<template>
  <div v-if="campaign != null">
    <information-dialog ref="informationDialog"
                        v-bind:close-button-label="$t('close')"
                        v-bind:message="$t('yourOrderHasBeenPlacedSuccessfully')"
                        v-bind:title="$t('thankYouForYourOrder')"
                        v-model="informationDialog" />

    <top-header v-bind:title="campaign.title">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-if="hasRole('SagasserPortalAdministrator')"
                     v-on:click="$router.go(-1)" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:disabled="!hasRole('SagasserPortalAdministrator') && closed"
                     v-bind:label="$t('orderNow')"
                     v-on:click="saveOrder" />
    </top-header>
    <v-alert tile
             type="error"
             v-if="!validationAlert"
             v-bind:value="closed">
      {{ $t('thisCampaignIsNotActive') }}
    </v-alert>
    <v-alert dismissible
             tile
             type="error"
             v-model="validationAlert">
      {{ $t('yourFormContainsErrorsPleaseCheckAndCorrectTheMarkedFieldsInOrderToContinue') }}
    </v-alert>
    <v-alert dismissible
             tile
             type="warning"
             v-if="!validationAlert"
             v-model="alreadyPlacedAlert">
      {{ $t('youHaveAlreadyPlacedAnOrderForThisCampaignIfYouPlaceTheOrderAgainYourExistingOrderWillBeOverwritten') }}
    </v-alert>
    <v-container>
      <v-card class="mb-4"
              outlined
              v-if="hasRole('SagasserPortalAdministrator')">
        <v-subheader>
          {{ $t('customerLink') }}
        </v-subheader>
        <v-card-text>
          <a v-bind:href="`${baseUri}/sale-management/campaigns/${campaign.id}/orders/${site.id}`">{{ baseUri }}/sale-management/campaigns/{{ campaign.id }}/orders/{{ site.id }}</a>
        </v-card-text>
      </v-card>

      <!--<v-card class="mb-4"
              outlined
              v-if="campaign.allowComment">
        <v-subheader>
          {{ $t('comment') }}
        </v-subheader>
        <v-card-text class="pa-0">
          <v-textarea flat
                      solo
                      v-bind:placeholder="$t('pleaseEnterYourComments')"
                      v-model="comment" />
        </v-card-text>
      </v-card>-->

      <v-card class="mb-4"
              outlined
              v-if="campaign.documentationId != null">
        <v-subheader>
          {{ $t('attachments') }}
        </v-subheader>
        <v-card-text>
          {{ $t('theInitiatorOfThiscampaignHasAddedAttachmentsForYou') }}
        </v-card-text>
        <v-divider />
        <v-card-text>
          <v-btn color="accent"
                 target="_blank"
                 v-bind:href="attachmentUrl(campaign.documentationId)">
            <v-icon left>
              mdi-file-pdf-box
            </v-icon>
            {{ $t('documentation') }}
          </v-btn>
        </v-card-text>
      </v-card>

      <campaign-order-card ref="campaignOrderCard"
                           v-bind:campaign="campaign"
                           v-bind:closed="closed"
                           v-bind:orders="orders">
        <v-form ref="picForm">  
          <v-text-field outlined
                        hide-details="auto"
                        v-bind:disabled="!hasRole('SagasserPortalAdministrator') && closed"
                        v-bind:placeholder="$t('personInCharge')"
                        v-model="personInCharge"
                        v-bind:rules="[ rules.required ]" />
        </v-form>
      </campaign-order-card>
    </v-container>
  </div>
</template>

<script>
  import moment from 'moment'

  import { mapActions, mapGetters } from 'vuex'

  import settings from '@/settings'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import InformationDialog from '@/components/common/InformationDialog.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import CampaignOrderCard from '@/components/sale-management/CampaignOrderCard.vue'

  export default {
    async beforeRouteUpdate(to, _, next) {
      const campaignId = parseInt(to.params.campaignId)
      const siteId = to.params.siteId

      await this.get(campaignId, siteId)

      next()
    },
    components: {
      CampaignOrderCard,
      HeaderButton,
      InformationDialog,
      TopHeader
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      baseUri() {
        return settings.baseUri
      },
      closed() {
        if (this.campaign == null) {
          return true
        }

        if (this.campaign.state !== 'active') {
          return true
        }

        return moment().isBefore(moment(this.campaign.startDate)) || moment().isAfter(moment(this.campaign.endDate).add(1, 'days'))
      },
      ...mapGetters({
        attachmentUrl: 'common/attachmentUrl',
        currentSiteId: 'common/currentSiteId',
        getCampaign: 'saleManagement/campaign',
        getCampaignOrdersForCampaignAndSite: 'saleManagement/campaignOrdersForCampaignAndSite',
        getSite: 'common/site',
        hasRole: 'auth/hasRole',
        isAuthenticated: 'auth/isAuthenticated',
      })
    },
    async created() {
      const campaignId = parseInt(this.$route.params.campaignId)
      const siteId = this.$route.params.siteId

      await this.get(campaignId, siteId)

      if (moment().isBefore(this.campaign.startDate)) {
        // TODO
        // this.$router.push({ name: 'home' })
      }
    },
    data: () => ({
      personInCharge: null,
      alreadyPlacedAlert: false,
      campaign: null,
      comment: null,
      informationDialog: false,
      orders: [],
      site: null,
      validationAlert: false
    }),
    methods: {
      async get(campaignId, siteId) {
        await this.loadCampaigns()
        await this.loadProducts()
        await this.loadSites()

        if (this.getCampaign(campaignId) == null || this.getSite(siteId) == null) {
          // TODO: Redirect to info page for customers
          this.$router.push({ name: 'home' })
        }

        if(this.currentSiteId !== siteId && !this.hasRole('SagasserPortalAdministrator')) {
          // TODO: Redirect to info page for customers
          this.$router.push({ name: 'home' })
        }

        this.campaign = Object.assign({}, this.getCampaign(campaignId))
        this.site = Object.assign({}, this.getSite(siteId))

        await this.loadCampaignOrdersForCampaignAndSite({ campaignId, siteId: this.site.id, siteKey: null })

        this.orders = this.getCampaignOrdersForCampaignAndSite(campaignId, this.site.id).map(o => ({
          ...o,
          lastQuantity: o.quantity
        }))

        if (this.orders.some(o => o.quantity > 0 || o.personInCharge != null)) {
          this.alreadyPlacedAlert = true
        }
      },
      async saveOrder() {
        this.validationAlert = false

        if (!this.$refs.campaignOrderCard.validate()) {
          this.validationAlert = true

          return
        }

        if (!this.$refs.picForm.validate()) {
          this.validationAlert = true

          return
        }

        let orders = this.orders.map(o => ({
          ...o,
          personInCharge: this.personInCharge
        }))

        await this.updateCampaignOrders({
          campaignId: this.campaign.id,
          siteId: this.site.id,
          orders: orders,
          siteKey: this.site.key
        })

        await this.get(this.campaign.id, this.site.id)

        await this.$refs.informationDialog.open(async r => {})
      },
      ...mapActions({
        loadCampaignOrdersForCampaignAndSite: 'saleManagement/loadCampaignOrdersForCampaignAndSite',
        loadCampaigns: 'saleManagement/loadCampaigns',
        loadProducts: 'common/loadProducts',
        loadSites: 'common/loadSites',
        updateCampaignOrders: 'saleManagement/updateCampaignOrders'
      })
    },
    name: 'CampaignOrder'
  }
</script>

<i18n>
  {
    "de": {
      "attachments": "Anhänge",
      "back": "Zurück",
      "close": "Schließen",
      "comment": "Kommentar",
      "customerLink": "Kundenlink",
      "documentation": "Informationsmaterial",
      "orderNow": "Bestellung aufgeben",
      "pleaseEnterYourComments": "Bitte geben Sie Ihre Kommentare ein…",
      "thankYouForYourOrder": "Danke für Ihre Bestellung!",
      "theInitiatorOfThiscampaignHasAddedAttachmentsForYou": "Der Ersteller dieser Aktion hat Anhänge für Sie hinterlegt.",
      "thisCampaignIsNotActive": "Diese Aktionsabfrage ist nicht aktiv.",
      "updateOrder": "Aktualisieren",
      "youHaveAlreadyPlacedAnOrderForThisCampaignIfYouPlaceTheOrderAgainYourExistingOrderWillBeOverwritten": "Sie haben bereits eine Bestellung für diese Aktion aufgeben. Wenn Sie die Bestellung erneut aufgeben, wird Ihre bestehende Bestellung überschrieben.",
      "yourFormContainsErrorsPleaseCheckAndCorrectTheMarkedFieldsInOrderToContinue": "Ihre Bestellung enthält fehlerhafte Angaben. Bitte prüfen und korrigieren Sie die rot markierten Felder um fortzufahren.",
      "yourOrderHasBeenPlacedSuccessfully": "Ihre Bestellung wurde erfolgreich aufgegeben.",
      "personInCharge": "Bearbeiter"
    },
    "en": {
      "attachments": "Attachments",
      "back": "Back",
      "close": "Close",
      "comment": "Comment",
      "customerLink": "Customer link",
      "documentation": "Documentation",
      "orderNow": "Order now",
      "pleaseEnterYourComments": "Please enter your comments…",
      "thankYouForYourOrder": "Thank you for your order!",
      "theInitiatorOfThiscampaignHasAddedAttachmentsForYou": "The initiator of the campaign has added attachments for you.",
      "thisCampaignIsNotActive": "This campaign is not active.",
      "updateOrder": "Update order",
      "youHaveAlreadyPlacedAnOrderForThisCampaignIfYouPlaceTheOrderAgainYourExistingOrderWillBeOverwritten": "You have already placed an order for this campaign. If you place the order again, your existing order will be overwritten.",
      "yourFormContainsErrorsPleaseCheckAndCorrectTheMarkedFieldsInOrderToContinue": "Your form contains errors. Please check and correct the marked fields in order to continue.",
      "yourOrderHasBeenPlacedSuccessfully": "Your order has been placed successfully.",
      "personInCharge": "Person in charge"
    }
  }
</i18n>