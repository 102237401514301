<template>
  <v-list class="py-0"
          dark>
    <v-list-item class="accent"
                 exact
                 v-bind:to="{ name: 'home' }">
      <v-list-item-icon>
        <v-icon>mdi-chevron-left</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('back') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list-item exact
                 v-bind:to="{ name: 'meter-management-data-entry' }">
      <v-list-item-icon>
        <v-icon>mdi-cursor-text</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('dataEntry') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-bind:to="{ name: 'meter-management-values' }">
      <v-list-item-icon>
        <v-icon>mdi-table</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('values') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-bind:to="{ name: 'meter-management-ranking' }">
      <v-list-item-icon>
        <v-icon>mdi-podium</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('ranking') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    name: 'LearningNavigation'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "dataEntry": "Datenerfassung",
      "values": "Werte",
      "ranking": "Ranking"
    },
    "en": {
      "back": "Back",
      "dataEntry": "Data entry",
      "values": "Values",
      "ranking": "Ranking"
    }
  }
</i18n>