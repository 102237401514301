import Vue from 'vue'

export default {
  actions: {
    updateSortPropertiesFor({ commit }, { id, sortProperties }) {
      commit('updateSortPropertiesFor', { id, sortProperties })
    }
  },
  getters: {
    sortPropertiesFor: state => id => state.sortProperties[id],
    tenant: state => id => state.tenants.find(t => t.id === id),
    tenants: state => state.tenants,
    tenantTitle: state => id => state.tenants.find(t => t.id === id)?.title
  },
  mutations: {
    updateSortPropertiesFor(state, { id, sortProperties }) {
      Vue.set(state.sortProperties, id, sortProperties)
    },
  },
  namespaced: true,
  state: {
    sortProperties:
      {
        campaignTable: {
          sortBy: [ 'state', 'startDate' ],
          sortDesc: [ false, true ]
        }
      }
    ,
    tenants: [
      {
        id: 1,
        title: 'Sagasser'
      },
      {
        id: 2,
        title: 'Hilf, Scheinfeld'
      },
      {
        id: 3,
        title: 'Hilf, Burgebrach'
      },
      {
        id: 4,
        title: 'Faulhaber'
      },
      {
        id: 5,
        title: 'Lisa Mai'
      },
      {
        id: 6,
        title: 'Sagasser Fachmarkt West',
        siteSelection: true
      },
      {
        id: 7,
        title: 'Sagasser Fachmarkt Ost',
        siteSelection: true
      },
      {
        id: 8,
        title: 'Sagasser Fachmarkt Nord',
        siteSelection: true
      },
      {
        id: 9,
        title: 'Sagasser Fachmarkt Süd',
        siteSelection: true
      },
      {
        id: 10,
        title: 'Sagasser Forchheim'
      }
    ]
  }
}