<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details
                          prepend-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('events') }}</v-toolbar-title>
    </v-toolbar>
    <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredEvents"
                  v-bind:items-per-page="50"
                  v-bind:loading="loading"
                  v-bind:options="{ sortBy: [ 'id' ] }"
                  v-on:click:row="goToItem">
      <template v-slot:[`item.dates`]="{ item }">
        <v-chip-group>
          <v-chip dark
                  label
                  v-bind:key="date.id"
                  v-for="date in item.dates">
            {{ date.start | formatDate('L LT') }}
          </v-chip>
        </v-chip-group>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  export default {
    computed: {
      filteredEvents() {
        let filteredEvents = this.events

        if (this.search != null && this.search.length !== 0) {
          filteredEvents = filteredEvents.filter(f => f.title.toLowerCase().includes(this.search.toLowerCase()))
        }

        return filteredEvents
      },
      headers() {
        return [
          {
            text: this.$t('number'),
            value: 'id'
          },
          {
            text: this.$t('title'),
            value: 'title'
          },
          {
            text: this.$t('category'),
            value: 'category'
          },
          {
            text: this.$t('subCategory'),
            value: 'subCategory'
          },
          {
            sortable: false,
            text: this.$t('dates'),
            value: 'dates'
          }
        ]
      }
    },
    data: () => ({
      search: null
    }),
    methods: {
      goToItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id }})
      }
    },
    name: 'EventTableCard',
    props: {
      events: Array,
      itemRouteName: String,
      loading: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "category": "Kategorie",
      "dates": "Termine",
      "events": "Seminare",
      "number": "Nummer",
      "search": "Suche",
      "subCategory": "Unterkategorie",
      "title": "Bezeichnung"
    },
    "en": {
      "category": "Category",
      "dates": "Dates",
      "events": "Events",
      "number": "Number",
      "search": "Search",
      "subCategory": "Sub category",
      "title": "Title"
    }
  }
</i18n>