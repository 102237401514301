<template>
  <div>
    <top-header v-bind:title="$t('inventories')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-bind:to="{ name: 'inventory-management-inventory-add' }" />
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="sync">
            <v-list-item-title>{{ $t('sync') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-container>
      <v-row>
        <v-col>
          <inventories-table-card item-route-name="inventory-management-inventory"
                                  v-bind:inventories="inventories"
                                  v-bind:loading="loading" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import InventoriesTableCard from '@/components/inventory-management/InventoriesTableCard'

  export default {
    components: {
      TopHeader,
      HeaderButton,
      InventoriesTableCard
    },
    computed: {
      ...mapGetters({ 
        hasRole: 'auth/hasRole',
        inventories: 'inventoryManagement/inventories'
      })
    },
    async created() {
      this.loading = true

      await this.loadTenants()
      await this.loadWarehouses()
      await this.loadInventories()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      async sync() {
        this.loading = true

        const deleteMissing = false

        await this.syncInventoryEmpties({ deleteMissing })
        await this.syncInventoryProducts({ deleteMissing })
        await this.syncInventoryPackagingUnits({ deleteMissing })
        await this.syncInventories({ deleteMissing })
        await this.syncInventoryReceipts({ deleteMissing })

        await this.loadInventories()
        
        this.loading = false
      },
      ...mapActions({
        loadInventories: 'inventoryManagement/loadInventories',
        syncInventoryProducts: 'inventoryManagement/syncInventoryProducts',
        syncInventoryEmpties: 'inventoryManagement/syncInventoryEmpties',
        syncInventoryReceipts: 'inventoryManagement/syncInventoryReceipts',
        syncInventories: 'inventoryManagement/syncInventories',
        syncInventoryPackagingUnits: 'inventoryManagement/syncInventoryPackagingUnits',
        loadTenants: 'common/loadTenants',
        loadWarehouses: 'common/loadWarehouses'
      })
    },
    name: 'Inventories'
  }
</script>

<i18n>
  {
    "de": {
      "inventories": "Inventuren",
      "add": "Neu",
      "sync": "Synchronisieren"
    },
    "en": {
      "inventories": "Inventories",
      "add": "Add",
      "sync": "Sync"
    }
  }
</i18n>