<template>
  <div>
    <top-header v-bind:title="$t('values')" />
    <v-container>
      <v-row>
        <v-col>
          <meter-values-table-card v-bind:site-meter-values="values"
                                   v-bind:loading="loading" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import MeterValuesTableCard from '@/components/meter-management/MeterValuesTableCard'

  export default {
    components: {
      TopHeader,
      MeterValuesTableCard
    },
    computed: {
      ...mapGetters({
        siteId: 'common/currentSiteId',
        siteMeters: 'meterManagement/siteMeters',
        siteMeterValues: 'meterManagement/siteMeterValues'
      })
    },
    async created() {
      this.loading = true

      await this.loadSiteMeters()
      await this.loadSiteMeterValues()

      this.values = [...this.siteMeterValues].filter(m => m.siteMeter.siteId === this.siteId).map(m => ({
        ...m,
        text: m.siteMeter.text,
        type: m.siteMeter.type,
        number: m.siteMeter.number,
        unit: m.siteMeter.unit,
        meter: `${m.siteMeter.number}: ${m.siteMeter.text}`
      })).sort((a, b) => a.date < b.date ? 1 : -1)

      this.loading = false
    },
    data: () => ({
      values: [],
      loading: false
    }),
    methods: {
      ...mapActions({
        loadSiteMeters: 'meterManagement/loadSiteMeters',
        loadSiteMeterValues: 'meterManagement/loadSiteMeterValues'
      })
    },
    name: 'MeterDataEntry'
  }
</script>

<i18n>
  {
    "de": {
      "values": "Werte"
    },
    "en": {
      "values": "Values"
    }
  }
</i18n>