import Exam from '../views/learning/Exam.vue'
import ExamAdd from '../views/learning/ExamAdd.vue'
import Exams from '../views/learning/Exams.vue'
import Learning from '../views/learning/Learning.vue'
import LearningNavigation from '../views/learning/navigation/LearningNavigation.vue'
import TraineeLearningNavigation from '../views/learning/navigation/TraineeLearningNavigation.vue'

import MyExam from '../views/learning/MyExam.vue'
import MyExams from '../views/learning/MyExams.vue'
import MyLearningPortal from '../views/learning/MyLearningPortal.vue'
import MyQuestionnaire from '../views/learning/MyQuestionnaire.vue'

import Subject from '../views/learning/Subject.vue'
import SubjectAdd from '../views/learning/SubjectAdd.vue'
import Subjects from '../views/learning/Subjects.vue'
import Trainee from '../views/learning/Trainee.vue'
import TraineeAdd from '../views/learning/TraineeAdd.vue'
import Trainees from '../views/learning/Trainees.vue'

export default [
  {
    components: {
      default: Learning,
      navigation: LearningNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'learning',
    path: '/learning'
  },

  {
    components: {
      default: ExamAdd,
      navigation: LearningNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'learning-exam-add',
    path: '/learning/exams/add'
  },
  {
    components: {
      default: Exam,
      navigation: LearningNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'learning-exam',
    path: '/learning/exams/:id'
  },
  {
    components: {
      default: Exams,
      navigation: LearningNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'learning-exams',
    path: '/learning/exams'
  },
  {
    components: {
      default: SubjectAdd,
      navigation: LearningNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'learning-subject-add',
    path: '/learning/subjects/add'
  },
  {
    components: {
      default: Subject,
      navigation: LearningNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'learning-subject',
    path: '/learning/subjects/:id'
  },
  {
    components: {
      default: Subjects,
      navigation: LearningNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'learning-subjects',
    path: '/learning/subjects'
  },
  {
    components: {
      default: TraineeAdd,
      navigation: LearningNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'learning-trainee-add',
    path: '/learning/trainees/add'
  },
  {
    components: {
      default: Trainee,
      navigation: LearningNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'learning-trainee',
    path: '/learning/trainees/:id'
  },
  {
    components: {
      default: Trainees,
      navigation: LearningNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'learning-trainees',
    path: '/learning/trainees'
  },


  {
    components: {
      default: MyExam,
      navigation: TraineeLearningNavigation
    },
    name: 'learning-my-exam',
    path: '/learning/my-exams/:invitationKey/:id'
  },
  {
    components: {
      default: MyExams,
      navigation: TraineeLearningNavigation
    },
    name: 'learning-my-exams',
    path: '/learning/my-exams/:invitationKey'
  },
  {
    components: {
      default: MyLearningPortal,
      navigation: TraineeLearningNavigation
    },
    name: 'learning-my-learning-portal',
    path: '/learning/my-exams'
  },
  {
    components: {
      default: MyQuestionnaire,
      navigation: TraineeLearningNavigation
    },
    name: 'learning-my-exam-questionnaire',
    path: '/learning/my-exams/:invitationKey/:id/questionnaire'
  }
]