<template>
  <div>
    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="$t('newInventory')">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'inventory-management-inventories' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="createInventory" />
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container>
      <v-row>
        <v-col cols="12"
               lg="6">
          <inventory-details-card ref="inventoryDetailsCard"
                                  v-bind:inventory="inventory" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import InventoryDetailsCard from '@/components/inventory-management/InventoryDetailsCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      InventoryDetailsCard,
      HeaderButton,
      TopHeader
    },
    data: () => ({
      inventory: {
      },
      loading: false,
      validationSnackbar: false
    }),
    created() {
      this.loading = true

      this.loadTenants()
      this.loadWarehouses()

      this.loading = false
    },
    methods: {
      async createInventory() {
        if (!this.$refs.inventoryDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        await this.doCreateInventory({ inventory: this.inventory })

        this.$router.push({ name: 'inventory-management-inventory', params: { id: this.inventory.id } })
      },
      ...mapActions({
        doCreateInventory: 'inventoryManagement/createInventory',
        loadTenants: 'common/loadTenants',
        loadWarehouses: 'common/loadWarehouses'
      })
    },
    name: 'InventoryAdd'
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "newInventory": "Neue Inventur",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "cancel": "Cancel",
      "newInventory": "New inventory",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>