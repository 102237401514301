<template>
  <div>
    <top-header v-bind:title="title">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'inventory-management-app-inventory-receipt', params: { inventoryId, id } }"
                     v-if="inventoryReceipt != null" />
    </top-header>
    <v-snackbar v-model="snackbar" color="light-green">
      Gespeichert
    </v-snackbar>
    <v-progress-linear color="accent"
                       indeterminate
                       v-bind:active="loading" />
                       <v-snackbar centered
             color="error"
             v-model="eanError">
      {{ $t('eanWasNotFound') }}
    </v-snackbar>
    <inventory-filled-stock-details-card ref="inventoryFilledStockDetailsCard"
                                         v-bind:transaction="transaction"
                                         v-if="inventoryReceipt != null"
                                         v-on:save="save"
                                         v-on:submit="save" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import IconButtonCard from '@/components/inventory-management/IconButtonCard.vue'
  import InventoryFilledStockDetailsCard from '@/components/inventory-management/InventoryFilledStockDetailsCard.vue'

  export default {
    async beforeRouteUpdate(to, _, next) {
      const id = to.params.id
      const inventoryId = parseInt(to.params.inventoryId)

      await this.get(id, inventoryId)

      next()
    },
    components: {
      TopHeader,
      HeaderButton,
      IconButtonCard,
      InventoryFilledStockDetailsCard
    },
    computed: {
      selectedPosition() {
        return this.inventoryPositions.find(i => i.positionNumber === this.transaction.positionNumber)
      },
      title() {
        return `${this.inventoryReceipt?.id || this.$t('receipt')}`
      },
      id() {
        return this.inventoryReceipt?.id
      },
      ...mapGetters({
        getUser: 'inventoryManagement/user',
        units: 'inventoryManagement/units',
        getInventoryReceipt: 'inventoryManagement/inventoryReceipt',
        inventoryPositions: 'inventoryManagement/inventoryPositions'
      })
    },
    async created() {
      const id = this.$route.params.id
      const inventoryId = parseInt(this.$route.params.inventoryId)

      window.androidProxy.scan = async ean => {
        this.transaction.positionNumber = null
        this.$refs.inventoryFilledStockDetailsCard.resetSearch('')

        const position = this.inventoryPositions.find(i => i.inventoryProduct?.ean === ean)


        this.transaction.positionNumber = position?.positionNumber

        if (!!position) {
          this.eanError = false
          this.$refs.inventoryFilledStockDetailsCard.doFocus()
        } else {
          this.eanError = true
        }
      }

      await this.get(id, inventoryId)
    },
    data: () => ({
      transaction: {
        positionNumber: null
      },
      inventoryId: null,
      inventoryReceipt: null,
      loading: false,
      snackbar: false,
      eanError: false
    }),
    methods: {
      async save() {

        if (!this.$refs.inventoryFilledStockDetailsCard.validate()) {
          return
        }

        for (const unit of this.selectedPosition.inventoryProduct.inventoryPackagingUnits) {
          if (this.transaction[unit.unit] != null && this.transaction[unit.unit] != 0) {
            const transaction = {
              inventoryPositionInventoryReceiptId: this.inventoryReceipt.id,
              InventoryPositionPositionNumber: this.transaction.positionNumber,
              unit: unit.unit,
              countQuantity: this.transaction[unit.unit],
              countedBy: this.getUser?.username
            }

            await this.createInventoryTransaction({ inventoryTransaction: transaction})

          }
        }

        this.snackbar = true

        this.$refs.inventoryFilledStockDetailsCard.resetSearch('')

        this.transaction = {
          positionNumber: null
        }

        //this.$router.push({ name: 'inventory-management-app-inventory-receipt', params: { inventoryId: this.inventoryId, id: this.id } })
      },
      async get(id, inventoryId) {
        this.inventoryId = inventoryId

        this.loading = true

        await this.retrieveUser()

        await this.loadInventoryPositionsForReceipt({ receiptId: id })
        await this.loadInventoryReceipt({ id })

        this.loading = false

        this.inventoryReceipt = Object.assign({}, this.getInventoryReceipt(id))
      },
      ...mapActions({
        createInventoryTransaction: 'inventoryManagement/createInventoryTransaction',
        retrieveUser: 'inventoryManagement/retrieveUser',
        loadInventoryPositionsForReceipt: 'inventoryManagement/loadInventoryPositionsForReceipt',
        loadInventoryReceipt: 'inventoryManagement/loadInventoryReceipt',
      })
    },
    name: 'AppInventoryFilledStock'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "receipt": "Beleg",
      "filledStock": "Vollgut",
      "empties": "Leergut",
      "transactions": "Transaktionen",
      "eanWasNotFound": "EAN nicht gefunden."
    },
    "en": {
      "back": "Back",
      "receipt": "Receipt",
      "filledStock": "Filled Stock",
      "empties": "Empties",
      "transactions": "Transactions",
      "eanWasNotFound": "EAN was not found."
    }
  }
</i18n>