<template>
  <div>
    <top-header v-bind:title="$t('learningPortal')" />
    <v-progress-linear indeterminate
                       v-bind:active="loading" />
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <invitation-code-card v-bind:error="error"
                                v-on:sign-in="signIn" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import InvitationCodeCard from '@/components/learning/InvitationCodeCard.vue'

  export default {
    components: {
      InvitationCodeCard,
      TopHeader
    },
    computed: {
      ...mapGetters({
        examsForInvitationKey: 'learning/examsForInvitationKey'
      })
    },
    data: () => ({
      error: false,
      loading: false
    }),
    methods: {
      async signIn(invitationKey) {
        invitationKey = invitationKey.toUpperCase()

        console.log(invitationKey)

        this.loading = true

        await this.loadExamsForInvitationKey({ invitationKey })

        this.loading = false

        const exams = [ ...this.examsForInvitationKey(invitationKey) ]

        if (exams.length === 0) {
          this.error = true

          return
        }

        this.$router.push({ name: 'learning-my-exams', params: { invitationKey } })
      },
      ...mapActions({
        loadExamsForInvitationKey: 'learning/loadExamsForInvitationKey'
      })
    },
    name: 'MyLearningPortal'
  }
</script>

<i18n>
  {
    "de": {
      "learningPortal": "Unterweisungsportal"
    },
    "en": {
      "learningPortal": "Learning portal"
    }
  }
</i18n>