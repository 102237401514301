<template>
  <div>
    <top-header v-bind:title="$t('learning')" />
    <v-container>
      <v-row>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator')">
          <icon-dashboard-card icon="mdi-account-school"
                               v-bind:title="$t('trainees')"
                               v-bind:to="{ name: 'learning-trainees' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator')">
          <icon-dashboard-card icon="mdi-book"
                               v-bind:title="$t('subjects')"
                               v-bind:to="{ name: 'learning-subjects' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator')">
          <icon-dashboard-card icon="mdi-pencil"
                               v-bind:title="$t('exams')"
                               v-bind:to="{ name: 'learning-exams' }" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import IconDashboardCard from '@/components/common/IconDashboardCard.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      IconDashboardCard,
      TopHeader
    },
    computed: {
      ...mapGetters({ hasRole: 'auth/hasRole' })
    },
    name: 'Learning'
  }
</script>

<i18n>
  {
    "de": {
      "exams": "Prüfungen",
      "learning": "Unterweisung",
      "subjects": "Module",
      "trainees": "Trainees"
    },
    "en": {
      "exams": "Exams",
      "learning": "Learning",
      "subjects": "Subjects",
      "trainees": "Trainees"
    }
  }
</i18n>