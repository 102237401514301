<template>
  <div>
    <v-card>
      <v-toolbar color="primary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <campaign-details ref="campaignDetails"
                          v-bind:campaign="campaign"
                          v-bind:restricted-editing="restrictedEditing" />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import CampaignDetails from '@/components/sale-management/CampaignDetails'

  export default {
    components: {
      CampaignDetails
    },
    name: 'CampaignDetailsCard',
    methods: {
      validate() {
        return this.$refs.campaignDetails.validate()
      }
    },
    props: {
      campaign: Object,
      restrictedEditing: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details"
    },
    "en": {
      "details": "Details"
    }
  }
</i18n>