<template>
  <div>
    <top-header v-bind:title="$t('administration')" />
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <import-sql-card v-bind:error="manufacturersError || manufacturersFilenameError"
                           v-bind:error-message="manufacturersFilenameError ? $t('theFilenameMustStartWith', { name: 'manufacturers' }) : $t('anErrorOccurredWhileImportingManufacturers')"
                           v-bind:help-text="$t('pleaseUploadACsvFileWithANameStartingWithAndTheFields', { fields: 'id, title, customerId', name: 'manufacturers' })"
                           v-bind:label="$t('manufacturers')"
                           v-bind:loading="manufacturersLoading"
                           v-bind:success="manufacturersSuccess"
                           v-bind:success-message="$t('manufacturersWereImportedSuccessfully')"
                           v-on:import="startImportManufacturers" />
        </v-col>
        <v-col cols="12" md="6">
          <import-sql-card v-bind:error="productsError || productsFilenameError"
                           v-bind:error-message="productsFilenameError ? $t('theFilenameMustStartWith', { name: 'products' }) : $t('anErrorOccurredWhileImportingProducts')"
                           v-bind:help-text="$t('pleaseUploadACsvFileWithANameStartingWithAndTheFields', { fields: 'id, title, primaryUnit, baseUnit, manufacturerId', name: 'products' })"
                           v-bind:label="$t('products')"
                           v-bind:loading="productsLoading"
                           v-bind:success="productsSuccess"
                           v-bind:success-message="$t('productsWereImportedSuccessfully')"
                           v-on:import="startImportProducts" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <import-card v-bind:error="sitesError || sitesFilenameError"
                       v-bind:error-message="sitesFilenameError ? $t('theFilenameMustStartWith', { name: 'sites' }) : $t('anErrorOccurredWhileImportingSites')"
                       v-bind:help-text="$t('pleaseUploadACsvFileWithANameStartingWithAndTheFields', { fields: 'id, title, streetAddress, postalCode, city, tenant, group, region, salutation, familyName', name: 'sites' })"
                       v-bind:label="$t('sites')"
                       v-bind:loading="sitesLoading"
                       v-bind:success="sitesSuccess"
                       v-bind:success-message="$t('sitesWereImportedSuccessfully')"
                       v-on:upload="uploadSites" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import Papa from 'papaparse'

  import ImportCard from '@/components/administration/ImportCard.vue'
  import ImportSqlCard from '@/components/administration/ImportSqlCard.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      ImportCard,
      TopHeader,
      ImportSqlCard
    },
    data: () => ({
      manufacturersError: false,
      manufacturersFilenameError: false,
      manufacturersLoading: false,
      manufacturersSuccess: false,
      productsError: false,
      productsFilenameError: false,
      productsLoading: false,
      productsSuccess: false,
      sitesError: false,
      sitesFilenameError: false,
      sitesLoading: false,
      sitesSuccess: false
    }),
    methods: {
      /*readFile(file) {
        return new Promise((resolve, reject) => {
          const fileReader = new FileReader()

          fileReader.onerror = e => {
            reject(e)
          }

          fileReader.onload = e => {
            const result = e.target.result

            resolve(result)
          }

          fileReader.readAsText(file)
        })
      },*/
      readCsv(file) {
        return new Promise((resolve, reject) => {
          Papa.parse(file, {
            complete: results => {
              resolve(results.data)
            },
            error: err => {
              reject(err)
            },
            header: true,
            skipEmptyLines: true,
            transform: (value, field) => {
              return value
            }
          })
        })
      },
      async startImportManufacturers(data) {
        this.manufacturersError = false
        this.manufacturersFilenameError = false
        this.manufacturersLoading = true
        this.manufacturersSuccess = false

        try {
          await this.importSqlManufacturers({
            deleteMissing: data.deleteCurrentData
          })

          this.manufacturersSuccess = true
        } catch {
          this.manufacturersError = true
        } finally {
          this.manufacturersLoading = false
        }
      },
      async uploadManufacturers(data) {
        this.manufacturersError = false
        this.manufacturersFilenameError = false
        this.manufacturersLoading = true
        this.manufacturersSuccess = false

        const filename = data.file?.name

        if (filename == null || !filename.startsWith('manufacturers')) {
          this.manufacturersFilenameError = true
          this.manufacturersLoading = false

          return
        }

        try {
          const result = await this.readCsv(data.file)

          await this.importManufacturers({
            deleteMissing: data.deleteCurrentData,
            manufacturers: result
          })

          this.manufacturersSuccess = true
        } catch {
          this.manufacturersError = true
        } finally {
          this.manufacturersLoading = false
        }
      },
      async startImportProducts(data) {
        this.productsError = false
        this.productsFilenameError = false
        this.productsLoading = true
        this.productsSuccess = false

        try {
          await this.importSqlProducts({
            deleteMissing: data.deleteCurrentData
          })

          this.productsSuccess = true
        } catch {
          this.productsError = true
        } finally {
          this.productsLoading = false
        }
      },
      async uploadProducts(data) {
        this.productsError = false
        this.productsFilenameError = false
        this.productsLoading = true
        this.productsSuccess = false

        const filename = data.file?.name

        if (filename == null || !filename.startsWith('products')) {
          this.productsFilenameError = true
          this.productsLoading = false

          return
        }

        try {
          const result = await this.readCsv(data.file)

          await this.importProducts({
            deleteMissing: data.deleteCurrentData,
            products: result
          })

          this.productsSuccess = true
        } catch {
          this.productsError = true
        } finally {
          this.productsLoading = false
        }
      },
      async uploadSites(data) {
        this.sitesError = false
        this.sitesFilenameError = false
        this.sitesLoading = true
        this.sitesSuccess = false

        const filename = data.file?.name

        if (filename == null || !filename.startsWith('sites')) {
          this.sitesFilenameError = true
          this.sitesLoading = false

          return
        }

        try {
          const result = await this.readCsv(data.file)

          const sites = result.map(r => ({
            ...r,
            postalCode: `${r.postalCode}`
          }))

          await this.importSites({
            deleteMissing: data.deleteCurrentData,
            sites
          })

          this.sitesSuccess = true
        } catch {
          this.sitesError = true
        } finally {
          this.sitesLoading = false
        }
      },
      ...mapActions({
        importManufacturers: 'common/importManufacturers',
        importProducts: 'common/importProducts',
        importSqlManufacturers: 'common/importSqlManufacturers',
        importSqlProducts: 'common/importSqlProducts',
        importSites: 'common/importSites'
      })
    },
    name: 'Administration'
  }
</script>

<i18n>
  {
    "de": {
      "administration": "Administration",
      "anErrorOccurredWhileImportingManufacturers": "Während dem Import der Hersteller ist ein Fehler aufgetreten.",
      "anErrorOccurredWhileImportingProducts": "Während dem Import der Artikel ist ein Fehler aufgetreten.",
      "anErrorOccurredWhileImportingSites": "Während dem Import der Märkte ist ein Fehler aufgetreten.",
      "pleaseUploadACsvFileWithANameStartingWithAndTheFields": "Bitte laden Sie eine CSV-Datei mit einem Namen, der mit {name} beginnt und den folgenden Feldern hoch: {fields}",
      "manufacturers": "Hersteller",
      "manufacturersWereImportedSuccessfully": "Die Hersteller wurden erfolgreich importiert.",
      "products": "Artikel",
      "productsWereImportedSuccessfully": "Die Artikel wurden erfolgreich importiert.",
      "sites": "Märkte",
      "sitesWereImportedSuccessfully": "Die Märkte wurden erfolgreich importiert.",
      "theFilenameMustStartWith": "Der Dateiname muss mit {name} beginnen."
    },
    "en": {
      "administration": "Administration",
      "anErrorOccurredWhileImportingManufacturers": "An error cccurred while importing manufacturers.",
      "anErrorOccurredWhileImportingProducts": "An error cccurred while importing products.",
      "anErrorOccurredWhileImportingSites": "An error cccurred while importing sites.",
      "pleaseUploadACsvFileWithANameStartingWithAndTheFields": "Please upload a csv file with a name starting with {name} and the fields {fields}.",
      "manufacturers": "Manufacturers",
      "manufacturersWereImportedSuccessfully": "Manufacturers were imported successfully.",
      "products": "Products",
      "productsWereImportedSuccessfully": "Products were imported successfully.",
      "sites": "Sites",
      "sitesWereImportedSuccessfully": "Sites were imported successfully.",
      "theFilenameMustStartWith": "The filename must start with {name}."
    }
  }
</i18n>