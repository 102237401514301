<template>
  <div>
    <v-card flat
            tile>
      <v-progress-linear color="accent"
                         indeterminate
                         v-if="loading" />
      <v-form v-model="valid">
        <v-subheader>
          {{ label }}
          <v-spacer />
          <v-tooltip bottom
                     v-if="helpText != null">
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="grey"
                      v-bind="attrs"
                      v-on="on">
                mdi-information
              </v-icon>
            </template>
            <span>{{ helpText }}</span>
          </v-tooltip>
        </v-subheader>
        <v-card-text>
          <v-alert dismissible
                   type="error"
                   v-if="error">
            {{ errorMessage }}
          </v-alert>
          <v-alert dismissible
                   type="success"
                   v-if="success">
            {{ successMessage }}
          </v-alert>
          <v-file-input accept="text/csv"
                        filled
                        show-size
                        v-bind:label="$t('file')"
                        v-bind:rules="[ rules.required ]"
                        v-model="file" />
          <v-checkbox v-bind:label="$t('deleteCurrentData')"
                      v-bind:rules="requireDeletion ? [ rules.required ] : []"
                      v-model="deleteCurrentData" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="accent"
                 text
                 v-bind:disabled="!valid"
                 v-on:click="$emit('upload', { deleteCurrentData, file })">
            {{ $t('upload') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
    },
    data: () => ({
      deleteCurrentData: false,
      file: null,
      valid: false
    }),
    name: 'ImportCard',
    props: {
      error: Boolean,
      errorMessage: String,
      helpText: String,
      label: String,
      loading: Boolean,
      requireDeletion: Boolean,
      success: Boolean,
      successMessage: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "deleteCurrentData": "Bestehende Daten löschen",
      "file": "Datei",
      "upload": "Hochladen"
    },
    "en": {
      "deleteCurrentData": "Delete current data",
      "file": "File",
      "upload": "Upload"
    }
  }
</i18n>