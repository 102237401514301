export default class SiteMeterRankingService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUri}/meterManagement/siteMeterRanking`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }
}