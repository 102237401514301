<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('dates') }}</v-toolbar-title>
        <v-spacer />
        <v-btn exact
               icon
               v-bind:to="{ name: this.addRouteName, params: routeParams }">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                       v-bind:items="eventDates"
                       v-bind:items-per-page="10">
        <template v-slot:default="props">
          <v-list>
            <v-list-item two-line
                         v-bind:key="eventDate.id"
                         v-for="eventDate in props.items"
                         v-on:click="goToItem(eventDate)">
              <v-list-item-avatar>
                <v-icon class="primary lighten-1"
                        dark>
                  mdi-calendar
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ eventDate.start | formatDate('L LT') }} - {{ eventDate.end | formatDate('L LT') }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ eventDate.online ? $t('online') : eventDate.location }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip class="ml-2 white--text"
                            label
                            small
                            v-bind="attrs"
                            v-bind:color="getColor(eventDate)"
                            v-on="on">
                      {{ eventDate.reservedSlots }} / {{ eventDate.slots }}
                    </v-chip>
                  </template>
                  <span>{{ $t('theRegistrationPeriodEndsOn', { date: $options.filters.formatDate(eventDate.registrationPeriod, 'L LT') }) }}</span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider />
        </template>
        <template v-slot:no-data>
        <v-card-text class="text-center text--disabled">
          {{ $t('noDatesAvailable') }}
        </v-card-text>
        <v-divider />
      </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'EventDateListCard',
    methods: {
      getColor(eventDate) {
        if (eventDate.isBookable) {
          return 'light-green'
        }

        return 'deep-orange'
      },
      goToItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id, ...this.routeParams }})
      }
    },
    props: {
      addRouteName: String,
      eventDates: Array,
      itemRouteName: String,
      routeParams: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "dates": "Termine",
      "noDatesAvailable": "Keine Termine vorhanden",
      "online": "Online",
      "theRegistrationPeriodEndsOn": "Die Anmeldefrist endet am {date}."
    },
    "en": {
      "dates": "Dates",
      "noDatesAvailable": "No dates available",
      "online": "Online",
      "theRegistrationPeriodEndsOn": "The registration period ends on {date}."
    }
  }
</i18n>