<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('attendees') }}</v-toolbar-title>
        <v-spacer />
        <v-btn exact
               icon
               v-bind:disabled="!allowCreation"
               v-on:click="addAttendee">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                       v-bind:items="attendees"
                       v-bind:items-per-page="10">
        <template v-slot:default="props">
          <v-list>
            <v-list-item two-line
                         v-bind:key="attendee.id"
                         v-for="attendee in props.items"
                         v-on:click="openAttendee(attendee.id)">
              <v-list-item-avatar>
                <v-icon class="primary lighten-1"
                        dark>
                  mdi-account
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ attendee.givenName }} <strong>{{ attendee.familyName }}</strong>
                  <v-icon class="ml-2"
                          color="deep-orange"
                          v-if="attendee.hasCancelled">
                    mdi-calendar-remove
                  </v-icon>
                </v-list-item-title>
                <v-list-item-subtitle v-if="!external">{{ tenantTitle(attendee.tenantId) }}, <span v-if="!!attendee.departmentId">{{ departmentTitle(attendee.departmentId)}}</span> <span v-if="!!attendee.siteId">{{ attendee.siteId }}</span></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <delete-confirmation-button v-bind:item="attendee"
                                            v-on:delete="deleteAttendee($event.id)" />
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider />
        </template>
        <template v-slot:no-data>
        <v-card-text class="text-center text--disabled">
          {{ $t('noAttendeesAvailable') }}
        </v-card-text>
        <v-divider />
      </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DeleteConfirmationButton from '@/components/common/DeleteConfirmationButton.vue'

  export default {
    components: {
      DeleteConfirmationButton
    },
    computed: {
      ...mapGetters({
        departmentTitle: 'common/departmentTitle',
        tenantTitle: 'local/tenantTitle'
      })
    },
    name: 'AttendeeListCard',
    methods: {
      addAttendee() {
        this.$emit('add-attendee')
      },
      deleteAttendee(attendeeId) {
        this.$emit('delete-attendee', attendeeId)
      },
      openAttendee(attendeeId) {
        this.$emit('open-attendee', attendeeId)
      }
    },
    props: {
      addRouteName: String,
      allowCreation: Boolean,
      attendees: Array,
      itemRouteName: String,
      routeParams: Object,
      external: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "attendees": "Teilnehmer",
      "noAttendeesAvailable": "Keine Teilnehmer vorhanden"
    },
    "en": {
      "attendees": "Attendee",
      "noAttendeesAvailable": "No dates available"
    }
  }
</i18n>