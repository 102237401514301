<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left"
                v-if="showQuantity">
              {{ $t('quantity') }}
            </th>
            <th class="text-left"
                v-if="showUnit">
              {{ $t('unit') }}
            </th>
            <th class="text-left">
              {{ $t('productId') }}
            </th>
            <th class="text-left">
              {{ $t('title') }}
            </th>
            <th class="text-left"
                v-if="showDiscount">
              {{ $t('discount') }}
            </th>
            <th v-if="showDelete || showEdit">
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-bind:key="productEntry.productId"
              v-for="productEntry in productEntries">
            <td v-if="showQuantity">{{ productEntry.quantity }} {{ productEntry.unit }}</td>
            <td v-if="showUnit">{{ productEntry.unit }}</td>
            <td>
              {{ productEntry.productId }}
              <v-icon small
                      v-if="productTemporary(productEntry.productId)">
                mdi-clock
              </v-icon>
            </td>
            <td>{{ productTitle(productEntry.productId) }}</td>
            <td v-if="showDiscount">{{ productEntry.discount }}</td>
            <td v-if="showDelete || showEdit">
              <v-btn class="mr-2"
                     icon
                     v-if="showEdit"
                     v-on:click="$emit('edit-product', productEntry.productId)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn color="deep-orange"
                     icon
                     v-if="showDelete"
                     v-on:click="$emit('delete-product', productEntry.productId)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      ...mapGetters({
        productTemporary: 'common/productTemporary',
        productTitle: 'common/productTitle'
      })
    },
    name: 'ProductEntryTable',
    props: {
      productEntries: Array,
      showDelete: Boolean,
      showDiscount: Boolean,
      showEdit: Boolean,
      showQuantity: Boolean,
      showUnit: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "discount": "Kondition",
      "productId": "Artikelnummer",
      "quantity": "Menge",
      "title": "Bezeichnung",
      "unit": "Einheit"
    },
    "en": {
      "discount": "Discount",
      "productId": "Product id",
      "quantity": "Quantity",
      "title": "Title",
      "unit": "Unit"
    }
  }
</i18n>