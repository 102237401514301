<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details
                          prepend-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
          <v-col>
            <v-autocomplete clearable
                            flat
                            hide-details
                            multiple
                            prepend-icon="mdi-book"
                            solo
                            v-bind:items="subjects | items({ text: 'title', value: 'id' })"
                            v-bind:label="$t('subject')"
                            v-model="subjectFilter" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('exams') }}</v-toolbar-title>
    </v-toolbar>
    <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredExams"
                  v-bind:items-per-page="50"
                  v-bind:loading="loading"
                  v-bind:options="{ sortBy: [ 'start' ], sortDesc: [ true ] }"
                  v-on:click:row="goToItem">
      <template v-slot:[`item.end`]="{ item }">
        {{ item.end | formatDate('L') }}
      </template>
      <template v-slot:[`item.start`]="{ item }">
        {{ item.start | formatDate('L') }}
      </template>
      <template v-slot:[`item.subjectId`]="{ item }">
        {{ subjectTitle(item.subjectId) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      filteredExams() {
        let filteredExams = this.exams

        if (this.search != null && this.search.length !== 0) {
          filteredExams = filteredExams.filter(f => f.title.toLowerCase().includes(this.search.toLowerCase()))
        }

        if (this.subjectFilter != null && this.subjectFilter.length !== 0) {
          filteredExams = filteredExams.filter(f => this.subjectFilter.includes(f.subjectId))
        }

        return filteredExams
      },
      headers() {
        return [
          {
            text: this.$t('number'),
            value: 'id'
          },
          {
            text: this.$t('title'),
            value: 'title'
          },
          {
            text: this.$t('start'),
            value: 'start'
          },
          {
            text: this.$t('end'),
            value: 'end'
          },
          {
            text: this.$t('subject'),
            value: 'subjectId'
          }
        ]
      },
      ...mapGetters({
        subjects: 'learning/subjects',
        subjectTitle: 'learning/subjectTitle'
      })
    },
    data: () => ({
      search: null,
      subjectFilter: null
    }),
    methods: {
      goToItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id }})
      }
    },
    name: 'ExamTableCard',
    props: {
      exams: Array,
      itemRouteName: String,
      loading: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "end": "Ende",
      "exams": "Prüfungen",
      "number": "Nummer",
      "search": "Suche",
      "start": "Start",
      "subject": "Modul",
      "title": "Bezeichnung"
    },
    "en": {
      "end": "End",
      "exams": "Exams",
      "number": "Number",
      "search": "Search",
      "start": "Start",
      "subject": "Subject",
      "title": "Title"
    }
  }
</i18n>