<template>
  <div>
    <v-dialog v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="500">
      <v-card>
        <v-card-title class="headline primary--text">
          {{ title }}
        </v-card-title>
        <v-card-text>
          {{ message }}
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn text
                 v-bind:color="closeButtonColor"
                 v-on:click="close">
            {{ closeButtonLabel }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data: () => ({
      resolve: null
    }),
    methods: {
      close() {
        this.$emit('input', false)
        this.resolve(true)
      },
      async open(resolve) {
        this.resolve = resolve
        this.$emit('input', true)
      }
    },
    name: 'InformationDialog',
    props: {
      closeButtonLabel: String,
      closeButtonColor: {
        default: 'accent',
        type: String
      },
      message: String,
      title: String,
      value: Boolean
    }
  }
</script>