<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <template v-slot:extension>
          <v-text-field flat
                        hide-details="auto"
                        prepend-icon="mdi-magnify"
                        solo
                        v-bind:label="$t('search')"
                        v-model="search" />
        </template>
        <v-toolbar-title>{{ $t('result') }}</v-toolbar-title>
        
      </v-toolbar>
      <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                    v-bind:headers="headers"
                    v-bind:items="filteredInventoryResults"
                    v-bind:items-per-page="50"
                    v-bind:loading="loading"
                    v-bind:options="{ sortBy: [ 'id' ], sortDesc: [ true ] }">
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      filteredInventoryResults() {
        let filteredInventoryResults = this.inventoryResults || []

        if (this.search != null && this.search.length !== 0) {
          filteredInventoryResults = filteredInventoryResults.filter(f => `${f.inventoryProductTitle.toLowerCase()} ${f.inventoryProductId}`.includes(this.search.toLowerCase()))
        }

        return filteredInventoryResults
      },
      headers() {
        return [
          {
            text: this.$t('receiptId'),
            value: 'inventoryReceiptId'
          },
          {
            text: this.$t('productId'),
            value: 'inventoryProductId'
          },
          {
            text: this.$t('product'),
            value: 'inventoryProductTitle'
          },
          {
            text: this.$t('quantity'),
            value: 'countQuantity'
          },
          {
            text: this.$t('stock'),
            value: 'stockQuantity'
          },
          {
            text: this.$t('unit'),
            value: 'unit'
          }
        ]
      },
      ...mapGetters({
      })
    },
    data: () => ({
      search: null
    }),
    name: 'InventoryResultsTableCard',
    methods: {

    },
    props: {
      inventoryResults: Array,
      loading: Boolean,
      itemRouteName: String,
      routeParams: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "result": "Ergebnis",
      "unit": "Einheit",
      "quantity": "Menge",
      "productId": "Artikelnr.",
      "product": "Artikel",
      "search": "Suche",
      "receiptId": "Belegnr.",
      "stock": "Soll GE"
    },
    "en": {
      "result": "Result",
      "unit": "Unit",
      "quantity": "Quantity",
      "productId": "Product id",
      "product": "Product",
      "search": "Search",
      "receiptId": "Receipt id",
      "stock": "Stock"
    }
  }
</i18n>