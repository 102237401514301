import moment from 'moment'
import Vue from 'vue'

import i18n from './plugins/i18n'

Vue.filter('defaultValue', (value, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue
  }

  return value
})

Vue.filter('formatCurrencyValue', (value, options, defaultValue) => {
  options = options ?? {}
  options.currency = options.currency ?? 'EUR'
  options.fractionDigits = options.fractionDigits ?? 2

  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  var number = Number(value)

  if (isNaN(number)) {
    return defaultValue ?? ''
  }

  return number.toLocaleString(i18n.locale, {
    currency: options.currency,
    minimumFractionDigits: options.fractionDigits,
    maximumFractionDigits: options.fractionDigits,
    style: 'currency'
  })
})

Vue.filter('formatPercentValue', (value, options, defaultValue) => {
  options = options ?? {}
  options.fractionDigits = options.fractionDigits ?? 2

  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  var number = Number(value)

  if (isNaN(number)) {
    return defaultValue ?? ''
  }

  return number.toLocaleString(i18n.locale, {
    minimumFractionDigits: options.fractionDigits,
    maximumFractionDigits: options.fractionDigits,
    style: 'percent'
  })
})

Vue.filter('formatNumber', (value, options, defaultValue) => {
  options = options ?? {}
  options.fractionDigits = options.fractionDigits ?? 2
  options.suffix = options.suffix ?? ''

  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  if (typeof value === 'string') {
    value = value.replace(',', '.');
  }

  var number = Number(value)

  if (isNaN(number)) {
    return defaultValue ?? ''
  }

  return `${number.toLocaleString(i18n.locale, {
    minimumFractionDigits: options.fractionDigits,
    maximumFractionDigits: options.fractionDigits,
    style: 'decimal'
  })} ${options.suffix}`
})

Vue.filter('formatDate', (value, format, defaultValue) => {
  if (value == null || value.length === 0) {
    return defaultValue ?? ''
  }

  return moment(value).locale(i18n.locale).format(format ?? 'LL')
})

Vue.filter('items', (values, options) => {
  options = options ?? {}
  options.text = options.text ?? 'text'
  options.value = options.value ?? 'value'

  if (values == null) {
    return []
  }

  return values.map(v => ({
    text: typeof options.text === 'function' ? options.text(v) : v[options.text],
    value: v[options.value]
  }))
})

Vue.filter('localizedText', localizedText => {
  if (localizedText == null) {
    return ''
  }

  if (localizedText[i18n.locale] != null) {
    return localizedText[i18n.locale]
  }

  if (localizedText['en'] != null) {
    return localizedText['en']
  }

  return localizedText[0]
})