<template>
  <div>
    <v-stepper class="elevation-0"
               v-model="step">
      <v-stepper-header class="elevation-0">
        <v-stepper-step step="1"
                        v-bind:complete="step > 1">
          {{ $t('details') }}
        </v-stepper-step>
        <v-stepper-step step="2"
                        v-bind:complete="step > 2">
          {{ $t('products') }}
        </v-stepper-step>
        <v-stepper-step step="3"
                        v-bind:complete="step > 3">
          {{ $t('signature') }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <receipt-details-step-card ref="receiptDetailsStepCard"
                                     v-bind:next-step-label="$t('continue')"
                                     v-bind:receipt="receipt"
                                     v-on:next-step="validateReceiptDetailsAndContinue" />
        </v-stepper-content>
        <v-stepper-content step="2">
          <receipt-products-step-card v-bind:next-step-label="$t('continue')"
                                      v-bind:previous-step-label="$t('back')"
                                      v-bind:receipt="receipt"
                                      v-on:next-step="validateReceiptProductsAndContinue"
                                      v-on:previous-step="step -= 1" />
        </v-stepper-content>
        <v-stepper-content step="3">
          <receipt-signature-step-card ref="receiptSignatureStepCard"
                                       v-bind:next-step-label="$t('complete')"
                                       v-bind:previous-step-label="$t('back')"
                                       v-bind:receipt="receipt"
                                       v-on:next-step="validateReceiptSignatureAndComplete"
                                       v-on:save-draft="saveDraft"
                                       v-on:previous-step="step -= 1" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
  import ReceiptDetailsStepCard from '@/components/free-product-accounting/ReceiptDetailsStepCard'
  import ReceiptProductsStepCard from '@/components/free-product-accounting/ReceiptProductsStepCard'
  import ReceiptSignatureStepCard from '@/components/free-product-accounting/ReceiptSignatureStepCard'

  export default {
    components: {
      ReceiptDetailsStepCard,
      ReceiptProductsStepCard,
      ReceiptSignatureStepCard
    },
    data: () => ({
      step: 1
    }),
    methods: {
      async saveDraft() {
        this.receipt.state = 'draft'

        await this.$emit('save-draft')
      },
      validateReceiptDetailsAndContinue() {
        if (!this.$refs.receiptDetailsStepCard.validate()) {
          return
        }

        this.step += 1
      },
      validateReceiptProductsAndContinue() {
        if (this.receipt.products.length < 1) {
          return
        }

        this.step += 1
      },
      async validateReceiptSignatureAndComplete() {
        if (!this.$refs.receiptSignatureStepCard.validate()) {
          return
        }

        this.receipt.signature = this.$refs.receiptSignatureStepCard.getSignature()

        await this.$emit('complete')
      }
    },
    name: 'NewReceiptStepper',
    props: {
      receipt: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "complete": "Abschließen",
      "continue": "Weiter",
      "details": "Details",
      "products": "Artikel",
      "signature": "Unterschrift"
    },
    "en": {
      "back": "Back",
      "complete": "Complete",
      "continue": "Continue",
      "details": "Details",
      "products": "Products",
      "signature": "Signature"
    }
  }
</i18n>