<template>
  <div>
    <new-product-entry-dialog allow-all-products
                              ref="newProductEntryDialog"
                              show-new
                              show-discount
                              show-unit
                              v-model="newProductEntryDialog" />

    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('campaign') }}</v-toolbar-title>
      </v-toolbar>
      <v-list class="py-0" two-line>
        <v-card-text v-if="campaign.description != null">
          {{ campaign.description }}
        </v-card-text>
        <v-subheader>{{ $t('details') }}</v-subheader>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">mdi-calendar-start</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{campaign.startDate | formatDate('LL') }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('start') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="campaign.reminderDate != null">
          <v-list-item-icon>
            <v-icon color="primary">mdi-bell-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ campaign.reminderDate | formatDate('LL') }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('reminder') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">mdi-calendar-end</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ campaign.endDate | formatDate('LL') }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('end') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
          <v-list-item-icon>
            <v-icon color="primary">mdi-tag</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ $t(campaignStateLabel(campaign.state)) }}</v-list-item-title>
            <v-list-item-subtitle>{{ $t('state') }}</v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="campaign.state === 'pending' || campaign.state === 'active' || campaign.state === 'upcoming'">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon>
                  <v-icon color="accent"
                          v-bind="attrs"
                          v-on="on">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item v-bind:disabled="campaign.products.length === 0"
                             v-if="campaign.state === 'pending'"
                             v-on:click="$emit('set-campaign-state', { id: campaign.id, state: 'upcoming' })">
                  <v-list-item-title>{{ $t('release') }}</v-list-item-title>
                </v-list-item>
                <v-list-item v-bind:disabled="campaign.products.length === 0"
                             v-if="campaign.state === 'active' || campaign.state === 'upcoming'"
                             v-on:click="$emit('set-campaign-state', { id: campaign.id, state: 'canceled' })">
                  <v-list-item-title>{{ $t('cancel') }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <v-divider />

      <v-subheader>{{ $t('attachements') }}</v-subheader>

      <v-card-text>
        <attachment-field accept="application/pdf"
                          v-bind:label="$t('documentation')"
                          v-bind:max-size="4096"
                          v-model="campaign.documentationId"
                          v-on:change="$emit('change', campaign)" />
      </v-card-text>

      <v-divider />

      <v-subheader>{{ $t('products') }}</v-subheader>

      <product-entry-table show-discount
                           show-unit
                           v-bind:product-entries="campaign.products"
                           v-bind:show-delete="campaign.state === 'pending'"
                           v-on:delete-product="$emit('delete-product', $event)" />

      <template v-if="campaign.state === 'pending'">
        <v-divider />

        <v-btn block
               color="accent"
               text
               tile
               v-on:click="openNewProductEntryDialog">
          {{ $t('addProduct') }}
        </v-btn>
      </template>
    </v-card>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import AttachmentField from '@/components/common/AttachmentField'
  import NewProductEntryDialog from '@/components/NewProductEntryDialog.vue'
  import ProductEntryTable from '@/components/ProductEntryTable.vue'

  export default {
    components: {
      AttachmentField,
      NewProductEntryDialog,
      ProductEntryTable
    },
    computed: {
      ...mapGetters({
        campaignStateLabel: 'saleManagement/campaignStateLabel'
      })
    },
    data: () => ({
      newProductEntryDialog: false
    }),
    methods: {
      /*deleteProduct(productId) {
        const index = this.campaign.products.findIndex(p => p.productId === productId)

        if (index !== -1) {
          this.campaign.products.splice(index, 1)
        }
      },*/
      openNewProductEntryDialog() {
        this.$refs.newProductEntryDialog.open(async productEntry => {
          if (productEntry == null) {
            return
          }

          if (productEntry.new) {
            const product = {
              id: productEntry.productId,
              title: productEntry.product,
              primaryUnit: productEntry.unit,
              baseUnit: productEntry.unit,
              manufacturerId: productEntry.manufacturerId,
              temporary: true
            }

            await this.createProduct({ product })
          }

          await this.deleteCampaignProduct({
            campaignId: this.campaign.id,
            productId: productEntry.productId
          })

          this.$emit('add-product', productEntry)
        }, {})
      },
      ...mapActions({
        createProduct: 'common/createProduct',
        deleteCampaignProduct: 'saleManagement/deleteCampaignProduct'
      })
    },
    name: 'CampaignCard',
    props: {
      campaign: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "addProduct": "Artikel hinzufügen",
      "attachements": "Anhänge",
      "campaign": "Aktion",
      "end": "Ende",
      "details": "Details",
      "documentation": "Informationsmaterial",
      "products": "Artikel",
      "release": "Freigeben",
      "reminder": "Erinnerung",
      "start": "Start",
      "state": "Status",
      "cancel": "Stornieren"
    },
    "en": {
      "addProduct": "Add product",
      "attachements": "Attachements",
      "campaign": "Campaign",
      "end": "End",
      "details": "Details",
      "documentation": "Documentation",
      "products": "Products",
      "release": "Release",
      "reminder": "Reminder",
      "start": "Start",
      "state": "State",
      "cancel": "Cancel"
    }
  }
</i18n>