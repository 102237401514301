<template>
  <div>
    <top-header>
      <h1 class="display-1 mx-4 pb-1 pt-2 white--text">{{ title | defaultValue($t('event')) }}</h1>
      <h2 class="text-h5 mx-4 pb-4 pt-1 white--text">{{ start | formatDate('L LT') }} - {{ end | formatDate('L LT') }}</h2>
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="backTo" />
    </top-header>

    <v-alert tile
             type="warning"
             v-if="eventDate != null && !eventDate.isBookable">
      <v-row align="center">
        <v-col class="grow">
          {{ $t('unfortunatelyThisDateIsNoLongerBookable') }}
        </v-col>
        <v-col class="shrink">
          <v-btn v-bind:to="backTo">{{ $t('showOtherDates') }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container v-if="event != null">
      <v-row>
        <v-col cols="12"
               lg="6">
          <v-card color="success"
                  dark
                  flat
                  tile
                  v-if="completed">
            <v-card-text>
              <p class="mb-0">
                {{ $t('thankYouYouHaveSuccessfullyRegisteredForTheSeminar') }}
              </p>
            </v-card-text>
          </v-card>
          <v-card color="error"
                  dark
                  flat
                  tile
                  v-if="error">
            <v-card-text>
              <p class="mb-0">
                {{ $t('unfortunatelySomethingWentWrongPleaseTryAgainAndCheckTheCodeForPrivateEvents') }}
              </p>
            </v-card-text>
          </v-card>
          <public-event-attendee-card ref="publicEventAttendeeCard"
                                      v-bind:attendee="attendee"
                                      v-bind:disabled="eventDate != null && !eventDate.isBookable"
                                      v-bind:private="event.private"
                                      v-bind:start="eventDate.start"
                                      v-bind:title="event.title"
                                      v-if="!completed && !error"
                                      v-on:register="registerAttendee"
                                      v-bind:external="event.external" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import PublicEventAttendeeCard from '@/components/booking/PublicEventAttendeeCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = parseInt(to.params.id)
      const eventDateId = parseInt(to.params.eventDateId)

      await this.get(id, eventDateId)

      next()
    },
    components: {
      PublicEventAttendeeCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      backTo() {
        if (this.event == null) {
          return null
        }

        return { name: 'booking-public-event', params: { category: this.event.category, subCategory: this.event.subCategory, eventId: this.event.id } }
      },
      end() {
        return this.eventDate?.end
      },
      start() {
        return this.eventDate?.start
      },
      title() {
        return this.event?.title
      },
      id() {
        return this.event?.id
      },
      ...mapGetters({
        getEvent: 'booking/event',
        getEventDate: 'booking/eventDate'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)
      const eventDateId = parseInt(this.$route.params.eventDateId)

      await this.get(id, eventDateId)
    },
    data: () => ({
      attendee: {},
      error: false,
      event: null,
      eventDate: null,
      completed: false,
      loading: false
    }),
    methods: {
      async get(id, eventDateId) {
        this.loading = true

        await this.loadDepartments()
        await this.loadEvent({ id })
        await this.loadEventDate({ id: eventDateId })
        await this.loadSitesExternal()

        this.loading = false

        this.event = Object.assign({}, this.getEvent(id))
        this.eventDate = Object.assign({}, this.getEventDate(eventDateId))
      },
      async registerAttendee() {
        if (!this.$refs.publicEventAttendeeCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.attendee.eventDateId = this.eventDate.id

        if (this.event.external) {
          this.attendee.tenantId = 1
          this.attendee.departmentId = 1
        }

        this.loading = true

        const attendee = await this.createAttendee({ attendee: this.attendee })

        this.loading = false

        if (attendee == null) {
          this.error = true

          return
        }

        this.completed = true
      },
      ...mapActions({
        createAttendee: 'booking/createAttendee',
        loadDepartments: 'common/loadDepartments',
        loadEvent: 'booking/loadEvent',
        loadEventDate: 'booking/loadEventDate',
        loadSitesExternal: 'common/loadSitesExternal'
      })
    },
    name: 'PublicEventBooking'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "event": "Seminar",
      "showOtherDates": "Andere Termine anzeigen",
      "thankYouYouHaveSuccessfullyRegisteredForTheSeminar": "Vielen Dank! Sie haben sich erfolgreich für das Seminar angemeldet.",
      "unfortunatelySomethingWentWrongPleaseTryAgainAndCheckTheCodeForPrivateEvents": "Leider ist etwas schiefgelaufen! Bitte versuchen Sie es erneut und prüfen Sie bei privaten Veranstaltungen den Code.",
      "unfortunatelyThisDateIsNoLongerBookable": "Leider ist dieser Termin nicht mehr buchbar."
    },
    "en": {
      "back": "Back",
      "event": "Event",
      "showOtherDates": "Show other dates",
      "thankYouYouHaveSuccessfullyRegisteredForTheSeminar": "Thank you! You have successfully registered for the seminar.",
      "unfortunatelySomethingWentWrongPleaseTryAgainAndCheckTheCodeForPrivateEvents": "Unfortunately, something went wrong! Please try again and check the code for private events.",
      "unfortunatelyThisDateIsNoLongerBookable": "Unfortunately, this date is no longer bookable."
    }
  }
</i18n>