export default class PriceTagPdfService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async create(productPriceTags, options) {
    const response = await fetch(`${this.baseUri}/price-tag/pdf`, {
      body: JSON.stringify(productPriceTags),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.blob()
  }
}