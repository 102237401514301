<template>
  <v-card flat
          tile>
    <v-subheader>{{ $t('signature') }}</v-subheader>
    <v-card-text>
      <signature-pad ref="signaturePad"
                     v-bind:label="$t('representative')" />
      <p class="error--text mb-0 mt-2 text-caption"
         v-if="hasErrors">
        {{ $t('pleaseSignWithinTheDesignatedArea') }}
      </p>
      <v-checkbox hide-details="auto"
                  v-bind:label="$t('noSignatureRequiredDiscussedOverThePhone')"
                  v-model="receipt.noSignatureRequired" />
      <v-checkbox class="mb-6"
                  hide-details="auto"
                  v-bind:label="$t('sendConfirmationEmail')"
                  v-model="receipt.sendConfirmationEmail" />
      <v-text-field filled
                    hide-details="auto"
                    v-bind:label="$t('email')"
                    v-if="receipt.sendConfirmationEmail"
                    v-model="receipt.confirmationEmail" />
    </v-card-text>
    <v-divider />
    <v-card-actions v-if="!!nextStepLabel || !!previousStepLabel">
      <v-btn text
             v-if="!!previousStepLabel"
             v-on:click="$emit('previous-step')">
        {{ previousStepLabel }}
      </v-btn>
      <v-spacer />
      <v-btn color="primary"
             text
             v-on:click="$emit('save-draft')">
        {{ $t('saveDraft') }}
      </v-btn>
      <v-btn color="accent"
             text
             v-if="!!nextStepLabel"
             v-on:click="$emit('next-step')">
        {{ nextStepLabel }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import SignaturePad from '@/components/common/SignaturePad'

  export default {
    components: {
      SignaturePad
    },
    data: () => ({
      hasErrors: false
    }),
    methods: {
      getSignature() {
        return this.$refs.signaturePad.getSignature()
      },
      validate() {
        if (this.receipt.noSignatureRequired) {
          return true
        }

        if (this.$refs.signaturePad.validate()) {
          return true
        } else {
          this.hasErrors = true

          return false
        }
      }
    },
    name: 'ReceiptSignatureStepCard',
    props: {
      nextStepLabel: String,
      previousStepLabel: String,
      receipt: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "email": "E-Mail-Adresse",
      "noSignatureRequiredDiscussedOverThePhone": "Keine Unterschrift erforderlich (telefonisch besprochen)",
      "pleaseSignWithinTheDesignatedArea": "Bitte unterschreiben Sie im dafür vorgesehenen Feld.",
      "representative": "Außendienstmitarbeiter:in",
      "saveDraft": "Entwurf speichern",
      "sendConfirmationEmail": "E-Mail-Bestätigung senden",
      "signature": "Unterschrift"
    },
    "en": {
      "email": "Email",
      "noSignatureRequiredDiscussedOverThePhone": "No signature required (discussed over the phone)",
      "pleaseSignWithinTheDesignatedArea": "Please sign within the designated area.",
      "representative": "Representative",
      "saveDraft": "Save draft",
      "sendConfirmationEmail": "Send confirmation email",
      "signature": "Signature"
    }
  }
</i18n>