<template>
  <div>
    <top-header v-bind:title="$t('campaigns')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('new')"
                     v-bind:to="{ name: 'sale-management-campaign-add' }"
                     v-if="hasRole('SagasserPortalAdministrator')" />
    </top-header>
    <v-container>
      <campaign-table-card v-bind:campaigns="campaigns"
                           v-on:select-campaign="selectCampaign" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import CampaignTableCard from '@/components/sale-management/CampaignTableCard.vue'

  export default {
    components: {
      CampaignTableCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      ...mapGetters({
        campaigns: 'saleManagement/campaigns',
        hasRole: 'auth/hasRole'
      })
    },
    async created() {
      await this.loadCampaigns()
    },
    methods: {
      selectCampaign(id) {
        this.$router.push({ name: 'sale-management-campaign', params: { id }})
      },
      ...mapActions({
        loadCampaigns: 'saleManagement/loadCampaigns'
      })
    },
    name: 'Campaigns'
  }
</script>

<i18n>
  {
    "de": {
      "campaigns": "Aktionen",
      "new": "Neu"
    },
    "en": {
      "campaigns": "Campaigns",
      "new": "New"
    }
  }
</i18n>