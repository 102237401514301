<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('givenName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="attendee.givenName" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('familyName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="attendee.familyName" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('email')"
                        v-bind:rules="[ rules.required ]"
                        v-model="attendee.email" />
        </v-col>
      </v-row>
      <v-row v-if="!external">
        <v-col>
          <v-autocomplete filled
                          hide-details="auto"
                          v-bind:items="tenants | items({ text: 'title', value: 'id' })"
                          v-bind:label="$t('tenant')"
                          v-bind:rules="[ rules.required ]"
                          v-model="attendee.tenantId" />
        </v-col>
      </v-row>
      <v-row  v-if="!external && siteSelection">
        <v-col>
          <v-autocomplete filled
                          hide-details="auto"
                          v-bind:items="filteredSites | items({ text: v => `${v.siteId} – ${v.siteName}`, value: 'siteId' })"
                          v-bind:label="$t('site')"
                          v-bind:rules="[ rules.required ]"
                          v-model="attendee.siteId" />
        </v-col>
      </v-row>
      <v-row v-if="!external">
        <v-col>
          <v-autocomplete filled
                          hide-details="auto"
                          v-bind:items="departments | items({ text: 'title', value: 'id' })"
                          v-bind:label="$t('department')"
                          v-bind:rules="[ rules.required ]"
                          v-model="attendee.departmentId" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      filteredSites() {
        if (!this.siteSelection) {
          return []
        }

        const tenantName = this.tenants.find(t => t.id === this.attendee.tenantId)?.title

        if (tenantName == null) {
          return []
        }

        return this.sites.filter(s => s.tenant === tenantName)
      },
      siteSelection() {
        if (this.attendee?.tenantId == null) {
          return false
        }

        return this.tenants.find(t => t.id === this.attendee.tenantId)?.siteSelection ?? false

      },
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      ...mapGetters({
        departments: 'common/departments',
        tenants: 'local/tenants',
        sites: 'common/externalSites'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'AttendeeDetails',
    props: {
      attendee: Object,
      external: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "site": "Markt",
      "department": "Abteilung",
      "givenName": "Vorname",
      "email": "E-Mail-Adresse",
      "familyName": "Nachname",
      "tenant": "Mandant"
    },
    "en": {
      "site": "Site",
      "department": "Department",
      "givenName": "Given name",
      "email": "Email",
      "familyName": "Family name",
      "tenant": "Tenant"
    }
  }
</i18n>