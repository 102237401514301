<template>
  <div>
    <top-header>
      <h1 class="display-1 mx-4 pb-1 pt-2 white--text">{{ $t('cancelRegistration') }}</h1>
      <h2 class="text-h5 mx-4 pb-4 pt-1 white--text">{{ title | defaultValue($t('event')) }}: {{ start | formatDate('L LT') }} - {{ end | formatDate('L LT') }}</h2>
    </top-header>
    <v-container>
      <v-row>
        <v-col cols="12"
               lg="6">
          <v-card flat
                  tile
                  v-if="!error && !completed">
            <v-card-title class="headline primary--text">
              {{ $t('cancelRegistration') }}
            </v-card-title>
            <v-card-text>
              {{ $t('areYouSureYouWantToCancelYourRegistrationTheCancellationCanNotBeReversed') }}
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn text
                     color="accent"
                     v-on:click="commitCancellation">
                {{ $t('confirmCancellation') }}
              </v-btn>
            </v-card-actions>
          </v-card>
          <v-card color="error"
                  dark
                  flat
                  tile
                  v-if="error">
            <v-card-text>
              <p class="mb-0">
                {{ $t('unfortunatelySomethingWentWrongPleaseTryAgainMaybeYouHaveAlreadyUnsubscribedFromTheEvent') }}
              </p>
            </v-card-text>
          </v-card>
          <v-card color="success"
                  dark
                  flat
                  tile
                  v-if="completed">
            <v-card-text>
              <p class="mb-0">
                {{ $t('youHaveSuccessfullyUnsubscribedFromTheEvent') }}
              </p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
     async beforeRouteUpdate(to, from, next) {
      const id = parseInt(to.params.id)
      const eventDateId = parseInt(to.params.eventDateId)
      this.attendeeId = parseInt(to.params.attendeeId)
      this.code = to.params.code

      await this.get(id, eventDateId)

      next()
    },
    components: {
      TopHeader
    },
    computed: {
      end() {
        return this.eventDate?.end
      },
      start() {
        return this.eventDate?.start
      },
      title() {
        return this.event?.title
      },
      ...mapGetters({
        getEvent: 'booking/event',
        getEventDate: 'booking/eventDate'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)
      const eventDateId = parseInt(this.$route.params.eventDateId)
      this.attendeeId = parseInt(this.$route.params.attendeeId)
      this.code =this.$route.params.code

      await this.get(id, eventDateId)
    },
    data: () => ({
      attendeeId: null,
      code: null,
      completed: false,
      error: false,
      event: null,
      eventDate: null,
      loading: false
    }),
    methods: {
      async get(id, eventDateId) {
        this.loading = true

        await this.loadEvent({ id })
        await this.loadEventDate({ id: eventDateId })

        this.event = Object.assign({}, this.getEvent(id))
        this.eventDate = Object.assign({}, this.getEventDate(eventDateId))

        this.loading = false
      },
      async commitCancellation() {
        this.loading = true

        const ok = await this.cancelAttendee({ id: this.attendeeId, code: this.code })

        this.loading = false

        if (ok) {
          this.completed = true
        } else {
          this.error = true
        }
      },
      ...mapActions({
        cancelAttendee: 'booking/cancelAttendee',
        loadEvent: 'booking/loadEvent',
        loadEventDate: 'booking/loadEventDate'
      })
    },
    name: 'PublicCancelEvent'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToCancelYourRegistrationTheCancellationCanNotBeReversed": "Sind sie sicher, dass Sie sich von dieser Veranstaltung abmelden möchten? Die Stornierung kann nicht rückgängig gemacht werden!",
      "cancelRegistration": "Anmeldung stornieren",
      "confirmCancellation": "Stornierung bestätigen",
      "event": "Seminar",
      "unfortunatelySomethingWentWrongPleaseTryAgainMaybeYouHaveAlreadyUnsubscribedFromTheEvent": "Leider ist etwas schiefgelaufen! Bitte versuchen Sie es erneut. Möglicherweise haben Sie sich bereits vom Seminar abgemeldet?",
      "youHaveSuccessfullyUnsubscribedFromTheEvent": "Sie haben sich erfolgreich vom Seminar abgemeldet."
    },
    "en": {
      "areYouSureYouWantToCancelYourRegistrationTheCancellationCanNotBeReversed": "Are you sure you want to cancel your registration? The cancellation can not be reversed!",
      "cancelRegistration": "Cancel registration",
      "confirmCancellation": "Confirm cancellation",
      "event": "Event",
      "unfortunatelySomethingWentWrongPleaseTryAgainMaybeYouHaveAlreadyUnsubscribedFromTheEvent": "Unfortunately, something went wrong! Please try again. Maybe you have already unsubscribed from the event?",
      "youHaveSuccessfullyUnsubscribedFromTheEvent": "You have successfully unsubscribed from the event."
    }
  }
</i18n>