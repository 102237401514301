<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('trainees') }}</v-toolbar-title>
      </v-toolbar>
      <v-autocomplete flat
                      hide-details="auto"
                      prepend-inner-icon="mdi-plus"
                      solo
                      v-bind:items="traineeItems"
                      v-bind:label="$t('addTrainee')"
                      v-on:change="addExamTrainee" />
      <v-divider />
      <v-autocomplete flat
                      hide-details="auto"
                      prepend-inner-icon="mdi-plus"
                      solo
                      v-bind:items="departments | items({ text: 'title', value: 'id' })"
                      v-bind:label="$t('addDepartment')"
                      v-on:change="addDepartment" />
      <v-divider />
      <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                       v-bind:items="examTrainees"
                       v-bind:items-per-page="10">
        <template v-slot:default="props">
          <v-list>
            <v-list-item two-line
                         v-bind:key="index"
                         v-for="(examTrainee, index) in props.items">
              <v-list-item-content>
                <v-list-item-title>
                  {{ examTrainee.trainee.givenName }} {{ examTrainee.trainee.familyName }}
                  <v-chip class="ml-2 white--text"
                          label
                          small
                          v-bind:color="getColor(examTrainee)">
                    {{ examTrainee.correctQuestions != null ? examTrainee.correctQuestions : '-' }}
                  </v-chip>
                  <v-icon class="ml-2"
                          color="deep-orange"
                          v-if="examTrainee.allowGracePeriod">
                    mdi-clock-check
                  </v-icon>
                </v-list-item-title>
                <v-list-item-subtitle>{{ examTrainee.trainee.number }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="(examTrainee.correctQuestions == null || (!examTrainee.hasPassed && !examTrainee.usedSecondAttempt)) && !examTrainee.allowGracePeriod">
                <v-btn icon
                       v-on:click="allowGracePeriodForExamTrainee(examTrainee)">
                  <v-icon color="secondary">
                    mdi-clock-check
                  </v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <delete-confirmation-button v-bind:item="examTrainee"
                                            v-on:delete="deleteExamTrainee($event.traineeId)" />
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider />
        </template>
        <template v-slot:no-data>
        <v-card-text class="text-center text--disabled">
          {{ $t('noTraineesAvailable') }}
        </v-card-text>
        <v-divider />
      </template>
      </v-data-iterator>
      <v-card-text>
        <p class="mb-0">
          <v-icon color="light-green">
            mdi-circle
          </v-icon>
          {{ $t('passed') }}
          <v-icon class="ml-4"
                  color="deep-orange">
            mdi-circle
          </v-icon>
          {{ $t('failed') }}
          <v-icon class="ml-4"
                  color="black">
            mdi-circle
          </v-icon>
          {{ $t('notParticipatedOrSecondAttemptNotUsed') }}
          <v-icon class="ml-4"
                  color="deep-orange">
            mdi-clock-check
          </v-icon>
          {{ $t('gracePeriodAllowed') }}
        </p>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DeleteConfirmationButton from '@/components/common/DeleteConfirmationButton.vue'

  export default {
    components: {
      DeleteConfirmationButton
    },
    computed: {
      traineeItems() {
        return this.trainees.filter(t => !this.examTrainees.some(e => t.id === e.traineeId))
          .map(t => ({
            text: `${t.givenName} ${t.familyName}`,
            value: t
          }))
      },
      ...mapGetters({
        departments: 'common/departments',
        trainees: 'learning/trainees'
      })
    },
    name: 'ExamTraineeListCard',
    methods: {
      addDepartment(departmentId) {
        this.$emit('add-department', departmentId)
      },
      addExamTrainee(trainee) {
        this.$emit('add-exam-trainee', trainee)
      },
      allowGracePeriodForExamTrainee(examTrainee) {
        this.$emit('allow-grace-period-for-exam-trainee', examTrainee)
      },
      deleteExamTrainee(traineeId) {
        this.$emit('delete-exam-trainee', traineeId)
      },
      getColor(examTrainee) {
        if (examTrainee.correctQuestions == null) {
          return 'black'
        } else if (examTrainee.hasPassed) {
          return 'light-green'
        } else if (!examTrainee.usedSecondAttempt) {
          return 'black'
        } else {
          return 'deep-orange'
        }
      },
      validate() {
        return true
      }
    },
    props: {
      examTrainees: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "addDepartment": "Abteilung hinzufügen",
      "addTrainee": "Trainee hinzufügen",
      "failed": "Durchgefallen",
      "gracePeriodAllowed": "Aufschub gestattet",
      "notParticipatedOrSecondAttemptNotUsed": "Nicht teilgenommen oder Zweitversuch ungenutzt",
      "noTraineesAvailable": "Keine Trainees vorhanden",
      "passed": "Bestanden",
      "trainees": "Trainees"
    },
    "en": {
      "addDepartment": "Add department",
      "addTrainee": "Add trainee",
      "failed": "Failed",
      "gracePeriodAllowed": "Grace period allowed",
      "notParticipatedOrSecondAttemptNotUsed": "Not participated or second attempt not used",
      "noTraineesAvailable": "No trainees available",
      "passed": "Passed",
      "trainees": "Trainees"
    }
  }
</i18n>