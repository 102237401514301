import { render, staticRenderFns } from "./CampaignTableCard.vue?vue&type=template&id=103f835e"
import script from "./CampaignTableCard.vue?vue&type=script&lang=js"
export * from "./CampaignTableCard.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./CampaignTableCard.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports