<template>
  <div>
    <v-card-text>
      <p class="text-h6">{{ question.text | localizedText }}</p>
      <v-checkbox hide-details
                  multiple
                  v-bind:key="answer.id"
                  v-bind:label="answer.text | localizedText"
                  v-bind:value="answer.id"
                  v-on:change="error = false"
                  v-model="model[question.id]"
                  v-for="answer in question.answers" />

      <v-expand-transition>
        <p v-if="error"
           class="error--text mb-0 mt-4">
          {{ $t('global.pleaseAnswerThisQuestion') }}
        </p>
      </v-expand-transition>
    </v-card-text>
  </div>
</template>

<script>
  export default {
    data: () => ({
      error: false
    }),
    methods: {
      validate() {
        if (this.model[this.question.id] == null || this.model[this.question.id].length == 0) {
          this.error = true

          return false
        }

        return true
      }
    },
    name: 'SelectQuestion',
    props: {
      model: Object,
      question: Object
    }
  }
</script>