<template>
  <div>
    <p class="mb-1 text-caption">{{ label }}</p>
    <div class="editor" ref="editor">
    </div>
  </div>
</template>

<script>
  import Quill from 'quill'

  export default {
    mounted() {
      let editor = new Quill(this.$refs.editor, {
        modules: {
          toolbar: [ [ 'bold', 'italic', 'underline'],  [{ 'list': 'ordered'}, { 'list': 'bullet' }] ]
        },
        theme: 'snow'
      })

      const delta = editor.clipboard.convert(this.value)

      editor.setContents(delta, 'silent')

      editor.on('text-change', () => {
        console.log(editor.root.innerHTML)
        this.$emit('input', editor.root.innerHTML)
      })
    },
    name: 'RichTextField',
    props: {
      label: String,
      value: String
    }
  }
</script>

<style scoped>
  .editor {
    height: 375px;
  }
</style>

<style src="quill/dist/quill.snow.css"></style>