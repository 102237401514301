<template>
  <div class="no-print pt-2" v-bind:class="color">
    <v-container v-bind:class="!!this.$slots['tabs'] ? 'pb-0' : null">
      <v-row align="center">
        <v-col cols="auto"
               v-if="!!this.$slots['left-button']">
          <slot name="left-button" />
        </v-col cols="auto">
        <v-col class="white--text font-weight-bold" v-if="!$vuetify.breakpoint.lgAndUp">
          {{ title }}
        </v-col>
        <v-spacer />
        <v-col cols="auto"
               v-if="!!this.$slots['right-button']">
          <slot name="right-button" />
        </v-col>
        <v-col cols="auto"
               v-if="!!this.$slots['menu']">
          <slot name="menu" />
        </v-col>
      </v-row>
      <v-row v-if="$vuetify.breakpoint.lgAndUp">
        <v-col>
          <slot>
            <h1 class="mx-4 pb-4 pt-2 white--text display-1">{{ title }}</h1>
          </slot>
        </v-col>
        <v-col cols="auto"
               v-if="!!this.$slots['right']">
          <slot name="right" />
        </v-col>
      </v-row>
      <slot name="extra" />
      <slot name="tabs" />
    </v-container>
  </div>
</template>

<script>
  export default {
    name: 'TopHeader',
    props: {
      title: String,
      color: {
        type: String,
        default: 'primary'
      }
    }
  }
</script>