<template>
  <div v-if="attendee != null">
    <v-dialog persistent
              v-bind:value="value"
              v-on:input="$emit('input', $event)"
              width="1000">
      <v-card>
        <v-toolbar color="secondary"
                   dark
                   flat>
          <v-toolbar-title>{{ $t('attendee') }}</v-toolbar-title>
        </v-toolbar>
        <v-alert tile
                 type="warning"
                 v-if="attendee.hasCancelled">
          {{ $t('theAttendeeHasCancelledOn', { date: $options.filters.formatDate(attendee.cancelledDate, 'L LT') }) }}
        </v-alert>
        <attendee-details v-bind:attendee="attendee" v-bind:external="external" />
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="secondary"
                 text
                 v-on:click="cancel">
            {{ $t('cancel') }}
          </v-btn>
          <v-btn color="accent"
                 text
                 v-on:click="confirm">
            {{ $t('save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import AttendeeDetails from '@/components/booking/AttendeeDetails'

  export default {
    components: {
      AttendeeDetails
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    data: () => ({
      resolve: null
    }),
    methods: {
      cancel() {
        this.$emit('input', false)
        this.resolve(false)
      },
      confirm() {
        /*if (!this.$refs.form.validate()) {
          return
        }*/

        this.$emit('input', false)
        this.$emit('confirm')
        this.resolve(this.attendee)
      },
      async open(resolve) {
        this.resolve = resolve
        this.$emit('input', true)
      }
    },
    name: 'AttendeeDialog',
    props: {
      attendee: Object,
      value: Boolean,
      external: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "attendee": "Teilnehmer",
      "cancel": "Abbrechen",
      "save": "Speichern",
      "theAttendeeHasCancelledOn": "Der Teilnehmer hat am {date} abgesagt."
    },
    "en": {
      "attendee": "Attendee",
      "cancel": "Cancel",
      "save": "Save",
      "theAttendeeHasCancelledOn": "The attendee has cancelled on {date}."
    }
  }
</i18n>