<template>
  <div>
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field clearable
                        filled
                        hide-details
                        prepend-inner-icon="mdi-magnify"
                        v-bind:label="$t('search')"
                        v-model="search" />
        </v-col>
        <v-col>
          <v-autocomplete clearable
                          filled
                          hide-details
                          multiple
                          prepend-inner-icon="mdi-tag"
                          v-bind:items="groupItems"
                          v-bind:label="$t('group')"
                          v-model="groupFilter" />
        </v-col>
        <v-col>
          <v-autocomplete clearable
                          filled
                          hide-details
                          multiple
                          prepend-inner-icon="mdi-map-marker"
                          v-bind:items="regionItems"
                          v-bind:label="$t('region')"
                          v-model="regionFilter" />
        </v-col>
        <v-col>
          <v-autocomplete clearable
                          filled
                          hide-details
                          multiple
                          prepend-inner-icon="mdi-domain"
                          v-bind:items="tenantItems"
                          v-bind:label="$t('tenant')"
                          v-model="tenantFilter" />
        </v-col>
      </v-row>
    </v-card-text>

    <v-data-table v-bind:items-per-page="20"
                  show-select
                  v-bind:headers="headers"
                  v-bind:items="filteredSites"
                  v-bind:value="value"
                  v-on:input="$emit('input', $event)">
    </v-data-table>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      filteredSites() {
        let filteredSites = this.sites

        if (this.search != null && this.search.length !== 0) {
          filteredSites = filteredSites.filter(f => f.title.toLowerCase().includes(this.search.toLowerCase()))
        }

        if (this.groupFilter != null && this.groupFilter.length !== 0) {
          filteredSites = filteredSites.filter(f => this.groupFilter.includes(f.group))
        }

        if (this.regionFilter != null && this.regionFilter.length !== 0) {
          filteredSites = filteredSites.filter(f => this.regionFilter.includes(f.region))
        }

        if (this.tenantFilter != null && this.tenantFilter.length !== 0) {
          filteredSites = filteredSites.filter(f => this.tenantFilter.includes(f.tenant))
        }

        return filteredSites
      },
      groupItems() {
        return [...new Set(this.sites.map(s => ({
          text: s.group,
          value: s.group
        })))].sort((a, b) => a.text.localeCompare(b.text))
      },
      headers() {
        return [
          {
            text: this.$t('title'),
            value: 'title'
          },
          {
            text: this.$t('group'),
            value: 'group'
          },
          {
            text: this.$t('region'),
            value: 'region'
          },
          ,
          {
            text: this.$t('tenant'),
            value: 'tenant'
          }
        ]
      },
      regionItems() {
        return [...new Set(this.sites.map(s => ({
          text: s.region,
          value: s.region
        })))].sort((a, b) => a.text.localeCompare(b.text))
      },
      tenantItems() {
        return [...new Set(this.sites.map(s => ({
          text: s.tenant,
          value: s.tenant
        })))].sort((a, b) => a.text.localeCompare(b.text))
      },
      ...mapGetters({
        sites: 'common/sites'
      })
    },
    data: () => ({
      groupFilter: null,
      regionFilter: null,
      search: null,
      tenantFilter: null
    }),
    methods: {

    },
    name: 'SiteSelectionTable',
    props: {
      value: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "group": "Gruppe",
      "region": "Region",
      "search": "Suche",
      "tenant": "Mandant",
      "title": "Bezeichnung"
    },
    "en": {
      "group": "Group",
      "search": "Search",
      "region": "Region",
      "tenant": "Tenant",
      "title": "Title"
    }
  }
</i18n>