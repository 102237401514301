<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <inventory-details ref="inventoryDetails"
                         v-bind:inventory="inventory" />
    </v-card>
  </div>
</template>

<script>
  import InventoryDetails from '@/components/inventory-management/InventoryDetails'

  export default {
    components: {
      InventoryDetails
    },
    name: 'InventoryDetailsCard',
    methods: {
      validate() {
        return this.$refs.inventoryDetails.validate()
      }
    },
    props: {
      inventory: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details"
    },
    "en": {
      "details": "Details"
    }
  }
</i18n>