<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('questions') }}</v-toolbar-title>
      </v-toolbar>
      <v-row no-gutters>
        <v-col>
          <multilingual-text-field flat
                                   ref="newQuestionTextField"
                                   solo
                                   v-bind:label="$t('addQuestion')"
                                   v-model="newQuestionText" />
        </v-col>
        <v-col cols="auto">
          <v-btn block
                 class="v-btn--search"
                 color="accent"
                 dark
                 depressed
                 large
                 tile
                 v-on:click="addQuestion">
            <v-icon>
              mdi-plus
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider />
      <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                       v-bind:items="questions"
                       v-bind:items-per-page="10">
        <template v-slot:default="props">
          <v-list>
            <v-list-item two-line
                         v-bind:key="question.id"
                         v-for="question in props.items">
              <v-list-item-content>
                <v-list-item-title>{{ question.text | localizedText }}</v-list-item-title>
                <v-list-item-subtitle>{{ $t('answers', { count: question.answers.length }) }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn color="secondary"
                      icon>
                  <v-icon v-on:click="openQuestion(question.id)">
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <delete-confirmation-button v-bind:item="question"
                                            v-on:delete="deleteQuestion($event.id)" />
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider />
        </template>
        <template v-slot:no-data>
        <v-card-text class="text-center text--disabled">
          {{ $t('noQuestionsAvailable') }}
        </v-card-text>
        <v-divider />
      </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DeleteConfirmationButton from '@/components/common/DeleteConfirmationButton.vue'
  import MultilingualTextField from '@/components/learning/MultilingualTextField.vue'

  export default {
    components: {
      DeleteConfirmationButton,
      MultilingualTextField
    },
    computed: {
      ...mapGetters({

      })
    },
    data: () => ({
      newQuestionText: null
    }),
    name: 'QuestionListCard',
    methods: {
      addQuestion() {
        if (!this.$refs.newQuestionTextField.validate()) {
          return
        }

        this.$emit('add-question', this.newQuestionText)

        this.newQuestionText = null
      },
      deleteQuestion(questionId) {
        this.$emit('delete-question', questionId)
      },
      openQuestion(questionId) {
        this.$emit('open-question', questionId)
      },
      validate() {
        return true
      }
    },
    props: {
      questions: Array
    }
  }
</script>

<style>
  .v-btn.v-btn--search {
    min-height: 48px;
  }
</style>

<i18n>
  {
    "de": {
      "addQuestion": "Frage hinzufügen",
      "answers": "{count} Antworten",
      "noQuestionsAvailable": "Keine Fragen vorhanden",
      "questions": "Fragen"
    },
    "en": {
      "addQuestion": "Add question",
      "answers": "{count} Antworten",
      "noQuestionsAvailable": "No questions available",
      "questions": "Questions"
    }
  }
</i18n>