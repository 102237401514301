<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        type="number"
                        v-bind:label="$t('position')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventoryPosition.positionNumber" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-autocomplete filled
                          hide-details="auto"
                          v-bind:items="inventoryProducts | items({ text: i => `${i.title} (${i.id})`, value: 'id' })"
                          v-bind:label="$t('product')"
                          v-bind:rules="[ rules.required ]"
                          v-model="inventoryPosition.inventoryProductId" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-combobox filled
                    hide-details="auto"
                    v-bind:items="units | items({ text: 'name', value: 'id' })"
                    v-bind:label="$t('unit')"
                    v-bind:rules="[ rules.required ]"
                    v-model="inventoryPosition.unit" />
        </v-col>
      </v-row>

      <!--<v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        type="number"
                        v-bind:label="$t('countQuantity')"
                        v-bind:rules="[ rules.required ]"
                        v-model.number="inventoryPosition.countQuantity" />
        </v-col>
      </v-row>-->
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row v-if="inventoryPosition.inventoryProductId != null">
        <v-col>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('empty') }}</span>: {{ inventoryProduct.inventoryEmpty.title }}</p>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('number') }}</span>: {{ inventoryProduct.inventoryEmpty.number }}</p>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('baseUnit') }}</span>: {{ inventoryProduct.inventoryEmpty.baseUnit }}</p>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('depositValue') }}</span>: {{ inventoryProduct.inventoryEmpty.depositValue | formatCurrencyValue }}</p>
        </v-col>
      </v-row>
    </v-card-text>
    <!--<v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('countedBy')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventoryPosition.countedBy" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <date-time-field disabled
                           filled
                           hide-details="auto"
                           v-bind:label="$t('createdAt')"
                           v-model="inventoryPosition.createdAt" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('createdBy')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventoryPosition.createdBy" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <date-time-field disabled
                           filled
                           hide-details="auto"
                           v-bind:label="$t('modifiedAt')"
                           v-model="inventoryPosition.modifiedAt" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('modifiedBy')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventoryPosition.modifiedBy" />
        </v-col>
      </v-row>
      
    </v-card-text>-->
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'
  import DateTimeField from '@/components/common/DateTimeField'

  export default {
    components: {
      DateTimeField
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      inventoryProduct() {
        return this.inventoryProducts.find(i => i.id === this.inventoryPosition.inventoryProductId)
      },
      ...mapGetters({
        units: 'inventoryManagement/units',
        inventoryProducts: 'inventoryManagement/inventoryProducts'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'InventoryPositionDetails',
    props: {
      inventoryPosition: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "position": "Position",
      "product": "Artikel",
      "unit": "Einheit",
      "countQuantity": "Zählmenge",
      "createdAt": "Erstellt am",
      "createdBy": "Erstellt von",
      "modifiedAt": "Geändert am",
      "modifiedBy": "Geändert von",
      "countedBy": "Gezählt von",
      "empty": "Leergutartikel",
      "depositValue": "Pfandwert",
      "baseUnit": "Grundeinheit",
      "number": "Nummer"
    },
    "en": {
      "position": "Position",
      "product": "Product",
      "unit": "Unit",
      "countQuantity": "Count quantity",
      "createdAt": "Created at",
      "createdBy": "Created by",
      "modifiedAt": "Modified at",
      "modifiedBy": "Modified by",
      "countedBy": "Counted by",
      "empty": "Empty product",
      "depositValue": "Deposit value",
      "baseUnit": "Base unit",
      "number": "Number"
    }
  }
</i18n>