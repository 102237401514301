import Administration from '../views/administration/Administration.vue'
import PrimaryNavigation from '../views/navigation/PrimaryNavigation.vue'

export default [
  {
    components: {
      default: Administration,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'administration',
    path: '/administration'
  }
]