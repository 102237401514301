import Event from '../views/booking/Event.vue'
import EventAdd from '../views/booking/EventAdd.vue'
import Events from '../views/booking/Events.vue'

import EventDate from '../views/booking/EventDate.vue'
import EventDateAdd from '../views/booking/EventDateAdd.vue'

import PrimaryNavigation from '../views/navigation/PrimaryNavigation.vue'

import PublicCancelEvent from '../views/booking/PublicCancelEvent.vue'
import PublicEventBooking from '../views/booking/PublicEventBooking.vue'
import PublicEventCategories from '../views/booking/PublicEventCategories.vue'
import PublicEvents from '../views/booking/PublicEvents.vue'
import PublicLearningNavigation from '../views/booking/navigation/PublicLearningNavigation.vue'

export default [
  {
    components: {
      default: EventAdd,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalBookingUser'))
    },
    name: 'booking-event-add',
    path: '/booking/events/add'
  },
  {
    components: {
      default: Event,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalBookingUser'))
    },
    name: 'booking-event',
    path: '/booking/events/:id'
  },
  {
    components: {
      default: Events,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalBookingUser'))
    },
    name: 'booking-events',
    path: '/booking/events'
  },

  {
    components: {
      default: EventDateAdd,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalBookingUser'))
    },
    name: 'booking-event-date-add',
    path: '/booking/events/:eventId/dates/add'
  },
  {
    components: {
      default: EventDate,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalBookingUser'))
    },
    name: 'booking-event-date',
    path: '/booking/events/:eventId/dates/:id'
  },
  {
    components: {
      default: PublicEventCategories,
      navigation: PublicLearningNavigation
    },
    meta: {
    },
    name: 'booking-public',
    path: '/booking/public'
  },
  {
    components: {
      default: PublicEventBooking,
      navigation: PublicLearningNavigation
    },
    meta: {
    },
    name: 'booking-public-event-booking',
    path: '/booking/public/events/:id/:eventDateId'
  },
  {
    components: {
      default: PublicCancelEvent,
      navigation: PublicLearningNavigation,
    },
    meta: {
    },
    name: 'booking-public-event-cancel',
    path: '/booking/public/events/:id/:eventDateId/cancel/:attendeeId/:code'
  },
  {
    components: {
      default: PublicEvents,
      navigation: PublicLearningNavigation
    },
    meta: {
    },
    name: 'booking-public-event',
    path: '/booking/public/categories/:category/:subCategory/:eventId?'
  }
]