<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <v-toolbar-title>{{ $t('sites') }}</v-toolbar-title>
      <v-spacer />
        <v-btn exact
               icon
               v-on:click="$emit('add-site')">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      <template v-slot:extension>
        <v-text-field flat
                      hide-details="auto"
                      prepend-icon="mdi-magnify"
                      solo
                      v-bind:label="$t('search')"
                      v-model="search" />
      </template>
    </v-toolbar>
    <v-data-table v-bind:headers="headers"
                  v-bind:items="sites"
                  v-bind:search="search">
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn icon>
          <v-icon color="secondary"
                  v-on:click="$emit('select-site', item )">
            mdi-clipboard-list
          </v-icon>
        </v-btn>
        <delete-confirmation-button v-if="item.orders === 0"
                                    v-bind:item="item"
                                    v-on:delete="$emit('delete-site', $event.id)" />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import DeleteConfirmationButton from '@/components/common/DeleteConfirmationButton.vue'

  export default {
    components: {
      DeleteConfirmationButton
    },
    computed: {
      headers() {
        return [
          {
            text: this.$t('siteId'),
            value: 'id'
          },
          {
            text: this.$t('siteName'),
            value: 'title'
          },
          {
            text: this.$t('customerGroup'),
            value: 'group'
          },
          {
            text: this.$t('orders'),
            value: 'orders'
          },
          {
            text: '',
            value: 'actions'
          }
        ]
      }
    },
    data: () => ({
      search: null
    }),
    name: 'CampaignSiteOrdersCard',
    props: {
      sites: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "customerGroup": "Kundengruppe",
      "orders": "Bestellungen",
      "search": "Suchen…",
      "siteId": "Marktnummer",
      "siteName": "Markt",
      "sites": "Märkte"
    },
    "en": {
      "customerGroup": "Customer group",
      "orders": "Orders",
      "search": "Search…",
      "siteId": "Site id",
      "siteName": "Site name",
      "sites": "Sites"
    }
  }
</i18n>