<template>
  <v-menu left
          nudge-left="52">
    <template v-slot:activator="{ on, value }">
      <v-btn icon
              v-bind:disabled="value"
              v-on="on">
        <v-icon color="secondary">
          mdi-close-circle
        </v-icon>
      </v-btn>
    </template>
    <v-card flat>
      <v-btn block
             color="deep-orange"
             dark
             depressed
             tile
             v-on:click="$emit('delete', item)">
        {{ $t('delete') }}
      </v-btn>
    </v-card>
  </v-menu>
</template>

<script>
  export default {
    name: 'DeleteConfirmationButton',
    props: {
      item: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "delete": "Löschen"
    },
    "en": {
      "delete": "Delete"
    }
  }
</i18n>