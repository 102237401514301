export default class InventoryResultService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }


  async getAllForReceipt(receiptId, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryResult/receipt/${receiptId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }
}