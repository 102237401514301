<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('positions') }}</v-toolbar-title>
        <v-spacer />
        <v-btn exact
               icon
               v-bind:to="{ name: this.addRouteName, params: routeParams }">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-toolbar>
      <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                       v-bind:items="inventoryPositions"
                       v-bind:items-per-page="30">
        <template v-slot:default="props">
          <v-list>
            <v-list-item two-line
                         v-bind:key="inventoryPosition.id"
                         v-for="inventoryPosition in props.items"
                         v-on:click="goToItem(inventoryPosition)">
              <v-list-item-avatar>
                <v-icon class="primary lighten-1"
                        dark>
                  mdi-receipt
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <strong>{{ typeLabel(inventoryPosition.type) }}</strong> {{ inventoryPosition.inventoryProductId }} {{ inventoryPosition.inventoryEmptyId }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ inventoryPosition.unit }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider />
        </template>
        <template v-slot:no-data>
        <v-card-text class="text-center text--disabled">
          {{ $t('noPositionsAvailable') }}
        </v-card-text>
        <v-divider />
      </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
  export default {
    name: 'InventoryPositionListCard',
    methods: {
      goToItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.positionNumber, ...this.routeParams }})
      },
      typeLabel(type) {
        if (type === 'product') {
          return 'VG'
        } else if (type === 'empty') {
          return 'LG'
        } else {
          return '-'
        }
      }
    },
    props: {
      addRouteName: String,
      inventoryPositions: Array,
      itemRouteName: String,
      routeParams: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "positions": "Positionen",
      "noPositionsAvailable": "Keine Positionen vorhanden"
    },
    "en": {
      "positions": "Positions",
      "noPositionsAvailable": "No Positions available"
    }
  }
</i18n>