<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <subject-details ref="subjectDetails"
                       v-bind:subject="subject" />
    </v-card>
  </div>
</template>

<script>
  import SubjectDetails from '@/components/learning/SubjectDetails'

  export default {
    components: {
      SubjectDetails
    },
    name: 'SubjectDetailsCard',
    methods: {
      validate() {
        return this.$refs.subjectDetails.validate()
      }
    },
    props: {
      subject: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details"
    },
    "en": {
      "details": "Details"
    }
  }
</i18n>