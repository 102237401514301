import PriceTag from '../views/price-tag/PriceTag.vue'
import PriceTagPrint from '../views/price-tag/PriceTagPrint.vue'
import PrimaryNavigation from '../views/navigation/PrimaryNavigation.vue'

export default [
  {
    components: {
      default: PriceTag,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalSiteEvaluation') || hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalPriceTag'))
    },
    name: 'price-tag',
    path: '/price-tag'
  },
  {
    components: {
      default: PriceTagPrint,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalSiteEvaluation') || hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalPriceTag'))
    },
    name: 'price-tag-print',
    path: '/price-tag/print'
  }
]