<template>
  <div>
    <top-header v-bind:title="$t('departments')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-bind:to="{ name: 'department-add' }" />
    </top-header>
    <v-container>
      <department-table-card item-route-name="department"
                             v-bind:loading="loading"
                             v-bind:departments="departments" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import DepartmentTableCard from '@/components/DepartmentTableCard.vue'

  export default {
    components: {
      DepartmentTableCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      ...mapGetters({
        departments: 'common/departments'
      })
    },
    async created() {
      this.loading = true

      await this.loadDepartments()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions({
        loadDepartments: 'common/loadDepartments'
      })
    },
    name: 'Departments'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Neu",
      "departments": "Abteilungen"
    },
    "en": {
      "add": "Add",
      "departments": "Departments"
    }
  }
</i18n>