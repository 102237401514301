<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisTransactionIrrevocably')"
                    v-bind:title="$t('deleteTransaction')"
                    v-model="confirmDeleteDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="$t('transaction')">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'inventory-management-app-inventory-transactions', params: { inventoryId: inventoryId, id: inventoryReceiptId } }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateInventoryTransaction" />
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="deleteInventoryTransaction">
            <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-progress-linear color="accent"
                       indeterminate
                       v-bind:active="loading" />
    <template v-if="inventoryTransaction != null">
      <inventory-transaction-details-card ref="inventoryTransactionDetailsCard"
                                              v-bind:inventory-transaction="inventoryTransaction" />
    </template>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import InventoryTransactionDetailsCard from '@/components/inventory-management/InventoryTransactionDetailsCard.vue'
  import ConfirmDialog from '@/components/common/ConfirmDialog'

  export default {
    async beforeRouteUpdate(to, _, next) {
      const id = parseInt(to.params.id)
      const inventoryId = parseInt(to.params.inventoryId)
      const inventoryReceiptId = to.params.inventoryReceiptId

      await this.get(id, inventoryId, inventoryReceiptId)

      next()
    },
    components: {
      HeaderButton,
      TopHeader,
      InventoryTransactionDetailsCard,
      ConfirmDialog
    },
    computed: {
      ...mapGetters({
        getInventoryTransaction: 'inventoryManagement/inventoryTransaction'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)
      const inventoryId = parseInt(this.$route.params.inventoryId)
      const inventoryReceiptId = this.$route.params.inventoryReceiptId

      await this.get(id, inventoryId, inventoryReceiptId)
    },
    data: () => ({
      loading: false,
      inventoryTransaction: null,
      inventoryId: null,
      inventoryReceiptId: null,
      validationSnackbar: false,
      confirmDeleteDialog: false
    }),
    methods: {
      async get(id, inventoryId, inventoryReceiptId) {
        this.inventoryId = inventoryId
        this.inventoryReceiptId = inventoryReceiptId

        this.loading = true
        
        await this.loadInventoryTransaction({ id })

        this.loading = false

        this.inventoryTransaction = Object.assign({}, this.getInventoryTransaction(id))
      },
      async updateInventoryTransaction() {
        if (!this.$refs.inventoryTransactionDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateInventoryTransaction({ inventoryTransaction: this.inventoryTransaction })

        this.loading = false

        this.$router.push({ name: 'inventory-management-app-inventory-transactions', params: { inventoryId: this.inventoryId, id: this.inventoryReceiptId } })
      },
      async deleteInventoryTransaction() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteInventoryTransaction({ id: this.inventoryTransaction.id })

          this.loading = false

          this.$router.push({ name: 'inventory-management-app-inventory-transactions', params: { inventoryId: this.inventoryId, id: this.inventoryReceiptId } })
        })
      },
      ...mapActions({
        doDeleteInventoryTransaction: 'inventoryManagement/deleteInventoryTransaction',
        loadInventoryTransaction: 'inventoryManagement/loadInventoryTransaction',
        doUpdateInventoryTransaction: 'inventoryManagement/updateInventoryTransaction'
      })
    },
    name: 'AppInventoryTransaction'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisTransactionIrrevocably": "Sind Sie sicher, dass Sie diese Buchung unwiderruflich löschen möchten?",
      "back": "Zurück",
      "transaction": "Buchung",
      "save": "Speichern",
      "delete": "Löschen",
      "cancel": "Abbrechen",
      "deleteTransaction": "Buchung löschen",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "areYouSureYouWantToDeleteThisTransactionIrrevocably": "Are you sure you want to delete this transaction irrevocably?",
      "back": "Back",
      "transaction": "transaction",
      "save": "Save",
      "delete": "Delete",
      "cancel": "Cancel",
      "deleteTransaction": "Delete transaction",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>