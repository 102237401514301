<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('filledStock') }}</v-toolbar-title>
      </v-toolbar>
      <inventory-filled-stock-details ref="inventoryFilledStockDetails"
                                      v-bind:transaction="transaction"
                                      v-on:submit="$emit('submit')" />
      <v-card-actions>
        <v-spacer />
        <v-btn color="accent"
                text
                v-on:click="$emit('save')">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  import InventoryFilledStockDetails from '@/components/inventory-management/InventoryFilledStockDetails'

  export default {
    components: {
      InventoryFilledStockDetails
    },
    name: 'InventoryFilledStockDetailsCard',
    methods: {
      validate() {
        return this.$refs.inventoryFilledStockDetails.validate()
      },
      doFocus() {
        this.$refs.inventoryFilledStockDetails.doFocus()
      },
      resetSearch(search) {
        this.$refs.inventoryFilledStockDetails.resetSearch(search)
      }
    },
    props: {
      transaction: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details",
      "save": "Speichern",
      "filledStock": "Vollgut"
    },
    "en": {
      "details": "Details",
      "save": "Save",
      "filledStock": "Filled Stock"
    }
  }
</i18n>