export default class SiteProductPerformanceService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }


  async getAllPerformance(id, group, options) {
    try {
      const response = await fetch(`${this.baseUrl}/productPerformance/site/performance/${id}?group=${group}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getTopProducts(id, group, options) {
    try {
      const response = await fetch(`${this.baseUrl}/productPerformance/site/top/${id}?group=${group}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getFlopProducts(id, group, options) {
    try {
      const response = await fetch(`${this.baseUrl}/productPerformance/site/top/${id}?group=${group}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAllGroups(options) {
    try {
      const response = await fetch(`${this.baseUrl}/evaluation/site/product-performance/group`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAllKinds(options) {
    try {
      const response = await fetch(`${this.baseUrl}/evaluation/site/product-performance/kind`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }
}