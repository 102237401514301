<template>
  <v-list class="py-0"
          dark>
    <v-list-item class="accent"
                 exact
                 v-bind:to="{ name: 'home' }">
      <v-list-item-icon>
        <v-icon>mdi-chevron-left</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('back') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-divider />

    <v-list-item exact
                 v-bind:to="{ name: 'learning' }">
      <v-list-item-icon>
        <v-icon>mdi-school</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('dashboard') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-bind:to="{ name: 'learning-trainees' }">
      <v-list-item-icon>
        <v-icon>mdi-account-school</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('trainees') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-bind:to="{ name: 'learning-subjects' }">
      <v-list-item-icon>
        <v-icon>mdi-book</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('subjects') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-list-item v-bind:to="{ name: 'learning-exams' }">
      <v-list-item-icon>
        <v-icon>mdi-pencil</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('exams') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    name: 'LearningNavigation'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "dashboard": "Dashboard",
      "exams": "Prüfungen",
      "subjects": "Module",
      "trainees": "Trainees"
    },
    "en": {
      "back": "Back",
      "dashboard": "Dashboard",
      "exams": "Exams",
      "subjects": "Subjects",
      "trainees": "Trainees"
    }
  }
</i18n>