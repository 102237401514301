<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisInventoryIrrevocably')"
                    v-bind:title="$t('deleteInventory')"
                    v-model="confirmDeleteDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="$t('inventory')">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'inventory-management-inventories' }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateInventory" />
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="deleteInventory">
            <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>
    <v-progress-linear color="accent"
                       indeterminate
                       v-bind:active="loading" />
    <v-container v-if="inventory != null">
      <v-row>
        <v-col cols="12"
               md="6">
          <inventory-details-card ref="inventoryDetailsCard"
                                  v-bind:inventory="inventory" />
        </v-col>
        <v-col cols="12"
               md="6">
          <inventory-receipt-list-card addRouteName="inventory-management-inventory-receipt-add"
                                       itemRouteName="inventory-management-inventory-receipt"
                                       v-bind:routeParams="{ inventoryId: id }"
                                       v-bind:inventory-receipts="inventoryReceipts" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import InventoryDetailsCard from '@/components/inventory-management/InventoryDetailsCard.vue'
  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import InventoryReceiptListCard from '@/components/inventory-management/InventoryReceiptListCard.vue'

  export default {
    async beforeRouteUpdate(to, _, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      HeaderButton,
      TopHeader,
      InventoryDetailsCard,
      ConfirmDialog,
      InventoryReceiptListCard
    },
    computed: {
      id() {
        return this.inventory?.id
      },
      inventoryReceipts() {
        return this.getInventoryReceipts.filter(i => i.inventoryId == this.id)
      },
      ...mapGetters({
        getInventory: 'inventoryManagement/inventory',
        getInventoryReceipts: 'inventoryManagement/inventoryReceipts'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      loading: false,
      inventory: null,
      validationSnackbar: false,
      confirmDeleteDialog: false
    }),
    methods: {
      async get(id) {
        this.loading = true
        
        await this.loadTenants()
        await this.loadWarehouses()
        await this.loadInventoryReceipts()
        await this.loadInventories()

        this.loading = false

        this.inventory = Object.assign({}, this.getInventory(id))
      },
      async updateInventory() {
        if (!this.$refs.inventoryDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateInventory({ inventory: this.inventory })

        this.loading = false

        this.$router.push({ name: 'inventory-management-inventories' })
      },
      async deleteInventory() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteInventory({ id: this.id })

          this.loading = false

          this.$router.push({ name: 'inventory-management-inventories' })
        })
      },
      ...mapActions({
        doDeleteInventory: 'inventoryManagement/deleteInventory',
        loadInventories: 'inventoryManagement/loadInventories',
        loadInventoryReceipts: 'inventoryManagement/loadInventoryReceipts',
        doUpdateInventory: 'inventoryManagement/updateInventory',
        loadTenants: 'common/loadTenants',
        loadWarehouses: 'common/loadWarehouses'
      })
    },
    name: 'Inventory'
  }
</script>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisInventoryIrrevocably": "Sind Sie sicher, dass Sie diese Inventur unwiderruflich löschen möchten?",
      "back": "Zurück",
      "inventory": "Inventur",
      "save": "Speichern",
      "delete": "Löschen",
      "cancel": "Abbrechen",
      "deleteInventory": "Inventur löschen",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "areYouSureYouWantToDeleteThisInventoryIrrevocably": "Are you sure you want to delete this inventory irrevocably?",
      "back": "Back",
      "inventory": "Inventory",
      "save": "Save",
      "delete": "Delete",
      "cancel": "Cancel",
      "deleteInventory": "Delete inventory",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>