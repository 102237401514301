<template>
  <v-list class="py-0"
          dark>
    <v-list-item v-bind:to="{ name: 'booking-public' }"
                 v-if="category !== 'Hausmesse' && subCategory !== 'Allgemein'">
      <v-list-item-icon>
        <v-icon>mdi-calendar</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('eventOverview') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    beforeRouteUpdate(to, from, next) {
      this.category = to.params.category
      this.subCategory = to.params.subCategory

      next()
    },
    created() {
      this.category = this.$route.params.category
      this.subCategory = this.$route.params.subCategory
    },
    data: () => ({
      category: null,
      subCategory: null
    }),
    name: 'PublicLearningNavigation'
  }
</script>

<i18n>
  {
    "de": {
      "eventOverview": "Seminarübersicht"
    },
    "en": {
      "eventOverview": "Event overview"
    }
  }
</i18n>