<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('inventories') }}</v-toolbar-title>
      </v-toolbar>
      <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                    v-bind:headers="headers"
                    v-bind:items="inventories"
                    v-bind:items-per-page="50"
                    v-bind:loading="loading"
                    v-bind:options="{ sortBy: [ 'createdAt' ], sortDesc: [ true ] }"
                    v-on:click:row="selectInventory">
        <template v-slot:[`item.dateOfCounting`]="{ item }">
          {{ item.dateOfCounting | formatDate('L') }}
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
          {{ item.createdAt | formatDate('L LT') }}
        </template>
        <template v-slot:[`item.tenantId`]="{ item }">
          {{ tenantTitle(item.tenantId) }}
        </template>
        <template v-slot:[`item.warehouseId`]="{ item }">
          {{ warehouseTitle(item.warehouseId) }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      headers() {
        return [
          {
            text: this.$t('number'),
            value: 'id'
          },
          {
            text: this.$t('tenant'),
            value: 'tenantId'
          },
          {
            text: this.$t('type'),
            value: 'inventoryType'
          },
          {
            text: this.$t('warehouse'),
            value: 'warehouseId'
          },
          {
            text: this.$t('dateOfCounting'),
            value: 'dateOfCounting'
          },
          {
            text: this.$t('createdAt'),
            value: 'createdAt'
          },
          {
            text: this.$t('createdBy'),
            value: 'createdBy'
          }
        ]
      },
      ...mapGetters({
        siteMeterTypeLabel: 'meterManagement/siteMeterTypeLabel',
        tenantTitle: 'common/tenantTitle',
        warehouseTitle: 'common/warehouseTitle'
      })
    },
    data: () => ({

    }),
    name: 'InventoriesTableCard',
    methods: {
      selectInventory(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id }})
      }
    },
    props: {
      inventories: Array,
      loading: Boolean,
      itemRouteName: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "inventories": "Inventuren",
      "number": "Nummer",
      "tenant": "Mandant",
      "warehouse": "Lager",
      "dateOfCounting": "Zählungsdatum",
      "createdAt": "Erstellt am",
      "createdBy": "Erstellt von",
      "type": "Art"
    },
    "en": {
      "inventories": "Inventories",
      "number": "Number",
      "tenant": "Tenant",
      "warehouse": "Warehouse",
      "dateOfCounting": "Date of counting",
      "createdAt": "Created at",
      "createdBy": "Created by",
      "type": "Type"
    }
  }
</i18n>