<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat
                 v-if="!hideTitleBar">
        <v-toolbar-title>{{ $t('transactions') }}</v-toolbar-title>
        <!--<v-spacer />
        <v-btn exact
               icon
               v-bind:to="{ name: this.addRouteName, params: routeParams }">
          <v-icon>mdi-plus</v-icon>
        </v-btn>-->
      </v-toolbar>
   
            <v-text-field 
                          flat
                          hide-details
                          prepend-inner-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
      <v-divider />
      <v-data-iterator v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                       v-bind:items="filteredInventoryTransactions"
                       v-bind:items-per-page="30">
        <template v-slot:default="props">
          <v-list>
            <v-list-item two-line
                         v-bind:key="inventoryTransaction.id"
                         v-for="inventoryTransaction in props.items"
                         v-on:click="goToItem(inventoryTransaction)">
              <v-list-item-avatar>
                <v-icon class="primary lighten-1"
                        dark>
                  mdi-ticket
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  {{ inventoryTransaction.inventoryProductId }} <strong>{{ inventoryTransaction.inventoryProductTitle }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle><strong>{{ inventoryTransaction.countedAt | formatDate('L') }}</strong> | {{ inventoryTransaction.countQuantity }} {{ inventoryTransaction.unit }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-divider />
        </template>
        <template v-slot:no-data>
        <v-card-text class="text-center text--disabled">
          {{ $t('noTransactionsAvailable') }}
        </v-card-text>
        <v-divider />
      </template>
      </v-data-iterator>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      filteredInventoryTransactions() {
        let filteredInventoryTransactions = this.inventoryTransactions

        if (!!this.search && this.search.length > 0) {
          filteredInventoryTransactions = filteredInventoryTransactions.filter(i => `${i.inventoryProductTitle.toLowerCase()} ${i.inventoryProductId}`.includes(this.search.toLowerCase()))
        }
      
        return filteredInventoryTransactions
      }
    },
    name: 'InventoryTransactionListCard',
    methods: {
      goToItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id, ...this.routeParams }})
      }
    },
    data: () => ({
      search: ''
    }),
    props: {
      addRouteName: String,
      inventoryTransactions: Array,
      itemRouteName: String,
      routeParams: Object,
      hideTitleBar: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "transactions": "Buchgungen",
      "noTransactionsAvailable": "Keine Buchungen vorhanden",
      "search": "Suche"
    },
    "en": {
      "transactions": "Transactions",
      "noTransactionsAvailable": "No transactions available",
      "search": "Search"
    }
  }
</i18n>