<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('title')"
                        v-bind:rules="[ rules.required ]"
                        v-model="event.title" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <rich-text-field v-bind:label="$t('description')"
                           v-model="event.description" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <v-checkbox class="mt-0"
                      hide-details="always"
                      v-bind:label="$t('privateEvent')"
                      v-model="event.private" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox class="mt-0"
                      hide-details="always"
                      v-bind:label="$t('externalEvent')"
                      v-model="event.external" />
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-if="event.private">
          <v-col>
            <v-text-field filled
                          hide-details="auto"
                          v-bind:append-icon="showCode ? 'mdi-eye' : 'mdi-eye-off'"
                          v-bind:label="$t('code')"
                          v-bind:rules="[ rules.required ]"
                          v-bind:type="showCode ? 'text' : 'password'"
                          v-on:click:append="showCode = !showCode"
                          v-model="event.code" />
          </v-col>
        </v-row>
      </v-expand-transition>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <v-combobox filled
                      hide-details="auto"
                      v-bind:items="categoryItems"
                      v-bind:label="$t('category')"
                      v-bind:return-object="false"
                      v-bind:rules="[ rules.required ]"
                      v-model="event.category" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-combobox filled
                      hide-details="auto"
                      v-bind:items="subCategoryItems"
                      v-bind:label="$t('subCategory')"
                      v-bind:return-object="false"
                      v-bind:rules="[ rules.required ]"
                      v-model="event.subCategory" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <v-textarea filled
                        hide-details="auto"
                        v-bind:label="$t('cancellationPolicy')"
                        v-model="event.cancellationPolicy" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        suffix="€"
                        type="number"
                        v-bind:label="$t('attendanceFee')"
                        v-model="event.attendanceFee" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('referee')"
                        v-bind:rules="[ rules.required ]"
                        v-model="event.referee" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('email')"
                        v-bind:rules="[ rules.required ]"
                        v-model="event.email" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  import RichTextField from '@/components/common/RichTextField.vue'

  export default {
    components: {
      RichTextField
    },
    computed: {
      categoryItems() {
        return [...new Set(this.events.map(e => ({
          text: e.category,
          value: e.category
        })))].sort((a, b) => a.text.localeCompare(b.text))
      },
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      subCategoryItems() {
        return [...new Set(this.events.map(e => ({
          text: e.subCategory,
          value: e.subCategory
        })))].sort((a, b) => a.text.localeCompare(b.text))
      },
      ...mapGetters({
        events: 'booking/events'
      })
    },
    data: () => ({
      showCode: false
    }),
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'EventDetails',
    props: {
      event: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "attendanceFee": "Teilnahmegebühr",
      "cancellationPolicy": "Stornobedingungen",
      "category": "Kategorie",
      "code": "Code",
      "description": "Beschreibung",
      "email": "E-Mail-Adresse",
      "privateEvent": "Private Veranstaltung",
      "referee": "Referent",
      "subCategory": "Unterkategorie",
      "title": "Bezeichnung",
      "externalEvent": "Externe Veranstaltung"
    },
    "en": {
      "attendanceFee": "Attendance fee",
      "cancellationPolicy": "Cancellation policy",
      "category": "Category",
      "code": "Code",
      "description": "Description",
      "email": "Email",
      "privateEvent": "Private event",
      "referee": "Referee",
      "subCategory": "Sub category",
      "title": "Title",
      "externalEvent": "External event"
    }
  }
</i18n>