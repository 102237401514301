import Vue from 'vue'

import App from './App.vue'
import './filters'
import i18n from './plugins/i18n'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

new Vue({
  render: h => h(App),
  router,
  store,
  i18n,
  vuetify
}).$mount('#app')

// TODO
import VueMask from 'v-mask'
Vue.use(VueMask);

window.androidProxy = {}