<template>
  <div>
    <confirm-dialog ref="confirmDeleteDialog"
                    confirm-button-color="deep-orange"
                    v-bind:cancel-button-label="$t('cancel')"
                    v-bind:confirm-button-label="$t('delete')"
                    v-bind:message="$t('areYouSureYouWantToDeleteThisSubjectIrrevocably')"
                    v-bind:title="$t('deleteSubject')"
                    v-model="confirmDeleteDialog" />

    <question-dialog ref="questionDialog"
                     v-bind:question="selectedQuestion"
                     v-model="questionDialog" />

    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="title | defaultValue($t('subject'))">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'learning-subjects' }" />

      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateSubject" />

      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="deleteSubject">
            <v-list-item-title class="deep-orange--text">{{ $t('delete') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container v-if="subject != null">
      <v-row>
        <v-col cols="12"
               lg="6">
          <subject-details-card ref="subjectDetailsCard"
                                v-bind:subject="subject" />
        </v-col>
        <v-col cols="12"
               lg="6">
          <subject-document-list-card ref="subjectDocumentListCard"
                                      v-bind:subject-documents="subject.documents"
                                      v-on:add-subject-document="addSubjectDocument"
                                      v-on:delete-subject-document="deleteSubjectDocument" />

          <question-list-card class="mt-4"
                              ref="questionListCard"
                              v-bind:questions="subject.questions"
                              v-on:add-question="addQuestion"
                              v-on:delete-question="deleteQuestion"
                              v-on:open-question="openQuestion" />
        </v-col>
      </v-row>
    </v-container>

    <input class="hidden"
           ref="fileInput"
           type="file"
           v-on:change="fileInputChange">
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import ConfirmDialog from '@/components/common/ConfirmDialog'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import QuestionDialog from '@/components/learning/QuestionDialog.vue'
  import QuestionListCard from '@/components/learning/QuestionListCard.vue'
  import SubjectDetailsCard from '@/components/learning/SubjectDetailsCard.vue'
  import SubjectDocumentListCard from '@/components/learning/SubjectDocumentListCard.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      ConfirmDialog,
      HeaderButton,
      QuestionDialog,
      QuestionListCard,
      SubjectDetailsCard,
      SubjectDocumentListCard,
      TopHeader
    },
    computed: {
      id() {
        return this.subject?.id
      },
      title() {
        return this.subject?.title
      },
      ...mapGetters({
        getSubject: 'learning/subject'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      confirmDeleteDialog: false,
      loading: false,
      newSubjectDocument: null,
      questionDialog: false,
      selectedQuestion: null,
      subject: null,
      validationSnackbar: false
    }),
    methods: {
      async addQuestion(questionText) {
        const question = {
          text: questionText,
          subjectId: this.id,
          answers: []
        }

        this.loading = true

        await this.createQuestion({ question })

        this.loading = false
      },
      async addSubjectDocument(documentTitle) {
        const subjectDocument = {
          title: documentTitle,
          attachmentId: 1,
          subjectId: this.id
        }

        this.newSubjectDocument = subjectDocument
        this.$refs.fileInput.click()
      },
      async deleteQuestion(questionId) {
        this.loading = true

        await this.doDeleteQuestion({ subjectId: this.id, id: questionId })

        this.loading = false
      },
      async deleteSubjectDocument(subjectDocumentId) {
        this.loading = true

        await this.doDeleteSubjectDocument({ subjectId: this.id, id: subjectDocumentId })

        this.loading = false
      },
      async deleteSubject() {
        await this.$refs.confirmDeleteDialog.open(async r => {
          if (!r) {
            return
          }

          this.loading = true

          await this.doDeleteSubject({ id: this.id })

          this.loading = false

          this.$router.push({ name: 'learning-subjects' })
        })
      },
      async fileInputChange(ev) {
        if (ev == null) {
          return
        }

        if (this.newSubjectDocument == null) {
          return
        }

        const file = ev.srcElement.files[0]

        if (file == null) {
          return
        }

        try {
          this.loading = true

          const reader = new FileReader()

          reader.onloadend = async () => {
            const data = new FormData()
            data.append('file', file)

            const attachment = await this.createAttachment({ data })

            this.newSubjectDocument.attachmentId = attachment.id
            this.newSubjectDocument.attachment = attachment

            await this.createSubjectDocument({ subjectDocument: this.newSubjectDocument })

            this.loading = false
          }

          reader.readAsDataURL(file)
        } catch (e) {
          console.log(e)
        }
      },
      async get(id) {
        this.loading = true

        await this.loadQuestions()
        await this.loadSubject({ id })
        await this.loadSubjectDocuments()

        this.loading = false

        this.subject = Object.assign({}, this.getSubject(id))
      },
      async openQuestion(questionId) {
        this.selectedQuestion = this.subject.questions.find(q => q.id === questionId)

        this.selectedQuestion = JSON.parse(JSON.stringify(this.selectedQuestion))

        await this.$refs.questionDialog.open(async r => {
          if (!r) {
            return
          }

          await this.updateQuestion({ question: r })
        })
      },
      async updateSubject() {
        if (!this.$refs.subjectDetailsCard.validate() | !this.$refs.questionListCard.validate()) {
          this.validationSnackbar = true

          return
        }

        this.loading = true

        await this.doUpdateSubject({ subject: this.subject })

        this.loading = false

        this.$router.push({ name: 'learning-subjects' })
      },
      ...mapActions({
        createAttachment: 'common/createAttachment',
        createQuestion: 'learning/createQuestion',
        createSubjectDocument: 'learning/createSubjectDocument',
        doDeleteQuestion: 'learning/deleteQuestion',
        doDeleteSubjectDocument: 'learning/deleteSubjectDocument',
        doDeleteSubject: 'learning/deleteSubject',
        doUpdateSubject: 'learning/updateSubject',
        loadQuestions: 'learning/loadQuestions',
        loadSubject: 'learning/loadSubject',
        loadSubjectDocuments: 'learning/loadSubjectDocuments',
        updateQuestion: 'learning/updateQuestion'
      })
    },
    name: 'Subject'
  }
</script>

<style>
  .hidden {
    display: none;
  }
</style>

<i18n>
  {
    "de": {
      "areYouSureYouWantToDeleteThisSubjectIrrevocably": "Sind Sie sicher, dass Sie dieses Modul unwiderruflich löschen möchten?",
      "back": "Zurück",
      "cancel": "Abbrechen",
      "delete": "Löschen",
      "deleteSubject": "Modul löschen",
      "save": "Speichern",
      "subject": "Modul",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "areYouSureYouWantToDeleteThisSubjectIrrevocably": "Are you sure you want to delete this subject irrevocably?",
      "back": "Back",
      "cancel": "Cancel",
      "delete": "Delete",
      "deleteSubject": "Delete subject",
      "save": "Save",
      "subject": "Subject",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>