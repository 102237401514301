import MeterManagementNavigation from '../views/meter-management/navigation/MeterManagementNavigation.vue'
import MeterManagement from '../views/meter-management/MeterManagement.vue'

import MeterDataEntry from '../views/meter-management/MeterDataEntry.vue'
import MeterRanking from '../views/meter-management/MeterRanking.vue'
import MeterValues from '../views/meter-management/MeterValues.vue'

export default [
  {
    components: {
      default: MeterManagement,
      navigation: MeterManagementNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSite'))
    },
    name: 'meter-management',
    path: '/meter-management'
  },
  {
    components: {
      default: MeterDataEntry,
      navigation: MeterManagementNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSite'))
    },
    name: 'meter-management-data-entry',
    path: '/meter-management/data-entry'
  },
  {
    components: {
      default: MeterRanking,
      navigation: MeterManagementNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSite'))
    },
    name: 'meter-management-ranking',
    path: '/meter-management/ranking'
  },
  {
    components: {
      default: MeterValues,
      navigation: MeterManagementNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSite'))
    },
    name: 'meter-management-values',
    path: '/meter-management/values'
  }
]