export default class InventoryStockService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async create(inventoryStock, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryStock`, {
      body: JSON.stringify(inventoryStock),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(inventoryProductId, warehouseId, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryStock/${inventoryProductId}/${warehouseId}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(inventoryProductId, warehouseId, options) {
    try {
      const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryStock/${inventoryProductId}/${warehouseId}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryStock`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async update(inventoryProductId, warehouseId, inventoryStock, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryStock/${inventoryProductId}/${warehouseId}`, {
      body: JSON.stringify(inventoryStock),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async sync(warehouseId, deleteMissing, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryStock/import/sync/${warehouseId}?deleteMissing=${deleteMissing}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return response.ok
  }
}