<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details
                          prepend-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
          <v-col>
            <v-autocomplete clearable
                            flat
                            hide-details
                            multiple
                            prepend-icon="mdi-domain"
                            solo
                            v-bind:items="tenants | items({ text: 'title', value: 'id' })"
                            v-bind:label="$t('tenant')"
                            v-model="tenantFilter" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('trainees') }}</v-toolbar-title>
    </v-toolbar>
    <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredTrainees"
                  v-bind:items-per-page="50"
                  v-bind:loading="loading"
                  v-bind:options="{ sortBy: [ 'familyName', 'givenName' ] }"
                  v-on:click:row="goToItem">
      <template v-slot:[`item.departmentId`]="{ item }">
        {{ departmentTitle(item.departmentId) }}
      </template>
      <template v-slot:[`item.tenantId`]="{ item }">
        {{ tenantTitle(item.tenantId) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      filteredTrainees() {
        let filteredTrainees = this.trainees

        if (this.search != null && this.search.length !== 0) {
          filteredTrainees = filteredTrainees.filter(f => `${f.number} ${f.givenName} ${f.familyName}`.toLowerCase().includes(this.search.toLowerCase()))
        }

        if (this.tenantFilter != null && this.tenantFilter.length !== 0) {
          filteredTrainees = filteredTrainees.filter(f => this.tenantFilter.includes(f.tenantId))
        }

        return filteredTrainees
      },
      headers() {
        return [
          {
            text: this.$t('number'),
            value: 'number'
          },
          {
            text: this.$t('givenName'),
            value: 'givenName'
          },
          {
            text: this.$t('familyName'),
            value: 'familyName'
          },
          {
            text: this.$t('tenant'),
            value: 'tenantId'
          },
          {
            text: this.$t('department'),
            value: 'departmentId'
          }
        ]
      },
      ...mapGetters({
        departmentTitle: 'common/departmentTitle',
        tenants: 'local/tenants',
        tenantTitle: 'local/tenantTitle'
      })
    },
    data: () => ({
      search: null,
      tenantFilter: null
    }),
    methods: {
      goToItem(item) {
        this.$router.push({ name: this.itemRouteName, params: { id: item.id }})
      }
    },
    name: 'TraineeTableCard',
    props: {
      trainees: Array,
      itemRouteName: String,
      loading: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "department": "Abteilung",
      "familyName": "Nachname",
      "givenName": "Vorname",
      "number": "Nummer",
      "search": "Suche",
      "tenant": "Mandant",
      "trainees": "Trainees"
    },
    "en": {
      "department": "Department",
      "familyName": "Family name",
      "givenName": "Given name",
      "number": "Number",
      "search": "Search",
      "tenant": "Tenant",
      "trainees": "Trainees"
    }
  }
</i18n>