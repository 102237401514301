<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <date-time-field filled
                           hide-details="auto"
                           v-bind:label="$t('registrationPeriod')"
                           v-bind:rules="[ rules.required ]"
                           v-model="eventDate.registrationPeriod" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <date-time-field filled
                           hide-details="auto"
                           v-bind:label="$t('start')"
                           v-bind:rules="[ rules.required ]"
                           v-model="eventDate.start" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <date-time-field filled
                           hide-details="auto"
                           v-bind:label="$t('end')"
                           v-bind:rules="[ rules.required ]"
                           v-model="eventDate.end" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        type="number"
                        v-bind:label="$t('slots')"
                        v-bind:rules="[ rules.required ]"
                        v-model="eventDate.slots" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-checkbox class="mt-0"
                      hide-details="always"
                      v-bind:label="$t('onlineEvent')"
                      v-model="eventDate.online" />
        </v-col>
      </v-row>
      <v-expand-transition>
        <v-row v-if="eventDate.online">
          <v-col>
            <v-text-field filled
                          hide-details="auto"
                          v-bind:label="$t('link')"
                          v-model="eventDate.link" />
          </v-col>
        </v-row>
      </v-expand-transition>

      <v-expand-transition>
        <v-row v-if="!eventDate.online">
          <v-col>
            <v-text-field filled
                          hide-details="auto"
                          v-bind:label="$t('location')"
                          v-model="eventDate.location" />
          </v-col>
        </v-row>
      </v-expand-transition>

    </v-card-text>
  </v-form>
</template>

<script>
  import DateTimeField from '@/components/common/DateTimeField'

  export default {
    components: {
      DateTimeField
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'EventDateDetails',
    props: {
      eventDate: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "end": "Ende",
      "link": "Link",
      "location": "Ort",
      "onlineEvent": "Online-Veranstaltung",
      "registrationPeriod": "Anmeldefrist",
      "slots": "Plätze",
      "start": "Beginn"
    },
    "en": {
      "end": "End",
      "link": "Link",
      "location": "Location",
      "onlineEvent": "Online event",
      "registrationPeriod": "Registration period",
      "slots": "Slots",
      "start": "Start"
    }
  }
</i18n>