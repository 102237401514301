export default class AttachmentService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }


  async create(data, options) {
    const response = await fetch(`${this.baseUri}/attachment`, {
      body: data,
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'POST'
    })

    return await response.json()
  }
}