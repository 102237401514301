<template>
  <div>
    <top-header v-bind:title="$t('result')">
      <v-menu slot="menu">
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark
                 icon
                 v-bind="attrs"
                 v-on="on">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item v-on:click="syncStocks">
            <v-list-item-title>{{ $t('syncStocks') }}</v-list-item-title>
          </v-list-item>
          <v-list-item v-on:click="downloadResults">
            <v-list-item-title>{{ $t('export') }}</v-list-item-title>
          </v-list-item>
          <v-divider />
        </v-list>
      </v-menu>


      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'inventory-management-inventory-receipt', params: { inventoryId: inventoryId, id: inventoryReceiptId } }" />
      <!--<header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-bind:to="{ name: 'inventory-management-inventory-add' }" />-->
    </top-header>
    <v-container>
      <v-row>
        <v-col>
          <inventory-results-table-card v-bind:inventory-results="filteredInventoryResults"
                                        v-bind:loading="loading" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import InventoryResultsTableCard from '@/components/inventory-management/InventoryResultsTableCard.vue'

  export default {
    async beforeRouteUpdate(to, _, next) {
      const id = parseInt(to.params.id)
      const inventoryId = parseInt(to.params.inventoryId)
      const inventoryReceiptId = to.params.inventoryReceiptId

      await this.get(id, inventoryId, inventoryReceiptId)

      next()
    },
    components: {
      TopHeader,
      HeaderButton,
      InventoryResultsTableCard
    },
    computed: {
      filteredInventoryResults() {
        return this.inventoryResults.filter(t => t.inventoryReceiptId === this.inventoryReceiptId)
      },
      ...mapGetters({ 
        hasRole: 'auth/hasRole',
        inventoryResults: 'inventoryManagement/inventoryResults',
        inventories: 'inventoryManagement/inventories',
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)
      const inventoryId = parseInt(this.$route.params.inventoryId)
      const inventoryReceiptId = this.$route.params.inventoryReceiptId

      await this.get(id, inventoryId, inventoryReceiptId)
    },
    data: () => ({
      loading: false,
      inventoryId: null,
      inventoryReceiptId: null
    }),
    methods: {
      async syncStocks() {
        this.loading = true

        const inventory = this.inventories.find(i => i.id === this.inventoryId)

        if (inventory == null) {
          this.loading = false
          return
        }

        await this.syncInventoryStocks({ warehouseId: inventory.warehouseId, deleteMissing: false })

        this.loading = false

        await this.get()
      },
      async downloadResults() {
        this.loading = true

        let csv = '"INV";"ARTNUM";"ARTBEZ";"MENGE";"EINHEIT"\n'

        this.filteredInventoryResults.forEach(r => {
          csv += `"${r.inventoryReceiptId}";"${r.inventoryProductId}";"${r.inventoryProductTitle}";"${r.countQuantity}";"${r.unit}"\n`
        });

        let element = document.createElement('a')
        element.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(csv))
        element.setAttribute('download', 'export.csv')
        element.style.display = 'none'

        document.body.appendChild(element)

        element.click();

        document.body.removeChild(element)

        this.loading = false
      },
      async get(id, inventoryId, inventoryReceiptId) {
        this.inventoryId = inventoryId
        this.inventoryReceiptId = inventoryReceiptId

        this.loading = true
        
        await this.loadInventories()
        await this.loadInventoryResultsForReceipt({ receiptId: inventoryReceiptId })

        this.loading = false
      },
      ...mapActions({
        loadInventoryResultsForReceipt: 'inventoryManagement/loadInventoryResultsForReceipt',
        loadInventories: 'inventoryManagement/loadInventories',
        syncInventoryStocks: 'inventoryManagement/syncInventoryStocks'
      })
    },
    name: 'InventoryResults'
  }
</script>

<i18n>
  {
    "de": {
      "result": "Ergebnis",
      "add": "Neu",
      "back": "Zurück",
      "export": "Exportieren",
      "syncStocks": "Lagerbestände synchronisieren"
    },
    "en": {
      "result": "Result",
      "add": "Add",
      "back": "Back",
      "export": "Export",
      "syncStocks": "Sync stocks"
    }
  }
</i18n>