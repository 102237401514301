export default class SiteEvaluationService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl
  }

  async find(id, start, end, options) {
    try {
      const response = await fetch(`${this.baseUrl}/evaluation/site/${id}?start=${start}&end=${end}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async findProductPerformance(id, group, options) {
    try {
      const response = await fetch(`${this.baseUrl}/evaluation/site/product-performance/${id}?group=${group}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }
}