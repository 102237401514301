<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <trainee-details ref="traineeDetails"
                       v-bind:show-invitation-key="showInvitationKey"
                       v-bind:trainee="trainee" />
    </v-card>
  </div>
</template>

<script>
  import TraineeDetails from '@/components/learning/TraineeDetails'

  export default {
    components: {
      TraineeDetails
    },
    name: 'TraineeDetailsCard',
    methods: {
      validate() {
        return this.$refs.traineeDetails.validate()
      }
    },
    props: {
      showInvitationKey: Boolean,
      trainee: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details"
    },
    "en": {
      "details": "Details"
    }
  }
</i18n>