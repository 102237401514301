import SiteEvaluation from '../views/evaluation/SiteEvaluation.vue'
import PrimaryNavigation from '../views/navigation/PrimaryNavigation.vue'

export default [
  {
    components: {
      default: SiteEvaluation,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && (hasRole('SagasserPortalSiteEvaluation') || hasRole('SagasserPortalAdministrator'))
    },
    name: 'evaluation-site',
    path: '/evaluation/site'
  }
]