<template>
  <v-btn dark
         exact
         v-bind:disabled="disabled"
         v-bind:text="$vuetify.breakpoint.lgAndUp"
         v-bind:icon="$vuetify.breakpoint.mdAndDown"
         v-bind:to="to"
         v-on:click="$emit('click')">
    <v-icon v-bind:left="$vuetify.breakpoint.lgAndUp"
            v-if="!right">
      {{ icon }}
    </v-icon>
    <span v-if="$vuetify.breakpoint.lgAndUp">{{ label }}</span>
    <v-icon v-bind:right="$vuetify.breakpoint.lgAndUp"
            v-if="right">
      {{ icon }}
    </v-icon>
  </v-btn>
</template>

<script>
  export default {
    name: 'HeaderButton',
    props: {
      disabled: Boolean,
      icon: String,
      label: String,
      right: Boolean,
      to: Object
    }
  }
</script>