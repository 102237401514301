import Department from '../views/Department.vue'
import DepartmentAdd from '../views/DepartmentAdd.vue'
import Departments from '../views/Departments.vue'
import Home from '../views/Home.vue'
import PrimaryNavigation from '../views/navigation/PrimaryNavigation.vue'
import SignInCallback from '../views/SignInCallback.vue'
import SignOutCallback from '../views/SignOutCallback.vue'

export default [
  {
    components: {
      default: Home,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ isAuthenticated }) => isAuthenticated
    },
    name: 'home',
    path: '/'
  },
  {
    component: SignInCallback,
    name: 'sign-in-callback',
    path: '/sign-in-callback'
  },
  {
    component: SignOutCallback,
    name: 'sign-out-callback',
    path: '/sign-out-callback'
  },
  {
    components: {
      default: DepartmentAdd,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'department-add',
    path: '/departments/add'
  },
  {
    components: {
      default: Department,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'department',
    path: '/departments/:id'
  },
  {
    components: {
      default: Departments,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'departments',
    path: '/departments'
  },
]