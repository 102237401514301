<template>
  <div>
    <top-header v-bind:title="$t('exams')">
      <header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-bind:to="{ name: 'learning-exam-add' }" />
    </top-header>
    <v-container>
      <exam-table-card item-route-name="learning-exam"
                       v-bind:loading="loading"
                       v-bind:exams="exams" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import ExamTableCard from '@/components/learning/ExamTableCard.vue'

  export default {
    components: {
      HeaderButton,
      TopHeader,
      ExamTableCard
    },
    computed: {
      ...mapGetters({
        exams: 'learning/exams'
      })
    },
    async created() {
      this.loading = true

      await this.loadExams()
      await this.loadSubjects()

      this.loading = false
    },
    data: () => ({
      loading: false
    }),
    methods: {
      ...mapActions({
        loadExams: 'learning/loadExams',
        loadSubjects: 'learning/loadSubjects'
      })
    },
    name: 'Exams'
  }
</script>

<i18n>
  {
    "de": {
      "add": "Neu",
      "exams": "Prüfungen"
    },
    "en": {
      "add": "Add",
      "exams": "Exams"
    }
  }
</i18n>