<template>
  <div class="text-center">
    <template v-if="stepCount <= 20">
      <v-icon color="primary lighten-1"
              small
              v-bind:key="`empty-${index}`"
              v-for="index in step">mdi-circle</v-icon>
      <v-icon color="secondary lighten-2"
              small
              v-bind:key="`full-${index}`"
              v-for="index in stepCount - step">mdi-circle-outline</v-icon>
    </template>
    <template v-else>
      <p class="mb-0 primary--text text--lighten-1">{{ step }} / {{ stepCount }}</p>
    </template>
  </div>
</template>

<script>
  export default {
    name: 'QuestionnaireStepHeader',
    props: {
      step: Number,
      stepCount: Number
    }
  }
</script>