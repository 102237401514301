<template>
  <div>
    <top-header v-bind:title="$t('receipt')">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'free-product-accounting-receipts' }" />
    </top-header>
    <v-progress-linear color="accent"
                       indeterminate
                       v-bind:active="loading" />
    <v-container>
      <receipt-card ref="receiptCard"
                    v-bind:receipt="receipt"
                    v-if="receipt != null"
                    v-on:delete-receipt="deleteReceiptAndReturn"
                    v-on:set-receipt-state="setReceiptStateAndRefresh"
                    v-on:sign="sign" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import ReceiptCard from '@/components/free-product-accounting/ReceiptCard.vue'

  export default {
    async beforeRouteUpdate(to, _, next) {
      const id = parseInt(to.params.id)

      await this.get(id)

      next()
    },
    components: {
      HeaderButton,
      ReceiptCard,
      TopHeader
    },
    computed: {
      ...mapGetters({
        getReceipt: 'freeProductAccounting/receipt'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      loading: false,
      receipt: null
    }),
    methods: {
      async deleteReceiptAndReturn({ id }) {
        await this.deleteReceipt({ id })

        this.$router.push({ name: 'free-product-accounting-receipts' })
      },
      async get(id) {
        this.loading = true

        await this.loadProducts()
        await this.loadReceipt({ id })
        await this.loadSites()

        this.loading = false

        this.receipt = Object.assign({}, this.getReceipt(id))
      },
      async setReceiptStateAndRefresh({ id, state }) {
        await this.setReceiptState({ id, state })

        await this.get(this.receipt.id)
      },
      async sign() {
        if (!this.$refs.receiptCard.validateSignature()) {
          return
        }

        this.receipt.signature = this.$refs.receiptCard.getSignature()
        this.receipt.state = 'open'

        await this.updateReceipt({ receipt: this.receipt})
      },
      ...mapActions({
        deleteReceipt: 'freeProductAccounting/deleteReceipt',
        loadProducts: 'common/loadProducts',
        loadReceipt: 'freeProductAccounting/loadReceipt',
        loadReceipts: 'freeProductAccounting/loadReceipts',
        loadSites: 'common/loadSites',
        setReceiptState: 'freeProductAccounting/setReceiptState',
        updateReceipt: 'freeProductAccounting/updateReceipt'
      })
    },
    name: 'Receipt'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "receipt": "Beleg"
    },
    "en": {
      "back": "Back",
      "receipt": "Receipt"
    }
  }
</i18n>