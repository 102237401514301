<template>
  <div>
    <v-timeline align-top
                class="mx-9"
                dense>
      <v-timeline-item color="deep-orange"
                       small>
        <v-row class="pt-1">
          <v-col cols="2">
            <strong>{{ preOrder.jobDate | formatDate }}</strong>
          </v-col>
          <v-col>
            <strong>{{ $t('orderPlaced') }}</strong>
            <div class="text-caption">
              {{  preOrder.jobNumber }}
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
      <v-timeline-item color="yellow"
                       small
                       v-if="preOrder.state === 'ordered' || preOrder.state === 'delivered'">
        <v-row class="pt-1">
          <v-col cols="2">
            <strong>{{ preOrder.invoiceDate | formatDate }}</strong>
          </v-col>
          <v-col>
            <strong>{{ $t('ordered') }}</strong>
            <div class="text-caption">
              {{  preOrder.invoiceNumber }}
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
      <v-timeline-item color="lime"
                       small
                       v-if="preOrder.state === 'delivered'">
        <v-row class="pt-1">
          <v-col cols="2">
            <strong>{{ preOrder.deliveryNoteDate | formatDate }}</strong>
          </v-col>
          <v-col>
            <strong>{{ $t('delivered') }}</strong>
            <div class="text-caption">
              {{  preOrder.deliveryNoteNumber }}
            </div>
          </v-col>
        </v-row>
      </v-timeline-item>
    </v-timeline>
    <v-divider />
  </div>
</template>

<script>
  export default {
    name: 'PreOrderTableExpansion',
    props: {
      preOrder: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "delivered": "Wareneingang",
      "ordered": "Bestellung Industrie",
      "orderPlaced": "Bestellungsaufgabe"
    },
    "en": {
      "delivered": "Delivered",
      "ordered": "Ordered",
      "orderPlaced": "orderPlaced"
    }
  }
</i18n>