<template>
  <div>
    <h1>FreeProductAccounting</h1>
  </div>
</template>

<script>
  export default {
    name: 'FreeProductAccounting'
  }
</script>