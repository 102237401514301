<template>
  <div>
    <top-header v-bind:title="$t('dashboard')" />
    <v-container>
      <v-row>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSite')">
          <icon-dashboard-card icon="mdi-gift"
                               v-bind:title="$t('freeProductAccounting')"
                               v-bind:to="{ name: 'free-product-accounting-receipts' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSiteEvaluation')">
          <icon-dashboard-card icon="mdi-star"
                               v-bind:title="$t('siteEvaluation')"
                               v-bind:to="{ name: 'evaluation-site' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSiteEvaluation')">
          <icon-dashboard-card icon="mdi-cart"
                               v-bind:title="$t('sitePreOrders')"
                               v-bind:to="{ name: 'pre-orders-site' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSiteEvaluation')">
          <icon-dashboard-card icon="mdi-podium-gold"
                               v-bind:title="$t('productPerformance')"
                               v-bind:to="{ name: 'product-performance-site' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalPriceTag')">
          <icon-dashboard-card icon="mdi-tag"
                               v-bind:title="$t('priceTagManagement')"
                               v-bind:to="{ name: 'price-tag' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSite') || hasRole('SagasserPortalSiteEvaluation')">
          <icon-dashboard-card icon="mdi-calendar"
                               v-bind:title="$t('eventOverview')"
                               v-bind:to="{ name: 'booking-public' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalSite')">
          <icon-dashboard-card icon="mdi-gauge"
                               v-bind:title="$t('meterManagement')"
                               v-bind:chip="hasOpenMeterEntries ? $t('entryRequired') : null"
                               v-bind:color="hasOpenMeterEntries ? 'amber': null"
                               v-bind:to="{ name: 'meter-management' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator')">
          <icon-dashboard-card icon="mdi-sale"
                               v-bind:title="$t('saleManagement')"
                               v-bind:to="{ name: 'sale-management-campaigns' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalSite')">
          <icon-dashboard-card icon="mdi-sale"
                               v-bind:title="$t('myCampaigns')"
                               v-bind:to="{ name: 'sale-management-site-campaigns' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator')">
          <icon-dashboard-card icon="mdi-school"
                               v-bind:title="$t('learning')"
                               v-bind:to="{ name: 'learning' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator') || hasRole('SagasserPortalBookingUser')">
          <icon-dashboard-card icon="mdi-calendar"
                               v-bind:title="$t('events')"
                               v-bind:to="{ name: 'booking-events' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator')">
          <icon-dashboard-card icon="mdi-domain"
                               v-bind:title="$t('departments')"
                               v-bind:to="{ name: 'departments' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator')">
          <icon-dashboard-card icon="mdi-warehouse"
                               v-bind:title="$t('inventories')"
                               v-bind:to="{ name: 'inventory-management-inventories' }" />
        </v-col>
        <v-col cols="12"
               md="6"
               v-if="hasRole('SagasserPortalAdministrator')">
          <icon-dashboard-card icon="mdi-toolbox"
                               v-bind:title="$t('administration')"
                               v-bind:to="{ name: 'administration' }" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import IconDashboardCard from '@/components/common/IconDashboardCard.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    components: {
      IconDashboardCard,
      TopHeader
    },
    computed: {
      hasOpenMeterEntries() {
        return this.siteMeters.filter(s => s.siteId === this.siteId).some(m => m.entryAllowed)
      },
      ...mapGetters({
        hasRole: 'auth/hasRole',
        siteId: 'common/currentSiteId',
        siteMeters: 'meterManagement/siteMeters'
      })
    },
    async created() {
      await this.loadSiteMeters()
    },
    methods: {
      ...mapActions({
        loadSiteMeters: 'meterManagement/loadSiteMeters'
      })
    },
    name: 'Home'
  }
</script>

<i18n>
  {
    "de": {
      "administration": "Administration",
      "dashboard": "Dashboard",
      "departments": "Abteilungen",
      "events": "Seminare",
      "eventOverview": "Seminarübersicht",
      "freeProductAccounting": "Gratiswarenabrechnung",
      "learning": "Unterweisung",
      "myCampaigns": "Meine Aktionen",
      "productPerformance": "Artikelperformance",
      "saleManagement": "Aktionsabfrage",
      "siteEvaluation": "Marktbewertung",
      "sitePreOrders": "Vorbestellungen",
      "meterManagement": "Zählerstände",
      "entryRequired": "Bitte ablesen",
      "inventories": "Inventuren",
      "priceTagManagement": "Preisschilddruck"
    },
    "en": {
      "administration": "Administration",
      "dashboard": "Dashboard",
      "departments": "Departments",
      "eventOverview": "Event overview",
      "events": "Events",
      "freeProductAccounting": "Free product accounting",
      "learning": "Learning",
      "myCampaigns": "My campaigns",
      "productPerformance": "Product performance",
      "saleManagement": "Sale management",
      "siteEvaluation": "Site evaluation",
      "sitePreOrders": "Site pre orders",
      "meterManagement": "Meter management",
      "entryRequired": "Entry required",
      "inventories": "Inventories",
      "priceTagManagement": "Price tag management"
    }
  }
</i18n>