<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('title')"
                        v-bind:rules="[ rules.required ]"
                        v-model="department.title" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'DepartmentDetails',
    props: {
      department: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "title": "Bezeichnung"
    },
    "en": {
      "title": "Title"
    }
  }
</i18n>