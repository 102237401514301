<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('meterValues') }}</v-toolbar-title>
      </v-toolbar>
      <v-expand-transition>
        <div v-if="!!selectedGroup">
          <meter-values-details v-bind:site-meter-values="selectedSiteMeterValues" />
          <v-divider />
        </div>
      </v-expand-transition>
      <v-data-table ref="table"
                    group-by="siteMeterId"
                    v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                    v-bind:headers="headers"
                    v-bind:items="siteMeterValues"
                    v-bind:items-per-page="50"
                    v-bind:loading="loading"
                    v-bind:options="{ sortBy: [ 'text', 'date' ], sortDesc: [ false, true ] }">
        <template #group.header="{ isOpen, toggle, group }">
          <td>
            <v-btn icon
                  v-on:click="toggle">
              <v-icon>
                {{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
              </v-icon>
            </v-btn>
            <strong>{{ getGroupText(group) }}</strong>
          </td>
          <td>
            <strong>{{ $t(siteMeterTypeLabel(getGroupType(group))) }}</strong>
          </td>
          <td>
            <strong>{{ getGroupNumber(group) }}</strong>
          </td>
          <td>
            <strong>{{ getGroupValue(group) | formatNumber({ fractionDigits: 3 }) }} {{ getGroupUnit(group) }}</strong>
          </td>
          <td>
            <strong>{{ getGroupDate(group) | formatDate('L') }}</strong>
          </td>
          <td>
            <v-btn color="accent"
                   icon
                   v-on:click="showChart(group)">
              <v-icon>
                mdi-chart-line
              </v-icon>
            </v-btn>
          </td>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ $t(siteMeterTypeLabel(item.type)) }}
        </template>
        <template v-slot:[`item.value`]="{ item }">
          {{ item.value | formatNumber({ fractionDigits: 3 }) }} {{ item.unit }}
        </template>
        <template v-slot:[`item.date`]="{ item }">
          {{ item.date | formatDate('L') }}
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import MeterValuesDetails from '@/components/meter-management/MeterValuesDetails'

  export default {
    components: {
      MeterValuesDetails
    },
    computed: {
      selectedSiteMeterValues() {
        return this.siteMeterValues.filter(s => s.siteMeterId === this.selectedGroup)
      },
      headers() {
        return [
          {
            text: this.$t('text'),
            value: 'text'
          },
          {
            text: this.$t('type'),
            value: 'type'
          },
          {
            text: this.$t('meterNumber'),
            value: 'number'
          },
          {
            text: this.$t('value'),
            value: 'value'
          },
          {
            text: this.$t('readingDate'),
            value: 'date'
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
            align: 'right',
            width: '96px'
          }
        ]
      },
      ...mapGetters({
        siteMeterTypeLabel: 'meterManagement/siteMeterTypeLabel'
      })
    },
    data: () => ({
      selectedGroup: null
    }),
    name: 'MeterValuesTableCard',
    methods: {
      closeAllGroups() {
        let table = this.$refs.table;
        let keys = Object.keys(table.$vnode.componentInstance.openCache);
        keys.forEach(x => {
            table.$vnode.componentInstance.openCache[x] = false;
        })
      },
      showChart(group) {
        this.selectedGroup = group
      },
      getGroupItems(group) {
        return this.siteMeterValues.filter(s => s.siteMeterId === group)
      },
      getGroupText(group) {
        return this.getGroupItems(group)?.[0]?.text
      },
      getGroupNumber(group) {
        return this.getGroupItems(group)?.[0]?.number
      },
      getGroupType(group) {
        return this.getGroupItems(group)?.[0]?.type
      },
      getGroupUnit(group) {
        return this.getGroupItems(group)?.[0]?.unit
      },
      getGroupValue(group) {
        return this.getGroupItems(group)?.[0]?.value
      },
      getGroupDate(group) {
        return this.getGroupItems(group)?.[0]?.date
      }
    },
    props: {
      siteMeterValues: Array,
      loading: Boolean
    },
    watch: {
      siteMeterValues(va) {
        setTimeout(() => {
          this.closeAllGroups()
        }, 100)
      }
    }
  }
</script>

<i18n>
  {
    "de": {
      "meterValues": "Zählerstände",
      "type": "Art",
      "text": "Bezeichnung",
      "meterNumber": "Zählernummer",
      "value": "Wert",
      "readingDate": "Ablesedatum",
      "meter": "Zähler"
    },
    "en": {
      "meterValues": "Meter values",
      "type": "Type",
      "text": "Text",
      "meterNumber": "Meter number",
      "value": "Value",
      "readingDate": "Reading date",
      "meter": "Meter"
    }
  }
</i18n>