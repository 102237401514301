<template>
  <div>
    <v-container>
      <v-card flat tile>
        <v-toolbar color="secondary"
                   dark
                   flat>
          <v-toolbar-title>{{ subject.title }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <questionnaire-step-header v-bind:step="step"
                                     v-bind:step-count="questions.length" />
        </v-card-text>
        <v-stepper class="elevation-0 pb-8 pt-4"
                   v-model="step">
          <v-stepper-items>
            <v-stepper-content class="pa-0"
                               v-bind:key="question.id"
                               v-bind:step="index + 1"
                               v-for="(question, index) in questions">
              <select-question ref="question"
                               v-bind:model="model"
                               v-bind:question="question" />
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-divider />
        <v-card-actions>
          <v-fade-transition>
            <v-btn text
                   v-if="hasPreviousQuestion"
                   v-on:click="goToPreviousQuestion">
              {{ $t('back') }}
            </v-btn>
          </v-fade-transition>

          <v-spacer />

          <v-btn color="accent"
                 text
                 v-if="hasNextQuestion"
                 v-on:click="goToNextQuestion">
            {{ $t('continue') }}
          </v-btn>

          <v-btn color="accent"
                 text
                 v-if="isLastQuestion"
                 v-on:click="submit">
            {{ $t('submit') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </div>
</template>

<script>
  import QuestionnaireStepHeader from '@/components/learning/QuestionnaireStepHeader.vue'
  import SelectQuestion from '@/components/learning/SelectQuestion.vue'

  export default {
    components: {
      QuestionnaireStepHeader,
      SelectQuestion
    },
    computed: {
      hasNextQuestion() {
        return this.step < this.subject.examQuestionCount
      },
      hasPreviousQuestion() {
        return this.step > 1
      },
      isLastQuestion() {
        return this.step === this.subject.examQuestionCount
      }
    },
    created() {
      this.questions = this.subject.questions.map(q => ({ q, sort: Math.random() })).sort((a, b) => a.sort - b.sort).map(({ q }) => q).slice(0, this.subject.examQuestionCount)
    },
    data: () => ({
      model: {},
      questions: [],
      step: 1
    }),
    methods: {
      goToNextQuestion() {
        if (!this.hasNextQuestion) {
          return
        }

        if (!this.$refs.question[this.step - 1].validate()) {
          return
        }

        this.step += 1
      },
      goToPreviousQuestion() {
        if (!this.hasPreviousQuestion) {
          return
        }

        this.step -= 1
      },
      submit() {
        if (!this.$refs.question[this.step - 1].validate()) {
          return
        }

        this.$emit('submit', this.model)
      }
    },
    name: 'QuestionnaireDisplay',
    props: {
      subject: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "continue": "Weiter",
      "submit": "Absenden"
    },
    "en": {
      "back": "Back",
      "continue": "Continue",
      "submit": "Submit"
    }
  }
</i18n>