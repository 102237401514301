<template>
  <div>
    <v-menu min-width="auto"
            ref="menu"
            offset-y
            transition="scale-transition"
            v-bind:close-on-content-click="false"
            v-model="menu">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field v-bind:filled="!outlined"
                      hide-details="auto"
                      readonly
                      v-bind="attrs"
                      v-bind:outlined="outlined"
                      v-bind:clearable="clearable"
                      v-bind:disabled="disabled"
                      v-bind:label="label"
                      v-bind:prepend-inner-icon="prependInnerIcon"
                      v-bind:rules="rules"
                      v-bind:value="internalValue"
                      v-bind:solo="solo"
                      v-on="{ ...on, 'click:clear': clear }" />
      </template>
      <v-date-picker color="accent"
                     header-color="secondary"
                     v-bind:locale="$i18n.locale"
                     v-bind:value="internalValue"
                     v-on:change="save" />
    </v-menu>
  </div>
</template>

<script>
  export default {
    computed: {
      internalValue() {
        if (this.value == null) {
          return null
        }

        return this.value.substring(0, 10)
      }
    },
    data: () => ({
      menu: false
    }),
    methods: {
      clear() {
        this.$emit('input', null)
      },
      save(date) {
        this.$emit('input', date)
        this.menu = false
      }
    },
    name: 'DateField',
    props: {
      clearable: Boolean,
      outlined: Boolean,
      disabled: Boolean,
      label: String,
      prependInnerIcon: String,
      rules: Array,
      solo: Boolean,
      value: String
    }
  }
</script>