<template>
  <v-form ref="form" v-on:submit.prevent="$emit('submit')">
    <v-card-text>
      <v-row>
        <v-col>
          <v-autocomplete filled
          ref="positionNumberInput" chips hide-selected
                          v-bind:search-input.sync="search"
                          hide-no-data
                          hide-details="auto"
                          v-bind:items="filteredInventoryPositions | items({ text: i => `${i.inventoryEmptyTitle} (${i.inventoryEmpty.number}) (${$options.filters.formatCurrencyValue(i.inventoryEmpty.depositValue)})`, value: 'positionNumber' })"
                          v-bind:label="$t('product')"
                          v-bind:rules="[ rules.required ]"
                          v-model="transaction.positionNumber"
                          v-on:change="doFocus" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field filled
                        ref="quantityInput"
                        hide-details="auto"
                        type="number"
                        v-bind:label="$t('countQuantity')"
                        v-bind:rules="[ rules.required ]"
                        v-model.number="transaction.countQuantity"
                        v-on:keyup="doChange($event)" />
          <p v-if="transaction.countQuantity > 1000" class="mt-2 mb-0 error--text">Die eingegebene Menge ist sehr hoch. (> 1.000)</p>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row v-if="transaction.inventoryProductId != null">
        <v-col>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('empty') }}</span>: {{ inventoryProduct.inventoryEmpty.title }}</p>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('baseUnit') }}</span>: {{ inventoryProduct.inventoryEmpty.baseUnit }}</p>
          <p class="mb-0"><span class="font-weight-bold">{{ $t('depositValue') }}</span>: {{ inventoryProduct.inventoryEmpty.depositValue | formatCurrencyValue }}</p>
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      filteredInventoryPositions() {
        if (!!this.transaction.positionNumber) return this.inventoryPositions.filter(i => i.positionNumber === this.transaction.positionNumber)

        if (!this.search || this.search.length < 3) return []
        
        return this.inventoryPositions.filter(i => i.type === 'empty') 
      },
      ...mapGetters({
        units: 'inventoryManagement/units',
        inventoryPositions: 'inventoryManagement/inventoryPositions'
      })
    },
    data: () => ({
      search: ''
    }),
    methods: {
      doChange(ev) {
        if (ev.key == 'Enter') {
          this.$emit('submit')
        }
      },
      resetSearch(search) {
        this.search = search
        this.$refs.positionNumberInput.$refs.input.focus()
        this.$refs.form.resetValidation()
      },
      doFocus() {        
        this.$refs.quantityInput.$refs.input.focus()
      },
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'InventoryEmptiesDetails',
    props: {
      transaction: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "product": "Artikel",
      "countQuantity": "Zählmenge",
      "empty": "Leergutartikel",
      "depositValue": "Pfandwert",
      "baseUnit": "Grundeinheit"
    },
    "en": {
      "product": "Product",
      "countQuantity": "Count quantity",
      "empty": "Empty product",
      "depositValue": "Deposit value",
      "baseUnit": "Base unit"
    }
  }
</i18n>