<template>
  <div>
    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="$t('newReceipt')">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'inventory-management-inventory', params: { id: inventoryReceipt.inventoryId } }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="createInventoryReceipt" />
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container>
      <v-row>
        <v-col cols="12"
               lg="6">
          <inventory-receipt-details-card ref="inventoryReceiptDetailsCard"
                                          v-bind:inventory-receipt="inventoryReceipt" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import InventoryReceiptDetailsCard from '@/components/inventory-management/InventoryReceiptDetailsCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      this.inventoryReceipt.inventoryId = parseInt(to.params.inventoryId)

      next()
    },
    components: {
      InventoryReceiptDetailsCard,
      HeaderButton,
      TopHeader
    },
    async created() {
      this.inventoryReceipt.inventoryId = parseInt(this.$route.params.inventoryId)
    },
    data: () => ({
      inventoryReceipt: {
        inventoryId: null
      },
      loading: false,
      validationSnackbar: false
    }),
    methods: {
      async createInventoryReceipt() {
        if (!this.$refs.inventoryReceiptDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        await this.doCreateInventoryReceipt({ inventoryReceipt: this.inventoryReceipt })

        this.$router.push({ name: 'inventory-management-inventory-receipt', params: { id: this.inventoryReceipt.id } })
      },
      ...mapActions({
        doCreateInventoryReceipt: 'inventoryManagement/createInventoryReceipt'
      })
    },
    name: 'InventoryAdd'
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "newReceipt": "Neuer Beleg",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "cancel": "Cancel",
      "newReceipt": "New receipt",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>