<template>
  <div>
    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="$t('newPosition')">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'inventory-management-inventory-receipt', params: { inventoryId: inventoryId, id: inventoryPosition.inventoryReceiptId } }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="createInventoryPosition" />
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container>
      <v-row>
        <v-col cols="12"
               lg="6">
          <inventory-position-details-card ref="inventoryPositionDetailsCard"
                                           v-bind:inventory-position="inventoryPosition" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import InventoryPositionDetailsCard from '@/components/inventory-management/InventoryPositionDetailsCard.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'

  export default {
    async beforeRouteUpdate(to, from, next) {
      this.inventoryId = parseInt(to.params.inventoryId)
      this.inventoryPosition.inventoryReceiptId = to.params.inventoryReceiptId

      next()
    },
    components: {
      InventoryPositionDetailsCard,
      HeaderButton,
      TopHeader
    },
    async created() {
      await this.loadInventoryProducts()
      this.inventoryId = parseInt(this.$route.params.inventoryId)
      this.inventoryPosition.inventoryReceiptId = this.$route.params.inventoryReceiptId
    },
    data: () => ({
      inventoryId: null,
      inventoryPosition: {
        inventoryReceiptId: null
      },
      loading: false,
      validationSnackbar: false
    }),
    methods: {
      async createInventoryPosition() {
        if (!this.$refs.inventoryPositionDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        await this.doCreateInventoryPosition({ inventoryPosition: this.inventoryPosition })

        this.$router.push({ name: 'inventory-management-inventory-position', params: { inventoryId: this.inventoryId, inventoryReceiptId: this.inventoryPosition.inventoryReceiptId, id: this.inventoryPosition.positionNumber } })
      },
      ...mapActions({
        doCreateInventoryPosition: 'inventoryManagement/createInventoryPosition',
        loadInventoryProducts: 'inventoryManagement/loadInventoryProducts'
      })
    },
    name: 'InventoryAdd'
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "newPosition": "Neue Position",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "cancel": "Cancel",
      "newPosition": "New position",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>