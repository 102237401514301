<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('number')"
                        v-bind:rules="[ rules.required ]"
                        v-model="trainee.number" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('givenName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="trainee.givenName" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('familyName')"
                        v-bind:rules="[ rules.required ]"
                        v-model="trainee.familyName" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete filled
                          hide-details="auto"
                          v-bind:items="tenants | items({ text: 'title', value: 'id' })"
                          v-bind:label="$t('tenant')"
                          v-bind:rules="[ rules.required ]"
                          v-model="trainee.tenantId" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-autocomplete filled
                          hide-details="auto"
                          v-bind:items="departments | items({ text: 'title', value: 'id' })"
                          v-bind:label="$t('department')"
                          v-bind:rules="[ rules.required ]"
                          v-model="trainee.departmentId" />
        </v-col>
      </v-row>
      <v-row v-if="showInvitationKey">
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        readonly
                        v-bind:label="$t('invitationKey')"
                        v-bind:rules="[ rules.required ]"
                        v-model="trainee.invitationKey" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      ...mapGetters({
        departments: 'common/departments',
        tenants: 'local/tenants'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'TraineeDetails',
    props: {
      showInvitationKey: Boolean,
      trainee: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "department": "Abteilung",
      "familyName": "Nachname",
      "givenName": "Vorname",
      "invitationKey": "Einladungsschlüssel",
      "number": "Nummer",
      "tenant": "Mandant"
    },
    "en": {
      "department": "Department",
      "familyName": "Family name",
      "givenName": "Given name",
      "invitationKey": "Invitation key",
      "number": "Number",
      "tenant": "Tenant"
    }
  }
</i18n>