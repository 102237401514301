<template>
  <v-list class="py-0"
          dark>
    <v-list-item v-bind:to="to">
      <v-list-item-icon>
        <v-icon>mdi-pencil</v-icon>
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title>{{ $t('myExams') }}</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script>
  export default {
    computed: {
      to() {
        if (this.invitationKey == null) {
          return { name: 'learning-my-learning-portal' }
        }

        return { name: 'learning-my-exams', params: { invitationKey: this.invitationKey } }
      }
    },
    created() {
      this.invitationKey = this.$route.params.invitationKey
    },
    data: () => ({
      invitationKey: null
    }),
    name: 'TraineeLearningNavigation'
  }
</script>

<i18n>
  {
    "de": {
      "myExams": "Meine Prüfungen"
    },
    "en": {
      "myExams": "My exams"
    }
  }
</i18n>