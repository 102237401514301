<template>
  <div>
    <top-header v-bind:title="$t('evaluation', { siteId: siteId })">
      <template slot="extra">
        <v-row>
          <v-col>
            <date-field prepend-inner-icon="mdi-calendar-start"
                        solo
                        v-model="startDate" />
          </v-col>
          <v-col>
            <date-field prepend-inner-icon="mdi-calendar-end"
                        solo
                        v-model="endDate" />
          </v-col>
        </v-row>
      </template>
    </top-header>

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container v-if="siteEvaluation != null">
      <v-row>
        <v-col cols="12" md="3">
          <number-dashboard-card v-bind:title="$t('totalRevenue')"
                                 v-bind:value="siteEvaluation.totalRevenue | formatCurrencyValue" />
        </v-col>
        <v-col cols="12" md="3">
          <number-dashboard-card v-bind:title="$t('loyaltyProgramRevenue')"
                                 v-bind:value="siteEvaluation.loyaltyProgramRevenue | formatCurrencyValue" />
        </v-col>
        <v-col cols="12" md="3">
          <ratio-dashboard-card v-bind:title="$t('loyaltyProgramRevenueShare')"
                                v-bind:value="siteEvaluation.loyaltyProgramRevenue / siteEvaluation.totalRevenue" />
        </v-col>
        <v-col cols="12" md="3">
          <number-dashboard-card v-bind:title="$t('revenuePerWorkingHour')"
                                 v-bind:value="siteEvaluation.revenuePerWorkingHour | formatCurrencyValue" />
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-8"
                 v-if="siteEvaluation != null">
      <v-row>
        <v-col cols="12" md="3">
          <number-dashboard-card v-bind:title="$t('averageInvoiceSum')"
                                 v-bind:value="siteEvaluation.averageInvoiceSum | formatCurrencyValue" />
        </v-col>
        <v-col cols="12" md="3">
          <number-dashboard-card v-bind:title="$t('averageProductCount')"
                                 v-bind:value="siteEvaluation.averageProductCount | formatNumber" />
        </v-col>
        <v-col cols="12" md="3">
          <number-dashboard-card v-bind:title="$t('spoilage')"
                                 v-bind:value="siteEvaluation.spoilage | formatCurrencyValue" />
        </v-col>
        <v-col cols="12" md="3">
          <ratio-dashboard-card v-bind:title="$t('spoilageShare')"
                                 v-bind:value="siteEvaluation.spoilage / siteEvaluation.totalRevenue" />
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-8"
                 v-if="siteEvaluation != null">
      <v-row>
        <v-col cols="12">
          <number-dashboard-card v-bind:title="$t('inventoryDifference')"
                                 v-bind:value="siteEvaluation.inventoryDifferencePercent | formatPercentValue" />
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mt-8"
                 v-if="siteEvaluation != null">
      <v-row>
        <v-col cols="12" md="4">
          <number-dashboard-card v-bind:sub-title="$t('ratings', { count: siteEvaluation.countWorkwear })"
                                 v-bind:title="$t('gradeWorkwear')"
                                 v-bind:value="siteEvaluation.gradeWorkwear | formatNumber " />
        </v-col>
        <v-col cols="12" md="4">
          <number-dashboard-card v-bind:sub-title="$t('ratings', { count: siteEvaluation.countPositioning })"
                                 v-bind:title="$t('gradePositioning')"
                                 v-bind:value="siteEvaluation.gradePositioning | formatNumber " />
        </v-col>
        <v-col cols="12" md="4">
          <number-dashboard-card v-bind:sub-title="$t('ratings', { count: siteEvaluation.countGeneralOrganization })"
                                 v-bind:title="$t('gradeGeneralOrganization')"
                                 v-bind:value="siteEvaluation.gradeGeneralOrganization | formatNumber " />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <number-dashboard-card color="accent"
                                 dark
                                 v-bind:title="$t('totalGrade')"
                                 v-bind:value="totalGrade | formatNumber " />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import moment from 'moment'

  import DateField from '@/components/common/DateField.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import NumberDashboardCard from '@/components/evaluation/NumberDashboardCard.vue'
  import RatioDashboardCard from '@/components/evaluation/RatioDashboardCard.vue'

  export default {
    components: {
      DateField,
      NumberDashboardCard,
      RatioDashboardCard,
      TopHeader
    },
    computed: {
      totalGrade() {
        if (this.siteEvaluation == null) {
          return 0
        }

        return (this.siteEvaluation.gradeWorkwear + this.siteEvaluation.gradePositioning + this.siteEvaluation.gradeGeneralOrganization) / 3
      },
      ...mapGetters({
        currentSiteId: 'common/currentSiteId',
        siteEvaluation: 'evaluation/siteEvaluation'
      })
    },
    async created() {
      this.endDate = moment().format('YYYY-MM-DD')
      this.startDate = moment().subtract(1, 'month').format('YYYY-MM-DD')

      this.siteId = this.currentSiteId

      await this.reload()
    },
    data: () => ({
      siteId: null,
      endDate: '2022-01-01',
      startDate: '2022-12-31',
      loading: false,
      /*siteEvaluation: {
        siteId: '1234',
        start: '2022-11-01',
        end: '2022-11-30',
        countWorkwear: 30,
        countGeneralOrganization: 12,
        countPositioning: 34,
        gradeWorkwear: 1.2,
        gradeGeneralOrganization: 2.44,
        gradePositioning: 1.65,
        inventoryDifferencePercent: 0.02,
        regularRevenue: 12455.22,
        loyaltyProgramRevenue: 2445.35,
        totalRevenue: 14800.57,
        spoilage: 129.3,
        spoilagePercent: 0.03
      }*/
    }),
    methods: {
      async reload() {
        this.loading = true

        await this.loadSiteEvaluation({ id: this.siteId, start: this.startDate, end: this.endDate })

        this.loading = false
      },
      ...mapActions({
        loadSiteEvaluation: 'evaluation/loadSiteEvaluation'
      })
    },
    name: 'SiteEvaluation',
    watch: {
      async startDate() {
        await this.reload()
      },
      async endDate() {
        await this.reload()
      }
    }
  }
</script>

<i18n>
  {
    "de": {
      "averageInvoiceSum": "⌀ Bonsumme",
      "averageProductCount": "⌀ Artikel pro Bon",
      "evaluation": "Marktbewertung {siteId}",
      "gradeGeneralOrganization": "⌀ Kundenkontakt",
      "gradePositioning": "⌀ Sortiment",
      "gradeWorkwear": "⌀ Sauberkeit & Ordnung",
      "inventoryDifference": "Inventurdifferenz",
      "loyaltyProgramRevenue": "KC-Umsatz",
      "loyaltyProgramRevenueShare": "Anteil KC-Umsatz/Gesamtumsatz",
      "ratings": "{count} Bewertungen",
      "spoilage": "Verderb",
      "spoilageShare": "Anteil Verderb/Gesamtumsatz",
      "totalGrade": "⌀ Gesamtbewertung",
      "totalRevenue": "Gesamtumsatz",
      "revenuePerWorkingHour": "Umsatz pro Mitarbeiterstunde"
    },
    "en": {
      "averageProductCount": "⌀ Product count",
      "averageInvoiceSum": "⌀ Invoice sum",
      "evaluation": "Evaluation {siteId}",
      "gradeGeneralOrganization": "⌀ Customer service",
      "gradePositioning": "⌀ Product range",
      "gradeWorkwear": "⌀ Cleanliness & order",
      "inventoryDifference": "Inventory difference",
      "loyaltyProgramRevenue": "Loyalty program revenue",
      "loyaltyProgramRevenueShare": "Loyalty program revenue share",
      "ratings": "{count} ratings",
      "spoilage": "Spoilage",
      "spoilageShare": "Spoilage share",
      "totalGrade": "Total grade",
      "totalRevenue": "Total revenue",
      "revenuePerWorkingHour": "Revenue per working hour"
    }
  }
</i18n>