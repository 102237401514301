<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <v-toolbar-title>{{ $t('orders') }}</v-toolbar-title>
      <template v-slot:extension>
        <v-text-field flat
                      hide-details="auto"
                      prepend-icon="mdi-magnify"
                      solo
                      v-bind:label="$t('search')"
                      v-model="search" />
      </template>
    </v-toolbar>
    <v-data-table v-bind:headers="headers"
                  v-bind:items="orders"
                  v-bind:search="search">
      <template v-slot:[`item.siteTitle`]="{ item }">
        {{ siteTitle(item.siteId) }}
      </template>
      <template v-slot:[`item.productTitle`]="{ item }">
        {{ productTitle(item.productId) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      headers() {
        return [
          {
            text: this.$t('siteId'),
            value: 'siteId'
          },
          {
            text: this.$t('siteName'),
            value: 'siteTitle'
          },
          {
            text: this.$t('productId'),
            value: 'productId'
          },
          {
            text: this.$t('productTitle'),
            value: 'productTitle'
          },
          {
            text: this.$t('quantity'),
            value: 'quantity'
          },
          {
            text: this.$t('unit'),
            value: 'unit'
          },
          {
            text: this.$t('personInCharge'),
            value: 'personInCharge'
          }
        ]
      },
      ...mapGetters({
        siteTitle: 'common/siteTitle',
        productTitle: 'common/productTitle'
      })
    },
    data: () => ({
      search: null
    }),
    name: 'CampaignOrdersCard',
    props: {
      orders: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "search": "Suchen…",
      "siteId": "Marktnummer",
      "siteName": "Markt",
      "orders": "Bestellungen",
      "productId": "Artikelnummer",
      "productTitle": "Artikelbezeichnung",
      "quantity": "Menge",
      "unit": "Einheit",
      "personInCharge": "Bearbeiter"
    },
    "en": {
      "search": "Search…",
      "siteId": "Site id",
      "siteName": "Site name",
      "orders": "Orders",
      "productId": "Product id",
      "productTitle": "Product title",
      "quantity": "Quantity",
      "unit": "Unit",
      "personInCharge": "Person in charge"
    }
  }
</i18n>