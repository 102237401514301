<template>
  <div>
    <v-stepper class="elevation-0"
               v-model="step">
      <v-stepper-header class="elevation-0">
        <v-stepper-step step="1"
                        v-bind:complete="step > 1">
          {{ $t('details') }}
        </v-stepper-step>
        <v-stepper-step step="2"
                        v-bind:complete="step > 2">
          {{ $t('sites') }}
        </v-stepper-step>
        <v-stepper-step step="3"
                        v-bind:complete="step > 3">
          {{ $t('products') }}
        </v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <campaign-details-step-card ref="campaignDetailsStepCard"
                                      v-bind:campaign="campaign"
                                      v-bind:next-step-label="$t('continue')"
                                      v-on:next-step="validateCampaignDetailsAndContinue" />
        </v-stepper-content>
        <v-stepper-content step="2">
          <site-selection-step-card ref="siteSelectionStepCard"
                                    v-bind:campaign="campaign"
                                    v-bind:next-step-label="$t('continue')"
                                    v-bind:previous-step-label="$t('back')"
                                    v-on:next-step="validateSiteSelectionAndContinue"
                                    v-on:previous-step="step -= 1" />
        </v-stepper-content>
        <v-stepper-content step="3">
          <campaign-products-step-card v-bind:campaign="campaign"
                                       v-bind:next-step-label="$t('complete')"
                                       v-bind:previous-step-label="$t('back')"
                                       v-on:next-step="validateCampaignProductsAndComplete"
                                       v-on:previous-step="step -= 1" />
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
  </div>
</template>

<script>
  import CampaignDetailsStepCard from '@/components/sale-management/CampaignDetailsStepCard'
  import CampaignProductsStepCard from '@/components/sale-management/CampaignProductsStepCard'
  import SiteSelectionStepCard from '@/components/sale-management/SiteSelectionStepCard'

  export default {
    components: {
      CampaignDetailsStepCard,
      CampaignProductsStepCard,
      SiteSelectionStepCard
    },
    data: () => ({
      step: 1
    }),
    methods: {
      validateCampaignDetailsAndContinue() {
        if (!this.$refs.campaignDetailsStepCard.validate()) {
          return
        }

        this.step += 1
      },
      validateCampaignProductsAndComplete() {
        if (this.campaign.products.length < 1) {
          return
        }

        this.$emit('complete')
      },
      validateSiteSelectionAndContinue() {
        if (this.campaign.sites.length < 1) {
          return
        }

        this.step += 1
      }
    },
    name: 'NewCampaignStepper',
    props: {
      campaign: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "complete": "Abschließen",
      "continue": "Weiter",
      "details": "Details",
      "products": "Artikel",
      "sites": "Märkte"
    },
    "en": {
      "back": "Back",
      "complete": "Complete",
      "continue": "Continue",
      "details": "Details",
      "products": "Products",
      "sites": "Sites"
    }
  }
</i18n>