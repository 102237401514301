<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-select clearable
                      flat
                      hide-details="auto"
                      prepend-icon="mdi-tag"
                      solo
                      v-bind:items="states"
                      v-bind:label="$t('state')"
                      v-model="stateFilter" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('preOrders') }}</v-toolbar-title>
    </v-toolbar>
    <v-data-table item-key="group"
                  show-expand
                  single-expand
                  v-bind:footer-props="{ itemsPerPageOptions: [ 25, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredPreOrders"
                  v-bind:items-per-page="25"
                  v-bind:options="{ sortBy: [ 'lastUpdate' ], sortDesc: [ true ] }">
      <template v-slot:[`item.state`]="{ item }">
        <v-icon v-bind:color="getStateColor(item.state)">mdi-circle</v-icon>
      </template>
      <template v-slot:[`item.lastUpdate`]="{ item }">
        {{ item.lastUpdate | formatDate }}
      </template><!--
      <template v-slot:[`item.state`]="{ item }">
        {{ $t(receiptStateLabel(item.state)) }}
      </template>
      <template v-slot:footer>
        <div>
          <v-btn block
                 text
                 v-on:click="$emit('load-all')">
            {{ $t('loadAll') }}
          </v-btn>
        </div>
      </template>-->
      <template v-slot:expanded-item="{ headers, item }">
        <td class="white pa-0"
            v-bind:colspan="headers.length">

          <pre-order-table-expansion v-bind:pre-order="item" />
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  import moment from 'moment'

  import PreOrderTableExpansion from '@/components/pre-order/PreOrderTableExpansion.vue'

  export default {
    components: {
      PreOrderTableExpansion
    },
    computed: {
      filteredPreOrders() {
        let filteredPreOrders = this.preOrders.map(p => ({
          ...p,
          lastUpdate: moment.max([ p.jobDate, p.invoiceDate, p.deliveryNoteDate ].filter(f => f != null).map(m => moment(m))).toISOString()
        }))

        if (this.stateFilter != null && this.stateFilter.length !== 0) {
          filteredPreOrders = filteredPreOrders.filter(f => this.stateFilter.includes(f.state))
        }

        /*if (this.siteFilter != null && this.siteFilter.length !== 0) {
          filteredReceipts = filteredReceipts.filter(f => this.siteFilter.includes(f.siteId))
        }

        if (this.stateFilter != null && this.stateFilter.length !== 0) {
          filteredReceipts = filteredReceipts.filter(f => this.stateFilter.includes(f.state))
        }

        if (this.periodFilter != null && this.periodFilter === 'Aktueller Monat') {
          filteredReceipts = filteredReceipts.filter(f => moment(f.date).get('month') === moment().get('month'))
        } else if (this.periodFilter != null && this.periodFilter === 'Aktuelles Quartal') {
          filteredReceipts = filteredReceipts.filter(f => moment(f.date).get('quarter') === moment().get('quarter'))
        } else if (this.periodFilter != null && this.periodFilter === 'Aktuelles Jahr') {
          filteredReceipts = filteredReceipts.filter(f => moment(f.date).get('year') === moment().get('year'))
        }

        if (this.manufacturerFilter != null && this.manufacturerFilter.length !== 0) {
          filteredReceipts = filteredReceipts.filter(f => this.manufacturerFilter.includes(f.manufacturerId))
        }*/

        return filteredPreOrders
      },
      headers() {
        return [
          {
            text: this.$t('state'),
            value: 'state',
            width: 96
          },
          {
            text: this.$t('productId'),
            value: 'productId'
          },
          {
            text: this.$t('productTitle'),
            value: 'productTitle'
          },
          {
            text: this.$t('quantity'),
            value: 'quantity'
          },
          {
            text: this.$t('unit'),
            value: 'unit'
          },
          {
            text: this.$t('lastUpdate'),
            value: 'lastUpdate'
          }
        ]
      },
      states() {
        return [
          {
            text: this.$t('placed'),
            value: 'placed'
          },
          {
            text: this.$t('ordered'),
            value: 'ordered'
          },
          {
            text: this.$t('delivered'),
            value: 'delivered'
          }
        ]
      }
    },
    data: () => ({
      manufacturerFilter: null,
      periodFilter: null,
      siteFilter: null,
      stateFilter: null
    }),
    methods: {
      getStateColor(state) {
        if (state === 'placed') {
          return 'deep-orange'
        } else if (state === 'ordered') {
          return 'yellow'
        } else if (state === 'delivered') {
          return 'lime'
        } else {
          return null
        }
      }
    },
    name: 'PreOrderTableCard',
    props: {
      preOrders: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "delivered": "Wareneingang",
      "lastUpdate": "Letzte Änderung",
      "placed": "Bestellungsaufgabe",
      "preOrders": "Vorbestellungen",
      "productId": "Artikelnr.",
      "productTitle": "Artikelbezeichnung",
      "ordered": "Bestellung Industrie",
      "quantity": "Menge",
      "state": "Status",
      "unit": "Einheit"
    },
    "en": {
      "delivered": "Delivered",
      "lastUpdate": "Last update",
      "placed": "Placed",
      "preOrders": "Pre orders",
      "productId": "Product ID",
      "productTitle": "Product",
      "ordered": "Ordered",
      "quantity": "Quantity",
      "state": "State",
      "unit": "Unit"
    }
  }
</i18n>