<template>
  <div>
    <v-card flat
            tile>
      <v-progress-linear color="accent"
                         indeterminate
                         v-if="loading" />
      <v-form v-model="valid">
        <v-subheader>
          {{ label }}
        </v-subheader>
        <v-card-text>
          <v-alert dismissible
                   type="error"
                   v-if="error">
            {{ errorMessage }}
          </v-alert>
          <v-alert dismissible
                   type="success"
                   v-if="success">
            {{ successMessage }}
          </v-alert>
          <v-checkbox v-bind:label="$t('deleteCurrentData')"
                      v-bind:rules="requireDeletion ? [ rules.required ] : []"
                      v-model="deleteCurrentData" />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="accent"
                 text
                 v-bind:disabled="!valid"
                 v-on:click="$emit('import', { deleteCurrentData })">
            {{ $t('import') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
    },
    data: () => ({
      deleteCurrentData: false,
      valid: false
    }),
    name: 'ImportSqlCard',
    props: {
      error: Boolean,
      errorMessage: String,
      label: String,
      loading: Boolean,
      requireDeletion: Boolean,
      success: Boolean,
      successMessage: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "deleteCurrentData": "Bestehende Daten löschen",
      "file": "Datei",
      "import": "Importieren"
    },
    "en": {
      "deleteCurrentData": "Delete current data",
      "file": "File",
      "import": "Import"
    }
  }
</i18n>