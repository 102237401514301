export default class InventoryProductService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async create(inventoryProduct, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryProduct`, {
      body: JSON.stringify(inventoryProduct),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(id, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryProduct/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(id, options) {
    try {
      const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryProduct/${id}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryProduct`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async update(id, inventoryProduct, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryProduct/${id}`, {
      body: JSON.stringify(inventoryProduct),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }

  async sync(deleteMissing, options) {
    const response = await fetch(`${this.baseUri}/inventoryManagement/inventoryProduct/import/sync?deleteMissing=${deleteMissing}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return response.ok
  }
}