<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <inventory-receipt-details ref="inventoryReceiptDetails"
                         v-bind:inventory-receipt="inventoryReceipt" />
    </v-card>
  </div>
</template>

<script>
  import InventoryReceiptDetails from '@/components/inventory-management/InventoryReceiptDetails'

  export default {
    components: {
      InventoryReceiptDetails
    },
    name: 'InventoryReceiptDetailsCard',
    methods: {
      validate() {
        return this.$refs.inventoryReceiptDetails.validate()
      }
    },
    props: {
      inventoryReceipt: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details"
    },
    "en": {
      "details": "Details"
    }
  }
</i18n>