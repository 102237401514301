<template>
  <div v-if="currentCampaign != null">
    <v-snackbar color="deep-orange"
                right
                top
                v-model="validationSnackbar">
      {{ $t('thereAreItemsThatRequireYourAttention') }}
    </v-snackbar>

    <top-header v-bind:title="currentCampaign.title | defaultValue($t('campaign'))">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'sale-management-campaign', params: { id: id } }" />
      <header-button icon="mdi-check"
                     right
                     slot="right-button"
                     v-bind:label="$t('save')"
                     v-on:click="updateCurrentCampaign" />
    </top-header>
    <v-container>
      <campaign-details-card ref="campaignDetailsCard"
                             v-bind:campaign="currentCampaign"
                             v-bind:restricted-editing="this.currentCampaign.state !== 'pending'" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import CampaignDetailsCard from '@/components/sale-management/CampaignDetailsCard.vue'

  export default {
    components: {
      CampaignDetailsCard,
      HeaderButton,
      TopHeader
    },
    computed: {
      ...mapGetters({
        campaign: 'saleManagement/campaign',
        hasRole: 'auth/hasRole'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)

      await this.get(id)
    },
    data: () => ({
      currentCampaign: null,
      id: null,
      validationSnackbar: false
    }),
    methods: {
      async get(id) {
        this.id = id

        await this.loadCampaigns()

        this.currentCampaign = Object.assign({}, this.campaign(id))
      },
      async updateCurrentCampaign() {
        if (!this.$refs.campaignDetailsCard.validate()) {
          this.validationSnackbar = true

          return
        }

        await this.updateCampaign({ campaign: this.currentCampaign })

        this.$router.push({ name: 'sale-management-campaign', params: { id: this.id } })
      },
      ...mapActions({
        loadCampaigns: 'saleManagement/loadCampaigns',
        updateCampaign: 'saleManagement/updateCampaign'
      })
    },
    name: 'CampaignEdit'
  }
</script>

<i18n>
  {
    "de": {
      "back": "Zurück",
      "campaign": "Kampagne",
      "dashboard": "Dashboard",
      "save": "Speichern",
      "thereAreItemsThatRequireYourAttention": "Es gibt Felder, die Ihre Aufmerksamkeit erfordern."
    },
    "en": {
      "back": "Back",
      "campaign": "Campaign",
      "dashboard": "Dashboard",
      "save": "Save",
      "thereAreItemsThatRequireYourAttention": "There are items that require your attention."
    }
  }
</i18n>