export default class EventDateService {
  constructor(baseUri) {
    this.baseUri = baseUri
  }

  async create(eventDate, options) {
    const response = await fetch(`${this.baseUri}/booking/eventDate`, {
      body: JSON.stringify(eventDate),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'POST'
    })

    return await response.json()
  }

  async delete(id, options) {
    const response = await fetch(`${this.baseUri}/booking/eventDate/${id}`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      },
      method: 'DELETE'
    })

    return response.ok
  }

  async find(id, options) {
    try {
      const response = await fetch(`${this.baseUri}/booking/eventDate/${id}`, {
        headers: {
          'Authorization': `Bearer ${options.accessToken}`
        }
      })

      return await response.json()
    } catch (e) {
      return null
    }
  }

  async getAll(options) {
    const response = await fetch(`${this.baseUri}/booking/eventDate`, {
      headers: {
        'Authorization': `Bearer ${options.accessToken}`
      }
    })

    return await response.json()
  }

  async update(id, eventDate, options) {
    const response = await fetch(`${this.baseUri}/booking/eventDate/${id}`, {
      body: JSON.stringify(eventDate),
      headers: {
        'Authorization': `Bearer ${options.accessToken}`,
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    })

    return response.ok
  }
}