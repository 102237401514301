<template>
  <div>
    <top-header v-bind:title="$t('examsFor', { name })" />

    <v-progress-linear indeterminate
                       v-bind:active="loading" />

    <v-container>
      <exam-list-card v-bind:exams="exams"
                      v-bind:invitation-key="invitationKey" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import ExamListCard from '@/components/learning/ExamListCard'

  export default {
    async beforeRouteUpdate(to, from, next) {
      const invitationKey = to.params.invitationKey

      await this.get(invitationKey)

      next()
    },
    components: {
      ExamListCard,
      TopHeader
    },
    computed: {
      name() {
        if (this.trainee == null) {
          return null
        }

        return `${this.trainee.givenName} ${this.trainee.familyName}`
      },
      trainee() {
        if (this.exams == null || this.exams.length === 0) {
          return null
        }

        return this.exams[0].trainees.find(t => t.trainee.invitationKey === this.invitationKey)?.trainee
      },
      ...mapGetters({
        examsForInvitationKey: 'learning/examsForInvitationKey'
      })
    },
    async created() {
      const invitationKey = this.$route.params.invitationKey

      await this.get(invitationKey)
    },
    data: () => ({
      exams: [],
      invitationKey: null,
      loading: false
    }),
    methods: {
      async get(invitationKey) {
        this.invitationKey = invitationKey

        this.loading = true

        await this.loadExamsForInvitationKey({ invitationKey })

        this.loading = false

        this.exams = [ ...this.examsForInvitationKey(invitationKey) ]
      },
      ...mapActions({
        loadExamsForInvitationKey: 'learning/loadExamsForInvitationKey'
      })
    },
    name: 'MyExams'
  }
</script>

<i18n>
  {
    "de": {
      "examsFor": "Prüfungen für {name}"
    },
    "en": {
      "examsFor": "Exams for {name}"
    }
  }
</i18n>