<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('meters') }}</v-toolbar-title>
      </v-toolbar>
      <template v-for="(meter, index) in siteMeters">
        <v-card-text v-bind:key="`${meter.id}-card_text`">
          <v-row align="center">
            <v-col>
              <v-alert class="mb-0"
                       type="success"
                       v-if="meter.updated">
                {{ $t('theNewMeterReadingHasBeenSavedSuccessfully') }}
              </v-alert>
              <v-row v-else>
                <v-col cols="12"
                      md="3">
                  <strong>{{ meter.text }}</strong>
                </v-col>
                <v-col cols="12"
                      md="3">
                  {{ $t('number') }}: {{ meter.number }}<br>
                  {{ $t('previousValue') }}: {{ meter.previousValue | formatNumber({ fractionDigits: 3 }) }} {{ meter.unit  }}<br>
                  {{ $t('nextEntryDate') }}: {{ meter.nextEntryDate | formatDate('L') }}
                </v-col>
                <v-col cols="12"
                      md="3">
                  <date-field outlined
                              v-bind:disabled="!meter.entryAllowed"
                              v-bind:label="$t('date')"
                              v-bind:rules="[ rules.required ]"
                              v-model="date" />
                </v-col>
                <v-col cols="12"
                      md="3">
                  <v-text-field outlined
                                type="number"
                                hide-details="auto"
                                v-bind:disabled="!meter.entryAllowed"
                                v-bind:label="$t('value')"
                                v-bind:suffix="meter.unit"
                                v-bind:rules="[ rules.required ]"
                                v-model.number="meter.value" />
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="auto">
              <v-btn color="accent"
                     icon
                     v-bind:disabled="meter.updated || !meter.value || !date"
                     v-on:click="$emit('add-meter-value', { siteMeterId: meter.id, value: meter.value, date: date })">
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider v-bind:key="`${meter.id}-divider`"
                   v-if="index !== siteMeters.length - 1" />
      </template>
    </v-card>
  </div>
</template>

<script>
  import DateField from '@/components/common/DateField.vue'

  export default {
    components: {
      DateField
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    data: () => ({
      date: null
    }),
    name: 'MeterDataEntryCard',
    methods: {

    },
    props: {
      siteMeters: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "meters": "Zähler",
      "number": "Nummer",
      "previousValue": "Letzter Stand",
      "value": "Stand",
      "date": "Datum",
      "theNewMeterReadingHasBeenSavedSuccessfully": "Der neue Zählerstand wurde erfolgreich gespeichert.",
      "nextEntryDate": "Nächste Ablesung"
    },
    "en": {
      "meters": "Meters",
      "number": "Number",
      "previousValue": "Previous value",
      "value": "Value",
      "date": "Date",
      "theNewMeterReadingHasBeenSavedSuccessfully": "The new meter reading has been saved successfully.",
      "nextEntryDate": "Next entry date"
    }
  }
</i18n>