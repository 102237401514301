<template>
  <div>
    <top-header v-bind:title="$t('dataEntry')" />
    <v-progress-linear indeterminate
                       v-bind:active="loading" />
    <v-container>
      <v-row>
        <v-col>
          <meter-data-entry-card v-bind:site-meters="meters"
                                 v-on:add-meter-value="addMeterValue" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import MeterDataEntryCard from '@/components/meter-management/MeterDataEntryCard'

  export default {
    components: {
      TopHeader,
      MeterDataEntryCard
    },
    computed: {
      ...mapGetters({
        siteId: 'common/currentSiteId',
        siteMeters: 'meterManagement/siteMeters'
      })
    },
    async created() {
      this.loading = true

      await this.loadSiteMeters()

      this.meters = [...this.siteMeters].filter(m => m.siteId === this.siteId).map(m => ({
        ...m,
        updated: false
      }))

      this.loading = false
    },
    data: () => ({
      loading: false,
      meters: []
    }),
    methods: {
      async addMeterValue(meterValue) {
        await this.createSiteMeterValue({ siteMeterValue: meterValue })

        const index = this.meters.findIndex(m => m.id === meterValue.siteMeterId)

        if (index === -1) {
          return
        }

        this.meters[index].updated = true
      },
      ...mapActions({
        createSiteMeterValue: 'meterManagement/createSiteMeterValue',
        loadSiteMeters: 'meterManagement/loadSiteMeters'
      })
    },
    name: 'MeterDataEntry'
  }
</script>

<i18n>
  {
    "de": {
      "dataEntry": "Datenerfassung"
    },
    "en": {
      "dataEntry": "Data entry"
    }
  }
</i18n>