import Vue from 'vue'

import ReceiptService from '../api/free-product-accounting/receipt-service'

import settings from '../settings'

const receiptService = new ReceiptService(settings.apiUri)

export default {
  actions: {
    // receipt
    // =======
    async createReceipt({ commit, rootGetters }, { receipt }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await receiptService.create(receipt, { accessToken })

      receipt.date = result.date
      receipt.id = result.id
      receipt.state = result.state

      commit('createReceipt', { receipt })
    },
    async deleteReceipt({ commit, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await receiptService.delete(id, { accessToken })

      if (result) {
        commit('deleteReceipt', { id })
      }
    },
    async exportReceiptProducts({ commit, getters, rootGetters }, { setAsCompleted, all }) {
      const accessToken = rootGetters['auth/accessToken']

      return await receiptService.exportProducts(setAsCompleted, all, { accessToken })
    },
    async loadReceipt({ commit, getters, rootGetters }, { id }) {
      const accessToken = rootGetters['auth/accessToken']

      const receipt = await receiptService.find(id, { accessToken })

      if (getters.receipt(id) != null) {
        commit('updateReceipt', { receipt })
      } else {
        commit('createReceipt', { receipt })
      }
    },
    async loadReceipts({ commit, getters, rootGetters }, params) {
      params = params || { all: false}

      const accessToken = rootGetters['auth/accessToken']

      const receipts = await receiptService.getAll(params.all, { accessToken })

      commit('setReceipts', { receipts })
    },
    async setReceiptState({ dispatch, getters }, { id, state }) {
      const receipt = { ...getters.receipt(id) }

      if (receipt == null) {
        return
      }

      receipt.state = state

      await dispatch('updateReceipt', { receipt })
    },
    async updateReceipt({ commit, rootGetters }, { receipt }) {
      const accessToken = rootGetters['auth/accessToken']

      const result = await receiptService.update(receipt.id, receipt, { accessToken })

      if (result) {
        commit('updateReceipt', { receipt })
      }
    }
  },
  getters: {
    reasons: state => state.reasons,
    reasonLabel: state => value => state.reasons.find(r => r.value === value)?.label,
    receipt: state => id => state.receipts.find(r => r.id === id),
    receipts: state => state.receipts,
    receiptStates: state => state.receiptStates,
    receiptStateLabel: state => value => state.receiptStates.find(r => r.value === value)?.label,
    refundTypes: state => state.refundTypes,
    refundTypeLabel: state => value => state.refundTypes.find(r => r.value === value)?.label
  },
  mutations: {
    // receipt
    // =======
    createReceipt(state, { receipt }) {
      state.receipts.push(receipt)
    },
    deleteReceipt(state, { id }) {
      const index = state.receipts.findIndex(r => r.id === id)

      if (index !== -1) {
        state.receipts.splice(index, 1)
      }
    },
    setReceipts(state, { receipts }) {
      state.receipts = receipts
    },
    updateReceipt(state, { receipt }) {
      const index = state.receipts.findIndex(r => r.id === receipt.id)

      Vue.set(state.receipts, index, receipt)
    }
  },
  namespaced: true,
  state: {
    reasons: [
      {
        label: 'global.breakage',
        value: 'breakage'
      },
      {
        label: 'global.newProduct',
        value: 'newProduct'
      },
      {
        label: 'global.replacement',
        value: 'replacement'
      },
      {
        label: 'global.siteCampaign',
        value: 'siteCampaign'
      }
    ],
    receipts: [],
    receiptStates: [
      {
        label: 'global.canceled',
        value: 'canceled'
      },
      {
        label: 'global.draft',
        value: 'draft'
      },
      {
        label: 'global.open',
        value: 'open'
      },
      {
        label: 'global.completed',
        value: 'completed'
      }
    ],
    refundTypes: [
      {
        label: 'global.delivery',
        value: 'delivery'
      },
      {
        label: 'global.refund',
        value: 'refund'
      }
    ]
  }
}