<template>
  <div>
    <top-header v-bind:title="$t('transactions')">
      <header-button icon="mdi-chevron-left"
                     slot="left-button"
                     v-bind:label="$t('back')"
                     v-bind:to="{ name: 'inventory-management-inventory-receipt', params: { inventoryId: inventoryId, id: inventoryReceiptId } }" />
      <!--<header-button icon="mdi-plus-box"
                     right
                     slot="right-button"
                     v-bind:label="$t('add')"
                     v-bind:to="{ name: 'inventory-management-inventory-add' }" />-->
    </top-header>
    <v-container>
      <v-row>
        <v-col>
          <inventory-transactions-table-card item-route-name="inventory-management-inventory-transaction"
                                             v-bind:inventory-transactions="filteredInventoryTransactions"
                                             v-bind:loading="loading"
                                             v-bind:routeParams="{ inventoryId, inventoryReceiptId }" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import TopHeader from '@/components/common/TopHeader.vue'
  import HeaderButton from '@/components/common/HeaderButton.vue'
  import InventoryTransactionsTableCard from '@/components/inventory-management/InventoryTransactionsTableCard.vue'

  export default {
    async beforeRouteUpdate(to, _, next) {
      const id = parseInt(to.params.id)
      const inventoryId = parseInt(to.params.inventoryId)
      const inventoryReceiptId = to.params.inventoryReceiptId

      await this.get(id, inventoryId, inventoryReceiptId)

      next()
    },
    components: {
      TopHeader,
      HeaderButton,
      InventoryTransactionsTableCard
    },
    computed: {
      filteredInventoryTransactions() {
        return this.inventoryTransactions.filter(t => t.inventoryPositionInventoryReceiptId === this.inventoryReceiptId)
      },
      ...mapGetters({ 
        hasRole: 'auth/hasRole',
        inventoryTransactions: 'inventoryManagement/inventoryTransactions'
      })
    },
    async created() {
      const id = parseInt(this.$route.params.id)
      const inventoryId = parseInt(this.$route.params.inventoryId)
      const inventoryReceiptId = this.$route.params.inventoryReceiptId

      await this.get(id, inventoryId, inventoryReceiptId)
    },
    data: () => ({
      loading: false,
      inventoryId: null,
      inventoryReceiptId: null
    }),
    methods: {
      async get(id, inventoryId, inventoryReceiptId) {
        this.inventoryId = inventoryId
        this.inventoryReceiptId = inventoryReceiptId

        this.loading = true
        
        await this.loadInventoryTransactionForReceipt({ receiptId: inventoryReceiptId })

        this.loading = false
      },
      ...mapActions({
        loadInventoryTransactionForReceipt: 'inventoryManagement/loadInventoryTransactionForReceipt'
      })
    },
    name: 'InventoryTransactions'
  }
</script>

<i18n>
  {
    "de": {
      "transactions": "Buchungen",
      "add": "Neu",
      "back": "Zurück"
    },
    "en": {
      "transactions": "Transactions",
      "add": "Add",
      "back": "Back"
    }
  }
</i18n>