<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('details') }}</v-toolbar-title>
      </v-toolbar>
      <exam-details ref="examDetails"
                    v-bind:exam="exam" />
    </v-card>
  </div>
</template>

<script>
  import ExamDetails from '@/components/learning/ExamDetails'

  export default {
    components: {
      ExamDetails
    },
    name: 'ExamDetailsCard',
    methods: {
      validate() {
        return this.$refs.examDetails.validate()
      }
    },
    props: {
      exam: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "details": "Details"
    },
    "en": {
      "details": "Details"
    }
  }
</i18n>