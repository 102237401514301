<template>
  <v-form ref="form">
    <v-card-text>
      <v-row>
        <v-col>
          <v-text-field disabled
                        filled
                        hide-details="auto"
                        type="number"
                        v-bind:label="$t('number')"
                        v-model="inventory.id" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        type="number"
                        v-bind:label="$t('inventoryNumber')"
                        v-model="inventory.inventoryNumber" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select filled
                    hide-details="auto"
                    v-bind:items="tenantItems"
                    v-bind:label="$t('tenant')"
                    v-bind:rules="[ rules.required ]"
                    v-model="inventory.tenantId" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        type="number"
                        v-bind:label="$t('type')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventory.inventoryType" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-select filled
                    hide-details="auto"
                    v-bind:items="warehouseItems"
                    v-bind:label="$t('warehouse')"
                    v-bind:rules="[ rules.required ]"
                    v-model="inventory.warehouseId" />
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <date-time-field filled
                           hide-details="auto"
                           v-bind:label="$t('dateOfCounting')"
                           v-bind:rules="[ rules.required ]"
                           v-model="inventory.dateOfCounting" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider />
    <v-card-text>
      <v-row>
        <v-col>
          <date-time-field disabled
                           filled
                           hide-details="auto"
                           v-bind:label="$t('createdAt')"
                           v-model="inventory.createdAt" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('createdBy')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventory.createdBy" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <date-time-field disabled
                           filled
                           hide-details="auto"
                           v-bind:label="$t('modifiedAt')"
                           v-model="inventory.modifiedAt" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field filled
                        hide-details="auto"
                        v-bind:label="$t('modifiedBy')"
                        v-bind:rules="[ rules.required ]"
                        v-model="inventory.modifiedBy" />
        </v-col>
      </v-row>
      
    </v-card-text>
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DateTimeField from '@/components/common/DateTimeField'

  export default {
    components: {
      DateTimeField
    },
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      tenantItems() {
        return this.tenants.map(t => ({
          text: t.name,
          value: t.id
        }))
      },
      warehouseItems() {
        return this.warehouses.map(w => ({
          text: w.name,
          value: w.id
        }))
      },
      ...mapGetters({
        tenants: 'common/tenants',
        warehouses: 'common/warehouses'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'InventoryDetails',
    props: {
      inventory: Object
    }
  }
</script>

<i18n>
  {
    "de": {
      "number": "Nummer",
      "inventoryNumber": "Inventurnummer",
      "tenant": "Mandant",
      "type": "Art",
      "warehouse": "Lager",
      "dateOfCounting": "Zählungsdatum",
      "createdAt": "Erstellt am",
      "createdBy": "Erstellt von",
      "modifiedAt": "Geändert am",
      "modifiedBy": "Geändert von"
    },
    "en": {
      "number": "Number",
      "inventoryNumber": "Inventory number",
      "tenant": "Tenant",
      "type": "Type",
      "warehouse": "Warehouse",
      "dateOfCounting": "Date of counting",
      "createdAt": "Created at",
      "createdBy": "Created by",
      "modifiedAt": "Modified at",
      "modifiedBy": "Modified by"
    }
  }
</i18n>