<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('categories') }}</v-toolbar-title>
      </v-toolbar>
      <v-expansion-panels accordion
                          flat
                          tile>
        <v-expansion-panel v-bind:key="index"
                           v-for="(category, index) in categories">
          <v-expansion-panel-header>
            <strong>{{ category }}</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <public-event-category-view v-bind:category="category"
                                        v-bind:sub-categories="subCategoriesFor(category)" />
          </v-expansion-panel-content>
          <v-divider />
        </v-expansion-panel>
      </v-expansion-panels>
      <v-card-text class="text-center text--disabled"
                   v-if="categories.length === 0">
        {{ $t('thereAreCurrentlyNoBookableEvents') }}
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import PublicEventCategoryView from '@/components/booking/PublicEventCategoryView.vue'

  export default {
    components: {
      PublicEventCategoryView
    },
    computed: {
      categories() {
        return [...new Set(this.filteredEvents.map(e => e.category))].sort((a, b) => a.localeCompare(b))
      },
      filteredEvents() {
        return this.events.filter(e => e.hasBookableDates)
      },
      ...mapGetters({
      })
    },
    methods: {
      subCategoriesFor(category) {
        return [...new Set(this.filteredEvents.filter(e => e.category === category).map(e => e.subCategory))].sort((a, b) => a.localeCompare(b))
      }
    },
    name: 'PublicEventCategoryOverview',
    props: {
      events: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "categories": "Kategorien",
      "thereAreCurrentlyNoBookableEvents": "Momentan sind keine buchbaren Seminare vorhanden"
    },
    "en": {
      "thereAreCurrentlyNoBookableEvents": "There are currently no bookable events"
    }
  }
</i18n>