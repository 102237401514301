<template>
  <div>
    <v-card-text>
      <line-chart v-bind:data="data"
                  v-bind:options="options" />
    </v-card-text>
  </div>
</template>

<script>
  import { Chart as ChartJS, TimeScale, BarElement, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js'
  import { Line as LineChart } from 'vue-chartjs'
  import { Bar as BarChart } from 'vue-chartjs'
  import 'chartjs-adapter-moment'

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, TimeScale, Legend, BarElement)

  export default {
    components: {
      LineChart,
      BarChart
    },
    computed: {
      differenceValues() {
        return this.sortedValues.map((v, i) => {
          if (i === 0) {
            return {
              ...v,
              difference: 0
            }
          }

          return {
            ...v,
            difference: v.value - this.siteMeterValues[i - 1].value
          }
        })
      },
      sortedValues() {
        let values = this.siteMeterValues.sort((a, b) => a.date < b.date ? -1 : 1)

        // return only last 10 values
        if (values.length > 10) {
          values = values.slice(values.length - 10, values.length)
        }

        return values
      },
      data() {
        return {
          labels: this.differenceValues.map(s => s.date),
          datasets: [
            {
              type: 'line',
              backgroundColor: '#e3000a',
              borderColor: '#e3000a',
              data: this.differenceValues.map(r => r.value),
              label: this.$t('value')
            },
            {
              type: 'bar',
              backgroundColor: '#113e7c',
              borderColor: '#113e7c',
              data: this.differenceValues.map(r => r.difference),
              label: this.$t('cumulative')
            }
          ]
        }
      },
      options() {
        return {
          plugins: {
            legend: {
              display: true,
              position: 'bottom'
            }
          },
          responsive: true,
          scales: {
            x: {

            },
            y: {
              min: 0,
              ticks: {
                callback: v => this.$options.filters.formatNumber(v, { fractionDigits: 3, suffix: this.unit  })
              }
            }
          }
        }
      },
      unit() {
        return this.siteMeterValues?.[0]?.unit ?? ''
      },
    },
    data: () => ({
    }),
    name: 'MeterValuesDetails',
    methods: {

    },
    props: {
      siteMeterValues: Array
    }
  }
</script>

<i18n>
  {
    "de": {
      "value": "Zählerstand",
      "cumulative": "Kumuliert"
    },
    "en": {
      "value": "Value",
      "cumulative": "Cumulative"
    }
  }
</i18n>