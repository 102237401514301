<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-select flat
                      hide-details="auto"
                      prepend-icon="mdi-tag"
                      solo
                      v-bind:items="groups"
                      v-bind:label="$t('group')"
                      v-bind:value="groupFilter"
                      v-on:change="$emit('change-group', $event)" />
          </v-col>
          <v-col>
            <v-select flat
                      hide-details="auto"
                      prepend-icon="mdi-glass-wine"
                      solo
                      v-bind:items="kinds"
                      v-bind:label="$t('kind')"
                      v-model="kindFilter" />
          </v-col>
          <v-col>
            <v-text-field clearable
                          flat
                          hide-details="auto"
                          prepend-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
        </v-row>
      </template>
      <v-toolbar-title>{{ $t('products') }}</v-toolbar-title>
    </v-toolbar>
    <v-data-table item-key="productId"
                  v-bind:footer-props="{ itemsPerPageOptions: [ 25, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredProducts"
                  v-bind:items-per-page="25"
                  v-bind:options="{ sortBy: [ 'dailyRange' ], sortDesc: [ true ] }">
      <template v-slot:[`item.stockDefaultUnit`]="{ item }">
        {{ item.stockDefaultUnit | formatNumber }}
      </template>
      <template v-slot:[`item.salesDefaultUnit`]="{ item }">
        {{ item.salesDefaultUnit | formatNumber }}
      </template>
      <template v-slot:[`item.dailyConsumption`]="{ item }">
        {{ item.dailyConsumption | formatNumber }}
      </template>
      <template v-slot:[`item.dailyRange`]="{ item }">
        {{ item.dailyRange | formatNumber }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    computed: {
      filteredProducts() {
        let filteredProducts = this.products || []

        if (this.groupFilter != null && this.groupFilter.length !== 0) {
          filteredProducts = filteredProducts.filter(f => this.groupFilter.includes(f.group))
        }

        if (this.kindFilter != null && this.kindFilter.length !== 0) {
          filteredProducts = filteredProducts.filter(f => this.kindFilter.includes(f.kind))
        }

        if (this.search != null && this.search.length !== 0) {
          filteredProducts = filteredProducts.filter(f => f.productTitle.toLowerCase().includes(this.search.toLowerCase()))
        }

        return filteredProducts
      },
      headers() {
        return [
          {
            text: this.$t('productId'),
            value: 'productId'
          },
          {
            text: this.$t('productTitle'),
            value: 'productTitle'
          },
          {
            align: 'right',
            text: this.$t('stockDefaultUnit'),
            value: 'stockDefaultUnit'
          },
          {
            align: 'right',
            text: this.$t('salesDefaultUnit'),
            value: 'salesDefaultUnit'
          },
          {
            align: 'right',
            text: this.$t('dailyConsumption'),
            value: 'dailyConsumption'
          },
          {
            align: 'right',
            text: this.$t('dailyRange'),
            value: 'dailyRange'
          }
        ]
      },
      ...mapGetters({
        groups: 'productPerformance/groups',
        kinds: 'productPerformance/kinds'
      })
    },
    data: () => ({
      search: null,
      kindFilter: null
    }),
    name: 'ProductPerformanceTableCard',
    props: {
      products: Array,
      groupFilter: String
    }
  }
</script>

<i18n>
  {
    "de": {
      "dailyConsumption": "Tagesverbrauch",
      "dailyRange": "Reichweite in Tagen",
      "group": "Gruppe",
      "inventoryStock": "Bestand",
      "products": "Artikel",
      "productId": "Artikelnr.",
      "productTitle": "Artikelbezeichnung",
      "quantity": "Menge",
      "salesDefaultUnit": "Verkauf GE",
      "search": "Suche",
      "stockDefaultUnit": "Bestand GE",
      "kind": "Sorte"
    },
    "en": {
      "dailyConsumption": "Daily consumption",
      "dailyRange": "Daily range",
      "group": "Group",
      "inventoryStock": "Inventory Stock",
      "products": "Products",
      "productId": "Product ID",
      "productTitle": "Product",
      "quantity": "Quantity",
      "salesDefaultUnit": "Sales default unit",
      "search": "Search",
      "stockDefaultUnit": "Stock default unit",
      "kind": "Kind"
    }
  }
</i18n>