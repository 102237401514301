<template>
  <v-card class="fill-height"
          elevation="0"
          tile
          v-bind:color="color"
          v-bind:dark="dark">
    <v-card-text>
      <div class="mb-1 text-center text-h6">
        <span>{{ title }}</span>
      </div>
      <div v-bind:class="{ 'text-center': true, 'display-1': true, 'primary--text': !dark, 'white--text': dark }">
        <span>{{ value }}</span>
      </div>
      <div class="mt-1 text-center text-body-1"
           v-if="subTitle != null && subTitle.length !== 0">
        <span>{{ subTitle }}</span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'IconDashboardCard',
    props: {
      color: String,
      dark: Boolean,
      icon: String,
      subTitle: String,
      title: String,
      to: Object,
      value: String
    }
  }
</script>