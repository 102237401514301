<template>
  <v-card flat
          tile>
    <v-toolbar color="secondary"
               dark
               flat>
      <v-toolbar-title>{{ $t('sales') }}</v-toolbar-title>
      <template v-slot:extension>
        <v-row>
          <v-col>
            <v-text-field flat
                          hide-details
                          prepend-icon="mdi-magnify"
                          solo
                          v-bind:label="$t('search')"
                          v-model="search" />
          </v-col>
        </v-row>
      </template>
    </v-toolbar>
    <v-data-table v-bind:footer-props="{ itemsPerPageOptions: [ 5, 10, 30, 50, 100, -1 ] }"
                  v-bind:headers="headers"
                  v-bind:items="filteredSales"
                  v-bind:items-per-page="50"
                  v-bind:options="{ sortBy: [ 'productId' ] }">
      <template v-slot:item.note="props">
        <v-edit-dialog v-bind:return-value.sync="props.item.saleText"
        large
          persistent
          v-bind:save-text="$t('save')" v-bind:cancel-text="$t('cancel')">
          <div><v-icon>mdi-pencil</v-icon></div>
          <template v-slot:input>
            <v-textarea
              v-model="props.item.saleText"
              hide-details="auto"
              solo
              flat
              autofocus />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:item.count="props">
        <v-edit-dialog v-bind:return-value.sync="props.item.count">
          <div>{{ props.item.count }}</div>
          <template v-slot:input>
            <v-text-field
              v-model.number="props.item.count"
              type="number"
              hide-details="auto"
              solo
              flat
              autofocus />
          </template>
        </v-edit-dialog>
      </template>
      <template v-slot:[`item.price`]="{ item }">
        {{ item.price | formatCurrencyValue }}     
      </template>
      <template v-slot:[`item.start`]="{ item }">
        {{ item.start | formatDate('L') }}     
      </template>
      <template v-slot:[`item.end`]="{ item }">
        {{ item.end | formatDate('L') }}     
      </template>
    </v-data-table>
    <v-divider />
    <v-card-actions>
      <v-spacer />
      <v-btn color="accent"
             text
             v-on:click="$emit('print')"
             v-bind:loading="loading">
        {{ $t('print') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    components: {
      
    },
    computed: {
      filteredSales() {
        let filteredSales = this.saleProducts

        if (this.search != null && this.search.length !== 0) {
          filteredSales = filteredSales.filter(f => `${f.productTitle.toLowerCase()} ${f.productId}`.includes(this.search.toLowerCase()))
        }

        return filteredSales
      },
      headers() {
        return [
          {
            text: this.$t('count'),
            value: 'count'
          },
          {
            text: this.$t('number'),
            value: 'productId'
          },
          {
            text: this.$t('title'),
            value: 'productTitle'
          },
          {
            text: this.$t('price'),
            value: 'price'
          },
          {
            text: this.$t('unit'),
            value: 'unit'
          },
          {
            text: this.$t('start'),
            value: 'start'
          },
          {
            text: this.$t('end'),
            value: 'end'
          },
          {
            sortable: false,
            value: 'note',
            width: '96px'
          },
        ]
      },
      ...mapGetters({
        siteId: 'common/currentSiteId'
      })
    },
    data: () => ({
      search: null
    }),
    methods: {
    },
    name: 'SaleProductTableCard',
    props: {
      saleProducts: Array,
      loading: Boolean
    }
  }
</script>

<style>
  .count-field {
    width: 96px;
  }
</style>

<i18n>
  {
    "de": {
      "sales": "Aktionen",
      "number": "Nummer",
      "title": "Bezeichnung",
      "print": "Drucken",
      "count": "Anzahl",
      "price": "Preis",
      "start": "Start",
      "end": "Ende",
      "unit": "Einheit",
      "storageFactor": "Lagerfaktor",
      "search": "Suche",
      "save": "Speichern",
      "cancel": "Abbrechen"
    },
    "en": {
      "sales": "Sales",
      "number": "Number",
      "title": "Title",
      "print": "Print",
      "count": "Count",
      "price": "Price",
      "start": "Start",
      "end": "End",
      "unit": "Unit",
      "storageFactor": "Storage factor",
      "search": "Search",
      "save": "Save",
      "cancel": "Cancel"
    }
  }
</i18n>