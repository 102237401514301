<template>
  <div>
    <v-card flat
            tile>
      <v-toolbar color="secondary"
                 dark
                 flat>
        <v-toolbar-title>{{ $t('invitation') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <p>{{ $t('welcomeToOurLearningPortalHereYouWillFindAllTheDocumentsYouNeedToPrepareSuccessfullyForYourUpcomingExamsWhichYouCanThenCompleteDirectlyAndDigitallyWithinTheSpecifiedExamPeriodToAccessYourExamsPleaseEnterYourPersonalInvitationKey') }}</p>
        <v-form ref="form">
          <v-text-field filled
                        hide-details="auto"
                        placeholder="XXXX-XXXX-XXXX-XXXX"
                        v-bind:label="$t('invitationKey')"
                        v-bind:rules="[ rules.required ]"
                        v-mask="'NNNN-NNNN-NNNN-NNNN'"
                        v-model="invitationKey" />
        </v-form>
        <v-expand-transition>
          <p v-if="error"
            class="error--text mb-0 mt-4">
            {{ $t('theInvitationKeyYouEnteredIsInvalidOrNoExamsHaveBeenRegisteredForYouYetPleaseCheckTheExactSpellingOfTheInvitationKey') }}
          </p>
        </v-expand-transition>
      </v-card-text>
      <v-divider />
      <v-card-actions>
        <v-spacer />
        <v-btn color="accent"
               text
               v-on:click="$emit('sign-in', invitationKey)">
          {{ $t('signIn') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
  export default {
    computed: {
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      }
    },
    data: () => ({
      invitationKey: null
    }),
    name: 'InvitationCodeCard',
    props: {
      error: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "invitation": "Einladung",
      "invitationKey": "Einladungsschlüssel",
      "signIn": "Anmelden",
      "theInvitationKeyYouEnteredIsInvalidOrNoExamsHaveBeenRegisteredForYouYetPleaseCheckTheExactSpellingOfTheInvitationKey": "Der von Ihnen eingegebene Einladungsschlüssel ist ungültig oder es wurden noch keine Prüfungen für Sie hinterlegt. Bitte kontrollieren Sie die genaue Schreibweise des Einladungsschlüssels.",
      "welcomeToOurLearningPortalHereYouWillFindAllTheDocumentsYouNeedToPrepareSuccessfullyForYourUpcomingExamsWhichYouCanThenCompleteDirectlyAndDigitallyWithinTheSpecifiedExamPeriodToAccessYourExamsPleaseEnterYourPersonalInvitationKey": "Herzlich willkommen in unserem Unterweisungsportal. Hier finden Sie alle Unterlagen, um sich perfekt auf bevorstehende Prüfungen vorbereiten zu können und können diese innerhalb des jeweiligen Prüfungszeitraumes direkt digital absolvieren. Um Zugang zu Ihren Prüfungen zu gelangen, geben Sie bitte Ihren persönlichen Einladungsschlüssel ein."
    },
    "en": {
      "invitation": "Invitation",
      "invitationKey": "Invitation key",
      "signIn": "Sign in",
      "theInvitationKeyYouEnteredIsInvalidOrNoExamsHaveBeenRegisteredForYouYetPleaseCheckTheExactSpellingOfTheInvitationKey": "The invitation key you entered is invalid or no exams have been registered for you yet. Please check the exact spelling of the invitation key.",
      "welcomeToOurLearningPortalHereYouWillFindAllTheDocumentsYouNeedToPrepareSuccessfullyForYourUpcomingExamsWhichYouCanThenCompleteDirectlyAndDigitallyWithinTheSpecifiedExamPeriodToAccessYourExamsPleaseEnterYourPersonalInvitationKey": "Welcome to our learning portal. Here you will find all the documents you need to prepare successfully for your upcoming exams, which you can then complete directly and digitally within the specified exam period. To access your exams, please enter your personal invitation key."
    }
  }
</i18n>