<template>
  <div>
    <top-header v-bind:title="campaign.title | defaultValue($t('newCampaign'))">
      <header-button icon="mdi-close"
                     slot="left-button"
                     v-bind:label="$t('cancel')"
                     v-bind:to="{ name: 'sale-management-campaigns' }" />
    </top-header>
    <v-container>
      <new-campaign-stepper v-bind:campaign="campaign"
                            v-on:complete="saveCampaign" />
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import HeaderButton from '@/components/common/HeaderButton.vue'
  import TopHeader from '@/components/common/TopHeader.vue'
  import NewCampaignStepper from '@/components/sale-management/NewCampaignStepper'

  export default {
    components: {
      HeaderButton,
      NewCampaignStepper,
      TopHeader
    },
    computed: {
      ...mapGetters({
        getCampaign: 'saleManagement/campaign',
        sites: 'common/sites'
      })
    },
    async created() {
      await this.loadCampaigns()
      await this.loadManufacturers()
      await this.loadProducts()
      await this.loadSites()

      if (this.$route.query?.from != null) {
        const fromId = parseInt(this.$route.query.from)

        await this.get(fromId)
      }
    },
    data: () => ({
      campaign: {
        products: [],
        sites: []
      }
    }),
    methods: {
      async get(id) {
        this.campaign = Object.assign({}, this.getCampaign(id))
        delete this.campaign.id
        delete this.campaign.state
        this.campaign.products = this.campaign.products.map(p => ({
          discount: p.discount,
          productId: p.productId,
          unit: p.unit
        }))
        this.campaign.sites = []
      },
      saveCampaign() {
        const sites = this.campaign.sites.map(s => ({
          siteId: s.id
        }))

        this.campaign.sites = sites

        this.createCampaign({ campaign: this.campaign })

        this.$router.push({ name: 'sale-management-campaigns' })
      },
      ...mapActions({
        createCampaign: 'saleManagement/createCampaign',
        loadCampaigns: 'saleManagement/loadCampaigns',
        loadManufacturers: 'common/loadManufacturers',
        loadProducts: 'common/loadProducts',
        loadSites: 'common/loadSites'
      })
    },
    name: 'CampaignAdd'
  }
</script>

<i18n>
  {
    "de": {
      "cancel": "Abbrechen",
      "newCampaign": "Neue Aktion"
    },
    "en": {
      "cancel": "Cancel",
      "newCampaign": "New campaign"
    }
  }
</i18n>