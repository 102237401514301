<template>
  <v-hover v-slot="{ hover }">
    <v-card elevation="0"
            tile
            v-bind:color="color"
            v-bind:to="to">
      <v-card-text class="text-center">
        <v-icon color="white"
                large
                left>
          {{ icon }}
        </v-icon>
        <p class="mb-0 mt-2 text-h6 font-weight-bold white--text">{{ title }}</p>
      </v-card-text>
    </v-card>
  </v-hover>
</template>

<script>
  export default {
    name: 'IconButtonCard',
    props: {
      icon: String,
      title: String,
      to: Object,
      color: {
        type: String,
        default: 'white'
      }
    }
  }
</script>