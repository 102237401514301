<template>
  <v-form ref="form">
    <v-text-field class="mb-6"
                  filled
                  hide-details="auto"
                  v-bind:label="$t('title')"
                  v-bind:rules="[ rules.required ]"
                  v-model="campaign.title" />
    <v-textarea class="mb-6"
                filled
                hide-details="auto"
                v-bind:label="$t('description')"
                v-model="campaign.description" />
    <v-textarea class="mb-6"
                filled
                hide-details="auto"
                v-bind:disabled="restrictedEditing"
                v-bind:label="$t('greeting')"
                v-model="campaign.greeting" />
    <date-field class="mb-6"
                prepend-inner-icon="mdi-calendar-start"
                v-bind:disabled="restrictedEditing"
                v-bind:label="$t('start')"
                v-bind:rules="[ rules.required ]"
                v-model="campaign.startDate" />
    <date-field class="mb-6"
                clearable
                prepend-inner-icon="mdi-bell-outline"
                v-bind:disabled="restrictedEditing"
                v-bind:label="$t('reminder')"
                v-model="campaign.reminderDate" />
    <date-field class="mb-6"
                prepend-inner-icon="mdi-calendar-end"
                v-bind:disabled="restrictedEditing"
                v-bind:label="$t('end')"
                v-bind:rules="[ rules.required ]"
                v-model="campaign.endDate" />
    <v-checkbox class="mb-6"
                hide-details="auto"
                v-bind:label="$t('sendConfirmationEmail')"
                v-model="campaign.sendConfirmationEmail" />
    <v-text-field filled
                  hide-details="auto"
                  v-bind:label="$t('email')"
                  v-bind:rules="[ rules.required ]"
                  v-if="campaign.sendConfirmationEmail"
                  v-model="campaign.confirmationEmail" />
    <v-checkbox v-bind:label="$t('allowComment')"
                v-model="campaign.allowComment" />
  </v-form>
</template>

<script>
  import { mapGetters } from 'vuex'

  import DateField from '@/components/common/DateField.vue'

  export default {
    components: {
      DateField
    },
    computed: {
      groupItems() {
        return this.siteGroups.map(s => ({
          text: s,
          value: s
        }))
      },
      regionItems() {
        return this.siteRegions.map(s => ({
          text: s,
          value: s
        }))
      },
      rules() {
        return {
          required: v => !!v || this.$t('global.pleaseFillOutThisField')
        }
      },
      ...mapGetters({
        siteGroups: 'common/siteGroups',
        siteRegions: 'common/siteRegions',
        sites: 'common/sites'
      })
    },
    methods: {
      validate() {
        return this.$refs.form.validate()
      }
    },
    name: 'CampaignDetails',
    props: {
      campaign: Object,
      restrictedEditing: Boolean
    }
  }
</script>

<i18n>
  {
    "de": {
      "allowComment": "Freitext-Kommentar zulassen",
      "description": "Beschreibung",
      "end": "Ende",
      "greeting": "E-Mail-Einleitung",
      "reminder": "Erinnerung",
      "start": "Start",
      "title": "Titel",
      "sendConfirmationEmail": "E-Mail-Bestätigung senden",
      "email": "E-Mail-Adresse"
    },
    "en": {
      "allowComment": "Allow comment",
      "description": "Description",
      "end": "End",
      "greeting": "Greeting",
      "reminder": "Reminder",
      "start": "Start",
      "title": "Title",
      "sendConfirmationEmail": "Send confirmation email",
      "email": "Email"
    }
  }
</i18n>