import Inventories from '../views/inventory-management/Inventories.vue'
import Inventory from '../views/inventory-management/Inventory.vue'
import InventoryAdd from '../views/inventory-management/InventoryAdd.vue'
import PrimaryNavigation from '../views/navigation/PrimaryNavigation.vue'
import InventoryReceiptAdd from '../views/inventory-management/InventoryReceiptAdd.vue'
import InventoryReceipt from '../views/inventory-management/InventoryReceipt.vue'
import InventoryPositionAdd from '../views/inventory-management/InventoryPositionAdd.vue'
import InventoryPosition from '../views/inventory-management/InventoryPosition.vue'
import InventoryManagementAppNavigation from '../views/inventory-management/navigation/AppInventoryManagementNavigation.vue'
import InventoryTransactions from '../views/inventory-management/InventoryTransactions.vue'
import InventoryTransaction from '../views/inventory-management/InventoryTransaction.vue'
import InventoryResults from '../views/inventory-management/InventoryResults.vue'


import AppInventories from '../views/inventory-management/AppInventories.vue'
import AppInventory from '../views/inventory-management/AppInventory.vue'
import AppInventoryFilledStock from '../views/inventory-management/AppInventoryFilledStock.vue'
import AppInventoryEmpties from '../views/inventory-management/AppInventoryEmpties.vue'
import AppInventoryTransactions from '../views/inventory-management/AppInventoryTransactions.vue'
import AppInventoryTransaction from '../views/inventory-management/AppInventoryTransaction.vue'


import AppInventoryReceipt from '../views/inventory-management/AppInventoryReceipt.vue'
import AppUser from '../views/inventory-management/AppUser.vue'


export default [
  {
    components: {
      default: InventoryAdd,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-inventory-add',
    path: '/inventory-management/inventories/add'
  },
  {
    components: {
      default: Inventories,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-inventories',
    path: '/inventory-management/inventories'
  },
  {
    components: {
      default: Inventory,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-inventory',
    path: '/inventory-management/inventories/:id'
  },
  {
    components: {
      default: InventoryReceiptAdd,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-inventory-receipt-add',
    path: '/inventory-management/inventories/:inventoryId/receipts/add'
  },
  {
    components: {
      default: InventoryReceipt,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-inventory-receipt',
    path: '/inventory-management/inventories/:inventoryId/receipts/:id'
  },
  {
    components: {
      default: InventoryPositionAdd,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-inventory-position-add',
    path: '/inventory-management/inventories/:inventoryId/receipts/:inventoryReceiptId/positions/add'
  },
  {
    components: {
      default: InventoryPosition,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-inventory-position',
    path: '/inventory-management/inventories/:inventoryId/receipts/:inventoryReceiptId/positions/:id'
  },
  {
    components: {
      default: InventoryTransactions,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-inventory-transactions',
    path: '/inventory-management/inventories/:inventoryId/receipts/:inventoryReceiptId/transactions'
  },
  {
    components: {
      default: InventoryTransaction,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-inventory-transaction',
    path: '/inventory-management/inventories/:inventoryId/receipts/:inventoryReceiptId/transactions/:id'
  },
  {
    components: {
      default: InventoryResults,
      navigation: PrimaryNavigation
    },
    meta: {
      auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-inventory-results',
    path: '/inventory-management/inventories/:inventoryId/receipts/:inventoryReceiptId/results'
  },


  // App
  {
    components: {
      default: AppUser,
      navigation: InventoryManagementAppNavigation
    },
    meta: {
      isRestricted: true
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-app-user',
    path: '/inventory-management-app/user'
  },
  {
    components: {
      default: AppInventories,
      navigation: InventoryManagementAppNavigation
    },
    meta: {
      isRestricted: true
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-app-inventories',
    path: '/inventory-management-app/inventories'
  },
  {
    components: {
      default: AppInventory,
      navigation: InventoryManagementAppNavigation
    },
    meta: {
      isRestricted: true
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-app-inventory',
    path: '/inventory-management-app/inventories/:id'
  },
  {
    components: {
      default: AppInventoryReceipt,
      navigation: InventoryManagementAppNavigation
    },
    meta: {
      isRestricted: true
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-app-inventory-receipt',
    path: '/inventory-management-app/inventories/:inventoryId/receipt/:id'
  },
  {
    components: {
      default: AppInventoryFilledStock,
      navigation: InventoryManagementAppNavigation
    },
    meta: {
      isRestricted: true
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-app-inventory-filled-stock',
    path: '/inventory-management-app/inventories/:inventoryId/receipt/:id/filled-stock'
  },
  {
    components: {
      default: AppInventoryEmpties,
      navigation: InventoryManagementAppNavigation
    },
    meta: {
      isRestricted: true
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-app-inventory-empties',
    path: '/inventory-management-app/inventories/:inventoryId/receipt/:id/emtpies'
  },
  {
    components: {
      default: AppInventoryTransactions,
      navigation: InventoryManagementAppNavigation
    },
    meta: {
      isRestricted: true
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-app-inventory-transactions',
    path: '/inventory-management-app/inventories/:inventoryId/receipt/:inventoryReceiptId/transactions'
  },
  {
    components: {
      default: AppInventoryTransaction,
      navigation: InventoryManagementAppNavigation
    },
    meta: {
      isRestricted: true
      //auth: ({ hasRole, isAuthenticated }) => isAuthenticated && hasRole('SagasserPortalAdministrator')
    },
    name: 'inventory-management-app-inventory-transaction',
    path: '/inventory-management-app/inventories/:inventoryId/receipt/:inventoryReceiptId/transactions/:id'
  },
]